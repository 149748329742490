import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  root: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    fontSize: '15px',
    height: '50px',
    backgroundColor: '#0c93d0',
    color: '#ffffff',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: '#067fb5',
      color: 'white'
    }
  },
});

const AWSLoginButton = ({ classes, className, title, onClick, disabled }) => {
  return (
    <Button
      className={`${classes.root} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default withStyles(styles)(AWSLoginButton);
