import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import { TextField } from "@material-ui/core";
import moment from 'moment';

// S3 관련
import { API, Storage } from "aws-amplify";

import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';
import SVGIcon from './SVGIcon'

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(7),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  title: {
    flexGrow: 1,
    fontSize : 17,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '40px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  button: {
    width: 100,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button2: {
    width: 100,
  },
  dialogButton: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  typoSubmit: {
    fontSize: 17
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  card2: {
    width: 100,
    marginTop: 5,
    marginBottom: 15,
    boxShadow: "none",
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 50,
  },
  listItemIcon: {
    minWidth: 0
  },
  typoTitle: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  textTypo : {
    fontSize: 16,
  },
  textField : {
    width: '100%',
    fontSize: 17,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '20px',
    paddingRight: '24px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
  },
  textFieldSize: {
    fontSize: 17,
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class OrderPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // 제작자 정보
      applicantUser: null,
      uploadUserId: "",
      applicantUserId: "",
      applicantCustomerType: "",
      applicantCompanyName: "",
      applicantCompanyNumber: '',
      applicantCompanyPhoneNumber: '',
      applicantAddress_1: '',
      applicantAddress: [],
      applicantName: '',
      applicantPhoneNumber: '',
      // application
      applicationUUId: '',
      applicationTitle: '', 
      dateStart: '', 
      dateEnd: '',
      applicationToggle: '',
      applicationInvoice: '', 
      applicationContent: '', 
      orderComment: '', 
      orderSign: '', 
      applicationSign: '',
      //--------------------
      fcmTokenIds: [],
      alarmDialogOpen_1: false,
      isLoading: false,
      completed : 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    // 제작자 정보 받아오기
    try {
      const applicantUser = await this.getApplicantUser();

      const { userId, customerType, companyName, companyNumber, companyPhoneNumber, 
              address, name, phoneNumber } = applicantUser;

      this.setState({
        applicantUser,
        applicantUserId: userId,
        applicantCustomerType: customerType,
        applicantCompanyName: companyName,
        applicantCompanyNumber: companyNumber, 
        applicantCompanyPhoneNumber: companyPhoneNumber,
        applicantAddress: address,
        applicantName: name, 
        applicantPhoneNumber: phoneNumber
      });
      this.setState({
        applicantAddress_1: this.state.applicantAddress[0],
      })
    } catch (e) {
      // alert(e);
      console.log("getApplicantUser() 에러");
    }

    // application 정보
    try {
      const application = await this.getApplication();

      const { applicationUUId, uploadUserId, applicationTitle, dateStart, dateEnd, applicationToggle, applicationInvoice, 
              applicationContent, orderComment, orderSign, applicationSign } = application;

      this.setState({applicationUUId, uploadUserId, applicationTitle, dateStart, dateEnd, applicationToggle, applicationInvoice,
                    applicationContent, orderComment, orderSign, applicationSign});
    } catch (e) {
      console.log("getApplication() 에러");
    }

    if(this.state.orderComment === "default") {
      this.setState({ orderComment: '' });
    }
    
    // orderSing은 리로드시등 무조건 다시 작성하게끔
    this.setState({ orderSign: '' });

    //------------------------------------------------------------------------------------
    // FCM으로 발송할 데이터를 미리 받아서 fcmTokenId를 배열로 저장.
    // 나중에 발송시 FCM으로 fcmTokenId 배열로 보내면 최대 1,000명한테 한번에 발송 가능
    try {
      await this.getNotifications();
      // fcmTokenIds를 배열로 받기
      if(this.state.notifications) { 
        let fcmTokenId
        Object.keys(this.state.notifications).map(notificationUUId => {
          const notification = this.state.notifications[notificationUUId];
            fcmTokenId = notification.fcmTokenId
            this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
        });
      }
    } catch(e) {
      console.log(e)
    }
    //------------------------------------------------------------------------------------
  }

  // 제작자 정보
  getApplicantUser() {
    const applicantUserId = sessionStorage.getItem('applicantUserId')
    return API.get("upload", `/user/${applicantUserId}`);
  }

  getApplication() {
    return API.get("upload", `/application/${this.props.match.params.id}`)
  }

  putApplication(applyingContent) {
    return API.put("upload", `/application/${this.state.applicationUUId}`, {
      body: applyingContent
    });
  }

  //------------------------------------------------------------------------
  // 지원 버튼 클릭시 알림 발송
  //------------------------------------------------------------------------
  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }
  
  getNotifications() {
    return API.get("upload", `/notification/?userId=${this.state.applicantUserId}`)
    .then(notifications => this.setState({notifications : notifications}));
  }
  //------------------------------------------------------------------------

  handleOrderSubmit = async event => {
    event.preventDefault();
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    // 작성 안한 파트 작성하게끔 해주는 코드
    if (this.state.orderSign != "발주확인") {
      this.setState({alarmDialogOpen_1: true});
      return;
    }

    if(this.state.orderComment === '') {
      this.state.orderComment = "default"
    }

    if(this.state.uploadUserId === currentAuthUserFromSession)
    {
      if(this.state.applicationToggle === '0')
      {
        try {
          await this.putApplication({
            applicationToggle: '1',
            applicationInvoice: this.state.applicationInvoice,
            applicationContent: this.state.applicationContent,
            orderComment: this.state.orderComment,
            orderSign: this.state.orderSign,
            applicationSign: 'default'
          });
        } catch (e) {
          // alert(e);
          console.log(e);
        }

        try {
          if(this.state.fcmTokenIds.length > 0) {
            await this.postNotificationFCM({
              registration_ids: this.state.fcmTokenIds,
              title: '발주를 받았습니다.',
              body: '제목 : ' + this.state.applicationTitle,
            });
          }
        } catch(e) {
          console.log(e)
        }

        // this.props.history.push("/");
        window.location.reload();
      }
      // this.props.history.push("/");
    } else
    {
      console.log("application is not matched");
    }
  }

  handleOrderCancel = async event => {
    event.preventDefault();
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    // 작성 안한 파트 작성하게끔 해주는 코드
    if (this.state.orderSign != "발주취소") {
      this.setState({alarmDialogOpen_1: true});
      return;
    }

    if(this.state.uploadUserId === currentAuthUserFromSession)
    {
      if(this.state.applicationToggle === '1')
      {
        try {
          await this.putApplication({
            applicationToggle: '0',
            applicationInvoice: this.state.applicationInvoice,
            applicationContent: this.state.applicationContent,
            orderComment: 'default',
            orderSign: 'default',
            applicationSign: 'default'
          });
        } catch (e) {
          // alert(e);
          console.log(e);
        }

        try {
          if(this.state.fcmTokenIds.length > 0) {
            await this.postNotificationFCM({
              registration_ids: this.state.fcmTokenIds,
              title: '발주를 취소하였습니다.',
              body: '제목 : ' + this.state.applicationTitle,
            });
          }
        } catch(e) {
          console.log(e)
        }

        // this.props.history.push("/");
        window.location.reload();
      }
      // this.props.history.push("/");
    } else
    {
      console.log("application is not matched");
    }
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
    })
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    발주 페이지
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          {currentAuthUserFromSession === this.state.uploadUserId
          ?
          <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    {this.state.applicantCustomerType === 'company'
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>외주 업체</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicantCompanyName}</Typography>     
                        </ListItem>
                      </Grid>
                      <Divider/>
                      {/*
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>사업자 등록번호</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicantCompanyNumber}</Typography>
                        </ListItem>
                      </Grid>
                      */}
                    </Grid>
                    :
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>외주 프리랜서</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicantName}</Typography>     
                        </ListItem>
                      </Grid>
                      <Divider/>
                      {/*
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>전화 번호</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicantPhoneNumber}</Typography>
                        </ListItem>
                      </Grid>
                    */}
                    </Grid>
                    }
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>프로젝트명</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicationTitle}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>계약기간</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>
                            {moment(this.state.dateStart).format("YYYY-MM-DD")} ~ {moment(this.state.dateEnd).format("YYYY-MM-DD")}
                          </Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    {/*
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>주소</Typography>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicantAddress_1}</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    */}
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>견적금액</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicationInvoice}</Typography>
                        </ListItem>
                      </Grid>
                      {/*
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>발주금액</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicationInvoice}</Typography>
                        </ListItem>
                      </Grid>
                      */}
                    </Grid>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>견적내용</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.applicationContent}</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>은행명</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>하나은행(오투공)</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>계좌번호</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>606-910079-55807</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    {/*
                    <ListItem className={classes.listItem}>
                    </ListItem>
                    <Divider/>
                    */}
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>비고</Typography>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          {this.state.applicationToggle === '0'
                          ?
                          <TextField
                            className={classes.textField}
                            id="orderComment"
                            name="orderComment"
                            autoFocus
                            value={this.state.orderComment}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          :
                          <Typography className={classes.textTypo}>{this.state.orderComment}</Typography>
                          }
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    {this.state.applicationToggle === '0'
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>서명(*) - '발주확인' 이라고 작성해주세요</Typography>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="orderSign"
                            name="orderSign"
                            autoFocus
                            value={this.state.orderSign}
                            onChange={this.handleValueChange}
                            placeholder="발주확인"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    this.state.applicationToggle === '1'
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>서명(*) - '발주취소' 라고 작성해주세요</Typography>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="orderSign"
                            name="orderSign"
                            autoFocus
                            value={this.state.orderSign}
                            onChange={this.handleValueChange}
                            placeholder="발주취소"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>서명(*)</Typography>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>발주완료</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    }
                    <Divider/>
                  </List>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card2}>
                  {this.state.applicationToggle === '0'
                  ?
                  <Button variant="contained" className={classes.button}
                    onClick={this.handleOrderSubmit}
                  >
                    <Typography className={classes.typoSubmit}>발주하기</Typography>
                  </Button>
                  :
                  this.state.applicationToggle === '1'
                  ?
                  <Button variant="contained" color="primary"  className={classes.button2}
                    onClick={this.handleOrderCancel}
                  >
                    <Typography className={classes.typoSubmit}>발주취소</Typography>
                  </Button>
                  :
                  <Button variant="contained" color="secondary" className={classes.button2} >
                    <Typography className={classes.typoSubmit}>발주완료</Typography>
                  </Button>
                  }
                </Card>
              </Grid>
            </Grid>
          </main>
          :
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          }
        </div>
        <Dialog open={this.state.alarmDialogOpen_1}>
          <DialogTitle>서명 해주세요</DialogTitle>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10} align="center">
                <Button variant="contained" className={classes.dialogButton} 
                  onClick={this.handleClose}>확인
                </Button>
              </Grid>
            </Grid>  
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

OrderPage = withStyles(styles, {withTheme: true})(OrderPage)
export default connect(mapStateToProps)(OrderPage);