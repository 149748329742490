import React, { Component } from 'react'
// import { Router, Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AppShellBox from './components/AppShellBox';

// 라우터 관련
// 'react-redux'라이브러리에서 connect 변수 import. 
// 단 여기서 connect 변수는 export 시에 default로 하지 않았기 때문에(export default connect가 아니기 때문에) -> { } 가 붙는다
import { connect } from 'react-redux'

//import Detail from './Detail';
// import KlaytnAuthPage from 'pages/KlaytnAuthPage' // 클레이튼 로그인 페이지 ( 현재는 사용안함. 나중에 참조하기)
import AWSAuthPage from 'pages/AWSAuthPage' // AWS 로그인 페이지
//import FeedPage from 'pages/FeedPage'
//import Footer from 'components/Footer'
//import Modal from 'components/Modal'
//import Toast from 'components/Toast'

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";
// AWS config
import config from "./config";

//firebase모듈
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// Firebase config
import firebaseConfig from "./firebaseConfig";

const styles = theme => ({
  app: {
    position: 'relative',
    width: '100%',
    height: '1px',
    minHeight: '100%',
    paddingBottom: '104px',
  },
});

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAuthenticating: true,  // 세션을 위한거 
    };

    /*
     * 1. redux store의 state 생성, `isLoggedIn`
     * cf) sessionStorage는 브라우저 탭이 닫히기 전까지 store data를 유지하는 인터넷 브라우저의 상태이다
     */
    //const walletFromSession = sessionStorage.getItem('walletInstance')
    //const { integrateWallet, removeWallet } = this.props

    // 세션스토리지에 walletInstance가 저장이 되어 있으면
    //if (walletFromSession) {
      //try {
        /**
         * 2-1. Integrate wallet
         * 만약 'walletInstance' 값이 존재하면,
         * intergrateWalle5bt 함수는 wallet instance를 caver의 wallet 및 redux store에 더한다
         * cf) redux/actions/auth.js -> integrateWallet()
         */
        //integrateWallet(JSON.parse(walletFromSession).privateKey)
      //} catch (e) {
        /**
         * 2-2. Remove wallet
         * 만약 sessionStorage안에 있는 값이 유효하지 않은 wallet instance 라면
         * removeWallet 함수는 caver's wallet 와 redux store 로 부터 wallet instance를 제거한다 
         * cf) redux/actions/auth.js -> removeWallet()
         */
        //removeWallet()
      //}
    //}
  }

  /*
  // 구독 관련. FCM 사용 하기 때문에 사용 안함 
  urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  updateBtn() {
    // const { isSubscribe } = this.props
    // 알림 권한 거부 처리 (브라우저 상에서 알림 권한을 막으면 나오게끔 하는거)
    if (Notification.permission === 'denied') {
      pushButton.textContent = 'Push Messaging Blocked.';
      pushButton.disabled = true;
      //this.updateSubscriptionOnServer(null);
      return;
    }
  
    /
    if (isSubscribe) {
      pushButton.textContent = 'Disable Push Messaging';
    } else {
      pushButton.textContent = 'Enable Push Messaging';
    }
    /
  
    pushButton.disabled = false;
  }

  updateSubscriptionOnServer(subscription) {
    // TODO: Send subscription to application server
  
    const subscriptionJson = document.querySelector('.js-subscription-json');
    const subscriptionDetails =
      document.querySelector('.js-subscription-details');
  
    if (subscription) {
      subscriptionJson.textContent = JSON.stringify(subscription);
      subscriptionDetails.classList.remove('is-invisible');
    } else {
      subscriptionDetails.classList.add('is-invisible');
    }
  }

  subscribeUser() {
    // firebase console에서 VapidKey를 할당 받아 적는다.
    const applicationServerPublicKey = 'BIFSArc-_Q0sCdP0IrDW6esIrbDCsGy_33P-bgpHHObRR_nuO_h5K8ZfsyMGM7IwOxudBGBAZCddfup36uziKN4';
    const applicationServerKey = this.urlB64ToUint8Array(applicationServerPublicKey);
    const { isSwRegistration /, subscribeTrue/ } = this.props
    isSwRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey
    })
    .then(function(subscription) {
      console.log('User is subscribed.');
  
      //this.updateSubscriptionOnServer(subscription);
  
      // subscribeTrue();
      sessionStorage.setItem("subscribe", true);
      console.log('subscribe :' + sessionStorage.getItem("subscribe"));

      // this.updateBtn();
    })
    .catch(function(err) {
      console.log('Failed to subscribe the user: ', err);
      // this.updateBtn();
    });
  }

  unsubscribeUser() {
    const { isSwRegistration /, subscribeFalse/ } = this.props
    isSwRegistration.pushManager.getSubscription()
    .then(function(subscription) {
      if (subscription) {
        return subscription.unsubscribe();
      }
    })
    .catch(function(error) {
      console.log('Error unsubscribing', error);
    })
    .then(function() {
      //this.updateSubscriptionOnServer(null);
  
      console.log('User is unsubscribed.');
      // subscribeFalse();
      sessionStorage.setItem("subscribe", false);
      console.log('subscribe :' + sessionStorage.getItem("subscribe"));
  
      // this.updateBtn();
    });
  }

  initializeUI() {
    const { isSwRegistration /, isSubscribe, subscribeTrue, subscribeFalse/ } = this.props

    /
    const pushButton = document.getElementById('subscribe')
    pushButton.addEventListener('click', function() {
      pushButton.disabled = true;
      if (isSubscribe) {
        this.unsubscribeUser();
      } else {
        this.subscribeUser();
      }
    });
    /
   
    // Set the initial subscription value
    isSwRegistration.pushManager.getSubscription()
    .then(function(subscription) {
      // this.state.isSubscribed = !(subscription === null);
      if(subscription === null) {
        // subscribeFalse();
        // console.log('isSubscribe :' + isSubscribe);
        sessionStorage.setItem("subscribe", false);
        console.log('subscribe :' + sessionStorage.getItem("subscribe"));
        console.log('User is NOT subscribed :' + subscription);
      } else {
        // subscribeTrue();
        sessionStorage.setItem("subscribe", true);
        console.log('subscribe :' + sessionStorage.getItem("subscribe"));
        console.log('User IS subscribed :' + subscription);
      }
  
      // this.updateSubscriptionOnServer(subscription);
  
      // this.updateBtn();
    });
  }
  */

  // 나중에 FCM 사용시 해제 하기
  componentWillMount() {

    try{
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    } catch(e) {
      //console.log(e);
    }

    /*
    //--------------------------------------
    // FCM서버와 앱을 연결하는 단계
    //--------------------------------------
    // firebase 관련 실행
    try{
      if(firebase.messaging.isSupported()) {
        firebase.initializeApp(firebaseConfig);
        // firebase모듈을 이용해서 messaging이라는 변수를 새로 만듬
        // 이렇게 생성된 변수는 사실 permission을 위한 모든 함수를 가지고 있다.
        const messaging = firebase.messaging();
        // firebase console에서 VapidKey를 할당 받아 적는다.
        messaging.usePublicVapidKey('BIFSArc-_Q0sCdP0IrDW6esIrbDCsGy_33P-bgpHHObRR_nuO_h5K8ZfsyMGM7IwOxudBGBAZCddfup36uziKN4');
  
        // 허가를 요청
        messaging.requestPermission()
        .then(function() {
          //console.log('허가!');
          return messaging.getToken(); //토큰을 받는 함수를 추가!
        })
        .then(function(token) {
          //console.log('token :' + token); //토큰을 출력!
          sessionStorage.setItem("currentFCMToken", token);
          // const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
          // console.log('currentFCMTokenFromSession :' + currentFCMTokenFromSession); //토큰을 출력!
        })
        .catch(function(err) {
          //console.log('fcm에러 :', err);
        })

        // 원래 발급받은 토큰이 사라지고 새롭게 생성되었을 때 자동으로 그 토큰을 리턴하게 되어있는 함수
        messaging.onTokenRefresh(function() {
        	messaging.getToken()
        	.then(function(refreshedToken) {
            //console.log('refreshed token :' + refreshedToken);
            sessionStorage.setItem("currentFCMToken", refreshedToken);
            // const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
            // console.log('refreshCurrentFCMTokenFromSession :' + currentFCMTokenFromSession); //토큰을 출력!
        	})
        	.catch(function(err) {
        		//console.log('Unable to retrieve refreshed token ', err);
        	});
        });
        //--------------------------------------

        //--------------------------------------
        // 현재 '일감'을 사용하고 있는 상태 이면 
        // 알림이 올 경우 이러이러한 작업을 실행하라! 라는 단계
        // 굳이 사용중에 알림 필요 없을듯 하다.
        //--------------------------------------
        messaging.onMessage(function(payload){
          //console.log(payload.data.title);  // title
          //console.log(payload.data.body);   // body

          /
          // '일감' 이용중 알림 창 띄우게끔 하려면 아래의 주석을 풀고 코딩하면 됨.
          const title = payload.notification.title;
          const options = {
            body: payload.notification.body,
            // icon: payload.notification.icon
          };
          //

          // 알림을 받으면 어떻게 할지 만들면 됨 
          // 아마 Dialog 사용해서 창 띄워주면 될 거 같음      
          /
        })
        //--------------------------------------
      }
    } catch(e) {
      //console.log(e)
    }
    */

    /*
    // Analytics
    try{
      firebase.analytics();
    } catch(e) {
      //console.log(e);
    }
    */
  }
  
  // AWS 세션 기능
  async componentDidMount() {
    const { userHasAuthenticatedTrue, swRegistration /* , currentAuthUser */ } = this.props
    try {
      await Auth.currentSession();
      userHasAuthenticatedTrue();
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  
    this.setState({ isAuthenticating: false });

    /*
    // Facebook 로그인관련 JS SDK 로드
    this.loadFacebookSDK();
    */

    // Google 로그인관련 JS SDK 로드
    const ga = window.gapi && window.gapi.auth2 ? 
      window.gapi.auth2.getAuthInstance() : 
      null;
    if (!ga) this.loadGoogleSDK();

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: " + userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: " + userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        /*
        // 나중에 삭제하기
        const loginType = localStorage.getItem("loginType");
        if(loginType === "facebook" || loginType === "google") {
          sessionStorage.setItem("currentUser_Freelancer", userID6);
        }
        if(loginType === "email") {
          sessionStorage.setItem("currentUser_Company", userID6);
        }
        */
        
        // currentAuthUser(currentAuthUserFromSession);
        const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        /*
        const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
        console.log('currentUser_Freelancer: ' + currentAuthUserFromSession_Freelancer);
        const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
        console.log('currentUser_Company: ' + currentAuthUserFromSession_Company);
        console.log('loginType: ' + loginType);
        */

        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        /*
        const loginType = localStorage.getItem("loginType");
        if(loginType === "facebook" || loginType === "google") {
          sessionStorage.setItem("currentUser_Freelancer", userID6);
        }
        if(loginType === "email") {
          sessionStorage.setItem("currentUser_Company", userID6);
        }
        */

        /*
        // currentAuthUser(currentAuthUserFromSession);
        const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
        console.log('currentUser_Company: ' + currentAuthUserFromSession_Company);
        const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
        console.log('currentUser_Freelancer: ' + currentAuthUserFromSession_Freelancer);
        console.log('loginType: ' + loginType);
        */
        
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          // alert(e);
          //console.log(e)
        }
    }

    this.setState({ isAuthenticating: false });

    /*
    try{
      // 서비스워커 등록
      if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
          navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function(registration) {
            // Registration was successful
            //console.log('ServiceWorker registration successful with scope: ', registration.scope);
          }, function(err) {
            // registration failed :(
            //console.log('ServiceWorker registration failed: ', err);
          });
        });
      }
    } catch (e){
      //console.log(e)
    }
    */

    /*
    // 서비스워커 등록 (push manager 들어가 있는 버전. FCM 사용 하기 때문에 push manager 필요 없을듯)
    if ('serviceWorker' in navigator  /&& 'PushManager' in window/) {
      navigator.serviceWorker.register('./firebase-messaging-sw.js').then(swReg => {
        console.log('Service Worker Registered' + swReg);
        swRegistration(swReg);

        // Push 기능 초기화 (pushManager 사용시 주석 풀기)
        // this.initializeUI();

        swReg.addEventListener('updatefound', () => {
          const newWorker = swReg.installing;
          console.log('Service Worker update found!');

          newWorker.addEventListener('statechange', function () {
            console.log('Service Worker state changed:', this.state);
          });
        });
      })
      .catch(function(error) {
        console.error('Service Worker Error', error);
      });

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('Controller changed');
      });
    } else {
      console.warn('Push messaging is not supported');
      pushButton.textContent = 'Push Not Supported';
    }
    */
  }

  /*
  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : config.social.FB,
        cookie           : true,
        status           : true,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.1'
      });
    };
  
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }
  */

  loadGoogleSDK() {
    // load the Google SDK
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.async = true;
    script.onload = this.initGapi;
    document.body.appendChild(script);
  }

  initGapi() {
    // init the Google SDK client
    const g = window.gapi;
    g.load('auth2', function() {
        g.auth2.init({
            client_id: config.social.GL,
            // authorized scopes
            scope: 'profile email openid'
        });
    });
  }

  /**
   * 3. Render the page
   * walletInstance가 session storage에 존재 하던지에 따라서 
   * Redux는 isLoggedIn state가 true인지 false인지를 초기화 할 것이다
   */
  render() {
    const { isKlaytnLoggedIn, isAuthenticated, classes /* isAwsLoggedIn */ } = this.props
    return (
      !this.state.isAuthenticating &&
      <div className={classes.app}>
        {/*<Modal />*/}
        {/*<Toast />*/}
        {/* {isKlaytnLoggedIn ? <AppShellBox /> : <KlaytnAuthPage />} */} {/* 처음 실행시 클레이튼 로그인 페이지가 나옴 */}
        {/* {isAuthenticated ? <AppShellBox /> : <AWSAuthPage />}*/}  {/* 처음 실행시 AWS 로그인 페이지가 나옴 */}
        <AppShellBox /> {/* 처음 실행시 메인페이지가 나옴 */}
      </div>
      /*
      // KlayStagram에 있던거. 나중에 아래 주석 지우기
      <div className="App">
        <Modal />
        <Toast />
        {isLoggedIn && <Nav />}
        {isLoggedIn ? <FeedPage /> : <AuthPage />}
        <Footer />
      </div>
      */
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isKlaytnLoggedIn: state.auth.isKlaytnLoggedIn,
  isAuthenticated: state.auth.isAuthenticated,
  // isSwRegistration: state.auth.isSwRegistration,
  // isSubscribe: state.auth.isSubscribe,
  // currentUser: state.auth.currentUser,
  // isAwsLoggedIn: state.auth.isAwsLoggedIn,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  integrateWallet: (privateKey) => dispatch(authActions.integrateWallet(privateKey)),
  removeWallet: () => dispatch(authActions.removeWallet()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),

  // swRegistration는 서비스워커 관련. 나중에 지워도 될거 같음
  // swRegistration: (isSwRegistration) => dispatch(authActions.swRegistration(isSwRegistration)),
  // subscribeTrue: () => dispatch(authActions.subscribeTrue()),
  // subscribeFalse: () => dispatch(authActions.subscribeFalse()),
  // currentAuthUser: (currentUser) => dispatch(authActions.currentAuthUser(currentUser)),
  // awsCognitoOn: (email, password) => dispatch(authActions.awsCognitoOn(email, password)),
})


App = (withStyles(styles))(App);
export default connect(mapStateToProps, mapDispatchToProps)(App)
//export default withStyles(styles)(App)
//export default App;