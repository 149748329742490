import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { TextField } from "@material-ui/core";
import moment from 'moment';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { connect } from 'react-redux'

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

// S3 관련
import { API, Storage } from "aws-amplify";
import config from "../config";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  GridContainer: {
    minHeight: "10vh"
  },
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "20px",
    // maxWidth: 650,
    // minWidth : 500,
    [theme.breakpoints.up('xs')]: { // xs보다 up일때 동작
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0.5),
      marginRight : theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: { // xs 보다 down 일때 동작
      mmarginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0.5),
      marginRight : theme.spacing(0.5),
    },
  },
  media: {
    marginTop: 5,
    height: 50,
    width: 130,
    objectFit: 'contain'
  },
  typoName: {
    width: '100%',
    fontSize : 17,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20
  },
  typoContent: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20
  },
  typoDate: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20
  },
  typoAddress: {
    width: '100%',
    fontSize : 17,
    color: '#999',
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 20
  },
  avatar: {
    // backgroundColor: red[500],
    backgroundColor: '#D3D3D3',
    marginTop: 5,
    width: 50,
    height: 50,
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  MoreVertIconRoot: {
    position: 'relative',
  },
  starBorderIconButton: {
    padding: 0,
  },
  starIconButton: {
    padding: 0,
  },
  starBorderIcon: {
    width: 25,
    height: 25,
  },
  starIcon: {
    width: 25,
    height: 25,
    color: '#FFD700'  // gold
  },
  dialogTitle: {
    fontSize: 17
  },
  dialogContent: {
    fontSize: 17
  },
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldLabelSize:{
    fontSize:17,
  },
  textFieldInputSize:{
    fontSize:17,
  },
});

class Feed_HomePromotion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarAttachmentURL: null,
      avatarimage: "",
      customerType1: '',
      customerType2: '',
      customerName: '',
      name: '',
      address_3:'',
      adminConfirm: '',
      alarmDialogOpen_1: false,
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  /*
  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      deleteDialogOpen : false,
      submitDialogOpen : false,
      applyDialogOpen : false,
      connectDialogOpen: false,
      alarmDialogOpen_1: false,
    })
  }
  */

  async componentDidMount() {
    // AWS S3 사용시 (user 관련 파일)
    try {
      let avatarAttachmentURL;
      const avatarimage = this.props.avatarimage;

      if (avatarimage != 'No_File') {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
          avatarAttachmentURL = await Storage.get(avatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }

      //  console.log(this.props.avatarimage);
      
      this.setState({
        avatarAttachmentURL,
        avatarimage,
      });
      // console.log(this.state.attachmentURL);
    } catch (e) {
      // alert(e);
      console.log(e)
    }
   
    if(this.props.customerType2 === 'company') {
      this.setState({customerName: this.props.companyName})
    } else if(this.props.customerType2 === 'freelancer') {
      this.setState({customerName: this.props.name})
    }

    // 주소 정보 받아오기
    this.setState({
      address_2: this.props.address[2],
    })
  }

  render() {
    const { classes, theme, isAuthenticated } = this.props;

    return (
      <Grid container item xs={6} sm={6} md={3} lg={3} xl={3}>   {/* 한줄에 4개씩 할 때 사용 */}
      {/*<Grid item xs={12} sm={12} md={12} lg={8} xl={8} align="center">*/} {/* 세로로 정렬시 사용 */}
        {isAuthenticated
        ?
        <Card className={classes.card}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="flex-end">
            <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
              {this.props.customerType2 === 'company'
              ?
                <Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>
                  {this.state.avatarAttachmentURL
                  ?
                  <img
                    className={classes.media}
                    src={this.state.avatarAttachmentURL}
                  />
                  :
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  }
                </Link>
              :
                <Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>
                  {this.state.avatarAttachmentURL
                  ?
                  <Avatar src={this.state.avatarAttachmentURL} aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  :
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  }
                </Link>
              }
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                  <Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>
                    <Typography className={classes.typoName}>
                      {this.state.customerName}
                    </Typography>
                  </Link>
                :
                  <Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>
                    <Typography className={classes.typoName}>
                      {this.state.customerName}
                    </Typography>
                  </Link>
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                  <Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>
                    <Typography className={classes.typoContent}>
                      {/* 글자 길이가 37개 이상 넘어가면 '...' 나옴 */}
                      {this.props.history.slice(0,20)}
                      {this.props.history.length > 20 
                      ? '...'
                      : null
                      }
                    </Typography>
                  </Link>
                :
                <Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoContent}>
                    {/* 글자 길이가 37개 이상 넘어가면 '...' 나옴 */}
                    {this.props.history.slice(0,20)}
                    {this.props.history.length > 20 
                    ? '...'
                    : null
                    }
                  </Typography>
                </Link>
              }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                  <Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>
                    <Typography className={classes.typoAddress}>
                      {this.state.address_2}
                    </Typography>
                  </Link>
                :
                  <Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>
                    <Typography className={classes.typoAddress}>
                      {this.state.address_2}
                    </Typography>
                  </Link>
                }
              </Grid>
            </Grid>
          </Grid>
        </Card>
        :
        <Card className={classes.card}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="flex-end">
            <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
              {this.props.customerType2 === 'company'
              ?
                <Link component={RouterLink} to={`/login`} underline='none' color='inherit'>
                  {this.state.avatarAttachmentURL
                  ?
                  <img
                    className={classes.media}
                    src={this.state.avatarAttachmentURL}
                  />
                  :
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  }
                </Link>
              :
                <Link component={RouterLink} to={`/login`} underline='none' color='inherit'>
                  {this.state.avatarAttachmentURL
                  ?
                  <Avatar src={this.state.avatarAttachmentURL} aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  :
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  }
                </Link>
              }
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Link component={RouterLink} to={`/login`} underline='none' color='inherit'>
                  <Typography className={classes.typoName}>
                    {this.state.customerName}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Link component={RouterLink} to={`/login`} underline='none' color='inherit'>
                  <Typography className={classes.typoContent}>
                    {this.props.history}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Link component={RouterLink} to={`/login`} underline='none' color='inherit'>
                  <Typography className={classes.typoAddress}>
                     {this.state.address_2}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        }
      </Grid>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

Feed_HomePromotion = withStyles(styles, {withTheme: true})(Feed_HomePromotion)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_HomePromotion);