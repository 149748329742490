import React from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  inputFile: {
    position: 'relative',
    fontSize: 14,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 5,
    marginTop: 26,
    clear: 'both',
    '&--err': {
      borderColor: theme.palette.error.main,
    },
  },
  inputLabel: {
    position: 'absolute',
    top: -26,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  inputButton: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
    height: 62,
    fontWeight: 'bold',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  inputFileInput: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: 0,
  },
  inputFileName: {
    float: 'left',
    padding: '22px 24px',
    fontSize: 17,
    textAlign: 'left',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&--empty': {
      color: theme.palette.text.secondary,
    },
  },
  inputFileErr: {
    position: 'absolute',
    top: -22,
    right: 0,
    fontSize: 12,
    color: theme.palette.error.main,
  },
}))

const InputFile = ({
  className,
  name,
  value,
  label,
  fileName,
  onChange,
  required,
  accept,
  err,
}) => {
  const classes = useStyles()

  return (
    <div className={cx(classes.inputFile, className, { 'InputFile--err': err })}>
      <Typography className={classes.inputLabel}>{label}</Typography>
      <label className={classes.inputButton} htmlFor="upload">
        <CloudUploadIcon /> Search
      </label>
      <input
        className={classes.inputFileInput}
        id="upload"
        type="file"
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        accept={accept}
      />
      <Typography
        className={cx(classes.inputFileName, {
          'InputFile__fileName--empty': !fileName,
        })}
      >
        {fileName || 'No photo'}
      </Typography>
      {err && <Typography className={classes.inputFileErr}>{err}</Typography>}
    </div>
  )
}

export default InputFile
