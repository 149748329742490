import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    fontSize: '15px',
    height: '50px',
    backgroundColor: '#4267B2',
    color: "#ffffff",
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: '#2a4a8f',
      color: 'white'
    },
  }
});

const FaceBookLoginButton = ({ className, title, onClick, disabled }) => {
  const classes = useStyles();
  return (
    <button className={cx(classes.root, className)} onClick={onClick} disabled={disabled}>
      {title}
    </button>
  );
};

export default FaceBookLoginButton;
