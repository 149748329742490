// AWS 관련
export default {
    s3: {
      REGION: "ap-northeast-2",
      BUCKET: "mmschool-s3upload-dev-s3bucket-koz9q4l38val" // 멀티 서버리스 사용 시
    },
    apiGateway: {
      REGION: "ap-northeast-2",
      URL: "https://zuidq8pkse.execute-api.ap-northeast-2.amazonaws.com/dev" // 멀티 서버리스 사용 시
    },
    cognito: {
      REGION: "ap-northeast-2",
     
     // 멀티 서버리스 사용 시
      USER_POOL_ID: "ap-northeast-2_43yfwQm98",
      APP_CLIENT_ID: "7v5beatq2qhf46vtfj61dgjcss",
      IDENTITY_POOL_ID: "ap-northeast-2:a4018b02-c336-416e-956f-85d9760067b4"
    },
    social: {
      // FB: "YOUR_FACEBOOK_APP_ID"
      // FB: "614585956009549",
      // GL: "your_google_client_id"
      //GL: "236778584592-s76fc4g8o7vla3bdugug3g54g7amum2j.apps.googleusercontent.com",  // ilgamApp (web)
      GL: "228887467012-61ivkrnmapaa0gegb4bo3d00j0j295m4.apps.googleusercontent.com",  // ilgam-fcm (web) X
      KakaoAppId : "ce61547a1a74b09050781ea62903c3a5", // <Kakao Javascript AppKey> X
      //KakaoAppId : "c1277df47a2e09f29aa5ee6b98b467f8", // <Kakao Rest API AppKey>
      // Kakao_API_URL : "https://5kjhfrq2kk.execute-api.ap-northeast-2.amazonaws.com/dev/getKakaoAuth",
      // KakaoAppId : "4c39a1c69b0905a46ae95bdb2a656c1b", // <Kakao Javascript AppKey>
      // Kakao_API_URL : "https://kyz040usnb.execute-api.ap-northeast-2.amazonaws.com/sabuStage/getKakaoAuth",
      // Kakao_API_URL : "https://kyz040usnb.execute-api.ap-northeast-2.amazonaws.com/sabuStage/KakaoAuth",
      // Kakao_API_URL : "https://m8nen618o5.execute-api.ap-northeast-2.amazonaws.com/prod/KakaoAuth",
    },
    oauth: {
      domain: 'https://mingmenschool.auth.ap-northeast-2.amazoncognito.com', //'<your-cognito-domain>',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: 'https://mingmenschool.auth.ap-northeast-2.amazoncognito.com/oauth2/idresponse', //'<your-redirect-sign-in-url>',
      redirectSignOut: 'https://mingmenschool.auth.ap-northeast-2.amazoncognito.com/oauth2/idresponse', //'<your-redirect-sign-out-url>',
      responseType: 'code',
    },
    // MAX_ATTACHMENT_SIZE: 5000000, // 5MB
    MAX_ATTACHMENT_SIZE: 20000000, // 20MB
};