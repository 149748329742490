import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';


import { connect } from 'react-redux'

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// '좋아요' 관련 Icon
import Badge from '@material-ui/core/Badge';
import FavoriteIcon from '@material-ui/icons/Favorite';

// S3 관련
import { API, Storage } from "aws-amplify";

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

const styles = theme => ({
  favoriteAvatar: {
    backgroundColor: '#0000FF',
    margin: 5,
    width: 35,
    height: 35,
  },
  /*
  root: {
    width : '100%',
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  paper: {
    width : '100%',
    padding: theme.spacing(0),
    margin: 'auto',
    maxWidth: 500,
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  card: {
    maxWidth: 450,
    marginTop: 15,
    marginBottom: 15,
  },
  favoriteCardActions: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 10,

  },
  favoriteAvatar: {
    backgroundColor: '#0000FF',
    margin: 5,
    width: 35,
    height: 35,
  },
  favoriteIconButton: {
    padding : 10,
  },
  */
});

class UploadFeedFavoriteList extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      favorAvatarAttachURL: '',
      favorAvatarimage: '',
      completed : 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    
    // AWS S3 사용시 (user 관련 파일)
    try {
      let favorAvatarAttachURL;
      const favorAvatarimage = this.props.favorAvatarimage;

      if (favorAvatarimage) {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
        favorAvatarAttachURL = await Storage.get(favorAvatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }
      
      this.setState({
        favorAvatarAttachURL,
        favorAvatarimage,
      });
    } catch (e) {
      alert(e);
    }
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        <Link component={RouterLink} to={`/upload/FavorList/${this.props.favoriteListSabuId}`} underline='none' color='inherit'>
          <Avatar src={this.state.favorAvatarAttachURL} aria-label="Recipe" className={classes.favoriteAvatar}>
            {this.props.id}
          </Avatar>
        </Link>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  // isFavoriteLoading: state.upload.isFavoriteLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  // favoriteloadingtrue: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingtrue(isFavoriteLoading)),
  // favoriteloadingfalse: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingfalse(isFavoriteLoading)),
})

UploadFeedFavoriteList = withStyles(styles, {withTheme: true})(UploadFeedFavoriteList)
export default connect(mapStateToProps, mapDispatchToProps)(UploadFeedFavoriteList);