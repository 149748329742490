import React from 'react';
import { Router, Route, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import SVGIcon from './SVGIcon'
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';

// 라우터 관련
// 'react-redux'라이브러리에서 connect 변수 import. 
// 단 여기서 connect 변수는 export 시에 default로 하지 않았기 때문에(export default connect가 아니기 때문에) -> { } 가 붙는다
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

import Hammer from 'hammerjs';

// import { createBrowserHistory } from 'history';

//import './MainAppShell.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(3),
    overFlowX : "auto",
    display: 'flex',
  },
  menu: {
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    marginLeft: 18,
    marginRight: 18
  },
  TableHead: {
    fontSize: '1.0rem'
  },
  /*
  table: {
    minWidth : 1080
  },
  */
  progress : theme.spacing(2),
  menuButton: {
    marginRight: theme.spacing(5),
  },
  title: {
    flexGrow: 1,
    fontSize: 15,
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  login: {
    fontSize: 15,
  },
  searchBadge: {
    fontSize: 25,
  },
  notificationsIcon: {
    fontSize: 25,
    // color: '#0c93d0'
  },
  arrowBackIosIcon: {
    width: 23,
    height: 23
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    /*
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    */
  },
  searchIcon: {
    height: 10,
    width: 10,
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldBorder: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {  // 평소
      borderColor: "#0c93d0",
      borderWidth: 1,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {  // hover
      borderColor: "#0c93d0",
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {  // 글 작성시
      borderColor: "#0c93d0",
      borderWidth: 1,
    }
  },
  inputRoot: {
    fontSize: 15,
    width: '100%',
    color: 'inherit',
  },
  inputInput: {
    fontSize: 15,
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    /*
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    */
  },
  searchTypo: {
    fontSize: 15,
  },
  clearIcon: {
    height: 25,
    width: 25,
    paddingRight: 3,
  },
  AppShellText: {
    fontSize : 15,
    color: 'inherit',
    padding: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  toolBar: {
    height : 10,
  },
  appBar2: {
    marginTop: '47px',
    background: "#F5F5F5",
  },
  toolBar2: {
    height : 15,
  },
  indicator: {
    backgroundColor: '#0c93d0',
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  content: {
    marginTop: '17px',
    flexGrow: 1,
    padding: theme.spacing(0),
    background: "#F8F8F8",
  },
  // toolbar: theme.mixins.toolbar,
  toolbar: {
    minHeight: 48,
  },
  hideIndicator: {
    height: 0
  },
})

class MainAppShellField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          searchKeyword: '',
          isSearchToggle: false,  // 검색창 나오에 하기 위한 토글
          tabLinkValue: 0,
          currentTabLinkValue: 0, // localStorge를 위한 부가 state
          tabindicator: true,
          userId: '',
          currentPageTitle: '',
          currentPage: '',
        };
    }

    // 내가 Following한 사람들 리스트
    getFollowees() {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

      return API.get("upload", `/follow/?followerUserId=${currentAuthUserFromSession}`)
        .then(getFollowees => this.setState({getFollowees : getFollowees}));
    }

    // render() 실행 후 동작. 뒤로 back 할때 필요
    componentDidUpdate() {
      this.updatechange();
    }

    // render() 실행 전 동작. 새로 고침시 이 함수 먼저 실행 
    componentWillMount() {
      this.mountchange();
    }

    updatechange = () => {
      const currentPage = localStorage.getItem('currentPage')

      if(this.props.history.location.pathname === `/fieldform/${currentPage}/projects`) {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 0,
            tabindicator: true        
          });
        }
      }
      /*
      else if(this.props.history.location.pathname === `/fieldform/${currentPage}/educations`) {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 1,
            tabindicator: true
          });
        } 
      }
      */
      else if(this.props.history.location.pathname === `/fieldform/${currentPage}/companylists`) {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 1,
            tabindicator: true
          });
        } 
      }
      /*
      else if(this.props.history.location.pathname === `/fieldform/${currentPage}/companypromotions`) {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 2,
            tabindicator: true
          });
        }
      }
      else if(this.props.history.location.pathname === `/fieldform/${currentPage}/freelancerpromotions`) {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 3,
            tabindicator: true
          });
        }
      }
      */
    }

    mountchange = () => {
      const currentPage = localStorage.getItem('currentPage')

      if(this.props.history.location.pathname === `/fieldform/${currentPage}/projects`) {
        this.setState({
          tabLinkValue: 0,
          tabindicator: true        
        });
      }
      /*
      else if(this.props.history.location.pathname === `/fieldform/${currentPage}/educations`) {
        this.setState({
          tabLinkValue: 1,
          tabindicator: true
        }); 
      }
      */
      else if(this.props.history.location.pathname === `/fieldform/${currentPage}/companylists`) {
        this.setState({
          tabLinkValue: 1,
          tabindicator: true
        });
      }
      /*
      else if(this.props.history.location.pathname === `/fieldform/${currentPage}/companypromotions`) {
        this.setState({
          tabLinkValue: 2,
          tabindicator: true
        });
      }
      else if(this.props.history.location.pathname === `/fieldform/${currentPage}/freelancerpromotions`) {
        this.setState({
          tabLinkValue: 3,
          tabindicator: true
        });
      }
      */
    }

    // render() 실행 후 동작. 새로 고침시 render()함수 실행후 이 함수 실행
    async componentDidMount() {

      this.mountchange();

      const currentPage = localStorage.getItem('currentPage')
      const SearchKeyword = sessionStorage.getItem("searchKeyword");
      const isSessionSearchToggle = sessionStorage.getItem("isSearchToggle");
      // const { searchtoggletrue, searchtogglefalse } = this.props;
      
      if(isSessionSearchToggle === 'true') {
        this.setState({isSearchToggle: true})
        //searchtoggletrue();
      } else if(isSessionSearchToggle === 'false') {
        this.setState({isSearchToggle: false})
        //searchtogglefalse();
      }
     
      if(SearchKeyword) {
        this.setState({
          searchKeyword: SearchKeyword,
        })
      }
      
      if(currentPage === 'search') {
        this.setState({
          currentPageTitle: '검색 페이지',
          // searchKeyword: SearchKeyword,
        })
      }
      if(currentPage === '3dprinter') {
        this.setState({currentPageTitle: '3D 프린터'})
      }
      if(currentPage === 'iot') {
        this.setState({currentPageTitle: 'IoT'})
       }
      if(currentPage === 'smartfactory') {
        this.setState({currentPageTitle: '스마트 팩토리'})
      }
      if(currentPage === 'arvr') {
        this.setState({currentPageTitle: 'AR, VR'})
      }
      if(currentPage === 'autocad') {
        this.setState({currentPageTitle: '오토 캐드'})
      }
      if(currentPage === 'solidworks') {
        this.setState({currentPageTitle: '솔리드 웍스'})
      }
      if(currentPage === 'inventor') {
        this.setState({currentPageTitle: '인벤터'})
      }
      if(currentPage === 'catia') {
        this.setState({currentPageTitle: '카티아'})
      }
      if(currentPage === 'designetc') {
        this.setState({currentPageTitle: '설계 기타'})
      }
      if(currentPage === 'melsec') {
        this.setState({currentPageTitle: 'Melsec'})
      }
      if(currentPage === 'ls') {
        this.setState({currentPageTitle: 'LS 산전'})
      }
      if(currentPage === 'siemens') {
        this.setState({currentPageTitle: '지멘스'})
      }
      if(currentPage === 'plcetc') {
        this.setState({currentPageTitle: 'PLC 기타'})
      }
      if(currentPage === 'machineassembly') {
        this.setState({currentPageTitle: '장비 조립'})
      }
      if(currentPage === 'machineharness') {
        this.setState({currentPageTitle: '장비 배선'})
      }
      if(currentPage === 'factoryharness') {
        this.setState({currentPageTitle: '공장 배선'})
      }
      if(currentPage === 'machinerepair') {
        this.setState({currentPageTitle: '장비 유지, 보수'})
      }
      if(currentPage === 'partsrepair') {
        this.setState({currentPageTitle: '부품 수리'})
      }
      if(currentPage === 'lathemilling') {
        this.setState({currentPageTitle: '범용 선반, 밀링'})
      }
      if(currentPage === 'cnclathe') {
        this.setState({currentPageTitle: 'CNC 선반'})
      }
      if(currentPage === 'mct') {
        this.setState({currentPageTitle: 'MCT'})
      }
      if(currentPage === 'engravingmachine') {
        this.setState({currentPageTitle: '조각기'})
      }
      if(currentPage === 'press') {
        this.setState({currentPageTitle: '프레스, 판금'})
      }
      if(currentPage === 'cam') {
        this.setState({currentPageTitle: '캠 프로그램'})
      }
      if(currentPage === 'processingetc') {
        this.setState({currentPageTitle: '가공 기타'})
      }
      if(currentPage === 'cylindricalgrinding') {
        this.setState({currentPageTitle: '원통 연마'})
      }
      if(currentPage === 'planegrinding') {
        this.setState({currentPageTitle: '평면 연마'})
      }
      if(currentPage === 'rotarygrinding') {
        this.setState({currentPageTitle: '로터리 연마'})
      }
      if(currentPage === 'centerlessgrinding') {
        this.setState({currentPageTitle: '센터리스 연마'})
      }
      if(currentPage === 'toolgrinding') {
        this.setState({currentPageTitle: '공구 연마'})
      }
      if(currentPage === 'honing') {
        this.setState({currentPageTitle: '호닝'})
      }
      if(currentPage === 'profile') {
        this.setState({currentPageTitle: '프로파일'})
      }
      if(currentPage === 'grindingetc') {
        this.setState({currentPageTitle: '연마 기타'})
      }
      if(currentPage === 'Argonwelding') {
        this.setState({currentPageTitle: '알곤 용접'})
      }
      if(currentPage === 'laserwelding') {
        this.setState({currentPageTitle: '레이저 용접'})
      }
      if(currentPage === 'lasermarking') {
        this.setState({currentPageTitle: '레이저 마킹'})
      }
      if(currentPage === 'weldingetc') {
        this.setState({currentPageTitle: '용접 기타'})
      }
      if(currentPage === 'gundrill') {
        this.setState({currentPageTitle: '건드릴'})
      }
      if(currentPage === 'heattreatment') {
        this.setState({currentPageTitle: '열처리'})
      }
      if(currentPage === 'plated') {
        this.setState({currentPageTitle: '도금, 아노다이징'})
      }
      if(currentPage === '3dmeasuring') {
        this.setState({currentPageTitle: '3차원 측정'})
      }
      if(currentPage === 'etcetc') {
        this.setState({currentPageTitle: '가공 기타'})
      }
      if(currentPage === 'english') {
        this.setState({currentPageTitle: '영어 통,번역'})
      }
      if(currentPage === 'chinese') {
        this.setState({currentPageTitle: '중국어 통,번역'})
      }
      if(currentPage === 'japanese') {
        this.setState({currentPageTitle: '일본어 통,번역'})
      }
      if(currentPage === 'vietnamese') {
        this.setState({currentPageTitle: '베트남어 통,번역'})
      }
      if(currentPage === 'languageetc') {
        this.setState({currentPageTitle: '기타 언어 통,번역'})
      }
      if(currentPage === 'factoryshare') {
        this.setState({currentPageTitle: '공장 공유'})
      }
      if(currentPage === 'useddeal') {
        this.setState({currentPageTitle: '중고 장터'})
      }

      // Add Hammer code here
      // 0: `/fieldform/${currentPage}/projects`, 1: `/fieldform/${currentPage}/companylists`
      const hammerTarget = document.getElementById('tablinkHammer2'); // replace with the ID of your element
      const hammer = new Hammer(hammerTarget);
      hammer.on('swipeleft', () => {
        // handle swipe left
        if (this.state.tabLinkValue == 0) {
          this.setState({tabLinkValue : 1})
          this.props.history.push(`/fieldform/${currentPage}/companylists`);
        }
      });
      hammer.on('swiperight', () => {
        // handle swipe right
        if (this.state.tabLinkValue == 1) {
          this.setState({tabLinkValue : 0})
          this.props.history.push(`/fieldform/${currentPage}/projects`);
        } else if (this.state.tabLinkValue == 0) {
          this.props.history.push('/');
        }
      });

      // await this.getFollowees();
    }

    handleValueChange = (e) => {
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }

    // tabs 클릭시 바뀌는 state값
    handleChange = (event, tabLinkValue, tabindicator) => {
      this.setState({ tabLinkValue, tabindicator : true });
      // this.props.history.push(tabLinkValue);

      // setState값은 바로 바로 안바뀌기 때문에 부득이하게 아래와 같이 코딩 
      this.state.currentTabLinkValue = tabLinkValue;

      if(this.state.currentTabLinkValue === 0) {
        localStorage.setItem('currentTapLink', '0');
      }
      if(this.state.currentTabLinkValue === 1) {
        localStorage.setItem('currentTapLink', '1');
      }
      /*
      if(this.state.currentTabLinkValue === 2) {
        localStorage.setItem('currentTapLink', '2');
      }
      if(this.state.currentTabLinkValue === 3) {
        localStorage.setItem('currentTapLink', '3');
      }
      */
    };

    // App bar의 검색창에 필터(Filter)기능 사용해서 검색기능 추가 함수
    handleSearch = () => {
      /*
      const { searchtoggletrue } = this.props;
      searchtoggletrue();
      */
      this.setState({isSearchToggle: true})
      sessionStorage.setItem("isSearchToggle", 'true');
    }

    handleAppbarBack = () => {
      /*
      // 세션스토리지로 대체
      const { uploadsearch, searchtogglefalse } = this.props;
      uploadsearch('');
      searchtogglefalse();
      */
      this.setState({searchKeyword: ''})
      sessionStorage.setItem("searchKeyword", '');
      
      this.setState({isSearchToggle: false})
      sessionStorage.setItem("isSearchToggle", 'false');
    }

    handleSearchBtn = () => {
      const { searchKeyword } = this.state;
      const { /*uploadsearch,*/ searchkeywordtrue } = this.props;
      sessionStorage.setItem("searchKeyword", searchKeyword);
      // uploadsearch(searchKeyword);
      searchkeywordtrue();
      // window.location.reload();
    }

    handleKeyPress = (e) => {  
      // key 13 = 엔터를 의미
      if(e.charCode === 13) {
        this.handleSearchBtn()
      }
    }

    handleSearchClear = () => {
      this.setState({searchKeyword: ''})
      sessionStorage.setItem("searchKeyword", '');
    }

    // 뒤로 Back
    handleGoBack = () => {
      //this.props.history.goBack();
      if (this.state.tabLinkValue == 0) {
        this.props.history.push('/');
      } else {
        this.props.history.goBack();
      }
    }

    render() {
      const { classes, theme/*, isSearchToggle*/ } = this.props;
      const { searchKeyword, isSearchToggle } = this.state;
      const currentPage = localStorage.getItem('currentPage')

      // tabindicator가 true 일때 tabindicator 나오는 코드
      const tabs_1 = (
        <div>
          { currentPage === 'english' || currentPage === 'chinese' || currentPage === 'japanese'
            || currentPage === 'vietnamese' || currentPage === 'languageetc'
          ?
          <Tabs
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            classes={{indicator: classes.indicator}}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="견적 요청" dir={theme.direction} to={`/fieldform/${currentPage}/projects`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            {/*
            <Tab label="교육 요청" dir={theme.direction} to={`/fieldform/${currentPage}/educations`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            <Tab label="오투공 회원" dir={theme.direction} to={`/fieldform/${currentPage}/companylists`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} */ 
            />
            {/*
            <Tab label="회사 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/companypromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            {/*
            <Tab label="프리랜서 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/freelancerpromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
          </Tabs>
          :
          <Tabs
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            classes={{indicator: classes.indicator}}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="견적 요청" dir={theme.direction} to={`/fieldform/${currentPage}/projects`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            {/*
            <Tab label="교육 요청" dir={theme.direction} to={`/fieldform/${currentPage}/educations`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            <Tab label="오투공 회원" dir={theme.direction} to={`/fieldform/${currentPage}/companylists`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} */ 
            />
            {/*
            <Tab label="회사 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/companypromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            {/*
            <Tab label="프리랜서 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/freelancerpromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
          </Tabs>
          }
        </div>
      );

      // tabindicator가 false 일때 tabindicator 안나오는 코드
      const tabs_2 = (
        <div>
          { currentPage === 'english' || currentPage === 'chinese' || currentPage === 'japanese'
            || currentPage === 'vietnamese' || currentPage === 'languageetc'
          ?
          <Tabs
            classes={{ indicator: classes.hideIndicator }}
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            // textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="견적 요청" dir={theme.direction} to={`/fieldform/${currentPage}/projects`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            {/*
            <Tab label="교육 요청" dir={theme.direction} to={`/fieldform/${currentPage}/educations`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            <Tab label="오투공 회원" dir={theme.direction} to={`/fieldform/${currentPage}/companylists`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} */ 
            />
            {/*
            <Tab label="회사 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/companypromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            {/*
            <Tab label="프리랜서 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/freelancerpromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
          </Tabs>
          :
          <Tabs
            classes={{ indicator: classes.hideIndicator }}
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            // textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="견적 요청" dir={theme.direction} to={`/fieldform/${currentPage}/projects`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            {/*
            <Tab label="교육 요청" dir={theme.direction} to={`/fieldform/${currentPage}/educations`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            <Tab label="오투공 회원" dir={theme.direction} to={`/fieldform/${currentPage}/companylists`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} */ 
            />
            {/*
            <Tab label="회사 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/companypromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            {/*
            <Tab label="프리랜서 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/freelancerpromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
          </Tabs>
          }
        </div>
      );

      const factoryTabs_1 = (
        <div>
          <Tabs
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            classes={{indicator: classes.indicator}}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="공유공장 요청 및 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/projects`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            {/*<Tab label="단기공장 요청" dir={theme.direction} to={`/fieldform/${currentPage}/educations`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />*/}
            {/*
            <Tab label="공장부지 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/companypromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            {/*<Tab label="기술자 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/freelancerpromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />*/}
          </Tabs>
        </div>
      );

      // tabindicator가 false 일때 tabindicator 안나오는 코드
      const factoryTabs_2 = (
        <div>
          <Tabs
            classes={{ indicator: classes.hideIndicator }}
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            // textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="공유공장 요청 및 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/projects`} component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            {/*<Tab label="단기공장 요청" dir={theme.direction} to={`/fieldform/${currentPage}/educations`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />*/}
            {/*
            <Tab label="공장부지 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/companypromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
            {/*<Tab label="기술자 홍보" dir={theme.direction} to={`/fieldform/${currentPage}/freelancerpromotions`} component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />*/}
          </Tabs>
        </div>
      );

      const usedDealTabs_1 = (
        <div>
          <Tabs
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            classes={{indicator: classes.indicator}}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="중고 장터" dir={theme.direction} to={`/fieldform/${currentPage}/projects`} component={RouterLink} className={classes.AppShellText} underline='none'/>
          </Tabs>
        </div>
      );

      const usedDealTabs_2 = (
        <div>
          <Tabs
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            classes={{indicator: classes.indicator}}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="중고 장터" dir={theme.direction} to={`/fieldform/${currentPage}/projects`} component={RouterLink} className={classes.AppShellText} underline='none'/>
          </Tabs>
        </div>
      );

      return (
        <div>
          {/* 네비게이션 바 */}
          <div className={classes.root}>
            <CssBaseline />
            {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
            <AppBar position="fixed" color="default" className={classes.appBar}>
              <Toolbar className={classes.toolBar} variant="dense"> {/* variant="dense" -> toolbar 크기 조정 가능 */}
              { isSearchToggle
                ?
                  currentPage === 'search'
                  ?
                  <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                    <Grid item sm={2} md={2} lg={2} xl={2}>
                      <IconButton
                        edge="start"
                        className={classes.iconButton}
                        color="inherit"
                        aria-label="back"
                        onClick={this.handleGoBack}
                      >
                        <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#000000" />
                      </IconButton>
                    </Grid>
                    <Grid item sm={2} md={2} lg={2} xl={2}>
                      <CardMedia
                        className={classes.logoMedia}
                        // image="/static/images/logo/app.svg"
                        image="/images/logo/app.svg"
                        aria-label="Recipe" 
                      />
                    </Grid>
                    {/* 아래는 기존 Seach 아이콘 관련 */}
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className={classes.search}>
                        <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                          {/*<Grid item sm={10} md={10} lg={10} xl={10}>*/}
                          <Grid item sm={12} md={12} lg={12} xl={12}>
                            <TextField
                              className={classes.textFieldBorder}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot
                                },
                              }}
                              InputProps={{
                                classes: {
                                  input: classes.inputInput,
                                },
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      edge="start"
                                      className={classes.iconButton}
                                      color="inherit"
                                      aria-label="back"
                                      onClick={this.handleSearchBtn}
                                    >
                                      <SearchIcon className={classes.searchIcon}/>        
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              placeholder="전체 검색"
                              inputProps={{ 'aria-label': 'Search' }}
                              // 상태 변화가 일어나면 handleValueChange함수가 실행 되게끔 하였고
                              // 함수안의 state 값이 변경된 이름값으로 변경된다.
                              // 실제로 변경된 값은 value라는 값으로 화면에 출력이 된다.
                              name="searchKeyword"
                              value={searchKeyword}
                              onChange={this.handleValueChange}
                              autoComplete="off"  // 추후 자동완성기능 넣으려면 바꾸기
                              onKeyPress={(e) => this.handleKeyPress(e)}
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          {/*
                          <Grid item sm={2} md={2} lg={2} xl={2} align='flex-start'>
                            <Typography variant="body2" style={{ cursor: 'pointer' }}
                              variant="h6" noWrap onClick={this.handleSearchClear}
                            >
                              <ClearIcon className={classes.clearIcon}/>
                            </Typography>
                          </Grid>
                          */}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  :
                  <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                    <Grid item sm={2} md={2} lg={2} xl={2}>
                      <IconButton
                        edge="start"
                        className={classes.iconButton}
                        color="inherit"
                        aria-label="back"
                        onClick={this.handleAppbarBack}
                      >
                        <ArrowBackIosIcon className={classes.arrowBackIosIcon}/>
                        {/* <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" /> */}                    
                      </IconButton>
                    </Grid>
                    <Grid item sm={2} md={2} lg={2} xl={2}>
                      <CardMedia
                        className={classes.logoMedia}
                        // image="/static/images/logo/app.svg"
                        image="/images/logo/app.svg"
                        aria-label="Recipe" 
                      />
                    </Grid>
                    {/* 아래는 기존 Seach 아이콘 관련 */}
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className={classes.search}>
                        <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                          {/*<Grid item sm={10} md={10} lg={10} xl={10}>*/}
                          <Grid item sm={12} md={12} lg={12} xl={12}>
                            <TextField
                              className={classes.textFieldBorder}
                              InputLabelProps={{
                                classes: {
                                  root: classes.inputRoot
                                },
                              }}
                              InputProps={{
                                classes: {
                                  input: classes.inputInput,
                                },
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      edge="start"
                                      className={classes.iconButton}
                                      color="inherit"
                                      aria-label="back"
                                      onClick={this.handleSearchBtn}
                                    >
                                      <SearchIcon className={classes.searchIcon}/>        
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              placeholder="검색하기"
                              inputProps={{ 'aria-label': 'Search' }}
                              // 상태 변화가 일어나면 handleValueChange함수가 실행 되게끔 하였고
                              // 함수안의 state 값이 변경된 이름값으로 변경된다.
                              // 실제로 변경된 값은 value라는 값으로 화면에 출력이 된다.
                              name="searchKeyword"
                              value={searchKeyword}
                              onChange={this.handleValueChange}
                              autoComplete="off"  // 추후 자동완성기능 넣으려면 바꾸기
                              onKeyPress={(e) => this.handleKeyPress(e)}
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          {/*
                          <Grid item sm={2} md={2} lg={2} xl={2} align='flex-start'>
                            <Typography variant="body2" style={{ cursor: 'pointer' }}
                              variant="h6" noWrap onClick={this.handleSearchClear}
                            >
                              <ClearIcon className={classes.clearIcon}/>
                            </Typography>
                          </Grid>
                          */}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                :
                <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                  <Grid item sm={2} md={2} lg={2} xl={2}>
                    <IconButton
                      edge="start"
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="back"
                      onClick={this.handleGoBack}
                    >
                      <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#000000" />
                    </IconButton>
                  </Grid>
                  <Grid item sm={2} md={2} lg={2} xl={2}>
                    <CardMedia
                      className={classes.logoMedia}
                      // image="/static/images/logo/app.svg"
                      image="/images/logo/app.svg"
                      aria-label="Recipe" 
                    />
                  </Grid>
                  <Grid item sm={5} md={5} lg={4} xl={4}>
                    <Typography className={classes.title} variant="h6" noWrap>
                      {this.state.currentPageTitle}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} md={3} lg={4} xl={4}>
                    {/* Search 버튼
                    {this.state.tabLinkValue === 0
                    ?
                    <IconButton  
                      edge="start"
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="back"
                      onClick={this.handleSearch}  // 클릭시 Search 팝업창 나오게끔 하기
                    >
                      <SearchIcon className={classes.searchIcon}/>
                    </IconButton>
                    :
                    <div></div>
                    }
                  */}
                  </Grid>
                </Grid>
              }
              </Toolbar>
              <Divider />
            </AppBar>
            <AppBar position="fixed" className={classes.appBar2} color="default">
              <Toolbar className={classes.toolBar2} variant="dense">
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  {currentPage === 'factoryshare'
                  ?
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {this.state.tabindicator ? factoryTabs_1 : factoryTabs_2}
                  </Grid>
                  :
                  currentPage === 'useddeal'
                  ?
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {this.state.tabindicator ? usedDealTabs_1 : usedDealTabs_2}
                  </Grid>
                  :
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {this.state.tabindicator ? tabs_1 : tabs_2}
                  </Grid>
                  }
                </Grid>
              </Toolbar>
              <Divider />
            </AppBar>
            {/*{console.log('First:' + this.state.value)}*/}
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {/*<div position="fixed" className={classes.appBar}>*/}
                {/* 실제 사용자가 선택한 링크 (App.js의 라우팅과 연결) */}
                {/*<div id="content" style={{margin: 'auto', marginTop: '5px'}}>*/}
                <div id="tablinkHammer2"> {/* add ID to your element */} 
                  {React.cloneElement(this.props.children)}
                </div>
              {/*</div>*/}
            </main>
          </div>
        </div>
      );
    }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //isKlaytnLoggedIn: state.auth.isKlaytnLoggedIn,
  isAuthenticated: state.auth.isAuthenticated,
  // currentUser: state.auth.currentUser,
  // searchKeyword: state.upload.searchKeyword,  // 실제 검색을 위한 버튼 클릭시
  // isSearchToggle: state.upload.isSearchToggle,  // 검색창 나오에 하기 위한 토글(현재 세션스토리지로 사용)
  isSearchKeyword: state.upload.isSearchKeyword,
  isdrawerToggle: state.upload.isdrawerToggle,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  //integrateWallet: (privateKey) => dispatch(authActions.integrateWallet(privateKey)),
  //removeWallet: () => dispatch(authActions.removeWallet()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  // uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
  // searchtoggletrue: () => dispatch(uploadActions.searchtoggletrue()),
  // searchtogglefalse: () => dispatch(uploadActions.searchtogglefalse()),
  searchkeywordtrue: () => dispatch(uploadActions.searchkeywordtrue()),
  searchkeywordfalse: () => dispatch(uploadActions.searchkeywordfalse()),
  drawertoggletrue: () => dispatch(uploadActions.drawertoggletrue()),
  drawertogglefalse: () => dispatch(uploadActions.drawertogglefalse()),
})


MainAppShellField = withStyles(styles, {withTheme: true})(MainAppShellField);
MainAppShellField = withRouter(MainAppShellField);
export default connect(mapStateToProps, mapDispatchToProps)(MainAppShellField)