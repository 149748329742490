import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SVGIcon from './SVGIcon'

import { connect } from 'react-redux'

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Button from '@material-ui/core/Button';

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#F5F5F5",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  iconButton: {
    margin: theme.spacing(0),
    //paddingLeft: 0
  },
  appbarTitle: {
    flexGrow: 1,
    fontSize : 18,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    // PC버전에서는 marginTop이 120, 모바일에서는 60
    marginTop: '60px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '120px',
    },
    flexGrow: 1,
    //marginBottom: '60px'
  },
  card: {
    maxWidth: 1500,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  // PC버전에서는 사진출력, 모바일에서는 사진출력 안됨
  mainMedia: {
    height: 0,
    paddingTop: '0%',
    [theme.breakpoints.up('sm')]: {
      height: 0,
      paddingTop: '40%',
      objectFit: 'fill',
    },
  },
  /*
  list: {
    width: '100%',
    padding: '0',
    alignItems: 'center'
    // background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center'
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  */
  typoTitle: {
    width: '100%',
    fontSize : 25,
    maringTop: 10,
    // PC버전에서는 marginBottom이 60, 모바일에서는 5
    marginBottom: 5,
    [theme.breakpoints.up('lg')]: {
      marginBottom: 60,
    },
    //marginLeft: 5,
    //marginRight: 5,
  },
  typo: {
    width: '100%',
    fontSize : 18,
    paddingTop: 30,
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  typoOR : {
    width: '100%',
    fontSize : 18,
  },
  buttonTypo: {
    width: '100%',
    fontSize : 18,
    paddingTop: 0,
    paddingBottom: 0,
  },
  typoLogin1: {
    width: '100%',
    fontSize : 16,
    // PC버전에서는 paddingTop이 30, 모바일에서는 15
    paddingTop: 15,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 30,
    },
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  typoLogin2: {
    color:'#0c93d0',
  },
  button: {
    width: '80%',
    // maringTop: 5,
    marginBottom: 30,
    //marginLeft: 5,
    //marginRight: 5,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
});

class ChooseAccount extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      completed : 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  // 뒤로 Back
  handleGoBack = () => {
    //this.props.history.goBack();
    this.props.history.push('/');
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.appbarTitle} align='center' noWrap>
                    오투공 - Online To 공장
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>
            <Grid container spacing={3} direction="column" justify="center" alignItems="center">
              <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} justify="center" align="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Typography className={classes.typoTitle}>
                    오투공 무료 가입하기
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5} align="center">
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.mainMedia}
                      image={'/images/chooseAccount/ForSupplier_paddingtop_40.png'}
                    />
                    <Typography className={classes.typo}>
                      유휴설비 및 기술을 매출로 만드세요
                    </Typography>
                    <Link component={RouterLink} to="/signupsuppliercompany" underline='none' color='inherit'>
                      <Button className={classes.button}>
                        <Typography className={classes.buttonTypo}>
                          공급업체로 가입하기
                        </Typography>
                      </Button>
                    </Link>
                    <Link component={RouterLink} to="/signupsupplierfreelancer" underline='none' color='inherit'>
                      <Button className={classes.button}>
                        <Typography className={classes.buttonTypo}>
                          프리랜서로 가입하기
                        </Typography>
                      </Button>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} xl={2} align="center">
                    <Typography className={classes.typoOR}>
                      or
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5} align="center">
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.mainMedia}
                      image={'/images/chooseAccount/ForBuyer_paddingtop_40.png'}
                    />
                    <Typography className={classes.typo}>
                      온라인상에서 제조공장을 찾으세요
                    </Typography>
                    <Link component={RouterLink} to="/signupbuyercompany" underline='none' color='inherit'>
                      <Button className={classes.button}>
                        <Typography className={classes.buttonTypo}>
                          기업으로 가입후 공장찾기
                        </Typography>
                      </Button>
                    </Link>
                    <Link component={RouterLink} to="/signupbuyerfreelancer" underline='none' color='inherit'>
                      <Button className={classes.button}>
                        <Typography className={classes.buttonTypo}>
                          개인으로 가입후 공장찾기
                        </Typography>
                      </Button>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Typography className={classes.typoLogin1}>
                    이미 계정이 있으신가요? &nbsp;
                    <Link component={RouterLink} to="/login" underline='none' className={classes.typoLogin2}>
                      로그인
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </main>
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

ChooseAccount = withStyles(styles, {withTheme: true})(ChooseAccount)
export default connect(mapStateToProps)(ChooseAccount);