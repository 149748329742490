import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Feed_MyUploads from './Feed_MyUploads';

// S3 관련
import { API } from "aws-amplify";

import { connect } from 'react-redux'

import SVGIcon from './SVGIcon'

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(7),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  title: {
    flexGrow: 1,
    fontSize : 20,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '30px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  iconButton: {
    margin: theme.spacing(0),
    //paddingLeft: 0
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  moreSeeButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  typoSubmit: {
    fontSize: 15
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  card2: {
    maxWidth: 64,
    marginTop: 5,
    marginBottom: 15,
    boxShadow: "none",
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 20,
    paddingBottom: 20,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 50,
  },
  listItemIcon: {
    minWidth: 0
  },
  typoTitle: {
    width: '100%',
    fontSize : 20,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  cardHeader: {
    width: '100%',
    paddingRight: 0,
  },
  textFieldName : {
    width: '100%',
    fontSize: 16,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '3px',
    paddingRight: '24px',
  },
  textField : {
    width: '100%',
    fontSize: 16,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '0px',
    paddingRight: '24px',
  },
  textFieldSize: {
    fontSize: 16,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    //backgroundColor: red[500],
    backgroundColor: '#D3D3D3',
    margin: 0,
    width: 80,
    height: 80,
  },
  avatarIconButton: {
    padding: 0,
  },
  media: {
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    width : '100%',
    height: '100%'
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%', /* 720 / 1280 = 0.5625 */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  typoMoreSee: {
    fontSize: 17,
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    // bottom: 20,
  },
});

class MyUploads extends Component {

  constructor(props) {
    super(props);

    this.state = {
      uploadContents: '',
      uploadContent: '',
      getApplications: '',
      getApplication: '',
      //userContents: '',
      //userContent: '',
      //favoriteThumbs: '',
      //favoriteThumb: '',
      //images: "",
      getNotifications: "",
      fcmTokenId: "",
      userId: "",
      eventToggle : '',
      //followUploadToggle : '',
      //followCommentToggle : '',
      //getFollowees: '',
      // scroll 관련
      page: 1,
      pagelimit: 9,
      hasNextPage: false, // 다음 페이지가 false이면 DB에서 더이상 가지고 올 자료 없다는 뜻
      completed : 0,
    };
  }

  getUploads() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    //const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
    //const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
    const myUploadPage = localStorage.getItem("myUploadPage");

    if(myUploadPage === "project") {
      return API.get("upload", `/upload?userId=${currentAuthUserFromSession}&subField=0&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
    }

    /*
    if(myUploadPage === "education") {
      return API.get("upload", `/upload?userId=${currentAuthUserFromSession}&subField=1&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
    }

    if(myUploadPage === "companyPromotion") {
      return API.get("upload", `/upload?userId=${currentAuthUserFromSession_Company}&subField=2&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
    }

    if(myUploadPage === "freelancerPromotion") {
      return API.get("upload", `/upload?userId=${currentAuthUserFromSession_Freelancer}&subField=3&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
    }
    */
  }

  // 나한테 지원한 프로젝트 리스트
  getApplications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/application/?uploadUserId=${currentAuthUserFromSession}`)
    .then(getApplications => this.setState({getApplications : getApplications}));
  }

  /*
  getUsers() {
    return API.get("upload", "/user")
    .then(userContents => this.setState({userContents : userContents}));
  }

  getFavorites() {
    return API.get("upload", "/favorite")
    .then(favoriteThumbs => this.setState({favoriteThumbs : favoriteThumbs}));
  }
  */

  /*
  // 내가 Following한 사람들 리스트
  getFollowees() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/follow/?followerUserId=${currentAuthUserFromSession}`)
    .then(getFollowees => this.setState({getFollowees : getFollowees}));
  }
  */

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;
    // console.log('subscribe :' + sessionStorage.getItem("subscribe"));
    /*
      // 아래는 예시를 든것이다. 아래와 같이 this._isMounted를 사용해서 그 안에 setState를 담아주기.
      if (this._isMounted) {
        this.setState({
          news: result.data.hits,
        });
      }
    */
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    const publicUploads = await this.getUploads();

    const { docs, hasNextPage } = publicUploads;
    this.setState({uploadContents: docs})
    this.setState({hasNextPage: hasNextPage})
    
    await this.getApplications();
    // await this.getUsers();
    // await this.getFavorites();
    // await this.getFollowees();
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  moreSeeContents = async() => {
    if(this.state.hasNextPage === true) {
      this.state.pagelimit = this.state.pagelimit + 9;
        
      const uploads = await this.getUploads();
    
      const { docs, hasNextPage } = uploads;
      this.setState({uploadContents: docs})
      this.setState({hasNextPage: hasNextPage})
    }
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, isFavoriteLoading, isFollowingLoading, theme, searchKeyword } = this.props;
    const myUploadPage = localStorage.getItem("myUploadPage");

    /*
    if(isFavoriteLoading) {
      this.getFavorites();
    }
    */
    
    /*
    if(isFollowingLoading) {
      this.getFollowees();
    }
    */

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredComponents = (data) => {
      data = data.filter((c) => {
        return c.content.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {
        
        let applyingCount = 0;
        Object.keys(this.state.getApplications).map(applicationUUId => {
          const getApplication = this.state.getApplications[applicationUUId];
          if(getApplication.uploadUUId === c.uploadUUId) {
            applyingCount = applyingCount + 1;
          }
        });
        /*
        let WalletAddress;
        let NickName;
        let Avatarimage;
        Object.keys(this.state.userContents).map(userId => {
          const userContent = this.state.userContents[userId];
          if(userContent.userId === c.userId) {
            WalletAddress = userContent.walletAddress;
            NickName = userContent.nickName;
            Avatarimage = userContent.avatarimage;
          }
        });
        */

        /*
        let favoriteUserId;
        let favoriteUUId;
        let favoriteContent;
        let favoriteContentNumbers = 0;
        Object.keys(this.state.favoriteThumbs).map(uploadUUId => {
          const favoriteThumb = this.state.favoriteThumbs[uploadUUId];
          if(favoriteThumb.uploadUUId === c.uploadUUId) {
            if(favoriteThumb.userId === currentAuthUserFromSession) {
              favoriteUserId = favoriteThumb.userId;
              favoriteUUId = favoriteThumb.favoriteUUId;
              favoriteContent = favoriteThumb.favoriteContent;
            }
            favoriteContentNumbers = favoriteContentNumbers + 1;
          }
        });
        */

        /*
        let followerUserId;
        let followeeUserId;
        let followingToggle;
        Object.keys(this.state.getFollowees).map(followingUUId => {
          const getFollowee = this.state.getFollowees[followingUUId];
          if(getFollowee.followerUserId === currentAuthUserFromSession) {
            if(getFollowee.followeeUserId === c.userId) {
              followerUserId=getFollowee.followerUserId;
              followeeUserId=getFollowee.followeeUserId;
              followingToggle=getFollowee.followingToggle;   
            }
          }
        });
        */
     
        return(
          <Feed_MyUploads
            stateRefresh={this.stateRefresh}
            key={c.uploadUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
            id={c.id}
            userId={c.userId}
            uploadUUId={c.uploadUUId}
            /*
            walletAddress={WalletAddress}
            nickName={NickName}
            avatarimage={Avatarimage}
            date={c.date}
            images={c.images}  // AWS S3 사용시
            selectTarget={c.selectTarget}
            */
            title={c.title}
            content={c.content}
            dateStart={c.dateStart}
            dateEnd={c.dateEnd}
            applyingCount={applyingCount}
            /*
            favoriteUserId={favoriteUserId}
            favoriteUUId={favoriteUUId}
            favoriteContent={favoriteContent}
            favoriteContentNumbers={favoriteContentNumbers}
            */
            /*
            followerUserId={followerUserId}
            followeeUserId={followeeUserId}
            followingToggle={followingToggle}
            */
          />
        );
      })
    }

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  { myUploadPage === "project"
                  ?
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    받은견적확인
                  </Typography>
                  :
                  null
                  }
                  {/*
                  { myUploadPage === "education"
                  ?
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    게재한 교육 요청
                  </Typography>
                  :
                  null
                  }
                  { myUploadPage === "companyPromotion"
                  ?
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    게재한 기업 홍보
                  </Typography>
                  :
                  null
                  }
                  { myUploadPage === "freelancerPromotion"
                  ?
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    게재한 기술자 홍보
                  </Typography>
                  :
                  null
                  }
                  */}
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                    { myUploadPage === "project"
                    ?
                      <Typography className={classes.typoTitle}>
                        받은견적확인
                      </Typography>
                    :
                    null
                    }
                    {/*
                    { myUploadPage === "education"
                    ?
                      <Typography className={classes.typoTitle}>
                        게재한 교육 요청
                      </Typography>
                    :
                    null
                    }
                    { myUploadPage === "companyPromotion"
                    ?
                      <Typography className={classes.typoTitle}>
                        게재한 기업 홍보
                      </Typography>
                    :
                    null
                    }
                    { myUploadPage === "freelancerPromotion"
                    ?
                      <Typography className={classes.typoTitle}>
                        게재한 기술자 홍보
                      </Typography>
                    :
                    null
                    }
                    */}
                    </ListItem>
                  </List>
                  {/* filter(검색)기능 */}
                  {this.state.uploadContents /*&& this.state.userContents*/
                    ?
                    // filteredComponents(this.state.uploadContents) // 필터 사용시 (나중에 삭제)
                    Object.keys(this.state.uploadContents).map(uploadUUId => {
                      const c = this.state.uploadContents[uploadUUId];

                      let applyingCount = 0;
                      Object.keys(this.state.getApplications).map(applicationUUId => {
                        const getApplication = this.state.getApplications[applicationUUId];
                        if(getApplication.uploadUUId ===  c.uploadUUId) {
                          applyingCount = applyingCount + 1;
                        }
                      });

                      return(
                        <Feed_MyUploads
                          stateRefresh={this.stateRefresh}
                          key={c.uploadUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                          id={c.id}
                          userId={c.userId}
                          uploadUUId={c.uploadUUId}
                          title={c.title}
                          content={c.content}
                          dateStart={c.dateStart}
                          dateEnd={c.dateEnd}
                          applyingCount={applyingCount}
                        />
                      );
                    })
                    :
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                      <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                    </Grid>
                  }
                  {this.state.hasNextPage
                  ?
                  <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <Button variant="contained" className={classes.moreSeeButton} 
                          onClick={this.moreSeeContents}
                        >
                          <Typography className={classes.typoMoreSee}>더보기</Typography>
                          <ExpandMoreOutlinedIcon className={classes.expandMoreOutlinedIcon}/>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  :
                  null
                  }
                </Card>
              </Grid>
            </Grid>
          </main>
          {/*
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Toolbar>
              <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button variant="contained" className={classes.button} 
                    onClick={this.connect}
                  >
                    <Typography className={classes.typoSubmit}>연락하기</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          */}
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  searchKeyword: state.upload.searchKeyword,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
})

MyUploads = withStyles(styles, {withTheme: true})(MyUploads)
export default connect(mapStateToProps)(MyUploads);