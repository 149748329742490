import React from 'react';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red, yellow } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TextField } from "@material-ui/core";
import moment from 'moment';

/*
// '좋아요' 관련 Icon
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
*/

import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

// 동영상 관련
import ReactPlayer from 'react-player'

// MoreVertIcon 관련
import PopupState, { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

// S3 관련
import { API, Storage } from "aws-amplify";
import config from "../config";
import { s3DeletePublic } from "../libs/awsLib";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  GridContainer: {
    minHeight: "10vh"
  },
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "10px",
    // maxWidth: 650,
    // minWidth : 500,
    [theme.breakpoints.up('xs')]: { // xs보다 up일때 동작
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: { // xs 보다 down 일때 동작
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  media: {
    marginTop: 5,
    height: 50,
    width: 130,
    objectFit: 'contain'
  },
  typoName: {
    width: '100%',
    fontSize : 17,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 40
  },
  typoContent: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 40
  },
  typoDate: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 40
  },
  typoAddress: {
    width: '100%',
    fontSize : 17,
    color: '#999',
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 40
  },
  avatar: {
    // backgroundColor: red[500],
    marginTop: 5,
    backgroundColor: '#D3D3D3',
    margin: 10,
    width: 70,
    height: 70,
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  dialogButton: {
    width: '80%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoButton: {
    fontSize : 17,
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  MoreVertIconRoot: {
    position: 'relative',
  },
  MoreVertIconContent: {
    padding: theme.spacing(0),
  },
  popupStateTypography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  popupStateMenu: {
    padding : 0,
  },
  /*
  starBorderIconButton: {
    padding: 0,
  },
  starIconButton: {
    padding: 0,
  },
  starBorderIcon: {
    width: 25,
    height: 25,
  },
  starIcon: {
    width: 25,
    height: 25,
    color: '#FFD700'  // gold
  },
  */
  dialogTitle: {
    fontSize: 17
  },
  dialogContent: {
    fontSize: 17
  },
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldLabelSize:{
    fontSize:17
  },
  textFieldInputSize:{
    fontSize:17
  },
});

class Feed_HomeGetProjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // expanded : false,  // extension 기능
      avatarAttachmentURL: null,
      attachmentURLs: [],
      attachmentURL_1: null,
      avatarimage: "",
      images: [],
      image_1: "",
      MoreVertIconOpen: false,
      deleteDialogOpen : false, // 다이얼로그 창이 열려 있는지 확인하는 변수
      submitDialogOpen: false,
      //applyDialogOpen: false,
      customerName: '',
      address_2:'',
      applicationTitle: '',
      applicationToggle: '',
      applicationInvoice: '',
      applicationContent: '',
      alarmDialogOpen_1: false,
      fcmTokenIds: [], // 여러명한테 FCM 발송시 시도
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  postApplication(application) {
    return API.post("upload", "/application", {
      body: application
    });
  }

  //------------------------------------------------------------------------
  // 지원 버튼 클릭시 알림 발송
  //------------------------------------------------------------------------
  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }
  
  getNotifications() {
    return API.get("upload", `/notification/?userId=${this.props.orderUserId}`)
    .then(notifications => this.setState({notifications : notifications}));
  }
  //------------------------------------------------------------------------

  /*
  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }
  */

  deleteDialog = async event => {
    event.preventDefault();

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.userId === currentAuthUserFromSession) {
      this.setState({
        deleteDialogOpen : true,
      });
    }
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      deleteDialogOpen : false,
      submitDialogOpen : false,
      //applyDialogOpen : false,
      alarmDialogOpen_1: false,
    })
  }

  handleClosePreInvoice = () => {
    this.setState({
      alarmDialogOpen_1: false,
    })
  }

  handleMoreVertIconToggle = () => {
    this.setState({MoreVertIconOpen: !this.state.MoreVertIconOpen});
  }

  // 프로젝트 지원하기 버튼 클릭 시 1
  handleSubmit = () => {
    this.setState({submitDialogOpen : true});
  }

  // 프로젝트 지원하기 버튼 클릭 시 2
  handleApplication = async () => {
    const { applyingloadingtrue, applyingloadingfalse } = this.props;
    const currentTapLink = localStorage.getItem("currentTapLink");

    // 작성 안한 파트 작성하게끔 해주는 코드 (일감 요청 란 에서만 동작)
    if(currentTapLink === '0') {
      if (!this.state.applicationInvoice) {
        this.setState({alarmDialogOpen_1: true});
        return;
      }
    }

    if(this.state.applicationInvoice === '') {
      this.state.applicationInvoice = "default"
    }
    
    if(this.state.applicationContent === '') {
      this.state.applicationContent = "default"
    }
    
    try {
      applyingloadingtrue();
      
      await this.postApplication({
        uploadUserId: this.props.orderUserId,
        uploadUUId: this.props.uploadUUId,
        applicationSubField: this.props.subField,
        applicationTitle: this.props.title,
        dateStart: moment(this.props.dateStart).format("YYYY-MM-DD"),
        dateEnd: moment(this.props.dateEnd).format("YYYY-MM-DD"),
        applicationToggle: '0', // '0': 지원자가 지원 신청 한거
        applicationInvoice: this.state.applicationInvoice,
        applicationContent: this.state.applicationContent,
        orderComment: 'default', 
        orderSign: 'default', 
        applicationSign: 'default'
      });
      
      applyingloadingfalse();
      // this.props.history.push("/");
      this.handleClose(); // 기존 dialog 창 닫기
      // this.setState({applyDialogOpen: true})  // 이후에 '지원신청 완료' dialog 창 열기
    } catch (e) {
      // lert(e);
      console.log(e)
    }

    try {
      if(this.state.fcmTokenIds.length > 0) {
        await this.postNotificationFCM({
          registration_ids: this.state.fcmTokenIds,
          title: this.props.title + '의 견적이 도착했습니다!',
          body: '견적 : ' + this.state.applicationInvoice,
        });
      }
    } catch(e) {
      console.log(e)
    }
  }

  async componentDidMount() {
    // AWS S3 사용시 (user 관련 파일)
    try {
      let avatarAttachmentURL;
      const avatarimage = this.props.avatarimage;

      if (avatarimage != 'No_File') {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
          avatarAttachmentURL = await Storage.get(avatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }

      //  console.log(this.props.avatarimage);
      
      this.setState({
        avatarAttachmentURL,
        avatarimage,
      });
      // console.log(this.state.attachmentURL);
    } catch (e) {
      alert(e);
    }

    /*
    // AWS S3 사용시 (upload 관련 파일).
    try {
      let attachmentURLs = [];
      let images = [];

      for(let i=0;i<this.props.images.length;i++) {

        images[i] = this.props.images[i];

        if (images[i] != 'No_File') {
          // attachmentURL = await Storage.vault.get(image);  // Private하게 볼 수 있는거
          if(this.props.selectTarget === 'public') {
            attachmentURLs[i] = await Storage.get(images[i], { level: 'public' });  // Public하게 볼 수 있는거
          }
          if(this.props.selectTarget === 'protected') {
            if(this.props.followeeUserId) {
              if(this.props.followingToggle === '1') {
                attachmentURLs[i] = await Storage.get(images[i], 
                  { level: 'protected',
                  identityId: this.props.userId   // the identityId of that user. 사진을 업로드한 사용자의 identityId
                });  // Protected 하게 볼 수 있는거
              }
            }
            if(this.props.userId === currentAuthUserFromSession) {
              attachmentURLs[i] = await Storage.get(images[i], 
                { level: 'protected',
                identityId: this.props.userId   // the identityId of that user. 사진을 업로드한 사용자의 identityId
              });  // Protected 하게 볼 수 있는거
            }
          }
          if(this.props.selectTarget === 'private') {
            if(this.props.userId === currentAuthUserFromSession) {
              attachmentURLs[i] = await Storage.get(images[i], {level: 'private'});  // Protected 하게 볼 수 있는거
            }
          }
        }

        this.setState({
          attachmentURLs: this.state.attachmentURLs.concat(attachmentURLs[i]),
          images: this.state.images.concat(images[i]),
        });
      }

      this.setState({ 
        image_1: images[0],
        attachmentURL_1: attachmentURLs[0]
      });
    } catch (e) {
      alert(e);
    }
    */
   
    if(this.props.customerType2 === 'company') {
      this.setState({customerName: this.props.companyName})
    } else if(this.props.customerType2 === 'freelancer') {
      this.setState({customerName: this.props.name})
    }

    // 주소 정보 받아오기
    this.setState({
      address_2: this.props.address[2],
    })

    //------------------------------------------------------------------------------------
    // FCM으로 발송할 데이터를 미리 받아서 fcmTokenId를 배열로 저장.
    // 나중에 발송시 FCM으로 fcmTokenId 배열로 보내면 최대 1,000명한테 한번에 발송 가능
    try {
      await this.getNotifications();
      // fcmTokenIds를 배열로 받기
      if(this.state.notifications) { 
        let fcmTokenId
        Object.keys(this.state.notifications).map(notificationUUId => {
          const notification = this.state.notifications[notificationUUId];
            fcmTokenId = notification.fcmTokenId
            this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
        });
      }
    } catch(e) {
      console.log(e)
    }
    //------------------------------------------------------------------------------------
  }

  render() {
    const { classes, theme } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentTapLink = localStorage.getItem("currentTapLink");

    return (
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>   {/* 한줄에 1개씩 할 때 사용 */}
      {/*<Grid container item xs={12} sm={6} md={4} lg={4} xl={4}>*/}   {/* 한줄에 3개씩 할 때 사용 */}
      {/*<Grid item xs={12} sm={12} md={12} lg={8} xl={8} align="center">*/} {/* 세로로 정렬시 사용 */}
        <Card className={classes.card}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="flex-end">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            {/*<Grid item xs={8} sm={8} md={8} lg={9} xl={9}>*/}
              <Link component={RouterLink} to={`/uploadconnect/${this.props.uploadConnectUUId}`} underline='none' color='inherit'>
                {this.state.avatarAttachmentURL
                ?
                  this.props.customerType2 === 'company'
                  ?
                  <img
                    className={classes.media}
                    src={this.state.avatarAttachmentURL}
                  />
                  :
                  <Avatar src={this.state.avatarAttachmentURL} aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  :
                <Avatar aria-label="Recipe" className={classes.avatar}>
                  <AccountCircleIcon style={{fontSize: '85px'}}/>
                </Avatar>
                }
              </Link>
            </Grid>
            {/*
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>

              this.props.favoriteContent === '1' && currentAuthUserFromSession === this.props.favoriteUserId
              ?
              <IconButton className={classes.starIconButton} aria-label="Add to favorites" onClick={this.handleThumbsUpButton}>
                <StarIcon className={classes.starIcon}/>
              </IconButton>
              :
              <IconButton className={classes.starBorderIconButton} aria-label="Add to favorites" onClick={this.handleThumbsUpButton}>
                <StarBorderIcon className={classes.starBorderIcon}/>
              </IconButton>

            </Grid>
            */}
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Link component={RouterLink} to={`/uploadconnect/${this.props.uploadConnectUUId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoName}>
                    {this.state.customerName}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Link component={RouterLink} to={`/uploadconnect/${this.props.uploadConnectUUId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoContent}>
                    {/* 글자 길이가 37개 이상 넘어가면 '...' 나옴 */}
                    {this.props.title.slice(0,37)}
                    {this.props.title.length > 37 
                    ? '...'
                    : null
                    }
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.dateStart && this.props.dateEnd
                ?
                <Link component={RouterLink} to={`/uploadconnect/${this.props.uploadConnectUUId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoDate}>
                    {moment(this.props.dateStart).format("YYYY-MM-DD")} ~ {moment(this.props.dateEnd).format("YYYY-MM-DD")}
                  </Typography>
                </Link>
                :
                null  
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Link component={RouterLink} to={`/uploadconnect/${this.props.uploadConnectUUId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoAddress}>
                     {this.state.address_2}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            {currentTapLink === '0'
            ?
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card>
                {currentAuthUserFromSession == this.props.orderUserId
                ?
                <Button variant="contained" color="primary" /*className={classes.button}*/  
                >
                  <Typography className={classes.typoButton}>내 공고</Typography>
                </Button>
                :
                this.props.applicationToggle === '0' || this.props.applicationToggle === '1' || this.props.applicationToggle === '2'
                ?
                <Button variant="contained" color="secondary" /*className={classes.button}*/ 
                >
                  <Typography className={classes.typoButton}>견적 완료</Typography>
                </Button>
                :
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleSubmit}
                >
                  <Typography className={classes.typoButton}>견적 발송</Typography>
                </Button>
                }
              </Card>
            </Grid>
            :
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card>
                {currentAuthUserFromSession == this.props.orderUserId
                ?
                <Button variant="contained" color="primary" /*className={classes.button}*/  
                >
                  <Typography className={classes.typoButton}>내 공고</Typography>
                </Button>
                :
                this.props.applicationToggle === '0' || this.props.applicationToggle === '1' || this.props.applicationToggle === '2'
                ?
                <Button variant="contained" color="secondary" /*className={classes.button}*/ 
                >
                  <Typography className={classes.typoButton}>연락처 완료</Typography>
                </Button>
                :
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleSubmit}
                >
                  <Typography className={classes.typoButton}>연락처 남기기</Typography>
                </Button>
                }
              </Card>
            </Grid>
            }
          </Grid>
        </Card>
        {currentTapLink === '0'
        ?
        <Dialog /*fullScreen={true}*/ fullWidth={true} /*maxWidth="md"*/ open={this.state.submitDialogOpen} /*onClose={this.handleClose}*/>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogTitle}>
              {this.props.title}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogContent}>
                {this.props.content}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="가견적" type="text" name="applicationInvoice" 
              value={this.state.applicationInvoice} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="간단한 내용 및 문의" type="text" name="applicationContent" 
              value={this.state.applicationContent} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
                  onClick={this.handleApplication}>
                    <Typography className={classes.typoButton}>견적 발송</Typography>
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
                  onClick={this.handleClose}>
                    <Typography className={classes.typoButton}>취소</Typography>
                  </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        :
        <Dialog /*fullScreen={true}*/ fullWidth={true} /*maxWidth="md"*/ open={this.state.submitDialogOpen} /*onClose={this.handleClose}*/>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogTitle}>
              {this.props.title}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogContent}>
                {this.props.content}
            </Typography>
          </DialogContent>
          {/*
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="가견적" type="text" name="applicationInvoice" 
              value={this.state.applicationInvoice} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          */}
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="간단한 내용 및 문의" type="text" name="applicationContent" 
              value={this.state.applicationContent} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
                  onClick={this.handleApplication}>연락처 남기기</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        }
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>가견적을 입력해주세요</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleClosePreInvoice}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.deleteDialogOpen} /*onClose={this.handleClose}*/>
          <DialogTitle>글을 삭제하시겠습니까?</DialogTitle>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.dialogButton}
                  onClick={this.handleDelete}>확인</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.dialogButton}
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        {/*
        <Dialog open={this.state.applyDialogOpen}>
          <DialogTitle>지원신청이 완료 되었습니다.</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button}
                  onClick={this.handleClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        */}
      </Grid>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  // isFavoriteLoading: state.upload.isFavoriteLoading,
  isApplyingLoading: state.upload.isApplyingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  /*
  favoriteloadingtrue: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingtrue(isFavoriteLoading)),
  favoriteloadingfalse: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingfalse(isFavoriteLoading)),
  */
  applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
})

Feed_HomeGetProjects = withStyles(styles, {withTheme: true})(Feed_HomeGetProjects)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_HomeGetProjects);