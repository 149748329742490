import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import Feed_ApplicantList from './Feed_ApplicantList';

// S3 관련
import { API, Storage } from "aws-amplify";
import config from "../config";

import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';

import SVGIcon from './SVGIcon'

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(7),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  title: {
    flexGrow: 1,
    fontSize : 16,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '30px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  iconButton: {
    margin: theme.spacing(0),
    //paddingLeft: 0
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  typoSubmit: {
    fontSize: 15
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  card2: {
    maxWidth: 64,
    marginTop: 5,
    marginBottom: 15,
    boxShadow: "none",
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 50,
  },
  listItemIcon: {
    minWidth: 0
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  list2: {
    width: '100%',
    padding: '0',
  },
  listItemTitle2: {
    paddingTop: 10,
    paddingBottom: 10,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle2: {
    width: '100%',
    fontSize : 14,
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  cardHeader: {
    width: '100%',
    paddingRight: 0,
  },
  textFieldName : {
    width: '100%',
    fontSize: 16,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '3px',
    paddingRight: '24px',
  },
  textField : {
    width: '100%',
    fontSize: 16,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '0px',
    paddingRight: '24px',
  },
  textFieldSize: {
    fontSize: 16,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    //backgroundColor: red[500],
    backgroundColor: '#D3D3D3',
    margin: 0,
    width: 80,
    height: 80,
  },
  avatarIconButton: {
    padding: 0,
  },
  media: {
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    width : '100%',
    height: '100%'
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%', /* 720 / 1280 = 0.5625 */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    // bottom: 20,
  },
});

class ApplicantList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      getApplications: '',
      getApplication: '',
      userContents: '',
      userContent: '',
      applicationTitle: '',
      isLoading: false,
      completed : 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    await this.getApplications();
    await this.getUsers();

    let applicationTitle;
    Object.keys(this.state.getApplications).map(applicationUUId => {
      const getApplication = this.state.getApplications[applicationUUId];
      applicationTitle = getApplication.applicationTitle;
    });

    if(applicationTitle) 
    {
      this.setState({applicationTitle: applicationTitle})
    }
  }

  // 나한테 지원한 프로젝트 리스트
  getApplications() {
    // const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
    //return API.get("upload", `/application/?uploadUserId=${currentAuthUserFromSession_Company}&uploadUUId=${this.state.uploadUUId}`)
    //return API.get("upload", `/application/?uploadUserId=${currentAuthUserFromSession_Company}`)
    return API.get("upload", `/application/?uploadUUId=${this.props.match.params.id}`)
    .then(getApplications => this.setState({getApplications : getApplications}))
  }

  getUsers() {
    return API.get("upload", '/user')
    .then(userContents => this.setState({userContents : userContents}));
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, searchKeyword } = this.props;

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredCompanyComponents = (data) => {
      data = data.filter((c) => {
        return c.uploadUUId.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {

        let walletAddress
        let customerType1
        let customerType2
        let companyName
        let companyPhoneNumber
        let address
        let homepage
        let history
        let name
        let phoneNumber
        let email
        let avatarimage
        Object.keys(this.state.userContents).map(userId => {
          const userContent = this.state.userContents[userId];
          if(userContent.userId === c.userId) {
            walletAddress = userContent.walletAddress;
            customerType1 = userContent.customerType1;
            customerType2 = userContent.customerType2;
            companyName = userContent.companyName;
            companyPhoneNumber = userContent.companyPhoneNumber;
            address = userContent.address;
            homepage = userContent.homepage;
            history = userContent.history;
            name = userContent.name;
            phoneNumber = userContent.phoneNumber;
            email = userContent.email;
            avatarimage = userContent.avatarimage;
          }
        });

        if(customerType2 === 'company') {
          return(
            <Feed_ApplicantList
              stateRefresh={this.stateRefresh}
              key={c.applicationUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
              id={c.id}
              userId={c.userId}
              uploadUUId={c.uploadUUId}
              applicationUUId={c.applicationUUId}
              date={c.date}
              applicationTitle={c.applicationTitle}
              dateStart={c.dateStart}
              dateEnd={c.dateEnd}
              applicationToggle={c.applicationToggle}
              applicationInvoice={c.applicationInvoice}
              applicationContent={c.applicationContent}
              orderComment={c.orderComment}
              orderSign={c.orderSign}
              applicationSign={c.applicationSign}
              walletAddress = {walletAddress}
              customerType1 = {customerType1}
              customerType2 = {customerType2}
              companyName = {companyName}
              companyPhoneNumber = {companyPhoneNumber}
              address = {address}
              homepage = {homepage}
              history = {history}
              name = {name}
              phoneNumber = {phoneNumber}
              email = {email}
              avatarimage = {avatarimage}
            />
          );
        }
      })
    }

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredFreelancerComponents = (data) => {
      data = data.filter((c) => {
        return c.uploadUUId.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {

        let walletAddress
        let customerType1
        let customerType2
        let companyName
        let companyPhoneNumber
        let address
        let homepage
        let history
        let name
        let phoneNumber
        let email
        let avatarimage
        Object.keys(this.state.userContents).map(userId => {
          const userContent = this.state.userContents[userId];
          if(userContent.userId === c.userId) {
            walletAddress = userContent.walletAddress;
            customerType1 = userContent.customerType1;
            customerType2 = userContent.customerType2;
            companyName = userContent.companyName;
            companyPhoneNumber = userContent.companyPhoneNumber;
            address = userContent.address;
            homepage = userContent.homepage;
            history = userContent.history;
            name = userContent.name;
            phoneNumber = userContent.phoneNumber;
            email = userContent.email;
            avatarimage = userContent.avatarimage;
          }
        });

        if(customerType2 === 'freelancer') {
          return(
            <Feed_ApplicantList
              stateRefresh={this.stateRefresh}
              key={c.applicationUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
              id={c.id}
              userId={c.userId}
              uploadUUId={c.uploadUUId}
              applicationUUId={c.applicationUUId}
              date={c.date}
              applicationTitle={c.applicationTitle}
              dateStart={c.dateStart}
              dateEnd={c.dateEnd}
              applicationToggle={c.applicationToggle}
              applicationInvoice={c.applicationInvoice}
              applicationContent={c.applicationContent}
              orderComment={c.orderComment}
              orderSign={c.orderSign}
              applicationSign={c.applicationSign}
              walletAddress = {walletAddress}
              customerType1 = {customerType1}
              customerType2 = {customerType2}
              companyName = {companyName}
              companyPhoneNumber = {companyPhoneNumber}
              address = {address}
              homepage = {homepage}
              history = {history}
              name = {name}
              phoneNumber = {phoneNumber}
              email = {email}
              avatarimage = {avatarimage}
            />
          );
        }
      })
    }

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    지원업체 및 지원자
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                      <Typography className={classes.typoTitle}>
                        {/* 글자 길이가 20개 이상 넘어가면 '...' 나옴 */}
                        {this.state.applicationTitle.slice(0,20)}
                        {this.state.applicationTitle.length > 20 
                        ? '...'
                        : null
                        }
                      </Typography>
                    </ListItem>
                  </List>
                  <List className={classes.list2}>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        지원 업체
                      </Typography>
                    </ListItem>
                  </List>
                  {/* filter(검색)기능 */}
                  {this.state.getApplications && this.state.userContents
                    ?
                    filteredCompanyComponents(this.state.getApplications)
                    :
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                      <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                    </Grid>
                  }
                  <Divider />
                  <List className={classes.list2}>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        지원자
                      </Typography>
                    </ListItem>
                  </List>
                  {/* filter(검색)기능 */}
                  {this.state.getApplications && this.state.userContents
                    ?
                    filteredFreelancerComponents(this.state.getApplications)
                    :
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                      <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                    </Grid>
                  }
                </Card>
              </Grid>
            </Grid>
          </main>
          {/*
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Toolbar>
              <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button variant="contained" className={classes.button} 
                    onClick={this.connect}
                  >
                    <Typography className={classes.typoSubmit}>연락하기</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          */}
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  searchKeyword: state.upload.searchKeyword,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
})

ApplicantList = withStyles(styles, {withTheme: true})(ApplicantList)
export default connect(mapStateToProps)(ApplicantList);