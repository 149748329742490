import React, { Component } from 'react'
import ui from 'utils/ui'
import { makeStyles } from '@material-ui/core/styles';
import UploadPhoto from './UploadPhoto'
import { connect } from 'react-redux'
import *as uploadActions from 'redux/actions/upload'
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  uploadButton: {
    width: '50px',
    height: '50px',
    position: 'fixed',
    right: '50px',
    bottom: '50px',
    marginBottom: '30px',
    zIndex: '5',
    color: '#0c93d0',
    background: '#0c93d0',
    borderRadius: '50%',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    cursor: 'pointer',
    '&::before, &::after': {
      content: '""',
      display: 'block',
      width: '24px',
      height: '2px',
      backgroundColor: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&::after': {
      transform: 'translate(-50%, -50%) rotate(90deg)',
    },
    [theme.breakpoints.down('sm')]: {
      right: '24px',
      bottom: '24px',
    },
  },
}));

function UploadButton(props) {
  const { uploadPage, upload, children } = props;
  const classes = useStyles();
  const handleUpload = () => {
    upload();
  }
  return(
    <div>
      <Link component={RouterLink} to='/upload' underline='none' color='inherit'>
        <button className={classes.uploadButton} onClick={handleUpload}></button>
      </Link>
      {children}
    </div>
  );
}

const mapStateToProps = (state) => ({
  uploadPage:state.upload.uploadPage,
})

const mapDispatchToProps = (dispatch) => ({
  upload: () =>dispatch(uploadActions.upload()),
})

UploadButton = withRouter(UploadButton);

export default connect(mapStateToProps, mapDispatchToProps)(UploadButton);
