import React, { Component } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Hidden } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
// import Textarea from './Textarea'
import UploadFeedComment from './UploadFeedComment'
import UploadFeedFavorite from './UploadFeedFavorite'
import UploadFeedFavoriteList from './UploadFeedFavoriteList'
import UploadFeedApplication from "./UploadFeedApplication";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import { connect } from 'react-redux'

// MoreVertIcon 관련
import PopupState, { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

// S3 관련
import { API, Storage } from "aws-amplify";
import config from "../config";
import { s3DeletePublic } from "../libs/awsLib";

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

// 동영상 관련
import ReactPlayer from 'react-player'

import SVGIcon from './SVGIcon'

// import './UploadFeed.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    //flexGrow: 1,
    // paddingBottom: 60,
    paddingBottom : theme.spacing(17),
  },
  topAppBar: {
    background: "#FFFFFF",
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    /*
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    */
  },
  toolBar: {
    height : 10,
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    // bottom: 20,
  },
  bottomToolbar: {
    width: '100%',
  },
  // toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    fontSize : 16,
    color : '#000000',
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  typoSubmit: {
    fontSize: 17,
  },
  content: {
    marginTop: '60px',
    flexGrow: 1,
    padding: theme.spacing(0),
    display: 'flex',
    marginBottom: '70px'
  },
  iconButton: {
    margin: theme.spacing(0),
    //paddingLeft: 0
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  moreVertIcon: {
    width: 20,
    height: 20,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  card: {
    width:'100%',
    //maxWidth: 650,
    //marginTop: 15,
    //marginBottom: 15,
    //marginLeft : theme.spacing(1),
    //marginRight : theme.spacing(1),
  },
  list: {
    width: '100%',
    padding: '0',
  },
  /*
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  cardHeader: {
    //textAlign: 'left',
    paddingTop: 10,
    paddingBottom: 10,
  },
  cardContent: {
    textAlign: 'left',
    padding: theme.spacing(5),
    paddingTop: 10,
    paddingBottom: 10,
  },
  commentCard: {
    textAlign: 'left',
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
  },
  commentCardHeader: {
    textAlign: 'left',
    paddingTop: 5,
    paddingBottom: 5,
  },
  commentCardContent: {
    textAlign: 'left',
    paddingTop: 5,
    paddingBottom: 5,
  },
  commentNickName: {
    textAlign: 'left',
  },
  commentContent: {
    textAlign: 'left',
  },
  commentDate: {
    textAlign: 'left',
  },
  commentCardActions: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 10,

  },
  commentThumbsUp: {
    marginRight: 7,
  },
  commentThumbsDown: {
    marginLeft: 7,
  },
  */
  avatar: {
    // backgroundColor: red[500],
    backgroundColor: '#0000FF',
    margin: 10,
    width: 80,
    height: 80,
  },
  avatarmedia: {
    marginTop: 5,
    height: 100,
    width: "100%",
    padding: 20,
    objectFit: 'contain'
  },
  typoName: {
    flexGrow: 1,
    fontSize : 18,
    paddingLeft: '20px',
    paddingRight: '24px',
  },
  typoTitle: {
    fontSize : 17,
    paddingLeft: 20,
    paddingTop: 0,
    paddingBottom: 20,
  },
  typoContent: {
    fontSize : 17,
    paddingLeft: 20,
    paddingTop: 0,
    paddingBottom: 18,
  },
  typoDate: {
    fontSize : 17,
    paddingLeft: 20,
    paddingTop: 0,
    paddingBottom: 18,
  },
  media: {
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    width : '100%',
    height: '100%'
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%', /* 720 / 1280 = 0.5625 */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  /*
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  commentAvatar: {
    backgroundColor: '#0000FF',
    margin: 5,
    width: 35,
    height: 35,
  },
  */
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  bottomAppBar: {
    top: 'auto',
    //bottom: 0,
    bottom: 100,
  },
  popupStateTypography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  popupStateMenu: {
    padding : 0,
  },
  Textarea__textarea: {
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      //height: '4vh',
      fontSize: 17,
      border: '1px solid light-grey',
      borderRadius: 5,
      paddingLeft: '22px',
      paddingRight: '24px',
      resize: 'none;', /* 크기고정 */ 
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      //height: '4vh',
      fontSize: 20,
      border: '1px solid light-grey',
      borderRadius: 5,
      paddingLeft: '22px',
      paddingRight: '24px',
      resize: 'none;', /* 크기고정 */ 
    },
  },
  /*
  textareaContent: {
    marginTop: '0px',
    marginBottom: '0px',
    padding : 10,
  },
  */
  commentIconButton: {
    padding : 10,
    color: "#0c93d0",
  },
  typoCommentSubmit: {
    fontSize: 20,
  },
});

class UploadFeed extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      // textarea 관련
			rows: 1,
			minRows: 1,
      maxRows: 4,
      //-------------
      user: null,
      upload: null,
      avatarAttachmentURL: null,
      imageAttachmentURLs: [],
      uploadUserId: '',
      currentUserId: '',
      currentUserNickName: '',
      uploadUUId: '',
      customerType2: '',
      nickName: "",
      date: '',
      //selectTarget: '',
      content: "",
      dateStart: null,
      dateEnd: null,
      avatarimage: "",
      images: [],
      customerName: '',
      isModify: false,
      getUploadConnects: '',
      getUploadConnect: '',
      completed : 0,
      deleteDialogOpen : false, // 수정, 삭제 다이얼로그 창이 열려 있는지 확인하는 변수
      pageDialogOpen: false,  // userId 확인 다이얼로그 창이 열려 있는지 확인하는 변수
      // isLoading: false,
      //
      // 댓글 관련 state
      commentContents: '',
      commentContent: '',
      userContents: '',
      userContent: '',
      //

      // favorite 관련
      /*
      favoriteUploadUUIds: '',
      favoriteUploadUUId: '',
      */

      getApplications: '',
      getApplication: '',
      
      /*
      favoriteThumbsLists: '',
      favoriteThumbsList: '',
      */

      // 댓글의 favorite 관련
      /*
      commFavorThumbsSabuIds: '',
      commFavorThumbsSabuId: '',
      */

      // 알림 발송 위한 state
      notiUserId: '',
      notifications: '',
      title: '',
      body: '',
      fcmTokenIds: [], // 여러명한테 FCM 발송시 시도,
      notiFCM : '',
      /*
      getFollowers: '',
      notiSettings: '',
      */
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { commentloadingtrue, commentloadingfalse,
            /*favoriteloadingtrue, favoriteloadingfalse,*/
            applyingloadingtrue, applyingloadingfalse,
            /*commfavoriteloadingtrue, commfavoriteloadingfalse*/ } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    /*
    // getCurrentUser()관련
    const currentUser = await this.getCurrentUser();
    const { userId, companyName, name } = currentUser;

    this.setState({
      currentUserId : userId,
      currentUserCompanyName : companyName,
      currentUserName : name
    });
    */

    //if(this.state.currentUserId === currentAuthUserFromSession) {
    if(currentAuthUserFromSession) {
      // getUpload() 관련
      try {
        let imageAttachmentURLs = [];
        const upload = await this.getUpload();
        const { userId, uploadUUId, date, field, subField, title, content, dateStart, dateEnd, images } = upload;

        this.setState({uploadUserId: userId});
      
        // AWS S3 사용시 (upload 관련 파일)
        for(let i=0;i<images.length;i++) {
          if (images[i] != 'No_File') {
              imageAttachmentURLs[i] = await Storage.get(images[i], {level: 'public'});
          }

          this.setState({
            upload,
            uploadUUId,
            date,
            field,
            subField,
            title,
            content,
            dateStart,
            dateEnd,
            imageAttachmentURLs: this.state.imageAttachmentURLs.concat(imageAttachmentURLs[i]),
            images: this.state.images.concat(images[i]),
            expanded : true,
          });
        }
      } catch (e) {
        // alert(e);
        console.log(e);
      }

      /*
      // getUploadConnects
      await this.getUploadConnects()
      try {
        let IsModify = true;
        if(this.state.getUploadConnects) {
          Object.keys(this.state.getUploadConnects).map(uploadConnectUUId => {
            const getUploadConnect = this.state.getUploadConnects[uploadConnectUUId];
              if(getUploadConnect.orderUserId === this.state.uploadUserId) {
                IsModify = false;
              }
          })
        }
        if(IsModify === true) {
          this.setState({isModify: true})
        } else if(IsModify === false) {
          this.setState({isModify: false})
        }
      } catch(e) {
        console.log(e)
        this.setState({isModify: false})
      }
      */

      // getUser() 관련 (uploadUser와 매칭되서 작성된 글의 user를 찾는 것이다.)
      try {
        let avatarAttachmentURL;
        const user = await this.getUser();
        const { customerType2, companyName, name, companyPhoneNumber, phoneNumber, email, avatarimage } = user;

        // 첨부 파일이 있으면 키를 사용하여 S3에 업로드 한 파일에 대한 보안 링크를 얻습니다. 
        // 그런 다음이를 구성 요소의 상태로로 저장합니다 attachmentURL.
        // 그 이유는 우리는 왜이 upload와 함께 상태에서 개체를 content하고는 
        // attachmentURL우리가 나중에 사용자가 노트를 편집 할 때 사용되기 때문이다.

        // AWS S3 사용시 (user 관련 파일)
        if (avatarimage) {
          avatarAttachmentURL = await Storage.get(avatarimage, {level: 'public'});
        }

        this.setState({
          user,
          avatarAttachmentURL,
          customerType2,
          companyName,
          name,
          companyPhoneNumber,
          phoneNumber,
          email,
          avatarimage,
        });
      } catch (e) {
        //alert(e);
        console.log(e);
      }
    
      //
      // await this.getUsers();

      commentloadingtrue();
      commentloadingfalse();

      /*
      favoriteloadingtrue();
      favoriteloadingfalse();
      */

      applyingloadingtrue();
      applyingloadingfalse();
      
      //

      /*
      try {
      const favorite = await this.getFavoritesThumbsUp();
      const { userId, uploadUUId, favoriteUUId, favoriteId, favoriteContent } = favorite;

      this.setState({
        favorite,
        favoriteUserId : userId,
        favoriteUploadUUId : uploadUUId ,
        favoriteUUId,
        favoriteId,
        favoriteContent,
      });

      } catch (e) {
        console.log(e);
      }
      */
      
      // console.log("favoriteContent :")      
      // console.log(this.state.favoriteContent)      
      //

      /*
      commfavoriteloadingtrue();
      commfavoriteloadingfalse();
      */
     
    // } else if(this.state.currentUserId === undefined) {
    } else if(currentAuthUserFromSession === undefined) {
      // console.log("userId is undefined");
      // console.log('currentUser: ' + currentUser);
      // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
      // console.log('userId: ' + this.state.userId);
      // this.props.history.push(`/user/${this.props.currentUser}`);
      this.setState({
        pageDialogOpen : true,
      });
    }
    else {
      // console.log("userId is not matched");
      this.setState({
        pageDialogOpen : true,
      });
      // console.log('currentUser: ' + currentUser);
      // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
      // console.log('userId: ' + this.state.userId);
    }

    // 글작성시 알림발송을 위한 코드
    // FCM으로 발송할 데이터를 미리 받아서 fcmTokenId를 배열로 저장.
    // 나중에 발송시 FCM으로 fcmTokenId 배열로 보내면 최대 1,000명한테 한번에 발송 가능

    await this.getNotifications();

    try {
      if(this.state.commentContents && this.state.notifications) {
        Object.keys(this.state.commentContents).map(uploadUUId => {
          const commentContent = this.state.commentContents[uploadUUId];
  
          if(commentContent.userId === notifications.userId && commentContent.userId != currentAuthUserFromSession) {
            let fcmTokenId
            Object.keys(this.state.notifications).map(notificationUUId => {
              const notification = this.state.notifications[notificationUUId];
                fcmTokenId = notification.fcmTokenId
                this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
            });
          }
        })
      }
    } catch(e) {
      ;
    }

    try {
      // upload한 사람 관련 체크
      const getnotification = await this.getNotification();
      const { userId, fcmTokenId} = getnotification;
      this.setState({notiUserId : userId, notiFCM : fcmTokenId});

      if (this.state.notiUserId != currentAuthUserFromSession) {
        this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(this.state.notiFCM) })
      }
    } catch(e) {
      ;
    }

    
    /*
    await this.getFollowers();
    await this.getNotiSettings();
    await this.getNotifications();
    
    // fcmTokenIds를 배열로 받기
    if(this.state.getFollowers && this.state.notiSettings && this.state.notifications) {
      Object.keys(this.state.getFollowers).map(followingUUId => {
        const getFollower = this.state.getFollowers[followingUUId];
        
        Object.keys(this.state.notiSettings).map(notiSettingUUId => {
          const notiSetting = this.state.notiSettings[notiSettingUUId];

          if(getFollower.followerUserId === notiSetting.userId) {
            let fcmTokenId
            Object.keys(this.state.notifications).map(notificationUUId => {
              const notification = this.state.notifications[notificationUUId];
              if(notiSetting.followCommentToggle === "true") {
                if(notiSetting.userId === notification.userId) {
                  fcmTokenId = notification.fcmTokenId
                  this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
                }
              }
            });
          }
        })
      })
    }
    */
    if(this.state.customerType2 === 'company') {
      this.setState({customerName: this.state.companyName})
    } else if(this.state.customerType2 === 'freelancer') {
      this.setState({customerName: this.state.name})
    }
  }

  // 경로 ( /user/:id). 작성된 업로드의 user를 찾기 위한 API
  // uploadUser와 매칭되서 작성된 글의 user를 찾는 것이다.
  getUser() {
    return API.get("upload", `/user/${this.state.uploadUserId}`);
  }

  /*
  // 경로 ( /user/:id). 로그인된 사용자 확인용 
  getCurrentUser() {
    // const { currentUser } = this.props
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    // return API.get("upload", `/user/${currentUser}`);
    return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }
  */

  // 경로 ( /upload/:id) 
  getUpload() {
    return API.get("upload", `/upload/${this.props.match.params.id}`);
  }

  /*
  // 경로 ( /upload/:id) 
  getUploadConnects() {
    return API.get("upload", `/uploadconnect?uploadUUId=${this.props.match.params.id}`)
    .then(getUploadConnects => this.setState({getUploadConnects : getUploadConnects}));
  }
  */

  // 삭제 API
  deleteUpload() {
    return API.del("upload", `/upload/${this.state.uploadUUId}`);
  }

  /*
  // Favorite 관련 (event.queryStringParameters 사용 시. 특정 favoriteUUId 불러올 때)
  getFavoriteUpload() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/favorite/?userId=${currentAuthUserFromSession}&uploadUUId=${this.state.uploadUUId}`)
    .then(favoriteUploadUUIds => this.setState({favoriteUploadUUIds : favoriteUploadUUIds}));
  }

  // 다시 확인해보기
  getFavoritesThumbsUp() {
    return API.get("upload", `/favorite/?uploadUUId=${this.state.uploadUUId}`);
  }

  // FavoriteList 관련 (event.queryStringParameters 사용 시. 특정 favoriteUUId 불러올 때)
  getFavoritesList() {
    return API.get("upload", `/favorite/?uploadUUId=${this.state.uploadUUId}`)
    .then(favoriteThumbsLists => this.setState({favoriteThumbsLists : favoriteThumbsLists}))
    //.then(sessionStorage.removeItem("favoriteThumbsLists"))
    .then(sessionStorage.setItem("favoriteThumbsLists", this.state.favoriteThumbsLists));
  }
  */

  // 내가 지원한 프로젝트 리스트
  getApplications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/application/?userId=${currentAuthUserFromSession}&uploadUUId=${this.state.uploadUUId}`)
    .then(getApplications => this.setState({getApplications : getApplications}));
  }
  
  //
  // Comment 관련 (event.queryStringParameters 사용 시. 특정 sabuId 불러올 때)
  getCommentsUploadUUId() {
    return API.get("upload", `/comment/?uploadUUId=${this.state.uploadUUId}`)
    .then(commentContents => this.setState({commentContents : commentContents}));
  }

  /*
  getUsers() {
    return API.get("upload", `/user/?uploadUUId=${this.state.uploadUUId}`)
    .then(userContents => this.setState({userContents : userContents}))
    //.then(sessionStorage.removeItem("userContentSabuIds"))
    .then(sessionStorage.setItem("userContentUploadUUIds", this.state.userContents));
  }
  */

  postComment(commentContent) {
    return API.post("upload", "/comment", {
      body: commentContent
    });
  }
  //

  /*
  // CommentFavorite 관련 (event.queryStringParameters 사용 시. 특정 favoriteUUId 불러올 때)
  getCommentFavorites() {
    return API.get("upload", `/commentFavorite/?uploadUUId=${this.state.uploadUUId}`)
    .then(commFavorThumbsUploadUUIds => this.setState({commFavorThumbsUploadUUIds : commFavorThumbsUploadUUIds}));
  }
  */

  //-------------------------------------------------------------------------------
  // 글 작성시 알림 발송을 위한 코드
    getNotifications() {
    return API.get("upload", "/notification")
    .then(notifications => this.setState({notifications : notifications}));
  }

  getNotification() {
    return API.get("upload", `/notification/${this.state.uploadUserId}`)
  }

  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }

  /*
  postNotificationList(notificationList) {
    return API.post("upload", "/notificationList", {
      body: notificationList
    });
  }

  // 나를 Following한 사람들 리스트
  getFollowers() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/follow/?followeeUserId=${currentAuthUserFromSession}`)
    .then(getFollowers => this.setState({getFollowers : getFollowers}));
  }

  getNotiSettings() {
    return API.get("upload", "/notiSetting")
    .then(notiSettings => this.setState({notiSettings : notiSettings}));
  }
  
  getNotifications() {
    return API.get("upload", "/notification")
    .then(notifications => this.setState({notifications : notifications}));
  }

  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }
  //-------------------------------------------------------------------------------

  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }
  //-------------------------------------------------------------------------------
  */

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  handleGoToPage = () => {
    // const { currentUser } = this.props
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    this.setState({
      pageDialogOpen : false,
    });
    // this.props.history.push(`/user/${currentUser}`);
    this.props.history.push(`/user/${currentAuthUserFromSession}`);
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  
  handletextAreaChange = (event) => {
		const textareaLineHeight = 24;
		const { minRows, maxRows } = this.state;
		
		const previousRows = event.target.rows;
  	event.target.rows = minRows; // reset number of rows in textarea 
		
		const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);
    
    if (currentRows === previousRows) {
    	event.target.rows = currentRows;
    }
		
		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
    }
    
  	this.setState({
    	commentContent: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  };
  

  handlePutClick = () => {
    this.props.history.push(`/upload/Modify/${this.state.uploadUUId}`);
  }

  deleteDialog = async event => {
    event.preventDefault();

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.state.uploadUserId === currentAuthUserFromSession) {
      this.setState({
        deleteDialogOpen : true,
      });
    }
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      pageDialogOpen: false,
      deleteDialogOpen : false,
      submitDialogOpen : false,
      applyDialogOpen : false,
    })
  }

  // 실제로 데이터가 삭제되는 것을 도와주기 위한 함수
  handleDelete = async event => {
    event.preventDefault();
    // const { currentUser } = this.props;

    this.setState({ isDeleting: true });

    for(let i=0;i<this.state.images.length;i++) {
      if(this.state.images[i] != 'No_File') {
        await s3DeletePublic(this.state.images[i]);
      }
    }

    try {
      await this.deleteUpload();

      /*
      await this.putPoint({
        pointEvent: "uploadCancel",
      });
      */
      this.props.history.push('/');
      // window.location.reload();
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  commentSubmit = async event => {
    event.preventDefault();
    const { commentloadingtrue, commentloadingfalse } = this.props;


    // 알림 발송을 위한 코드
    try {
      await this.postNotificationFCM({
        registration_ids: this.state.fcmTokenIds,
        title: this.state.title,
        body: this.state.content,
        //icon: 'icon',
        click_action: "https://otwogong.com",
        //click_action: 'http://192.168.0.3:5555/',
      });

      // this.props.history.push("/");
    } catch (e) {
      // console.log("postNotificationFCM 에러");
      console.log(e)
    }

    /*
    // postNotificationList()
    try {
      await this.postNotificationList({
        title: this.state.currentUserNickName,
        body: this.state.commentContent,
        image: "default",
      });
    } catch (e) {
      console.log("postNotificationList 에러");
    }
    */

    /*
    // postFCM 실행 (여러명한테 발송)
    try {
      await this.postNotificationFCM({
        registration_ids: this.state.fcmTokenIds,
        title: this.state.currentUserNickName,
        body: this.state.commentContent,
      });
    } catch (e) {
      console.log("postNotificationList 에러");
    }
    */

    /*
    // file을 같이 발송시에 사용. 현재는 파일 발송은 안 할 예정
    if(this.state.file) {
      try {
        const attachment = this.state.file
        ? await s3UploadPublic(this.state.file)
        : null;

        await this.postNotificationList({
          attachment,
          title: this.state.title,
          body: this.state.body,
          image: attachment,
        });
        //this.props.history.push("/");
      } catch (e) {
        // alert(e);
        console.log("postNotificationList() 동작 안됨");
        this.setState({ isLoading: false });
      }
    } else {
      await this.postNotificationList({
        title: this.state.title,
        body: this.state.body,
        image: "default",
      });
      //this.props.history.push("/");
    }
    
    // postFCM 실행 (여러명한테 발송)
    if(this.state.file) {
      try {
        const attachment = this.state.file
        ? s3UploadPublic(this.state.file)
        : null;

        this.postNotificationFCM({
          attachment,
          registration_ids: this.state.fcmTokenIds,
          title: this.state.title,
          body: this.state.body,
          image: attachment,
        });
        console.log("FCM Post 동작 1");
        this.setState({postFCMs : false});
        this.props.history.push("/");
      } catch (e) {
        // alert(e);
        console.log("FCM Post 동작 안됨");
        this.setState({postFCMs : false});
        this.setState({ isLoading: false });
      }
    } else {
      await this.postNotificationFCM({
        registration_ids: this.state.fcmTokenIds,
        title: this.state.title,
        body: this.state.body,
      });
      console.log("FCM Post 동작 2");
      this.setState({postFCMs : false});
      this.props.history.push("/");
    }
    */

    commentloadingtrue();
    
    try {
      await this.postComment({
        uploadUUId: this.state.uploadUUId,
        commentContent: this.state.commentContent,
      });

      /*
      await this.putPoint({
        pointEvent: "comment",
      });
      */

      commentloadingfalse();

      this.setState({
        commentContent: '',
        rows: 1,
      });
      // this.props.history.push("/");
    } catch (e) {
      alert(e);
      commentloadingfalse();
    }
  }

  LinkButton = () => {
    sessionStorage.setItem("uploadUserId", null);
    sessionStorage.setItem("uploadUserId", this.state.uploadUserId);
    /*
    sessionStorage.setItem("followingToggle", null);
    sessionStorage.setItem("followingToggle", this.props.followingToggle);
    sessionStorage.setItem("followerUserId", null);
    sessionStorage.setItem("followerUserId", this.props.followerUserId);
    */
  }

  /*
  compressImage = async (imageFile) => {
    try {
      const compressedFile = await imageCompression(imageFile, MAX_IMAGE_SIZE_MB)
      this.setState({
        isCompressing: false,
        file: compressedFile,
        fileName: compressedFile.name,
      })
    } catch (error) {
      this.setState({
        isCompressing: false,
        warningMessage: '* Fail to compress image'
      })
    }
  }
  */

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  /*
  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  }
  */

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    uploadContents : '',
    userContents : '',
    //favoriteThumbsUploadUUIds: '',
    //commFavorThumbsUploadUUIds: '',
    completed : 0,
    });
    
    this.getUsers();
    // 초기화 이후 uploadContents 목록을 불러온다.
    //this.getComments();
    this.getCommentsUploadUUId();
  }

  render() {
    const { classes, theme, /*isFavoriteLoading,*/ isApplyingLoading, isCommentLoading, /*isCommFavoriteLoading*/ } = this.props;
    const { commentContent, } = this.state
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    // const message = `Truncation should be conditionally applicable on this long line of text as this is a much longer line than what the container can support. `;
    // const i = 0;

    if(isCommentLoading) {
      // this.getComments();
      this.getCommentsUploadUUId();
    }

    /*
    if(isFavoriteLoading) {
      this.getFavoriteUpload();
      //this.getFavoritesList();
    }
    */

    if(isApplyingLoading) {
      this.getApplications();
    }

    /*
    if(isCommFavoriteLoading) {
      this.getCommentFavorites();
    }
    */

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.topAppBar}>
            <Toolbar className={classes.toolBar}>
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} align='center' variant="h6" noWrap>
                    오투공
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          { this.state.user  && this.state.avatarAttachmentURL && this.state.upload
          ?
          <main className={classes.content}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                {/* AWS S3 사용시 바로 아래 attachmentURL관련 state 주석 풀기 */}
                {/* <div className={classes.toolbar} /> */}
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ListItem>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          {this.state.avatarAttachmentURL
                          ?
                            this.state.customerType2 === 'company'
                            ?
                            <img
                              className={classes.avatarmedia}
                              src={this.state.avatarAttachmentURL}
                            />
                            :
                            <Avatar src={this.state.avatarAttachmentURL} aria-label="Recipe" className={classes.avatar}>
                              <AccountCircleIcon style={{fontSize: '75px'}}/>
                            </Avatar>
                          :
                            <Avatar aria-label="Recipe" className={classes.avatar}>
                              <AccountCircleIcon style={{fontSize: '75px'}}/>
                            </Avatar>
                          }
                        </Grid>
                        <Grid item xs={7} sm={7} md={6} lg={6} xl={6} align="start">
                          <Typography className={classes.typoName}>{this.state.customerName}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                          {currentAuthUserFromSession === this.state.uploadUserId
                          ?
                          /*this.state.isModify === true
                          ?*/
                          <PopupState variant="popover" popupId="MoreVertIconPopover">
                            {popupState => (
                              <div>
                                <IconButton className={classes.iconButton} variant="contained" {...bindTrigger(popupState)}>
                                  <MoreVertIcon className={classes.moreVertIcon}/>
                                </IconButton>
                                <Menu {...bindMenu(popupState)}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <MenuItem onClick={popupState.close} className={classes.popupStateMenu}>
                                    <Typography variant="body2" style={{ cursor: 'pointer' }} 
                                      onClick={this.handlePutClick} className={classes.popupStateTypography}
                                      variant="h6" noWrap
                                    >
                                      수정
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem onClick={popupState.close} className={classes.popupStateMenu}>
                                    <Typography variant="body2" style={{ cursor: 'pointer' }} 
                                      onClick={this.deleteDialog} className={classes.popupStateTypography}
                                      variant="h6" noWrap
                                    >
                                      삭제
                                    </Typography>
                                  </MenuItem>
                                </Menu>
                              </div>
                            )}
                          </PopupState>
                          /*:
                          null*/
                          :
                          null
                          /*
                          this.state.favoriteUploadUUIds.length > 0
                            ?
                            Object.keys(this.state.favoriteUploadUUIds).map(favoriteUUId => {
                              const favoriteUploadUUId = this.state.favoriteUploadUUIds[favoriteUUId];
                              return(
                                <UploadFeedFavorite
                                  stateRefresh={this.stateRefresh}
                                  key={favoriteUploadUUId.favoriteUUId}
                                  id={favoriteUploadUUId.favoriteUUId}
                                  favoriteUserId={favoriteUploadUUId.userId}
                                  favoriteUploadUUId={favoriteUploadUUId.uploadUUId}
                                  favoriteUUId={favoriteUploadUUId.favoriteUUId}
                                  favoriteDate={favoriteUploadUUId.favoriteDate}
                                  favoriteContent={favoriteUploadUUId.favoriteContent}
                                />
                              );
                            })
                            :
                            <UploadFeedFavorite
                              favoriteUploadUUId={this.state.uploadUUId}
                            />
                          */ 
                          }
                        </Grid>
                      </ListItem>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.typoTitle}>
                            {this.state.title}
                          </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.typoContent}>
                            {this.state.content}
                          </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className={classes.typoDate}>
                          {moment(this.state.dateStart).format("YYYY-MM-DD")} 부터 {moment(this.state.dateEnd).format("YYYY-MM-DD")} 까지
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                    {/* AWS S3 사용시 */}
                    {this.state.images &&
                      (this.state.images || []).map((image, index) => (
                        image != 'No_File'
                        ?
                        <CardActionArea key={image} disabled={image.length === 1 ? true : false}>
                          {image.substr(image.length-4, 4) == (".mp3" && ".mp4")
                          ?
                          <div className={classes.videoWrapper}>
                            <ReactPlayer
                              className={classes.reactPlayer} 
                              url={this.state.imageAttachmentURLs[index]}
                              width='100%'
                              height='100%'
                              controls
                              playing
                              // 다운로드 금지 코드
                              config={{ file: {
                                attributes: {
                                  controlsList: 'nodownload'
                                }
                              }}}
                            />
                          </div>
                          :
                         <img
                          className={classes.media}
                          src={this.state.imageAttachmentURLs[index]}
                         />
                          }    
                        </CardActionArea>
                        :
                        null
                      ))
                    }
                    {/*
                    {this.state.image_1 &&
                      this.state.image_1 != 'No_File'
                      ?
                      <CardActionArea disabled={this.state.image_1.length === 1 ? true : false}>
                        {this.state.image_1.substr(this.state.image_1.length-4, 4) == (".mp3" && ".mp4")
                        ?
                        <div className={classes.videoWrapper}>
                          <ReactPlayer
                            className={classes.reactPlayer} 
                            url={this.state.imageAttachmentURL_1}
                            width='100%'
                            height='100%'
                            controls
                            playing
                            // 다운로드 금지 코드
                            config={{ file: {
                              attributes: {
                                controlsList: 'nodownload'
                              }
                            }}}
                          />
                        </div>
                        :
                       <img
                        className={classes.media}
                        src={this.state.imageAttachmentURL_1}
                       />
                        }    
                      </CardActionArea>
                      :
                      null
                    }
                    {this.state.image_2 &&
                      this.state.image_2 != 'No_File'
                      ?
                      <CardActionArea disabled={this.state.image_2.length === 1 ? true : false}>
                        {this.state.image_2.substr(this.state.image_2.length-4, 4) == (".mp3" && ".mp4")
                        ?
                        <div className={classes.videoWrapper}>
                          <ReactPlayer
                            className={classes.reactPlayer} 
                            url={this.state.imageAttachmentURL_2}
                            width='100%'
                            height='100%'
                            controls
                            playing
                            // 다운로드 금지 코드
                            config={{ file: {
                              attributes: {
                                controlsList: 'nodownload'
                              }
                            }}}
                          />
                        </div>
                        :
                       <img
                        className={classes.media}
                        src={this.state.imageAttachmentURL_2}
                       />
                        }    
                      </CardActionArea>
                      :
                      null
                    }
                    {this.state.image_3 &&
                      this.state.image_3 != 'No_File'
                      ?
                      <CardActionArea disabled={this.state.image_3.length === 1 ? true : false}>
                        {this.state.image_3.substr(this.state.image_3.length-4, 4) == (".mp3" && ".mp4")
                        ?
                        <div className={classes.videoWrapper}>
                          <ReactPlayer
                            className={classes.reactPlayer} 
                            url={this.state.imageAttachmentURL_3}
                            width='100%'
                            height='100%'
                            controls
                            playing
                            // 다운로드 금지 코드
                            config={{ file: {
                              attributes: {
                                controlsList: 'nodownload'
                              }
                            }}}
                          />
                        </div>
                        :
                       <img
                        className={classes.media}
                        src={this.state.imageAttachmentURL_3}
                       />
                        }    
                      </CardActionArea>
                      :
                      null
                    }
                    */}
                    {/*<Divider />*/}
                    {/* 좋아요 리스트 관련 Component */}
                    {/*
                    <CardActions disableSpacing>
                      { this.state.favoriteThumbsLists.length > 0 && this.state.userContents
                        ?
                        Object.keys(this.state.favoriteThumbsLists).map(favoriteUUId => {
                          const favoriteThumbsList = this.state.favoriteThumbsLists[favoriteUUId];

                          // let favorWalletAddress;
                          let favorNickName;
                          let favorAvatarimage;
                          // let favorContentNumbers = 0;
                          Object.keys(this.state.userContents).map(userId => {
                            const userContent = this.state.userContents[userId];
                            if(userContent.userId === favoriteThumbsList.userId) {
                              // favorWalletAddress = userContent.walletAddress;
                              favorNickName = userContent.nickName;
                              favorAvatarimage = userContent.avatarimage;
                            }
                          });

                          return(
                            <UploadFeedFavoriteList
                              stateRefresh={this.stateRefresh}
                              key={favoriteThumbsList.favoriteUUId}
                              id={favoriteThumbsList.favoriteId}
                              favoriteListUserId={favoriteThumbsList.userId}
                              favoriteListSabuId={favoriteThumbsList.sabuId}
                              favorNickName={favorNickName}
                              favorAvatarimage={favorAvatarimage}
                              favoriteListSabuId={favoriteThumbsList.sabuId}
                              favoriteListUUId={favoriteThumbsList.favoriteUUId}
                              favoriteListDate={favoriteThumbsList.favoriteDate}
                              favoriteListContent={favoriteThumbsList.favoriteContent}
                            />
                          );
                        })
                        :
                        null
                      }                
                    </CardActions>
                    <Divider />
                    */}
                    {/* 답글 관련 Component */}
                    {     
                      this.state.commentContents /*&& this.state.userContents*/
                      ?
                      Object.keys(this.state.commentContents).map(commentUUId => {
                        const commentContent = this.state.commentContents[commentUUId];

                        /*
                        let commentWalletAddress;
                        let commentNickName;
                        let commentAvatarimage;
                        let commentCustomerType2;
                        Object.keys(this.state.userContents).map(userId => {
                          const userContent = this.state.userContents[userId];
                          if(userContent.userId === commentContent.userId) {
                            commentWalletAddress = userContent.walletAddress;
                            commentNickName = userContent.nickName;
                            commentAvatarimage = userContent.avatarimage;
                            commentCustomerType2 = userContent.customerType2;
                          }
                        });
                        */

                        /*
                        let commFavoriteUserId;
                        let commFavoriteUUId;
                        let commFavoriteContent;
                        let commFavoriteContentNumbers = 0;
                        Object.keys(this.state.commFavorThumbsSabuIds).map(commentFavoriteUUId => {
                          const commFavorThumbsSabuId = this.state.commFavorThumbsSabuIds[commentFavoriteUUId];
                          if(commFavorThumbsSabuId.commentUUId === commentContent.commentUUId) {
                            if(commFavorThumbsSabuId.userId === currentAuthUserFromSession) {
                              commFavoriteUserId = commFavorThumbsSabuId.userId;
                              commFavoriteUUId = commFavorThumbsSabuId.commentFavoriteUUId;
                              commFavoriteContent = commFavorThumbsSabuId.commentFavoriteContent;
                            }
                            commFavoriteContentNumbers = commFavoriteContentNumbers + 1;
                          }
                        });
                        */

                        return(
                          <UploadFeedComment
                            stateRefresh={this.stateRefresh}
                            key={commentContent.commentUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                            commentUUId={commentContent.commentUUId}
                            id={commentContent.commentId}
                            userId={commentContent.userId}
                            commentContent={commentContent.commentContent}
                            commentDate={commentContent.commentDate}
                            /*
                            commentWalletAddress={commentWalletAddress}
                            commentNickName={commentNickName}
                            commentAvatarimage={commentAvatarimage}
                            commentCustomerType2={commentCustomerType2}
                            */
                            /*
                            commFavoriteUserId = {commFavoriteUserId}
                            commFavoriteUUId = {commFavoriteUUId}
                            commFavoriteContent = {commFavoriteContent}
                            commFavoriteContentNumbers = {commFavoriteContentNumbers}
                            */
                          />
                        );
                      })
                      :
                      null
                    }
                  </List>
                </Card>
              </Grid>
            </Grid>
          </main>
          :
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          }
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Divider/>
            <Toolbar>
              <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="flex-start">
                  <div>
                    <textarea
                      id="commentContent"
                      name="commentContent"
                      rows={this.state.rows}
                      value={commentContent}
                      onChange={this.handletextAreaChange}
                      placeholder="댓글 달기"
                      className={classes.Textarea__textarea}
                      autoComplete="off"
                    />
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                  <IconButton
                    edge="end"
                    className={classes.commentIconButton}
                    aria-label="back"
                    onClick={this.commentSubmit}
                  >
                    <Typography className={classes.typoCommentSubmit}>등록</Typography>
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                { this.state.getApplications.length > 0
                    ?
                    Object.keys(this.state.getApplications).map(applicationUUId => {
                      const getApplication = this.state.getApplications[applicationUUId];
                      return(
                        <UploadFeedApplication
                          stateRefresh={this.stateRefresh}
                          //applicationUploadUUId={getApplication.uploadUUId}
                          key={getApplication.applicationUUId}
                          id={getApplication.applicationId}
                          applicationTitle={getApplication.applicationTitle}
                          applicationToggle={getApplication.applicationToggle}
                          uploadUserId={this.state.uploadUserId}
                          uploadUUId={this.state.uploadUUId}
                          field={this.state.field}
                          subField={this.state.subField}
                          title={this.state.title}
                          content={this.state.content}
                          dateStart={this.state.dateStart}
                          dateEnd={this.state.dateEnd}
                        />
                      );
                    })
                    :
                    <UploadFeedApplication
                      uploadUserId={this.state.uploadUserId}
                      uploadUUId={this.state.uploadUUId}
                      field={this.state.field}
                      subField={this.state.subField}
                      title={this.state.title}
                      content={this.state.content}
                      dateStart={this.state.dateStart}
                      dateEnd={this.state.dateEnd}
                    />
                  }
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {/*
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Grid container spacing={0} direction="column" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Toolbar className={classes.bottomToolbar}>
                  { this.state.getApplications.length > 0
                    ?
                    Object.keys(this.state.getApplications).map(applicationUUId => {
                      const getApplication = this.state.getApplications[applicationUUId];
                      return(
                        <UploadFeedApplication
                          stateRefresh={this.stateRefresh}
                          //applicationUploadUUId={getApplication.uploadUUId}
                          key={getApplication.applicationUUId}
                          id={getApplication.applicationId}
                          applicationTitle={getApplication.applicationTitle}
                          applicationToggle={getApplication.applicationToggle}
                          uploadUserId={this.state.uploadUserId}
                          uploadUUId={this.state.uploadUUId}
                          field={this.state.field}
                          subField={this.state.subField}
                          title={this.state.title}
                          content={this.state.content}
                          dateStart={this.state.dateStart}
                          dateEnd={this.state.dateEnd}
                        />
                      );
                    })
                    :
                    <UploadFeedApplication
                      uploadUserId={this.state.uploadUserId}
                      uploadUUId={this.state.uploadUUId}
                      field={this.state.field}
                      subField={this.state.subField}
                      title={this.state.title}
                      content={this.state.content}
                      dateStart={this.state.dateStart}
                      dateEnd={this.state.dateEnd}
                    />
                  }
                </Toolbar>
              </Grid>
            </Grid>
          </AppBar>
          */}
          <Dialog open={this.state.deleteDialogOpen} /*onClose={this.handleClose}*/>
          <DialogTitle>글을 삭제하시겠습니까?</DialogTitle>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                <Button variant="contained" /*color="primary"*/ className={classes.button}
                  onClick={this.handleDelete}>확인</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button variant="contained" /*color="primary"*/ className={classes.button}
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
          <Dialog open={this.state.pageDialogOpen} onClose={this.handleClose}>
          <DialogTitle>글 작성을 위해서는 사용자 정보가 필요합니다.</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" className={classes.button} onClick={this.handleGoToPage}>사용자 페이지로 가기</Button>
                </Grid>
              </Grid>  
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  // currentUser: state.auth.currentUser,
  isCommentLoading: state.upload.isCommentLoading,
  // isFavoriteLoading: state.upload.isFavoriteLoading,
  // isCommFavoriteLoading: state.upload.isCommFavoriteLoading,
  isApplyingLoading: state.upload.isApplyingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  // currentAuthUser: (currentUser) => dispatch(authActions.currentAuthUser(currentUser)),
  commentloadingtrue: (isCommentLoading) => dispatch(uploadActions.commentloadingtrue(isCommentLoading)),
  commentloadingfalse: (isCommentLoading) => dispatch(uploadActions.commentloadingfalse(isCommentLoading)),
  // favoriteloadingtrue: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingtrue(isFavoriteLoading)),
  // favoriteloadingfalse: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingfalse(isFavoriteLoading)),
  applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
  // commfavoriteloadingtrue: (isCommFavoriteLoading) => dispatch(uploadActions.commfavoriteloadingtrue(isCommFavoriteLoading)),
  // commfavoriteloadingfalse: (isCommFavoriteLoading) => dispatch(uploadActions.commfavoriteloadingfalse(isCommFavoriteLoading)),
})

UploadFeed = withStyles(styles, {withTheme: true})(UploadFeed)
export default connect(mapStateToProps, mapDispatchToProps)(UploadFeed);