import React, { Component } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'

// S3 관련
import { API, Storage } from "aws-amplify";

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  paper: {
    width : '100%',
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  avatar: {
    backgroundColor: '#0000FF',
    margin: 5,
    width: 35,
    height: 35,
  },
  nickName: {
    textAlign: 'left',
  },
  typography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
});

class UploadFavoriteList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completed : 0,
      favorAvatarAttachURL: null,
      favorAvatarimage: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    
    // AWS S3 사용시 (user 관련 파일)
    try {
      let favorAvatarAttachURL;
      const favorAvatarimage = this.props.favorAvatarimage;

      if (favorAvatarimage) {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
        favorAvatarAttachURL = await Storage.get(favorAvatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }
      
      this.setState({
        favorAvatarAttachURL,
        favorAvatarimage,
      });      
    } catch (e) {
      alert(e);
    }
  }
      
  /*
  // 차후 Follow post / delete 함수 작성시 참조
  // comment의 favorite 관련
  postCommentFavorite(commentFavoriteThumb) {
    return API.post("upload", "/commentFavorite", {
      body: commentFavoriteThumb
    });
  }

  deleteCommentFavorite() {
    return API.del("upload", `/commentFavorite/${this.props.commFavoriteUUId}`);
  }
  */

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // comment의 favorite 관련 버튼
  handleFollow = async event => {
    event.preventDefault();
    const { commfavoriteloadingtrue, commfavoriteloadingfalse } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.commFavoriteContent === '2') {
      if(currentAuthUserFromSession === this.props.commFavoriteUserId) {
        commfavoriteloadingtrue();
        try {
          await this.deleteCommentFavorite();
          commfavoriteloadingfalse();
        } catch (e) {
          alert(e);
          commfavoriteloadingfalse();
        }
      }
    } else {
      commfavoriteloadingtrue();
      try {
        await this.postCommentFavorite({
          uploadUUId: this.props.uploadUUId,
          commentUUId: this.props.commentUUId,
          commentFavoriteContent: '2',
        });
        commfavoriteloadingfalse();
      } catch (e) {
        alert(e);
        commfavoriteloadingfalse();
      }
    }
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme } = this.props;
    // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container wrap="nowrap" spacing={2} alignItems="center">
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Avatar src={this.state.favorAvatarAttachURL} aria-label="Recipe" className={classes.avatar}>
                {this.props.id}
              </Avatar>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              <Typography variant="body2" className={classes.nickName} variant="h6">
                {this.props.favorNickName}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Button variant="contained" color="primary" onClick={this.handleFollow}>Follow</Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({

})

UploadFavoriteList = withStyles(styles, {withTheme: true})(UploadFavoriteList)
export default connect(mapStateToProps, mapDispatchToProps)(UploadFavoriteList);