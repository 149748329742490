import {
  KLAYTNLOGIN,
  KLAYTNLOGOUT,
  INTEGRATE_WALLET,
  REMOVE_WALLET,
  //AWSLOGIN,
  //AWSLOGOUT,
  USERHASAUTHENTICATEDTRUE,
  USERHASAUTHENTICATEDFALSE,
  CURRENTAUTHENTICATEDUSER,
  SWREGISTRATION,
  // AWSCOGNITOON,
} from 'redux/actions/actionTypes'

// 초기State값
const initialState = {
  isKlaytnLoggedIn: !!sessionStorage.getItem('walletInstance'),
  privateKey: null,
  address: null,
  isAuthenticated: null,
  currentUser: null,
  isSwRegistration: null,
  // isAwsLoggedIn: !!sessionStorage.getItem('emailInstance'),
  // email: null,
  // password: null,
}

// state = initialState 초기 상태 값
// action을 취하면 
// 새로움 state값으로 변경 => { }
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case KLAYTNLOGIN:
      return {
        ...state,
        isKlaytnLoggedIn: true,
      }
    case KLAYTNLOGOUT:
      return {
        ...state,
        isKlaytnLoggedIn: false,
      }
    case INTEGRATE_WALLET:
      return {
        ...state,
        privateKey: action.payload.privateKey,
        address: action.payload.address,
      }
    case REMOVE_WALLET:
      return {
        ...state,
        privateKey: null,
        address: null,
      }
     /*
     case AWSLOGIN:
      return {
        ...state,
        isAwsLoggedIn: true,
      }
    case AWSLOGOUT:
      return {
        ...state,
        isAwsLoggedIn: false,
    }
    */
    case USERHASAUTHENTICATEDTRUE:
      return {
        ...state,
        isAuthenticated: true,
    }
    case USERHASAUTHENTICATEDFALSE:
      return {
        ...state,
        isAuthenticated: false,
    }
    case CURRENTAUTHENTICATEDUSER:
      return {
        ...state,
        currentUser: action.payload.currentUser,
    }
    case SWREGISTRATION:
      return {
        ...state,
        isSwRegistration: action.payload.isSwRegistration,
    }
    default:
      return state
  }
}

export default authReducer
