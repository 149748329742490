import React, { Component } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
// import Textarea from './Textarea'
import Feed_NotiGetting from './Feed_NotiGetting'
import { connect } from 'react-redux'

import SVGIcon from './SVGIcon'

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth, API } from "aws-amplify";

//import './ChangeEmail.scss'
//import './Input.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    //paddingBottom: 60,
  },
  topAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
    /*
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    */
  },
  toolBar: {
    height : 10,
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  title: {
    flexGrow: 1,
    fontSize : 16,
    color: 'black'
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  content: {
    marginTop: '30px',
    flexGrow: 1,
    //padding: theme.spacing(0),
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  list: {
    width: '100%',
    padding: 0,
  },
  listItemTitle: {
    height: 40,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 90,
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoRecentEmail: {
    width: '100%',
    fontSize : 16,
    paddingTop: 20,
    paddingBottom: 10,
  },
  button: {
    width: 200,
    marginBottom: 16,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoButton: {
    fontSize: 14
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  ChangeEmail__input: {
    marginTop: '16px',
    marginBottom: '16px',
    width: '100%',
    '& input': {
      paddingLeft: '48px',
      background: '16px center / 24px no-repeat url("/images/icon-lock.png")',
    },
  },
  ChangeEmail__button: {
    marginBottom: '80px',
    width: '300px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
  },
  Input__input: {
    width: '100%',
    //borderWidth: 0,
    boxSizing: 'border-box',
    fontSize: '14px',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '22px 24px',
    borderRadius: '5px',
    '&::placeholder': {
      color: theme.palette.grey[500],
    },
    '&--err': {
      borderColor: theme.palette.error.main,
    },
  }
});

class ChangeEmail extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      // getUser
      userId: '', 
      customerType1: '', 
      customerType2: '', 
      walletAddress: '', 
      companyName: '', 
      avatarimage: '', 
      ceoName: '', 
      companyNumber: '', 
      companyPhoneNumber: '', 
      companyFaxNumber: '', 
      zoneCode: '', 
      address: [], 
      homepage: '', 
      history: '', 
      name: '', 
      position: '', 
      phoneNumber: '', 
      email: '',
      recentEmail: '',
      adminConfirm: '',
      level: '', 
      checkedMain: [],
      checkedSub: [], 
      checkedTerms: [], 
      images: [],
      //
      warningMessage: '',
      code: "",
      email: "",
      codeSent: false,
      isConfirming: false,
      isSendingCode: false,
      completed : 0,
    };
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    if(this.props.match.params.id === currentAuthUserFromSession) {
      // return API.get("upload", `/user/${this.props.match.params.id}`);
      return API.get("upload", `/user/${currentAuthUserFromSession}`);
    } else {
      console.log("currentUser is different");
      this.props.history.push("/");
    }
  }

  putUser(userContent) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    if(this.props.match.params.id === currentAuthUserFromSession) {
      // return API.put("upload", `/user/${this.props.match.params.id}`, {
      return API.put("upload", `/user/${currentAuthUserFromSession}`, {
        body: userContent
      });
    } else {
      console.log("currentUser is different");
      this.props.history.push("/");
    }
  }

  validatEmailForm() {
    return this.state.email.length > 0;
  }

  validateConfirmForm() {
    return this.state.code.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleUpdateClick = async event => {
    // event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: this.state.email });

      this.setState({ codeSent: true });
    } catch (e) {
      alert(e.message);
      this.setState({ isSendingCode: false });
    }
  };

  handleConfirmClick = async event => {
    // event.preventDefault();

    this.setState({ isConfirming: true });

    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", this.state.code);

      localStorage.setItem("email", this.state.email);

      await this.putUser({
        customerType1: this.state.customerType1,
        customerType2: this.state.customerType2,
        walletAddress: this.state.walletAddress, 
        companyName: this.state.companyName, 
        avatarimage: this.state.avatarimage, 
        ceoName: this.state.ceoName, 
        companyNumber: this.state.companyNumber, 
        companyPhoneNumber: this.state.companyPhoneNumber, 
        companyFaxNumber: this.state.companyFaxNumber, 
        zoneCode: this.state.zoneCode, 
        address: this.state.address, 
        homepage: this.state.homepage, 
        history: this.state.history, 
        name: this.state.name, 
        position: this.state.position,
        phoneNumber: this.state.phoneNumber, 
        email: this.state.email,
        checkedMain: this.state.checkedMain,
        checkedSub: this.state.checkedSub,
        checkedTerms: this.state.checkedTerms,
        images: this.state.images,
        adminConfirm: this.state.adminConfirm,
        level: this.state.level, 
      });

      this.props.history.push("/myaccount");
    } catch (e) {
      alert(e.message);
      this.setState({ isConfirming: false });
    }
  };

  // 뒤로 Back
  handleGoBack = () => {
    //this.props.history.goBack();
    this.props.history.push('/');
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  async componentDidMount() {
    this._isMounted = true;

    const loginType = localStorage.getItem("loginType");

    if(loginType != 'email') {
      this.props.history.push("/");
    }

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    try{
      const user = await this.getUser();

      const { userId, customerType1, customerType2, walletAddress, companyName, avatarimage, ceoName, companyNumber, 
              companyPhoneNumber, companyFaxNumber, zoneCode, address, homepage, history, 
              name, position, phoneNumber, email, checkedMain, checkedSub,  checkedTerms, images, adminConfirm, level } = user;

      this.setState({userId, customerType1, customerType2, walletAddress, companyName, avatarimage, ceoName, companyNumber, 
        companyPhoneNumber, companyFaxNumber, zoneCode, address, homepage, history, 
        name, position, phoneNumber, checkedMain, checkedSub,  checkedTerms, images, adminConfirm, level});
      this.setState({recentEmail : email})
    } catch(e) {
      console.log(e)
      console.log("User is not getting")
    }
  }

  handleChenageEmailKeyPress = (e) => {
    // console.log("e.charCode :");
    // console.log(e.charCode);

    // key 13 = 엔터를 의미
    if(e.charCode === 13) {
      this.handleUpdateClick()
    }
  }

  renderUpdateForm() {
    const { classes, theme } = this.props
    return (
      <div>
      {/* <div className="ChangePasswordForm"> */}
      <Grid container spacing={0} direction="row" justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
          <List className={classes.list}>
            <ListItem className={classes.listItemTitle}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Typography className={classes.typoTitle}>이메일 변경</Typography>
              </Grid>
            </ListItem>
            <Divider/>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
              <Typography className={classes.typoRecentEmail}>
                현재 이메일: {this.state.recentEmail}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
              <div className={classes.ChangeEmail__input}>
                <input
                  className={classes.Input__input}
                  id="email"
                  type="email"
                  name="email"
                  autoFocus
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="변경할 이메일 주소 입력"
                  onKeyPress={(e) => this.handleChenageEmailKeyPress(e)}
                  autoComplete="off"
                />
              </div>
            </Grid>
          </List>       
          {this.state.isLoading 
          ?
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          :
          <Button
            className={classes.button}
            variant="contained"
            disabled={!this.validatEmailForm()}
            onClick={this.handleUpdateClick}
          > 
            <Typography className={classes.typoButton}>
              확인 번호 받기
            </Typography>
          </Button>
          }
          </Grid>
        </Grid>
      {/* </div> */}
      </div>
    )
  }

  handleConfirmEmailKeyPress = (e) => {
    // console.log("e.charCode :");
    // console.log(e.charCode);

    // key 13 = 엔터를 의미
    if(e.charCode === 13) {
      this.handleConfirmClick()
    }
  }

  renderConfirmationForm() {
    const { classes, theme } = this.props
    return (
      <div>
      {/* <div className="ChangePasswordForm"> */}
      <Grid container spacing={0} direction="row" justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
          <List className={classes.list}>
            <ListItem className={classes.listItemTitle}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Typography className={classes.typoTitle}>이메일 변환</Typography>
              </Grid>
            </ListItem>
            <Divider/>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
              <div className={classes.ChangeEmail__input}>
                <input
                  className={classes.Input__input}
                  id="code"
                  type="tel"
                  name="code"
                  autoFocus
                  value={this.state.code}
                  onChange={this.handleChange}
                  placeholder="이메일에서 확인번호 입력"
                  onKeyPress={(e) => this.handleConfirmEmailKeyPress(e)}
                  autoComplete="off"
                />
              </div>
            </Grid>
          </List>       
          {this.state.isLoading 
          ?
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          :
          <Button
            className={classes.button}
            disabled={!this.validateConfirmForm()}
            variant="contained"
            onClick={this.handleConfirmClick}
          > 
            <Typography className={classes.typoButton}>
              확인
            </Typography>
          </Button>
          }
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.topAppBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                  {/*<Button
                    className="NotUploadButton"
                    label="<"
                    onClick={this.handleNotUpload}
                  />*/}
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography className={classes.title} variant="h6" noWrap>
                    이메일 변경
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  {!this.state.codeSent
                    ? this.renderUpdateForm()
                    : this.renderConfirmationForm()
                  }
                </Card>
              </Grid>
            </Grid>
          </main>
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

ChangeEmail = withStyles(styles, {withTheme: true})(ChangeEmail)
export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);