import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import * as authActions from 'redux/actions/auth'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const styles = theme => ({
    root: {
        width : '100%',
        //minWidth: 1080,
        marginTop : theme.spacing(7),
        overFlowX : "auto",
        display: 'flex',
    },
    card: {
        width : '100%',
        marginTop: 5,
        marginBottom: 15,
        marginLeft : theme.spacing(1),
        marginRight : theme.spacing(1),
    },
    list: {
      width: '100%',
      padding: 0,
    },
    listItemTitle: {
      height: 40,
      background: "#E8E8E8"  // 살짝 짙은 grey
    },
    listItem: {
      height: 90,
    },
    typoTitle: {
      width: '100%',
      fontSize : 17,
      paddingTop: 0,
      paddingBottom: 0,
      //background: "#E8E8E8"  // 살짝 짙은 grey
    },
    button: {
      width: 100,
      backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
      color: "#ffffff",
      '&:hover': {
        color: "#ffffff",
        backgroundColor: '#067fb5',
        borderColor: '#0062cc',
        boxShadow: 'none',
      },
      '&:active': {
        color: "#ffffff",
        backgroundColor: '#067fb5',
        borderColor: '#005cbf',
        boxShadow: 'none',
      },
      '&:focus': {
        color: "#ffffff",
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
    typoButton: {
      fontSize: 17
    },
});

class MyAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          completed : 0,
          isLoading: false,
        };
    }

    async componentDidMount() {
      this._isMounted = true;
      const loginType = localStorage.getItem("loginType");

      if(loginType != 'email') {
        this.props.history.push("/");
      }
  
      if (this._isMounted) {
        this.timer = setInterval(this.progress, 20);
      }
    }

    // 로딩중일때 progress바 사용(material ui)
    progress = () => {
      const { completed } = this.state;
      this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
    }
    
    render() {
      const { classes, theme } = this.props;
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      return (
        <div className={classes.root}>
          <Card className={classes.card}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <List className={classes.list}>
                <ListItem className={classes.listItemTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoTitle}>계정 변경</Typography>
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                    <Typography className={classes.typoTitle}>이메일 변경</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    <Link component={RouterLink} to={`/myaccount/email/${currentAuthUserFromSession}`} underline='none' color='inherit'>
                        <Button variant="contained" className={classes.button}>
                          <Typography className={classes.typoButton}>
                            클릭
                          </Typography>
                        </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                    <Typography className={classes.typoTitle}>비밀번호 변경</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    <Link component={RouterLink} to={'/myaccount/password'} underline='none' color='inherit'>
                        <Button variant="contained" className={classes.button}>
                          <Typography className={classes.typoButton}>
                            클릭
                          </Typography>
                        </Button>  
                    </Link>
                  </Grid>
                </ListItem>
                {/*
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                    <Typography className={classes.typoTitle}>팔로우 글 알람</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    <Switch checked={this.state.followUploadPush} 
                      onChange={this.handleSwitchChange_2('followUploadPush')} 
                      value="followUploadPush"  
                      color="primary"
                    />
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                    <Typography className={classes.typoTitle}>팔로우 댓글 알람</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    <Switch checked={this.state.followCommentPush} 
                      onChange={this.handleSwitchChange_3('followCommentPush')} 
                      value="followCommentPush"  
                      color="primary"
                    />
                  </Grid>
                </ListItem>
                */}
              </List>
            </Grid>
          </Card>
        </div>
      );
    }
}


// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
    isSwRegistration: state.auth.isSwRegistration,
})
  
// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
    swRegistration: (isSwRegistration) => dispatch(authActions.swRegistration(isSwRegistration)),
})
  
MyAccount = withStyles(styles, {withTheme: true})(MyAccount)
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)