import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { TextField } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import imageCompression from 'utils/imageCompression';
import SVGIcon from './SVGIcon'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CardMedia from '@material-ui/core/CardMedia';

import * as authActions from 'redux/actions/auth'
import * as photoActions from 'redux/actions/photos'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

import CircularProgress from "@material-ui/core/CircularProgress";

// S3 업로드 관련
import { API, Auth } from "aws-amplify";
import config from "../config";
import { s3UploadPublic } from "../libs/awsLib";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

/*
// select 관련(공개, 비공개)
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
*/

// 달력
import Calendar from 'react-calendar';
import moment from 'moment';

//import './UploadPhoto.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(17),
  },
  appBar: {
    background: "#FFFFFF",
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    /*
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    */
  },
  toolBar: {
    height: 10
  },
  // toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    fontSize : 16,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '70px',
    flexGrow: 1,
    padding: theme.spacing(0),
    marginBottom: '70px',
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  buttonLine: {
    color: '#0c93d0',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '30px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldLabelSize:{
    fontSize:25
  },
  textFieldInputSize:{
    fontSize:20
  },
  typoCalendar: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  textfieldCalendar: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '30px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldCalendarLabelSize:{
    fontSize:18
  },
  textFieldCalendarInputSize:{
    fontSize:18
  },
  calendar: {
    marginBottom: '30px',
  },
  Textarea__label: {
    display: 'block',
    fontSize: 20,
    fontWeight: 'bold',
    color: 'brown-grey', //'#3E2723',
    marginBottom: 8,
  },
  Textarea__textarea: {
    width: '100%',
    height: '40vh',
    fontSize: 20,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '22px',
    paddingRight: '24px',
    resize: 'none;', /* 크기고정 */ 
  },
  UploadPhoto__file: {
    marginTop: '0px',
    marginBottom: '16x',
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed',
    //left: '50%', 
    top: '47%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  hidden: {
    display: 'none'
  },
  bottomAppBar: {
    top: 'auto',
    //bottom: 0,
    bottom: 100,
  },
  UploadPhoto__file: {
    //visibility: 'hidden'
    display: 'none'
  },
  media: {
    width: 50,
    height: 50,
  },
  addPhotoIcon: {
    width: 50,
    height: 50,
  },
  /*
  selectFormControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    //paddingTop: 0,
    height : 80,
    minWidth: 200,
  },
  selectSelect: {
    fontSize: 20,
  },
  selectInputlabel: {
    fontSize: 20,
  },
  selectMenuItem: {
    fontSize: 20,
  },
  */
  submitButton: {
    width: 150,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoSubmit: {
    fontSize: 17,
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
});

const MAX_IMAGE_SIZE = 30000 // 30KB
const MAX_IMAGE_SIZE_MB = 0.03 // 30KB

// AWS에서 만든 API 주소를 넣는다.
// 내가 작성한거 사용시 사용. AWS Amplify에서는 사용 안함.
// const awsdatabaseURL = "https://7mesa0l65c.execute-api.ap-northeast-2.amazonaws.com/dev/upload";

class UploadPhoto extends Component {

  file = [];
  fileArray = [];

  constructor(props) {
    super(props);

    // this.file = null;
  
    this.state = {
      uploadContent: '',
      // file: '',  // single
      fileName: '',
      warningMessage: '',
      isCompressing: false,
      userId: '',
      walletAddress: '',
      nickName: '',
      avatarimage: '',
      field: '',
      title: '',
      content: '',
      image: null,
      isLoading: false,
      dialogOpen : false, // 다이얼로그 창이 열려 있는지 확인하는 변수
      // tempUploadImage: null, // single
      tempUploadImage: [null],  // multiple
      completed : 0,
      // 알림 발송 위한 state
      getFollowers: '',
      notiSettings: '',
      notifications: '',
      adminNotifications: '',
      body: '',
      fcmTokenIds: [], // 여러명한테 FCM 발송시 시도
      /*
      selectTarget: 'public',  // 공개, 비공개 여부
      selectOpen: false,
      */
      // 달력 관련
      calendarOpen_1: false,
      calendarOpen_2: false,
      dateOriginal_1: new Date(), // 오늘 날짜 (시작 캘린더용)
      dateOriginal_2: new Date(), // 오늘 날짜 (종료 캘린더용)
      dateStart: null, // 실제 선택한 일감 시작 날짜 (서버에 저장 날짜)
      dateEnd: null, // 실제 선택한 일감 종료 날짜 (서버에 저장 날짜)
      // 다이얼 로그
      alarmDialogOpen_0: false,
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
    };
  }

  // AWS Amplify 사용한 POST
  postUpload(uploadContent) {
    return API.post("upload", "/upload", {
      body: uploadContent
    });
  }

  // 경로 ( /user/:id) 
  getUser() {
    // const { currentUser } = this.props
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    // return API.get("upload", `/user/${currentUser}`);
    return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  //-------------------------------------------------------------------------------
  // 글 작성시 알림 발송을 위한 코드
    getNotifications() {
    return API.get("upload", "/notification")
    .then(notifications => this.setState({notifications : notifications}));
  }

  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }

  /*
  postNotificationList(notificationList) {
    return API.post("upload", "/notificationList", {
      body: notificationList
    });
  }

  // 나를 Following한 사람들 리스트
  getFollowers() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/follow/?followeeUserId=${currentAuthUserFromSession}`)
    .then(getFollowers => this.setState({getFollowers : getFollowers}));
  }

  getNotiSettings() {
    return API.get("upload", "/notiSetting")
    .then(notiSettings => this.setState({notiSettings : notiSettings}));
  }
  
  // 삭제 API
  deleteNotification() {
    // const currentFCMTokenUUIDFromSession = sessionStorage.getItem("currentFCMTokenUUID");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.del("upload", `/notification/${currentFCMTokenFromSession}`);
  }

  // 내 핸드폰으로 (나중에 더 좋은 방법으로 바꾸기)
  getAdminNotifications() {
    return API.get("upload", `/notification?userId=ap-northeast-2:5c47086a-d50f-4914-9f3c-9e91904eed46`)
    .then(adminNotifications => this.setState({adminNotifications : adminNotifications}));
  }
  */
  //-------------------------------------------------------------------------------

  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }
  //-------------------------------------------------------------------------------

  // 실제로 데이터가 삽입되는 것을 도와주기 위한 함수
  handleSubmit = async event => {
    event.preventDefault();

    // 작성 안한 파트 작성하게끔 해주는 코드
    if (this.state.adminConfirm === 'false') {
      this.setState({alarmDialogOpen_0: true});
      return;
    }
    if (!this.state.title) {
      this.setState({alarmDialogOpen_1: true});
      return;
    }
    if (!this.state.dateStart) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }
    if (!this.state.dateEnd) {
      this.setState({alarmDialogOpen_3: true});
      return;
    }
    if (!this.state.content) {
      this.setState({alarmDialogOpen_4: true});
      return;
    }

    const currentPage = localStorage.getItem("currentPage");
    const currentTapLink = localStorage.getItem("currentTapLink");

    this.setState({ isLoading: true });

    let attachments = []

    try{
      for(let i=0;i<9;i++) {  // 총 9장까지 파일 올릴수 있게 해 놓은 코드
        this.file[0][i]
        ? attachments.push(await s3UploadPublic(this.file[0][i]))
        : attachments.push('No_File');
      }
    } catch (e) {
      //console.log(e)
      for(let i=0;i<9;i++) {  // 총 9장까지 파일 올릴수 있게 해 놓은 코드
        attachments.push('No_File');
      }
    }

    try {
      await this.postUpload({
        attachments,
        field: currentPage,
        subField: currentTapLink,
        title: this.state.title,
        content: this.state.content,
        dateStart: moment(this.state.dateStart).format("YYYY-MM-DD"),
        dateEnd: moment(this.state.dateEnd).format("YYYY-MM-DD"),
        images: attachments,
        });

        // this.props.history.push("/");
    } catch (e) {
      // lert(e);
      console.log(e)
      this.setState({ isLoading: false });
    }

    /*
    // point API
    await this.putPoint({
      pointEvent: "upload",
    });
    */

    // 알림 발송
    try {
      await this.postNotificationFCM({
        registration_ids: this.state.fcmTokenIds,
        title: this.state.title,
        body: this.state.title,
        //body: this.state.content,
        //icon: 'icon',
        click_action: "https://otwogong.com",
        //click_action: 'http://192.168.0.3:5555/',
      });

      // this.props.history.push("/");
    } catch (e) {
      // console.log("postNotificationFCM 에러");
      console.log(e)
    }

    // 알림 발송을 위한 코드
    // postNotificationList()
    /*
    try {
      await this.postNotificationList({
        title: this.state.nickName,
        body: this.state.content,
        image: "default",
      });
    } catch (e) {
      console.log("postNotificationList 에러");
    }
    */
    /*
    // postFCM 실행 (여러명한테 발송)
    try {
      await this.postNotificationFCM({
        registration_ids: this.state.fcmTokenIds,
        title: this.state.nickName,
        body: this.state.content,
      });
    } catch (e) {
      console.log("postNotificationList 에러");
    }
    
    // postFCM 실행 (여러명한테 발송)
    if(this.state.file) {
      try {
        const attachment = this.state.file
        ? s3UploadPublic(this.state.file)
        : null;

        this.postNotificationFCM({
          attachment,
          registration_ids: this.state.fcmTokenIds,
          title: this.state.title,
          body: this.state.body,
          image: attachment,
        });
        console.log("FCM Post 동작 1");
        this.setState({postFCMs : false});
        this.props.history.push("/");
      } catch (e) {
        // alert(e);
        console.log("FCM Post 동작 안됨");
        this.setState({postFCMs : false});
        this.setState({ isLoading: false });
      }
    } else {
      await this.postNotificationFCM({
        registration_ids: this.state.fcmTokenIds,
        title: this.state.title,
        body: this.state.body,
      });
      console.log("FCM Post 동작 2");
      this.setState({postFCMs : false});
      this.props.history.push("/");
    }
    */

   this.props.history.push("/");
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  handleFileChange = (e) => {

    this.file = []
    this.fileArray = []
    this.tempFile = null;

    this.file.push(e.target.files)
    // for (let i = 0; i < this.file[0].length; i++) {  // 업로드 사진 수량 상관 없는 코드
    for (let i = 0; i < 9; i++) { // 사진 9장까지만 업로드 하기 위한 코드 
      // 만약 동영상이 한개라도 있으면 제일 첫 동영상 1개만 업로드 하기 위한 코드
      if(this.file[0][i]) {
        if(this.file[0][i].name.substr(this.file[0][i].name.length-4, 4) == (".mp3" && ".mp4")) 
        {
          this.tempFile = this.file[0][i]
          this.file = []
          this.fileArray = []
          this.file.push(this.tempFile)
          this.file[0][0] = this.file[0]

          this.fileArray.push(URL.createObjectURL(this.file[0][0]))

          return;
        }
        this.fileArray.push(URL.createObjectURL(this.file[0][i]))
      }
    }
    this.setState({ tempUploadImage: this.fileArray })
  }

  handleGoToPage = async() => {
    const loginType = localStorage.getItem("loginType")
    
    const { userHasAuthenticatedFalse } = this.props
    // DB에 FCM Token 삭제 API
    try {
      await this.deleteNotification();
      sessionStorage.setItem("FCMLoginToggle", "false");
      // window.location.reload();
    } catch (e) {
      // alert(e);
      console.log("deleteNotification() 에러")
      sessionStorage.setItem("FCMLoginToggle", "false");
    }
    // 로컬스트로리지 삭제
    // localStorage.removeItem("notiChecking"); // <- 로그아웃해도 notiChecking관련 localstoge는 안 지울것임. 나중에 재 로그인 위해서

    // 로그아웃시 세션 지우기 기능
    await Auth.signOut()

    if (loginType === "facebook") {
      // 페이스북 로그아웃
      window.FB.logout();
      console.log("facebook Log Out!");
      this.props.history.push(`/chooseaccount`);
      window.location.reload();
    } else if (loginType === "google") {
      // 구글 로그아웃
      // this.googleSignOut();
      document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://www.otwogong.com/chooseaccount";
      // document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://localhost:5555";
      console.log("google Log Out!");
    }

    userHasAuthenticatedFalse();
    //sessionStorage.setItem("currentUser", null);

    // console.log("Log Out!");

    sessionStorage.clear()
    localStorage.clear()

  this.setState({
    alarmDialogOpen_1 : false,
  });
}

  compressImage = async (imageFile) => {
    try {
      const compressedFile = await imageCompression(imageFile, MAX_IMAGE_SIZE_MB)
      this.setState({
        isCompressing: false,
        file: compressedFile,
        fileName: compressedFile.name,
      })
    } catch (error) {
      this.setState({
        isCompressing: false,
        warningMessage: '* Fail to compress image'
      })
    }
  }

  handleCancelPhoto = () => {
    this.setState({
      file: '',
      fileName: '',
      tempUploadImage: [null]
    });
    this.file = []
    this.fileArray = []
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  /*
  // 공개, 비공개 관련
  handleSelectChange = event => {
    this.setState({selectTarget : event.target.value})
  };
  
  handleSelectClose = () => {
    this.setState({selectOpen: false});
  };

  handleSelectOpen = () => {
    this.setState({selectOpen: true})
  };
  */

  handleCalendarOpen_1 = () => {
    if(this.state.calendarOpen_1 === false) {
      this.setState({calendarOpen_1: true})
    } else if (this.state.calendarOpen_1 === true) {
      this.setState({calendarOpen_1: false})
    }
    if(this.state.calendarOpen_2 === true) {
      this.setState({calendarOpen_2: false})
    }
  }

  handleCalendarOpen_2 = () => {
    if(this.state.calendarOpen_2 === false) {
      this.setState({calendarOpen_2: true})
    } else if (this.state.calendarOpen_2 === true) {
      this.setState({calendarOpen_2: false})
    }
    if(this.state.calendarOpen_1 === true) {
      this.setState({calendarOpen_1: false})
    }
  }
  
  onDateChange_1 = dateOriginal_1 => { 
    this.setState({
      dateOriginal_1,
      dateStart : dateOriginal_1,
      calendarOpen_1: false,
    })

    /*
    console.log(moment(date_1).format("YYYY-MM-DD"))
    console.log(moment(date_1).format("YYYY" + "년"))
    console.log(moment(date_1).format("MM" + "월"))
    console.log(moment(date_1).format("DD" + "일"))
    */
  }

  onDateChange_2 = dateOriginal_2 => { 
    this.setState({
      dateOriginal_2,
      dateEnd : dateOriginal_2,
      calendarOpen_2: false,
    })
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_0: false,
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
    })
  }

  async componentDidMount() {

    //console.log("tempUploadImage :")
    //console.log(this.state.tempUploadImage)

    this._isMounted = true;
    // const { currentUser } = this.props

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const loginType_FromLocal = localStorage.getItem("loginType");

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    // 만약 user관련 서버에서 데이터를 못받아오면 nickName등 작성하게끔 MyPage로 이동 시키기
    try {
      const user = await this.getUser();
      const { userId, walletAddress, companyName, avatarimage, ceoName, companyNumber, 
        companyPhoneNumber, companyFaxNumber, zoneCode, address, homepage, history, 
        name, position, phoneNumber, email, checked, adminConfirm } = user;

      this.setState({userId});

      // 첨부 파일이 있으면 키를 사용하여 S3에 업로드 한 파일에 대한 보안 링크를 얻습니다. 
      // 그런 다음이를 구성 요소의 상태로로 저장합니다 attachmentURL.
      // 그 이유는 우리는 왜이 upload와 함께 상태에서 개체를 content하고는 
      // attachmentURL우리가 나중에 사용자가 노트를 편집 할 때 사용되기 때문이다.
      // if(this.state.userId === currentUser)
      if(this.state.userId === currentAuthUserFromSession)
      {  
        this.setState({
          user,
          walletAddress, companyName, avatarimage, ceoName, companyNumber, 
          companyPhoneNumber, companyFaxNumber, zoneCode, address, homepage, history, 
          name, position, phoneNumber, email, checked, adminConfirm,
          expanded : true,
        });
        // console.log("userId is matched");
        // console.log('currentUser: ' + currentUser);
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // console.log('userId: ' + this.state.userId);        
      } else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
        // console.log("userId is undefined");
        // console.log('currentUser: ' + currentUser);
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // console.log('userId: ' + this.state.userId);
        // this.props.history.push(`/user/${this.props.currentUser}`);
        this.setState({
          dialogOpen : true,
        });
      }
      else {
        console.log("userId is not matched");
        // console.log('currentUser: ' + currentUser);
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // console.log('userId: ' + this.state.userId);
      } 
    } catch (e) {
      // alert(e);
      console.log(e);
    }

    // 글작성시 알림발송을 위한 코드
    // FCM으로 발송할 데이터를 미리 받아서 fcmTokenId를 배열로 저장.
    // 나중에 발송시 FCM으로 fcmTokenId 배열로 보내면 최대 1,000명한테 한번에 발송 가능
    // await this.getFollowers();
    // await this.getNotiSettings();
    await this.getNotifications();
    //await this.getAdminNotifications();

    if(this.state.notifications) {
      let fcmTokenId
      Object.keys(this.state.notifications).map(notificationUUId => {
        const notification = this.state.notifications[notificationUUId];
          fcmTokenId = notification.fcmTokenId
          this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
      });
    }

    /*
    // fcmTokenIds를 배열로 받기
    if(this.state.getFollowers && this.state.notiSettings && this.state.notifications) {
      Object.keys(this.state.getFollowers).map(followingUUId => {
        const getFollower = this.state.getFollowers[followingUUId];
        
        Object.keys(this.state.notiSettings).map(notiSettingUUId => {
          const notiSetting = this.state.notiSettings[notiSettingUUId];

          if(getFollower.followerUserId === notiSetting.userId) {
            let fcmTokenId
            Object.keys(this.state.notifications).map(notificationUUId => {
              const notification = this.state.notifications[notificationUUId];
              if(notiSetting.followUploadToggle === "true") {
                if(notiSetting.userId === notification.userId) {
                  fcmTokenId = notification.fcmTokenId
                  this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
                }
              }
            });
          }
        })
      })
    }
    */
    
    /*
    // adminNotifications
    // 관리자 fcmTokenIds를 배열로 받기
    if(this.state.adminNotifications) {
      let fcmTokenId
      Object.keys(this.state.adminNotifications).map(notificationUUId => {
        const adminNotification = this.state.adminNotifications[notificationUUId];
        fcmTokenId = adminNotification.fcmTokenId
        this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
      });
    }
    */

    //----------------------------------------------------------------------------------
    const currentPage = localStorage.getItem("currentPage");
    const currentTapLink = localStorage.getItem("currentTapLink");

    if(currentPage === '3dprinter') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '3D프린터 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '3D프린터 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '3D프린터 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '3D프린터 기술자홍보')
      }
    }
    if(currentPage === 'iot') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'IoT 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'IoT 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'IoT 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'IoT 기술자홍보')
      }
    }
    if(currentPage === 'smartfactory') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '스마트팩토리 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '스마트팩토리 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '스마트팩토리 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '스마트팩토리 기술자홍보')
      }
    }
    if(currentPage === 'arvr') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'AR,VR 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'AR,VR 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'AR,VR 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'AR,VR 기술자홍보')
      }
    }
    if(currentPage === 'autocad') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '오토 캐드 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '오토 캐드 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '오토 캐드 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '오토 캐드 기술자홍보')
      }
    }
    if(currentPage === 'solidworks') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '솔리드 웍스 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '솔리드 웍스 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '솔리드 웍스 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '솔리드 웍스 기술자홍보')
      }
    }
    if(currentPage === 'inventor') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '인벤터 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '인벤터 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '인벤터 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '인벤터 기술자홍보')
      }
    }
    if(currentPage === 'catia') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '카티아 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '카티아 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '카티아 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '카티아 기술자홍보')
      }
    }
    if(currentPage === 'designetc') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '설계 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '설계 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '설계 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '설계 기타 기술자홍보')
      }
    }
    if(currentPage === 'melsec') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'Melsec 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'Melsec 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'Melsec 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'Melsec 기술자홍보')
      }
    }
    if(currentPage === 'ls') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'LS 산전 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'LS 산전 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'LS 산전 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'LS 산전 기술자홍보')
      }
    }
    if(currentPage === 'siemens') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '지멘스 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '지멘스 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '지멘스 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '지멘스 기술자홍보')
      }
    }
    if(currentPage === 'plcetc') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'PLC 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'PLC 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'PLC 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'PLC 기타 기술자홍보')
      }
    }
    if(currentPage === 'machineassembly') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '장비 조립 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '장비 조립 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '장비 조립 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '장비 조립 기술자홍보')
      }
    }
    if(currentPage === 'machineharness') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '장비 배선 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '장비 배선 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '장비 배선 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '장비 배선 기술자홍보')
      }
    }
    if(currentPage === 'factoryharness') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '공장 배선 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '공장 배선 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '공장 배선 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '공장 배선 기술자홍보')
      }
    }
    if(currentPage === 'machinerepair') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '장비 유지,보수 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '장비 유지,보수 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '장비 유지,보수 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '장비 유지,보수 기술자홍보')
      }
    }
    if(currentPage === 'partsrepair') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '부품수리 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '부품수리 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '부품수리 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '부품수리 기술자홍보')
      }
    }
    if(currentPage === 'lathemilling') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '범용 선반,밀링 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '범용 선반,밀링 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '범용 선반,밀링 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '범용 선반,밀링 기술자홍보')
      }
    }
    if(currentPage === 'cnclathe') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'CNC 선반 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'CNC 선반 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'CNC 선반 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'CNC 선반 기술자홍보')
      }
    }
    if(currentPage === 'mct') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'MCT 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'MCT 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'MCT 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'MCT 기술자홍보')
      }
    }
    if(currentPage === 'engravingmachine') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '조각기 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '조각기 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '조각기 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '조각기 기술자홍보')
      }
    }
    if(currentPage === 'press') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '프레스,판금 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '프레스,판금 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '프레스,판금 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '프레스,판금 기술자홍보')
      }
    }
    if(currentPage === 'cam') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '캠 프로그램 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '캠 프로그램 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '캠 프로그램 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '캠 프로그램 기술자홍보')
      }
    }
    if(currentPage === 'processingetc') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '가공 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '가공 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '가공 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '가공 기타 기술자홍보')
      }
    }
    if(currentPage === 'cylindricalgrinding') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '원통 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '원통 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '원통 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '원통 연마 기술자홍보')
      }
    }
    if(currentPage === 'planegrinding') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '평면 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '평면 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '평면 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '평면 연마 기술자홍보')
      }
    }
    if(currentPage === 'rotarygrinding') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '로터리 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '로터리 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '로터리 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '로터리 연마 기술자홍보')
      }
    }
    if(currentPage === 'centerlessgrinding') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '센터리스 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '센터리스 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '센터리스 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '센터리스 연마 기술자홍보')
      }
    }
    if(currentPage === 'toolgrinding') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '공구 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '공구 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '공구 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '공구 연마 기술자홍보')
      }
    }
    if(currentPage === 'honing') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '호닝 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '호닝 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '호닝 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '호닝 기술자홍보')
      }
    }
    if(currentPage === 'profile') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '프로파일 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '프로파일 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '프로파일 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '프로파일 기술자홍보')
      }
    }
    if(currentPage === 'grindingetc') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '연마 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '연마 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '연마 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '연마 기타 기술자홍보')
      }
    }
    if(currentPage === 'Argonwelding') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '알곤 용접 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '알곤 용접 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '알곤 용접 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '알곤 용접 기술자홍보')
      }
    }
    if(currentPage === 'laserwelding') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '레이저 용접 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '레이저 용접 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '레이저 용접 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '레이저 용접 기술자홍보')
      }
    }
    if(currentPage === 'lasermarking') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '레이저 마킹 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '레이저 마킹 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '레이저 마킹 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '레이저 마킹 기술자홍보')
      }
    }
    if(currentPage === 'weldingetc') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '용접 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '용접 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '용접 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '용접 기타 기술자홍보')
      }
    }
    if(currentPage === 'gundrill') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '건드릴 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '건드릴 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '건드릴 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '건드릴 기술자홍보')
      }
    }
    if(currentPage === 'heattreatment') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '열처리 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '열처리 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '열처리 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '열처리 기술자홍보')
      }
    }
    if(currentPage === 'plated') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '도금,아노다이징 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '도금,아노다이징 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '도금,아노다이징 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '도금,아노다이징 기술자홍보')
      }
    }
    if(currentPage === '3dmeasuring') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '3차원 측정 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '3차원 측정 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '3차원 측정 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '3차원 측정 기술자홍보')
      }
    }
    if(currentPage === 'etcetc') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '가공 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '가공 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '가공 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '가공 기타 기술자홍보')
      }
    }
    if(currentPage === 'english') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '영어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '영어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '영어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '영어 통,번역 기술자홍보')
      }
    }
    if(currentPage === 'chinese') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '중국어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '중국어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '중국어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '중국어 통,번역 기술자홍보')
      }
    }
    if(currentPage === 'japanese') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '일본어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '일본어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '일본어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '일본어 통,번역 기술자홍보')
      }
    }
    if(currentPage === 'vietnamese') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '베트남어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '베트남어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '베트남어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '베트남어 통,번역 기술자홍보')
      }
    }

    if(currentPage === 'languageetc') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '기타 언어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '기타 언어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '기타 언어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '기타 언어 통,번역 기술자홍보')
      }
    }
    if(currentPage === 'factoryshare') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '공장 공유 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '공장 공유 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '공장 공유 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '공장 공유 기술자홍보')
      }
    }
    //----------------------------------------------------------------------------------
  }

  render() {
    const { classes } = this.props;
    const { title, dateOriginal_1, dateOriginal_2, dateStart, dateEnd, content} = this.state
    const currentFieldName = localStorage.getItem("currentFieldName");

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#000000" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} align='center' variant="h6" noWrap>
                    {currentFieldName}
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>              
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
                <TextField className={classes.textfieldTitle}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldInputSize,
                    },
                    }}
                  label="제목" type="text" name="title" value={title} onChange={this.handleValueChange} autoComplete="off"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography style={{ cursor: 'pointer' }} component={'span'} className={classes.typoCalendar} onClick={this.handleCalendarOpen_1}>
                { this.state.dateStart
                ?
                <TextField className={classes.textfieldCalendar}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldCalendarLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldCalendarInputSize,
                    },
                    }}
                  label="일감 시작 날짜" type="dateStart" name="dateStart" value={moment(dateStart).format("YYYY-MM-DD") || ''} 
                  autoComplete="off"
                />
                :
                <TextField className={classes.textfieldCalendar}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldCalendarLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldCalendarInputSize,
                    },
                    }}
                  label="일감 시작 날짜" type="dateStart" name="dateStart" value={undefined || ''} autoComplete="off"
                />
                }
                </Typography>               
              </Grid>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography style={{ cursor: 'pointer' }} component={'span'} className={classes.typoCalendar} onClick={this.handleCalendarOpen_2}>
                { this.state.dateEnd
                ?
                <TextField className={classes.textfieldCalendar}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldCalendarLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldCalendarInputSize,
                    },
                    }}
                  label="일감 종료 날짜" type="dateEnd" name="dateEnd" value={moment(dateEnd).format("YYYY-MM-DD") || ''} 
                  autoComplete="off"
                />
                :
                <TextField className={classes.textfieldCalendar}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldCalendarLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldCalendarInputSize,
                    },
                    }}
                    label="일감 종료 날짜" type="dateEnd" name="dateEnd" value={undefined || ''} autoComplete="off"
                />
                }
                </Typography>               
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={11} lg={10} xl={10} align='center'>
                {this.state.calendarOpen_1
                ?
                <Calendar
                  className={classes.calendar}
                  onChange={this.onDateChange_1}
                  value={dateOriginal_1}
                  />
                :
                null
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={11} lg={10} xl={10} align='center'>
                {this.state.calendarOpen_2
                ?
                <Calendar
                  className={classes.calendar}
                  onChange={this.onDateChange_2}
                  value={dateOriginal_2}
                  />
                :
                null
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
                <label className={classes.Textarea__label} htmlFor="content">
                  내용 작성
                </label>
                <textarea
                  id="content"
                  name="content"
                  value={content}
                  onChange={this.handleValueChange}
                  placeholder="내용을 작성해주세요."
                  className={classes.Textarea__textarea}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/
                  onClick={this.handleSubmit} className={classes.submitButton}
                >
                  <Typography /*variant='h6'*/ className={classes.typoSubmit}>등록</Typography>
                </Button>
              </Grid>
            </Grid>
            {this.state.isLoading 
              ?
              <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
              </Grid>
              :
              null
              
            }
          </main>
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Divider/>
            <Toolbar>
              <Grid container spacing={5} direction="row" justify="flex-start" alignItems="center">
                <Grid item sm={10} md={10} lg={10} xl={10}>
                  <div>
                    <input
                      className={classes.UploadPhoto__file}
                      id="uploadPhoto"
                      type="file"
                      name="Search file"
                      onChange={this.handleFileChange}
                      accept=".png, .jpg, .jpeg, .mp3, .mp4"
                      required
                      multiple 
                    />
                    <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
                      <label htmlFor="uploadPhoto">
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <AddPhotoAlternateIcon className={classes.addPhotoIcon}/>
                        </Grid>                      
                      </label>
                      {(this.fileArray || []).map(url => (
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={url}>
                          {this.file[0][0].name.substr(this.file[0][0].name.length-4, 4) == (".mp3" && ".mp4")
                          ?
                          <Typography>
                            {this.file[0][0].name}
                          </Typography>
                          :
                          <CardMedia
                            className={classes.media}
                            image={url}
                            aria-label="Recipe" 
                          >
                          </CardMedia>
                          }
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Grid>
                <Grid item sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    //className={classes.commentIconButton}
                    /*color="primary"*/ className={classes.buttonLine}
                    aria-label="back"
                    onClick={this.handleCancelPhoto}
                  >
                    <span>첨부취소</span>
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Dialog open={this.state.dialogOpen} onClose={this.handleGoToPage}>
            <DialogTitle>글 작성을 위해서는 사용자 정보가 필요합니다.</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button}
                    onClick={this.handleGoToPage}>회원가입 페이지로 가기</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_0}>
            <DialogTitle>관리자의 승인 이후에 견적요청을 하실수 있습니다.</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_1}>
            <DialogTitle>제목을 입력해주세요</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_2}>
            <DialogTitle>일감 시작 날짜를 입력해주세요</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_3}>
            <DialogTitle>일감 종료 날짜를 입력해주세요</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_4}>
            <DialogTitle>내용을 작성해주세요</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  address: state.auth.address,
  uploadContents: state.upload.uploadContents,
  // currentUser: state.auth.currentUser,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  uploadPhoto: (file, fileName, location, caption) =>
    dispatch(photoActions.uploadPhoto(file, fileName, location, caption)),
    notupload: () => dispatch(uploadActions.notupload()), // 현재 사용 안함. 나중에 지우기
    uploadcontents: () => dispatch(uploadActions.uploadcontents()),
})

UploadPhoto = withStyles(styles, {withTheme: true})(UploadPhoto)
export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto)