import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import ReactPlayer from 'react-player';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import moment from 'moment';
import { TextField } from "@material-ui/core";

// S3 관련
import { API, Storage } from "aws-amplify";
import { s3UploadPublic } from "../libs/awsLib";

import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import SVGIcon from './SVGIcon'

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(17),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  title: {
    flexGrow: 1,
    fontSize : 17,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '30px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  iconButton: {
    margin: theme.spacing(0),
    //paddingLeft: 0
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  typoSubmit: {
    fontSize: 17
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  card2: {
    maxWidth: 64,
    marginTop: 5,
    marginBottom: 15,
    boxShadow: "none",
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemContent: {
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemStep: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    padding: 0
  },
  listItemTextField: {
    height: 50,
  },
  listItemIcon: {
    minWidth: 0
  },
  typoTitle: {
    width: '100%',
    fontSize : 17,
    marginTop: 5,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  textTypo : {
    width: '100%',
    paddingLeft: 20,
    fontSize: 17,
  },
  typoContent: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 20
  },
  typoDate: {
    width: '100%',
    fontSize : 17,
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 0,
    paddingBottom: 5,
  },
  typoGetStep: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
  },
  stepperRoot: {
    width: '100%',
  },
  stepper: {
    // background: "#F5F5F5"
  },
  step: {
    //fontSize: 20,
  },
  stepButton: {
  },
  stepLabelRoot: {
    "&$completed": {
      color: "#f44336",  // red
    },
    "&$active": {
      color: "#0c93d0",  // ilgam color
    },
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  text: {
    fontSize: 17
  },
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
    }
  },
  iconContainer: { // define styles for icon container
    transform: 'scale(1.7)',
  },
  /*
  // 사용 가능하나 현재는 적용 안함
  connectorActive: {
    '& $connectorLine': {
      borderColor: "#0c93d0",  // ilgam color
    }
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: "#f44336",  // red
    }
  },
  */
  connectorLine: {
    // borderColor: 'red',
    borderTopWidth: 1.2,
    borderRadius: 1,
  },
  cardHeader: {
    width: '100%',
    paddingRight: 0,
  },
  textFieldName : {
    width: '100%',
    fontSize: 17,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '3px',
    paddingRight: '24px',
  },
  textField : {
    width: '100%',
    fontSize: 17,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '20px',
    paddingRight: '24px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
  },
  textFieldSize: {
    fontSize: 17,
  },
  Textarea__textarea: {
    width: '100%',
    height: '20vh',
    fontSize: 17,
    //border: '1px solid light-grey',
    border: 'none',
    //borderRadius: 5,
    paddingLeft: '22px',
    paddingRight: '24px',
  },
  media: {
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    width : '100%',
    height: '100%'
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%', /* 720 / 1280 = 0.5625 */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  submitButton: {
    width: 100,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  submitButton2: {
    width: 100
  },
  typoSubmit: {
    fontSize: 17,
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  bottomAppBar: {
    top: 'auto',
    //bottom: 0,
    bottom: 100,
  },
  UploadPhoto__file: {
    //visibility: 'hidden'
    display: 'none'
  },
  mediaAdd: {
    width: 50,
    height: 50,
  },
  addPhotoIcon: {
    width: 50,
    height: 50,
  },
  buttonLine: {
    color: '#0c93d0',
  },
});

class Process extends Component {

  steps =  ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

  file = [];
  fileArray = [];

  constructor(props) {
    super(props);

    this.file_1 = null;

    this.state = {
      // process API
      process: '',
      processUserId: '', 
      customerUserId: '',
      uploadUUId: '',
      applicationUUId: '', 
      processUUId: '', 
      processId: '', 
      processTitle: '',
      dateStart: null, 
      dateEnd: null, 
      processToggle: '', 
      processComment: [],
      processComment_0: '',
      processComment_1: '',
      processComment_2: '',
      processComment_3: '',
      customerComment: '',
      /*
      customerComment: [],
      customerComment_0: '',
      customerComment_1: '',
      customerComment_2: '',
      customerComment_3: '',
      */
      processImages_0: [],
      processImages_1: [], 
      processImages_2: [], 
      processImages_3: [], 
      customerSign: '',
      // image 관련
      attachmentURLs_0: [],
      attachmentURLs_1: [],
      attachmentURLs_2: [],
      attachmentURLs_3: [],
      tempUploadImage: [null],  // multiple
      // Stepper
      activeStep: 0, 
      completedStep: [],
      // fcm
      notificationsToBuyer: '',
      notificationsToSupplier: '',
      fcmTokenIdToBuyer: [],
      fcmTokenIdToSupplier: [],
      //알람
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      completed : 0,
    };
  }

  // 내가 Following한 사람들 리스트
  getProcess() {
    return API.get("upload", `/process/${this.props.match.params.id}`)
  }

  // AWS Amplify 사용한 POST
  putProcess(processContent) {
    return API.put("upload", `/process/${this.state.processUUId}`, {
      body: processContent
    });
  }

  //------------------------------------------------------------------------
  // 지원 버튼 클릭시 알림 발송
  //------------------------------------------------------------------------
  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }
  
  // 발주자한테 발송
  getNotifications_ToBuyer() {
    return API.get("upload", `/notification/?userId=${this.state.customerUserId}`)
    .then(notificationsToBuyer => this.setState({notificationsToBuyer : notificationsToBuyer}));
  }

  // 공급자 한테 발송
  getNotifications_ToSupplier() {
    return API.get("upload", `/notification/?userId=${this.state.processUserId}`)
    .then(notificationsToSupplier => this.setState({notificationsToSupplier : notificationsToSupplier}));
  }
  //------------------------------------------------------------------------

  async componentDidMount() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    try {
      let attachmentURLs_0 = [];
      let attachmentURLs_1 = [];
      let attachmentURLs_2 = [];
      let attachmentURLs_3 = [];

      const process = await this.getProcess();

      const { processUserId, customerUserId, uploadUUId, applicationUUId, processUUId, processId, processTitle,
              dateStart, dateEnd, processToggle, processComment, customerComment, processImages_0,
              processImages_1, processImages_2, processImages_3, customerSign } = process;

      this.setState({processUserId, customerUserId, processToggle});

      // 제작자
      if(this.state.processUserId === currentAuthUserFromSession)
      {
        // 코멘트 관련
        for(let i=0;i<processComment.length;i++) {
          this.setState({
            processComment: this.state.processComment.concat(processComment[i]),
          });
        }
        /*
        for(let i=0;i<customerComment.length;i++) {
          this.setState({
            customerComment: this.state.customerComment.concat(customerComment[i]),
          });
        }
        */

        // 사진 관련
        for(let i=0;i<processImages_0.length;i++) {
          if (processImages_0[i] != 'No_File') {
            attachmentURLs_0[i] = await Storage.get(processImages_0[i], {level: 'public'});
          } else if (processImages_0[i] === 'No_File') {
            attachmentURLs_0[i] = null
          }
          this.setState({
            attachmentURLs_0: this.state.attachmentURLs_0.concat(attachmentURLs_0[i]),
            processImages_0: this.state.processImages_0.concat(processImages_0[i]),
          });
        }
        for(let i=0;i<processImages_1.length;i++) {
          if (processImages_1[i] != 'No_File') {
            attachmentURLs_1[i] = await Storage.get(processImages_1[i], {level: 'public'});
          } else if (processImages_1[i] === 'No_File') {
            attachmentURLs_1[i] = null
          }
          this.setState({
            attachmentURLs_1: this.state.attachmentURLs_1.concat(attachmentURLs_1[i]),
            processImages_1: this.state.processImages_1.concat(processImages_1[i]),
          });
        }
        for(let i=0;i<processImages_2.length;i++) {
          if (processImages_2[i] != 'No_File') {
            attachmentURLs_2[i] = await Storage.get(processImages_2[i], {level: 'public'});
          } else if (processImages_2[i] === 'No_File') {
            attachmentURLs_2[i] = null
          }
          this.setState({
            attachmentURLs_2: this.state.attachmentURLs_2.concat(attachmentURLs_2[i]),
            processImages_2: this.state.processImages_2.concat(processImages_2[i]),
          });
        }
        for(let i=0;i<processImages_3.length;i++) {
          if (processImages_3[i] != 'No_File') {
            attachmentURLs_3[i] = await Storage.get(processImages_3[i], {level: 'public'});
          } else if (processImages_3[i] === 'No_File') {
            attachmentURLs_3[i] = null
          }
          this.setState({
            attachmentURLs_3: this.state.attachmentURLs_3.concat(attachmentURLs_3[i]),
            processImages_3: this.state.processImages_3.concat(processImages_3[i]),
          });
        }

        this.setState({process, uploadUUId, applicationUUId, processUUId, processId,
                       processTitle, dateStart, dateEnd, customerComment, customerSign});
          
        this.setState({
          processComment_0: this.state.processComment[0],
          processComment_1: this.state.processComment[1],
          processComment_2: this.state.processComment[2],
          processComment_3: this.state.processComment[3],
          /*
          customerComment_0: this.state.customerComment[0],
          customerComment_1: this.state.customerComment[1],
          customerComment_2: this.state.customerComment[2],
          customerComment_3: this.state.customerComment[3],
          */
        })

        if(this.state.processComment_0 === 'default') {
          this.setState({ processComment_0: '' });
        }
        if(this.state.processComment_1 === 'default') {
          this.setState({ processComment_1: '' });
        }
        if(this.state.processComment_2 === 'default') {
          this.setState({ processComment_2: '' });
        }
        if(this.state.processComment_3 === 'default') {
          this.setState({ processComment_3: '' });
        }
        /*
        if(this.state.customerComment_0 === 'default') {
          this.setState({ customerComment_0: '' });
        }
        if(this.state.customerComment_1 === 'default') {
          this.setState({ customerComment_1: '' });
        }
        if(this.state.customerComment_2 === 'default') {
          this.setState({ customerComment_2: '' });
        }
        if(this.state.customerComment_3 === 'default') {
          this.setState({ customerComment_3: '' });
        }
        */
      }

      // 발주자
      if(this.state.customerUserId === currentAuthUserFromSession) 
      {
        // 코멘트 관련
        for(let i=0;i<processComment.length;i++) {
          this.setState({
            processComment: this.state.processComment.concat(processComment[i]),
          });
        }
        /*
        for(let i=0;i<customerComment.length;i++) {
          this.setState({
            customerComment: this.state.customerComment.concat(customerComment[i]),
          });
        }
        */

        // 사진 관련
        for(let i=0;i<processImages_0.length;i++) {
          if (processImages_0[i] != 'No_File') {
            attachmentURLs_0[i] = await Storage.get(processImages_0[i], {level: 'public'});
          } else if (processImages_0[i] === 'No_File') {
            attachmentURLs_0[i] = null
          }
          this.setState({
            attachmentURLs_0: this.state.attachmentURLs_0.concat(attachmentURLs_0[i]),
            processImages_0: this.state.processImages_0.concat(processImages_0[i]),
          });
        }
        for(let i=0;i<processImages_1.length;i++) {
          if (processImages_1[i] != 'No_File') {
            attachmentURLs_1[i] = await Storage.get(processImages_1[i], {level: 'public'});
          } else if (processImages_1[i] === 'No_File') {
            attachmentURLs_1[i] = null
          }
          this.setState({
            attachmentURLs_1: this.state.attachmentURLs_1.concat(attachmentURLs_1[i]),
            processImages_1: this.state.processImages_1.concat(processImages_1[i]),
          });
        }
        for(let i=0;i<processImages_2.length;i++) {
          if (processImages_2[i] != 'No_File') {
            attachmentURLs_2[i] = await Storage.get(processImages_2[i], {level: 'public'});
          } else if (processImages_2[i] === 'No_File') {
            attachmentURLs_2[i] = null
          }
          this.setState({
            attachmentURLs_2: this.state.attachmentURLs_2.concat(attachmentURLs_2[i]),
            processImages_2: this.state.processImages_2.concat(processImages_2[i]),
          });
        }
        for(let i=0;i<processImages_3.length;i++) {
          if (processImages_3[i] != 'No_File') {
            attachmentURLs_3[i] = await Storage.get(processImages_3[i], {level: 'public'});
          } else if (processImages_3[i] === 'No_File') {
            attachmentURLs_3[i] = null
          }
          this.setState({
            attachmentURLs_3: this.state.attachmentURLs_3.concat(attachmentURLs_3[i]),
            processImages_3: this.state.processImages_3.concat(processImages_3[i]),
          });
        }

        this.setState({process, applicationUUId, processUUId, processId,
                       processTitle, dateStart, dateEnd, customerComment, customerSign});
          
        this.setState({
          processComment_0: this.state.processComment[0],
          processComment_1: this.state.processComment[1],
          processComment_2: this.state.processComment[2],
          processComment_3: this.state.processComment[3],
          /*
          customerComment_0: this.state.customerComment[0],
          customerComment_1: this.state.customerComment[1],
          customerComment_2: this.state.customerComment[2],
          customerComment_3: this.state.customerComment[3],
          */
        })

        if(this.state.processComment_0 === 'default') {
          this.setState({ processComment_0: '' });
        }
        if(this.state.processComment_1 === 'default') {
          this.setState({ processComment_1: '' });
        }
        if(this.state.processComment_2 === 'default') {
          this.setState({ processComment_2: '' });
        }
        if(this.state.processComment_3 === 'default') {
          this.setState({ processComment_3: '' });
        }
        if(this.state.customerComment === 'default') {
          this.setState({ customerComment: '' });
        }
        if(this.state.customerSign === 'default') {
          this.setState({ customerSign: '' });
        }
      }
    } catch(e) {
      console.log(e)
    }

    if(this.state.processToggle === 'start') {
      this.state.completedStep[0] = false
      this.state.completedStep[1] = false
      this.state.completedStep[2] = false
      this.state.completedStep[3] = false
      this.state.completedStep[4] = false
      this.setState({activeStep: 0})
    } else if(this.state.processToggle === '0') {
      this.state.completedStep[0] = true
      this.state.completedStep[1] = false
      this.state.completedStep[2] = false
      this.state.completedStep[3] = false
      this.state.completedStep[4] = false
      this.setState({activeStep: 1})
    } else if(this.state.processToggle === '1') {
      this.state.completedStep[0] = true
      this.state.completedStep[1] = true
      this.state.completedStep[2] = false
      this.state.completedStep[3] = false
      this.state.completedStep[4] = false
      this.setState({activeStep: 2})
    } else if(this.state.processToggle === '2') {
      this.state.completedStep[0] = true
      this.state.completedStep[1] = true
      this.state.completedStep[2] = true
      this.state.completedStep[3] = false
      this.state.completedStep[4] = false
      this.setState({activeStep: 3})
    } else if(this.state.processToggle === '3') {
      this.state.completedStep[0] = true
      this.state.completedStep[1] = true
      this.state.completedStep[2] = true
      this.state.completedStep[3] = true
      this.state.completedStep[4] = false
      this.setState({activeStep: 4})
    } else if(this.state.processToggle === '4') {
      this.state.completedStep[0] = true
      this.state.completedStep[1] = true
      this.state.completedStep[2] = true
      this.state.completedStep[3] = true
      this.state.completedStep[4] = true
      this.setState({activeStep: 4})
    }

    //------------------------------------------------------------------------------------
    // FCM to Buyer
    if(this.state.processToggle === 'start' || this.state.processToggle === '0' || this.state.processToggle === '1'
       || this.state.processToggle === '2') {
      try {
        await this.getNotifications_ToBuyer();
        if(this.state.notificationsToBuyer) { 
          let fcmTokenIdToBuyer
          Object.keys(this.state.notificationsToBuyer).map(notificationUUId => {
            const notificationToBuyer = this.state.notificationsToBuyer[notificationUUId];
              fcmTokenIdToBuyer = notificationToBuyer.fcmTokenIdToBuyer
              this.setState({ fcmTokenIdToBuyer: this.state.fcmTokenIdToBuyer.concat(fcmTokenIdToBuyer) })
          });
        }
      } catch(e) {
        console.log(e)
      }
    }
    // FCM to Supplier
    if(this.state.processToggle === '3') {
      try {
        await this.getNotifications_ToSupplier();
        if(this.state.notificationsToSupplier) { 
          let fcmTokenIdToSupplier
          Object.keys(this.state.notificationsToSupplier).map(notificationUUId => {
            const notificationToBuyer = this.state.notificationsToSupplier[notificationUUId];
              fcmTokenIdToSupplier = notificationToBuyer.fcmTokenIdToSupplier
              this.setState({ fcmTokenIdToSupplier: this.state.fcmTokenIdToSupplier.concat(fcmTokenIdToSupplier) })
          });
        }
      } catch(e) {
        console.log(e)
      }
    }
    //------------------------------------------------------------------------------------
  }

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return 'Step 1 : 소재 준비 및 제작 시작';
      case 1:
        return 'Step 2 : 제작 중';
      case 2:
        return 'Step 3 : 제작 중';
      case 3:
        return 'Step 4 : 제작 완료';
      case 4:
        return 'Step 5 : 검수 완료';
      default:
        return 'Unknown step';
    }
  }

  handleStep = (step) => () => {
    this.setState({activeStep: step})
  };

  handleComplete = async event => {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    // 제작자
    if(this.state.processUserId === currentAuthUserFromSession)
    {
      if(this.state.processToggle != '3' || this.state.processToggle != '4') {

        // 사진 업로드
        let attachments = []

        // 항상 첫 파일은 this.file_1(=this.file[0][0])이기 때문에
        // 첫 파일이 첨부가 되었으면 뒤에 나머지 파일도 바뀌었다고 보면 됨
        if (this.file_1) {
          try{
            for(let i=0;i<3;i++) {
              this.file[0][i]
              ? attachments.push(await s3UploadPublic(this.file[0][i]))
              : attachments.push('No_File');
            }
          } catch (e) {
            console.log(e)
          }
        } else if(!this.file_1) {
          try{
            for(let i=0;i<3;i++) {
              attachments.push('No_File');
            }
          } catch (e) {
            console.log(e)
          }
        }

        if(this.state.activeStep === 0) {
          // 작성 안한 파트 작성하게끔 해주는 코드
          if (!this.state.processComment_0) {
            this.setState({alarmDialogOpen_1: true});
            return;
          }
          
          // process comment
          this.state.processComment[0] = this.state.processComment_0
          this.state.processComment[1] = 'default'
          this.state.processComment[2] = 'default'
          this.state.processComment[3] = 'default'

          try {
            await this.putProcess({
              attachments,
              processToggle: '0', 
              processComment: this.state.processComment, 
              customerComment: this.state.customerComment, 
              processImages_0: attachments, 
              processImages_1: this.state.processImages_1,
              processImages_2: this.state.processImages_2,
              processImages_3: this.state.processImages_3,
              customerSign: this.state.customerSign
            });
            window.location.reload();
          } catch (e) {
             console.log(e)
          }      
        } else if (this.state.activeStep === 1) {
          // 작성 안한 파트 작성하게끔 해주는 코드
          if (!this.state.processComment_1) {
            this.setState({alarmDialogOpen_1: true});
            return;
          }
    
          // process comment
          this.state.processComment[0] = this.state.processComment_0
          this.state.processComment[1] = this.state.processComment_1
          this.state.processComment[2] = 'default'
          this.state.processComment[3] = 'default'
    
          try {
            await this.putProcess({
              attachments,
              processToggle: '1', 
              processComment: this.state.processComment, 
              customerComment: this.state.customerComment, 
              processImages_0: this.state.processImages_0, 
              processImages_1: attachments,
              processImages_2: this.state.processImages_2,
              processImages_3: this.state.processImages_3,
              customerSign: this.state.customerSign
            });
            window.location.reload();
          } catch (e) {
             console.log(e)
          }
        } else if (this.state.activeStep === 2) {
          // 작성 안한 파트 작성하게끔 해주는 코드
          if (!this.state.processComment_2) {
            this.setState({alarmDialogOpen_1: true});
            return;
          }
    
          // process comment
          this.state.processComment[0] = this.state.processComment_0
          this.state.processComment[1] = this.state.processComment_1
          this.state.processComment[2] = this.state.processComment_2
          this.state.processComment[3] = 'default'
    
          try {
            await this.putProcess({
              attachments,
              processToggle: '2', 
              processComment: this.state.processComment, 
              customerComment: this.state.customerComment, 
              processImages_0: this.state.processImages_0, 
              processImages_1: this.state.processImages_1,
              processImages_2: attachments,
              processImages_3: this.state.processImages_3,
              customerSign: this.state.customerSign
            });
            window.location.reload();
          } catch (e) {
             console.log(e)
          }
        } else if (this.state.activeStep === 3) {
          // 작성 안한 파트 작성하게끔 해주는 코드
          if (!this.state.processComment_3) {
            this.setState({alarmDialogOpen_1: true});
            return;
          }
    
          // process comment
          this.state.processComment[0] = this.state.processComment_0
          this.state.processComment[1] = this.state.processComment_1
          this.state.processComment[2] = this.state.processComment_2
          this.state.processComment[3] = this.state.processComment_3
    
          try {
            await this.putProcess({
              attachments,
              processToggle: '3', 
              processComment: this.state.processComment, 
              customerComment: this.state.customerComment, 
              processImages_0: this.state.processImages_0, 
              processImages_1: this.state.processImages_1,
              processImages_2: this.state.processImages_2,
              processImages_3: attachments,
              customerSign: this.state.customerSign
            });
            window.location.reload();
          } catch (e) {
             console.log(e)
          }
        }
      }

      try {
        if(this.state.fcmTokenIdToBuyer.length > 0) {
          await this.postNotificationFCM({
            registration_ids: this.state.fcmTokenIdToBuyer,
            title: '제작진행상황을 체크해주세요.',
            body: '제목 : '+ this.state.processTitle,
          });
        }
      } catch(e) {
        console.log(e)
      }
    } 
  };

  handleSign = async event => {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    // 발주자
    if(this.state.customerUserId === currentAuthUserFromSession)
    {
      if(this.state.processComment_0 === '') {
        this.state.processComment_0 === 'default'
      }
      if(this.state.processComment_1 === '') {
        this.state.processComment_1 === 'default'
      }
      if(this.state.processComment_2 === '') {
        this.state.processComment_2 === 'default'
      }
      if(this.state.processComment_3 === '') {
        this.state.processComment_3 === 'default'
      }

      // process comment
      this.state.processComment[0] = this.state.processComment_0
      this.state.processComment[1] = this.state.processComment_1
      this.state.processComment[2] = this.state.processComment_2
      this.state.processComment[3] = this.state.processComment_3

      if(this.state.processToggle === '3') {
        // 작성 안한 파트 작성하게끔 해주는 코드
        if (this.state.customerComment === '') {
          this.setState({alarmDialogOpen_1: true});
          return;
        }
        if (this.state.customerSign != '검수완료') {
          this.setState({alarmDialogOpen_2: true});
          return;
        }

        try {
          await this.putProcess({
            processToggle: '4', 
            processComment: this.state.processComment, 
            customerComment: this.state.customerComment, 
            processImages_0: this.state.processImages_0, 
            processImages_1: this.state.processImages_1,
            processImages_2: this.state.processImages_2,
            processImages_3: this.state.processImages_3,
            customerSign: this.state.customerSign
          });
          window.location.reload();
        } catch (e) {
           console.log(e)
        }
      }

      try {
        if(this.state.fcmTokenIdToSupplier.length > 0) {
          await this.postNotificationFCM({
            registration_ids: this.state.fcmTokenIdToSupplier,
            title: '발주업체의 검수가 완료되었습니다.',
            body: '제목 : '+ this.state.processTitle,
          });
        }
      } catch(e) {
        console.log(e)
      }
    } 
  };

  handleFileChange = (e) => {

    this.file = []
    this.fileArray = []
    this.tempFile = null;

    this.file.push(e.target.files)
    // for (let i = 0; i < this.file[0].length; i++) {  // 업로드 사진 수량 상관 없는 코드
    for (let i = 0; i < 3; i++) { // 사진 3장까지만 업로드 하기 위한 코드 
      // 만약 동영상이 한개라도 있으면 제일 첫 동영상 1개만 업로드 하기 위한 코드
      if(this.file[0][i]) {
        if(this.file[0][i].name.substr(this.file[0][i].name.length-4, 4) == (".mp3" && ".mp4")) 
        {
          this.tempFile = this.file[0][i]
          this.file = []
          this.fileArray = []
          this.file.push(this.tempFile)
          this.file[0][0] = this.file[0]

          this.fileArray.push(URL.createObjectURL(this.file[0][0]))

          this.file_1 = this.file[0][0]

          return;
        }
        this.fileArray.push(URL.createObjectURL(this.file[0][i]))
        this.file_1 = this.file[0][0]
      }
    }
    this.setState({ tempUploadImage: this.fileArray })
  }

  compressImage = async (imageFile) => {
    try {
      const compressedFile = await imageCompression(imageFile, MAX_IMAGE_SIZE_MB)
      this.setState({
        isCompressing: false,
        file: compressedFile,
        fileName: compressedFile.name,
      })
    } catch (error) {
      this.setState({
        isCompressing: false,
        warningMessage: '* Fail to compress image'
      })
    }
  }

  handleCancelPhoto = () => {
    this.setState({
      file: '',
      fileName: '',
      tempUploadImage: [null]
    });
    this.file = []
    this.fileArray = []
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
    })
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    const connector = <StepConnector 
      classes={{
        //active: classes.connectorActive,
        //completed: classes.connectorCompleted,
        line: classes.connectorLine
      }} />;

    const stepper = (
      <div className={classes.stepperRoot}>
        <Stepper nonLinear activeStep={this.state.activeStep} className={classes.stepper} connector={connector}>
          {this.steps.map((label, index) => (
            <Step key={label} className={classes.step}>
              <StepButton onClick={this.handleStep(index)} className={classes.stepButton}
               completed={this.state.completedStep[index]}>
                <StepLabel
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    labelContainer: classes.labelContainer,
                    iconContainer: classes.iconContainer
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.stepLabelRoot,
                      completed: classes.completed,
                      active: classes.active,
                      text: classes.text
                    }
                  }}
                >
                  {/*{label}*/}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>
    );

    const stepperButton = (
      <div className={classes.stepperRoot}>
        {this.state.activeStep !== this.steps.length &&
          (this.state.completedStep[this.state.activeStep] 
            ? 
            (
              <Button variant="contained"  color="secondary" className={classes.submitButton2}>
                <Typography className={classes.typoSubmit}>
                  등록됨
                </Typography>
              </Button>
            ) 
            : 
            (
            <Button variant="contained" className={classes.submitButton} onClick={this.handleComplete}>
              <Typography className={classes.typoSubmit}>
                등록
              </Typography>
            </Button>
            )
          )
        }
      </div>
    );

    const bottomAppbar = (
      <div>
      <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
        <Divider/>
        <Toolbar>
          <Grid container spacing={5} direction="row" justify="flex-start" alignItems="center">
            <Grid item sm={10} md={10} lg={10} xl={10}>
              <div>
                <input
                  className={classes.UploadPhoto__file}
                  id="uploadPhoto"
                  type="file"
                  name="Search file"
                  onChange={this.handleFileChange}
                  accept=".png, .jpg, .jpeg, .mp3, .mp4"
                  required
                  multiple 
                />
                <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
                  <label htmlFor="uploadPhoto">
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                      <AddPhotoAlternateIcon className={classes.addPhotoIcon}/>
                    </Grid>                      
                  </label>
                  {(this.fileArray || []).map(url => (
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={url}>
                      {this.file[0][0].name.substr(this.file[0][0].name.length-4, 4) == (".mp3" && ".mp4")
                      ?
                      <Typography>
                        {this.file[0][0].name}
                      </Typography>
                      :
                      <CardMedia
                        className={classes.mediaAdd}
                        image={url}
                        aria-label="Recipe" 
                      >
                      </CardMedia>
                      }
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
            <Grid item sm={2} md={2} lg={2} xl={2}>
              <IconButton
                edge="start"
                //className={classes.commentIconButton}
                /*color="primary"*/ className={classes.buttonLine}
                aria-label="back"
                onClick={this.handleCancelPhoto}
              >
                <span>첨부취소</span>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      </div>
    );

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    프로세스
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          { this.state.process
          ?
          <main className={classes.content}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography className={classes.typoTitle}>
                            {/* 글자 길이가 20개 이상 넘어가면 '...' 나옴 */}
                            {this.state.processTitle.slice(0,20)}
                            {this.state.processTitle.length > 20 
                            ? '...'
                            : null
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography className={classes.typoDate}>
                            {moment(this.state.dateStart).format("YYYY-MM-DD")} ~ {moment(this.state.dateEnd).format("YYYY-MM-DD")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider/>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoGetStep}>
                        {this.getStepContent(this.state.activeStep)}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemStep}>
                      {stepper}
                    </ListItem>
                    <Divider/>
                    { this.state.processUserId === currentAuthUserFromSession
                    ?
                    <div>
                      { this.state.activeStep === 0 || this.state.activeStep === 1 || this.state.activeStep === 2
                        || this.state.activeStep === 3
                      ?
                      <ListItem className={classes.listItemTitle}>
                        <Typography className={classes.typoTitle}>코멘트(제작자)</Typography>
                      </ListItem>
                      :
                      null
                      }
                      <Divider/>
                      {this.state.processToggle === 'start'
                      ?
                        <ListItem className={classes.listItem}>
                          { this.state.activeStep === 0
                          ?
                          <textarea
                            id="processComment_0"
                            name="processComment_0"
                            value={this.state.processComment_0}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 1)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          this.state.activeStep === 1
                          ?
                          <textarea
                            id="processComment_1"
                            name="processComment_1"
                            value={this.state.processComment_1}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 2)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          this.state.activeStep === 2
                          ?
                          <textarea
                            id="processComment_2"
                            name="processComment_2"
                            value={this.state.processComment_2}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 3)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          this.state.activeStep === 3
                          ?
                          <textarea
                            id="processComment_3"
                            name="processComment_3"
                            value={this.state.processComment_3}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 4)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          null
                          }
                        </ListItem>
                      :
                      this.state.processToggle === '0'
                      ?
                        <ListItem className={classes.listItem}>
                          { this.state.activeStep === 0
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_0}</Typography>
                          :
                          this.state.activeStep === 1
                          ?
                          <textarea
                            id="processComment_1"
                            name="processComment_1"
                            value={this.state.processComment_1}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 2)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          this.state.activeStep === 2
                          ?
                          <textarea
                            id="processComment_2"
                            name="processComment_2"
                            value={this.state.processComment_2}
                          onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 3)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          this.state.activeStep === 3
                          ?
                          <textarea
                            id="processComment_3"
                            name="processComment_3"
                            value={this.state.processComment_3}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 4)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          null
                          }
                        </ListItem>
                      :
                      this.state.processToggle === '1'
                      ?
                        <ListItem className={classes.listItem}>
                          { this.state.activeStep === 0
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_0}</Typography>
                          :
                          this.state.activeStep === 1
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_1}</Typography>
                          :
                          this.state.activeStep === 2
                          ?
                          <textarea
                            id="processComment_2"
                            name="processComment_2"
                            value={this.state.processComment_2}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 3)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          this.state.activeStep === 3
                          ?
                          <textarea
                            id="processComment_3"
                            name="processComment_3"
                            value={this.state.processComment_3}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 4)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          null
                          }
                        </ListItem>
                      :
                      this.state.processToggle === '2'
                      ?
                        <ListItem className={classes.listItem}>
                          { this.state.activeStep === 0
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_0}</Typography>
                          :
                          this.state.activeStep === 1
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_1}</Typography>
                          :
                          this.state.activeStep === 2
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_2}</Typography>
                          :
                          this.state.activeStep === 3
                          ?
                          <textarea
                            id="processComment_3"
                            name="processComment_3"
                            value={this.state.processComment_3}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 4)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                          :
                          null
                          }
                        </ListItem>
                      :
                      this.state.processToggle === '3' || this.state.processToggle === '4'
                      ?
                        <ListItem className={classes.listItem}>
                          { this.state.activeStep === 0
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_0}</Typography>
                          :
                          this.state.activeStep === 1
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_1}</Typography>
                          :
                        this.state.activeStep === 2
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_2}</Typography>
                          :
                          this.state.activeStep === 3
                          ?
                          <Typography className={classes.textTypo}>{this.state.processComment_3}</Typography>
                          :
                          null
                          }
                        </ListItem>
                      :
                      null
                      }
                    </div>
                    :
                    this.state.customerUserId === currentAuthUserFromSession
                    ?
                    <div>
                      { this.state.activeStep === 0 || this.state.activeStep === 1 || this.state.activeStep === 2
                        || this.state.activeStep === 3
                      ?
                      <ListItem className={classes.listItemTitle}>
                        <Typography className={classes.typoTitle}>제작자 코멘트</Typography>
                      </ListItem>
                      :
                      null
                      }
                      <Divider/>
                      <ListItem className={classes.listItem}>
                        { this.state.activeStep === 0
                        ?
                        <Typography className={classes.textTypo}>{this.state.processComment_0}</Typography>
                        :
                        this.state.activeStep === 1
                        ?
                        <Typography className={classes.textTypo}>{this.state.processComment_1}</Typography>
                        :
                        this.state.activeStep === 2
                        ?
                        <Typography className={classes.textTypo}>{this.state.processComment_2}</Typography>
                        :
                        this.state.activeStep === 3
                        ?
                        <Typography className={classes.textTypo}>{this.state.processComment_3}</Typography>
                        :
                        null
                        }
                      </ListItem>
                    </div>
                    :
                    null
                    }
                    { this.state.customerUserId === currentAuthUserFromSession
                    ?
                    <div>
                      { this.state.activeStep === 4
                      ?
                      <ListItem className={classes.listItemTitle}>
                        <Typography className={classes.typoTitle}>코멘트(발주자)</Typography>
                      </ListItem>
                      :
                      null
                      }
                      <Divider/>
                      {this.state.processToggle === '3' && this.state.activeStep === 4
                      ?
                        <ListItem className={classes.listItem}>
                          <textarea
                            id="customerComment"
                            name="customerComment"
                            value={this.state.customerComment}
                            onChange={this.handleValueChange}
                            placeholder="내용을 작성해주세요 (Step 5)"
                            className={classes.Textarea__textarea}
                            autoComplete="off"
                          />
                        </ListItem>
                      :
                      this.state.processToggle === '4' && this.state.activeStep === 4
                      ?
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textTypo}>{this.state.customerComment}</Typography>
                        </ListItem>
                      :
                      null
                      }
                    </div>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.activeStep === 0 || this.state.activeStep === 1 || this.state.activeStep === 2
                    || this.state.activeStep === 3
                    ?
                    <ListItem className={classes.listItemTitle}>
                      <Typography className={classes.typoTitle}>사진</Typography>
                    </ListItem>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.activeStep === 0
                    ?
                    <ListItem>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* AWS S3 사용시 */}
                        {this.state.processImages_0 &&
                          (this.state.processImages_0 || []).map((image, index) => (
                            image != 'No_File'
                            ?
                            <CardActionArea key={image} disabled={image.length === 1 ? true : false}>
                              {image.substr(image.length-4, 4) == (".mp3" && ".mp4")
                              ?
                              <div className={classes.videoWrapper}>
                                <ReactPlayer
                                  className={classes.reactPlayer} 
                                  url={this.state.attachmentURLs_0[index]}
                                  width='100%'
                                  height='100%'
                                  controls
                                  playing
                                  // 다운로드 금지 코드
                                  config={{ file: {
                                    attributes: {
                                      controlsList: 'nodownload'
                                    }
                                  }}}
                                />
                              </div>
                              :
                             <img
                              className={classes.media}
                              src={this.state.attachmentURLs_0[index]}
                             />
                              }    
                            </CardActionArea>
                            :
                            null
                          ))
                        }
                      </Grid>
                    </ListItem>
                    :
                    this.state.activeStep === 1
                    ?
                    <ListItem>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* AWS S3 사용시 */}
                        {this.state.processImages_1 &&
                          (this.state.processImages_1 || []).map((image, index) => (
                            image != 'No_File'
                            ?
                            <CardActionArea key={image} disabled={image.length === 1 ? true : false}>
                              {image.substr(image.length-4, 4) == (".mp3" && ".mp4")
                              ?
                              <div className={classes.videoWrapper}>
                                <ReactPlayer
                                  className={classes.reactPlayer} 
                                  url={this.state.attachmentURLs_1[index]}
                                  width='100%'
                                  height='100%'
                                  controls
                                  playing
                                  // 다운로드 금지 코드
                                  config={{ file: {
                                    attributes: {
                                      controlsList: 'nodownload'
                                    }
                                  }}}
                                />
                              </div>
                              :
                             <img
                              className={classes.media}
                              src={this.state.attachmentURLs_1[index]}
                             />
                              }    
                            </CardActionArea>
                            :
                            null
                          ))
                        }
                      </Grid>
                    </ListItem>
                    :
                    this.state.activeStep === 2
                    ?
                    <ListItem>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* AWS S3 사용시 */}
                        {this.state.processImages_2 &&
                          (this.state.processImages_2 || []).map((image, index) => (
                            image != 'No_File'
                            ?
                            <CardActionArea key={image} disabled={image.length === 1 ? true : false}>
                              {image.substr(image.length-4, 4) == (".mp3" && ".mp4")
                              ?
                              <div className={classes.videoWrapper}>
                                <ReactPlayer
                                  className={classes.reactPlayer} 
                                  url={this.state.attachmentURLs_2[index]}
                                  width='100%'
                                  height='100%'
                                  controls
                                  playing
                                  // 다운로드 금지 코드
                                  config={{ file: {
                                    attributes: {
                                      controlsList: 'nodownload'
                                    }
                                  }}}
                                />
                              </div>
                              :
                             <img
                              className={classes.media}
                              src={this.state.attachmentURLs_2[index]}
                             />
                              }    
                            </CardActionArea>
                            :
                            null
                          ))
                        }
                      </Grid>
                    </ListItem>
                    :
                    this.state.activeStep === 3
                    ?
                    <ListItem>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* AWS S3 사용시 */}
                        {this.state.processImages_3 &&
                          (this.state.processImages_3 || []).map((image, index) => (
                            image != 'No_File'
                            ?
                            <CardActionArea key={image} disabled={image.length === 1 ? true : false}>
                              {image.substr(image.length-4, 4) == (".mp3" && ".mp4")
                              ?
                              <div className={classes.videoWrapper}>
                                <ReactPlayer
                                  className={classes.reactPlayer} 
                                  url={this.state.attachmentURLs_3[index]}
                                  width='100%'
                                  height='100%'
                                  controls
                                  playing
                                  // 다운로드 금지 코드
                                  config={{ file: {
                                    attributes: {
                                      controlsList: 'nodownload'
                                    }
                                  }}}
                                />
                              </div>
                              :
                             <img
                              className={classes.media}
                              src={this.state.attachmentURLs_3[index]}
                             />
                              }    
                            </CardActionArea>
                            :
                            null
                          ))
                        }
                      </Grid>
                    </ListItem>
                    :
                    null
                    }
                    { this.state.processToggle === '3' && this.state.activeStep === 4
                      ?
                        this.state.customerUserId === currentAuthUserFromSession 
                        ?
                        <div>
                          <ListItem className={classes.listItemTitle}>
                            <Typography className={classes.typoTitle}>서명(*) - '검수완료' 라고 작성해주세요</Typography>
                          </ListItem>
                          <ListItem className={classes.listItemTextField}>
                            <TextField
                              className={classes.textField}
                              id="customerSign"
                              name="customerSign"
                              autoFocus
                              value={this.state.customerSign}
                              onChange={this.handleValueChange}
                              placeholder="검수완료"
                              autoComplete="off"
                              InputProps={{
                                classes: {
                                  input: classes.textFieldSize,
                                },
                              }}
                            />
                          </ListItem>
                        </div>
                        :
                        null
                      :
                      this.state.processToggle === '4' && this.state.activeStep === 4
                      ?
                        this.state.customerUserId === currentAuthUserFromSession 
                        ?
                        <div>
                          <ListItem className={classes.listItemTitle}>
                            <Typography className={classes.typoTitle}>서명(*)</Typography>
                          </ListItem>
                          <ListItem className={classes.listItem}>
                            <Typography className={classes.textTypo}>검수완료</Typography>
                          </ListItem>
                        </div>
                        :
                        this.state.processUserId === currentAuthUserFromSession 
                        ?
                        <div>
                          <ListItem className={classes.listItemTitle}>
                            <Typography className={classes.typoTitle}>코멘트(발주자)</Typography>
                          </ListItem>
                          <ListItem className={classes.listItem}>
                            <Typography className={classes.textTypo}>{this.state.customerComment}</Typography>
                          </ListItem>
                          <ListItem className={classes.listItemTitle}>
                            <Typography className={classes.typoTitle}>서명(*)</Typography>
                          </ListItem>
                          <ListItem className={classes.listItem}>
                            <Typography className={classes.textTypo}>검수완료</Typography>
                          </ListItem>
                        </div>
                        :
                        null
                      :
                      null
                    }
                  </List>
                </Card>
                { this.state.processUserId === currentAuthUserFromSession
                ?
                  this.state.activeStep === 0 || this.state.activeStep === 1 || this.state.activeStep === 2
                  || this.state.activeStep === 3
                  ?
                  <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {stepperButton}
                    </Grid>
                  </Grid>
                  :
                  null
                :
                null
                }
                { this.state.processToggle === '3' && this.state.activeStep === 4
                  ?
                    this.state.customerUserId === currentAuthUserFromSession 
                    ?
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                     <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button variant="contained" className={classes.submitButton}
                          onClick={this.handleSign}
                        >
                          <Typography className={classes.typoSubmit}>검수완료</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    :
                    null
                  :  
                  this.state.processToggle === '4' && this.state.activeStep === 4
                  ?
                  <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                   <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Button variant="contained" color='secondary' className={classes.submitButton2}>
                        <Typography className={classes.typoSubmit}>검수완료</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  :
                  null
                }
              </Grid>
            </Grid>
          </main>
          :
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          }
          { this.state.processUserId === currentAuthUserFromSession
          ?
            this.state.processToggle === 'start'
            ?
              bottomAppbar
            :
            this.state.processToggle === '0'
            ?
              this.state.activeStep === 1
              ?
              bottomAppbar
              :
              this.state.activeStep === 2
              ?
              bottomAppbar
              :
              this.state.activeStep === 3
              ?
              bottomAppbar
              :
              null
            :
            this.state.processToggle === '1'
            ?
              this.state.activeStep === 2
              ?
              bottomAppbar
              :
              this.state.activeStep === 3
              ?
              bottomAppbar
              :
              null
            :
            this.state.processToggle === '2'
            ?
              this.state.activeStep === 3
              ?
              bottomAppbar
              :
              null
            :
            this.state.processToggle === '3'
            ?
              null
            :
            null
          :
          null
          }
        </div>
        <Dialog open={this.state.alarmDialogOpen_1}>
          <DialogTitle>코멘트를 입력해주세요</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2}>
          <DialogTitle>'검수완료' 서명 해주세요</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

Process = withStyles(styles, {withTheme: true})(Process)
export default connect(mapStateToProps)(Process);