import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(7),
    overFlowX : "auto",
    display: 'flex',
    marginBottom : theme.spacing(13),
  },
  card: {
    width : '100%',
    marginTop: 5,
    marginBottom: 15,
    marginLeft : theme.spacing(1),
    marginRight : theme.spacing(1),
  },
  list: {
    width: '100%',
    padding: 0,
  },
  listItem: {
    height: 60,
  },
  listItemTitle: {
    height: 40,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle: {
    width: '100%',
    fontSize : 20,
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoButton: {
    width: '100%',
    fontSize : 20,
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    // width: '100%',
    // borderRadius: "20px",
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class Invoice_Admin extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        //companyToggle: false,
        completed : 0,
      };
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(!(currentAuthUserFromSession === "ap-northeast-2:0eac84f9-d5d6-454a-8468-f46c99cef2ec"
    || currentAuthUserFromSession === "ap-northeast-2:1ef91eaf-bfd0-49eb-91ce-f70191165a07"
    )) {
      this.props.history.push("/");
    }
    
    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;
    // console.log('subscribe :' + sessionStorage.getItem("subscribe"));

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: ");
      // console.log(userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: ");
      // console.log(userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        /*
        sessionStorage.setItem("currentUser_Freelancer", userID6);
        const loginType = localStorage.getItem("loginType");
        if(loginType === "facebook" || loginType === "google") {
          sessionStorage.setItem("currentUser_Freelancer", userID6);
        }
        if(loginType === "email") {
          sessionStorage.setItem("currentUser_Company", userID6);
        }
        */
        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        /*
        const loginType = localStorage.getItem("loginType");
        if(loginType === "facebook" || loginType === "google") {
          sessionStorage.setItem("currentUser_Freelancer", userID6);
        }
        if(loginType === "email") {
          sessionStorage.setItem("currentUser_Company", userID6);
        }
        */       
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          alert(e);
        }
    }

    //const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
    //const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
    // const loginType = localStorage.getItem("loginType");

    /*
    // 기업만 프로젝트 업로드 가능
    if(loginType === 'email' && currentAuthUserFromSession_Company) {
      this.setState({companyToggle: true})
    } else if(loginType != 'email' && currentAuthUserFromSession_Freelancer) {
      this.setState({companyToggle: false})
    }
    */
  }

  //----------------------------------------------------------
  myUploadPage_Project = () => {
    localStorage.setItem("myUploadPage", "project")
  }

  /*
  myUploadPage_Education = () => {
    localStorage.setItem("myUploadPage", "education")
  }

  myUploadPage_CompanyPromotion = () => {
    localStorage.setItem("myUploadPage", "companyPromotion")
  }

  myUploadPage_freelancerPromotion = () => {
    localStorage.setItem("myUploadPage", "freelancerPromotion")
  }
  */

  //----------------------------------------------------------
  myApplyingPage_Project = () => {
    localStorage.setItem("myApplyingPage", "project")
  }

  /*
  myApplyingPage_Education = () => {
    localStorage.setItem("myApplyingPage", "education")
  }

  myApplyingPage_Company = () => {
    localStorage.setItem("myApplyingPage", "company")
  }

  myApplyingPage_Freelancer = () => {
    localStorage.setItem("myApplyingPage", "freelancer")
  }
  */
  //----------------------------------------------------------

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes } = this.props;
    const customerType1_FromLocal = localStorage.getItem("customerType1");

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <List className={classes.list}>
                {customerType1_FromLocal === 'buyer'
                ?
                <div>
                <ListItem className={classes.listItemTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoTitle}>받은견적, 제작체크</Typography>
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>받은견적확인</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myUploads`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myUploadPage_Project}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>제작체크</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myOrderLists`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myOrderCheck}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                </div>
                :
                null
                }
                {/*
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>교육 요청</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myUploads`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myUploadPage_Education}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                */}
                {/*
                {this.state.companyToggle
                ?
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>기업 홍보</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myUploads`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myUploadPage_CompanyPromotion}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                :
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>기술자 홍보</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myUploads`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myUploadPage_freelancerPromotion}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                }
                <Divider/>
                */}
                {customerType1_FromLocal === 'supplier'
                ?
                <div>
                <ListItem className={classes.listItemTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoTitle}>받은요청, 제작업로드</Typography>
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>받은요청</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myApplications`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myApplyingPage_Project}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>제작업로드</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myProcessLists`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                </div>
                :
                null
                }
                {/*
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>교육</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myApplications`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myApplyingPage_Education}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                */}
                {/*
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>기업</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myApplications`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myApplyingPage_Company}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Typography className={classes.typoTitle}>기술자, 프리랜서</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    <Link component={RouterLink} to={`/myApplications`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.myApplyingPage_Freelancer}>
                        <Typography className={classes.typoButton}>리스트 보기</Typography>
                      </Button>
                    </Link>
                  </Grid>
                </ListItem>
                <Divider/>
                */}
              </List>
            </Grid>
          </Card>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  searchKeyword: state.upload.searchKeyword,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

Invoice_Admin = withStyles(styles, {withTheme: true})(Invoice_Admin)
export default connect(mapStateToProps, mapDispatchToProps)(Invoice_Admin)