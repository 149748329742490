import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
// 'react-redux'라이브러리에서 connect 변수 import. 
// 단 여기서 connect 변수는 export 시에 default로 하지 않았기 때문에(export default connect가 아니기 때문에) -> { } 가 붙는다
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';

//import './AppShellBox.scss'

import ChooseAccount from './ChooseAccount' // Choose Account 페이지
import AWSLoginForm from './AWSAuth/AWSLoginForm' // 로그인 페이지
import AWSSignupForm_Supplier_Company from './AWSAuth/AWSSignupForm_Supplier_Company'
import AWSSignupForm_Buyer_Company from './AWSAuth/AWSSignupForm_Buyer_Company'
import AWSSignupForm_Supplier_Freelancer from './AWSAuth/AWSSignupForm_Supplier_Freelancer'
import AWSSignupForm_Buyer_Freelancer from './AWSAuth/AWSSignupForm_Buyer_Freelancer'
import AWSSignupForm_Buyer_Freelancer_FederatedLogin from './AWSAuth/AWSSignupForm_Buyer_Freelancer_FederatedLogin'
import AWSResetPassword from './AWSAuth/AWSResetPassword' // 비밀번호 찾기 페이지
import MainAppShell from './MainAppShell';
import MainAppShellField from './MainAppShellField';

// import MainAppShellProcess fro./MainAppShellProcess(곧 삭제)'; //프로세스 완성후 삭제
// import SabuPage from './SabuPage';
// import JejaPage from './JejaPage';

import Home from './Home';
import Home_Promotion from './Home_Promotion';
import Home_Main from './Home_Main';
import Home_Introduce from './Home_Introduce';
import Home_Entrance from './Home_Entrance';
import Home_Education from './Home_Education';
import Home_Culture from './Home_Culture';
import HomeGetProjects from './HomeGetProjects';
import Home_Manual from './Home_Manual';
import MyHome from './MyHome';
import PeopleHome from './PeopleHome';
import Follow from './Follow';
import MyUploads from './MyUploads';
import ApplicantList from './ApplicantList';
import MyApplications from './MyApplications';
import MyOrderUpload from './MyOrderUpload';
import MyOrderLists from './MyOrderLists';
import MyProcessLists from './MyProcessLists';
import Process from './Process';
import NewPagePrepare from './NewPagePrepare';

import NotiGetting from './NotiGetting';
import Memberterms from './Memberterms';
import InfoAgreememnt from './InfoAgreememnt';

// drawer 관련
import FieldFormProject from './FieldFormProject';
import FieldFormEducation from './FieldFormEducation';
import FieldFormCompanyList from './FieldFormCompanyList';
import FieldFormCompanyPromotion from './FieldFormCompanyPromotion';
import FieldFormFreelancerPromotion from './FieldFormFreelancerPromotion';

import MyPage_Company from './MyPage_Company';
import MyPage_Freelancer from './MyPage_Freelancer';
// import AuthForm from './Auth/AuthForm';
import MyAccount from './MyAccount';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import NotiSetting from './NotiSetting';
import HangOutManual from './HangOutManual';
import OrderPage from './OrderPage';
import OrderGetPage from './OrderGetPage';

import ViewCompany from './ViewCompany';
import ViewFreelancer from './ViewFreelancer';

// 업로드 관련
import UploadPhoto from './UploadPhoto';
import UploadPhotoModify from './UploadPhotoModify';
import UploadFeed from './UploadFeed';
import UploadConnectFeed from './UploadConnectFeed';
import UploadFavorListPage from './UploadFavorListPage';
// import SignupPage from './SignupPage';
// import LoginPage from './LoginPage';

// admin 관련
import MainAppShell_Admin from './Admin/MainAppShell_Admin'
import MainAppShellField_Admin from './Admin/MainAppShellField_Admin'
import FieldFormHome_Admin from './Admin/FieldFormHome_Admin'
import FieldFormProject_Admin from './Admin/FieldFormProject_Admin'
import Buyer_Admin from './Admin/Buyer_Admin'
import Supplier_Admin from './Admin/Supplier_Admin'
import Invoice_Admin from './Admin/Invoice_Admin'
import MyPage_Company_Admin from './Admin/MyPage_Company_Admin'
import MyPage_Freelancer_Admin from './Admin/MyPage_Freelancer_Admin'
import UploadFeed_Admin from './Admin/UploadFeed_Admin';
import UploadPhotoModify_Admin from './Admin/UploadPhotoModify_Admin';
import NotiSending_Admin from './Admin/NotiSending_Admin';
import Process_Admin from './Admin/Process_Admin';

//import './AppShellBox.scss'

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
// import * as uploadActions from 'redux/actions/upload'

import { createBrowserHistory } from 'history';

const styles = theme => ({
});

const customHistory = createBrowserHistory();
const prevHistoryPush = customHistory.push;
let lastLocation = customHistory.location;

customHistory.listen(location => {
  lastLocation = location;
});
customHistory.push = (pathname, state = {}) => {
  if (
    lastLocation === null ||
    pathname !==
      lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(state) !== JSON.stringify(lastLocation.state)
  ) {
    prevHistoryPush(pathname, state);
  }
};


//----------------------------------------------------------------------------
// 이거 안씀. 그냥 참조 바람.
//----------------------------------------------------------------------------
/*
const UploadContainer = () => (
    <div>
      <Route exact path="/" render={() => <Redirect to="/upload" />} />
      <Route path="/upload" component={UploadPhoto} />
    </div>
  )
  
  
   const DefaultContainer = () => (
        <MainAppShell>
            <div>

            <Route exact path="/" component={Home} />
            <Route exact path="/design" component={Design} />
            <AppBarRoute exact path="/law" component={Law} />
            

            <Route exact path="/mypage" component={MyPage} />
            <Route exact path="/myaccount" component={MyAccount} />
            <Route exact path="/texts" component={Texts} />
            <Route exact path="/words" component={Words} />
            </div>
        </MainAppShell>
   )
*/
//----------------------------------------------------------------------------

/*
// 아래의 주석처리된것도 동작 됨. 단, 코드가 조금 더 길어서 다른것으로 사용했음.
const AppBarLayout = (props) => {
    return (
        <div>
            {props.appBar ? React.createElement(props.appBar) : null}
            {props.children}
        </div>
    );
};

const AppBarRoute = ({ component, ...routeProps }) => {
    return (
        <Route {...routeProps} render={(props) => {
            return (
                <MainAppShell>
                    <AppBarLayout { ...props} {...routeProps}>
                        {React.createElement(component, props)}
                    </AppBarLayout>
                </MainAppShell>
            );
        }} />
    );
};
*/

const AppBarRoute = ({exact, path, component: Component}) => (
    <Route exact={exact} path={path} render={(props) => (   
      <div>
        <MainAppShell>
            <Component {...props}/>
        </MainAppShell>
      </div>
    )}/>
)

const AppBarFieldRoute = ({exact, path, component: Component}) => (
    <Route exact={exact} path={path} render={(props) => (   
      <div>
        <MainAppShellField>
            <Component {...props}/>
        </MainAppShellField>
      </div>
    )}/>
)

// admin 페이지
const AppBarAdminRoute = ({exact, path, component: Component}) => (
  <Route exact={exact} path={path} render={(props) => (   
    <div>
      <MainAppShell_Admin>
          <Component {...props}/>
      </MainAppShell_Admin>
    </div>
  )}/>
)

// admin 페이지
const AppBarFieldAdminRoute = ({exact, path, component: Component}) => (
  <Route exact={exact} path={path} render={(props) => (   
    <div>
      <MainAppShellField_Admin>
          <Component {...props}/>
      </MainAppShellField_Admin>
    </div>
  )}/>
)

/*
프로세스 완성후 삭제
const AppBarProcessRoute = ({exact, path, component: Component}) => (
  <Route exact={exact} path={path} render={(props) => (   
    <div>
      <MainAppShellProcess>
          <Component {...props}/>
      </MainAppShellProcess>
    </div>
  )}/>
)
*/


class AppShellBox extends React.Component {
    render() {
        const { classes, theme, isuploadtbtn } = this.props;

        return (
            <div className={classes.root}>
                <Router history={customHistory}>
                    <Switch>
                        {/*
                        <Route exact path="/(upload)" component={UploadContainer}/>
                        <Route component={DefaultContainer}/>
                        */}

                        {/*----------------------------admin 관련----------------------------*/}
                        <AppBarAdminRoute exact path="/projectadmin" component={FieldFormHome_Admin} />
                        <AppBarAdminRoute exact path="/buyeradmin" component={Buyer_Admin} />
                        <AppBarAdminRoute exact path="/supplieradmin" component={Supplier_Admin} />
                        <AppBarAdminRoute exact path="/invoiceadmin" component={Invoice_Admin} />

                        <AppBarFieldAdminRoute exact path="/fieldformadmin/:id/projects" component={FieldFormProject_Admin} />

                        <Route exact path="/useradmin/company/:id" component={MyPage_Company_Admin} />
                        <Route exact path="/useradmin/freelancer/:id" component={MyPage_Freelancer_Admin} />
                        <Route exact path="/uploadadmin/:id" component={UploadFeed_Admin} />
                        <Route exact path="/uploadadmin/modify/:id" component={UploadPhotoModify_Admin} />
                        <Route exact path="/notiSendingadmin" component={NotiSending_Admin} />
                        <Route exact path="/processadmin/:id" component={Process_Admin} />
                        {/*-----------------------------------------------------------------*/}

                        {/* Appbar */}
                        <AppBarRoute exact path="/" component={Home_Main} />
                        <AppBarRoute exact path="/introduce" component={Home_Introduce} />
                        <AppBarRoute exact path="/entrance" component={Home_Entrance} />
                        <AppBarRoute exact path="/education" component={Home_Education} />
                        <AppBarRoute exact path="/culture" component={Home_Culture} />
                        <AppBarRoute exact path="/home" component={Home} />
                        <AppBarRoute exact path="/homemanual" component={Home_Manual} />
                        <AppBarRoute exact path="/homegetprojects" component={HomeGetProjects} />
                        <AppBarRoute exact path="/myHome" component={MyHome} />
                        <AppBarRoute exact path="/home/:id" component={PeopleHome} />
                        <AppBarRoute exact path="/follow" component={Follow} />
                        <AppBarRoute exact path="/newpageprepare" component={NewPagePrepare} />

                        {/* 왼쪽 분야 클릭시 나오는 App bar */}
                        <AppBarFieldRoute exact path="/fieldform/:id/projects" component={FieldFormProject} />
                        <AppBarFieldRoute exact path="/fieldform/:id/educations" component={FieldFormEducation} />
                        <AppBarFieldRoute exact path="/fieldform/:id/companylists" component={FieldFormCompanyList} />
                        <AppBarFieldRoute exact path="/fieldform/:id/companypromotions" component={FieldFormCompanyPromotion} />
                        <AppBarFieldRoute exact path="/fieldform/:id/freelancerpromotions" component={FieldFormFreelancerPromotion} />
        
                        {/* MyProcess 관련 */}
                        {/*<AppBarProcessRoute exact path="/process/:id" component={Process} />

                        {/* Drawer */}
                        <AppBarRoute exact path="/myaccount" component={MyAccount} />
                        <AppBarRoute exact path="/notiSetting" component={NotiSetting} />
                        {/*<AppBarRoute exact path="/follow" component={Follow} />*/}
                        
                        {/* upload (Appbar 안나옴) */}
                        <Route exact path="/login" component={AWSLoginForm} />
                        <Route exact path="/chooseaccount" component={ChooseAccount} />
                        <Route exact path="/signupsuppliercompany" component={AWSSignupForm_Supplier_Company} />
                        <Route exact path="/signupbuyercompany" component={AWSSignupForm_Buyer_Company} />
                        <Route exact path="/signupsupplierfreelancer" component={AWSSignupForm_Supplier_Freelancer} />
                        <Route exact path="/signupbuyerfreelancer" component={AWSSignupForm_Buyer_Freelancer} />
                        <Route exact path="/signupformbuyerfreelancerfederatedlogin" component={AWSSignupForm_Buyer_Freelancer_FederatedLogin} />
                        <Route exact path="/resetpassword" component={AWSResetPassword} />
                        {/*<Route exact path="/signup" component={AWSAuthSignUpPage} />*/}
                        <Route exact path="/myaccount/email/:id" component={ChangeEmail} />
                        <Route exact path="/myaccount/password" component={ChangePassword} />
                        <Route exact path="/user/company/:id" component={MyPage_Company} />
                        <Route exact path="/user/freelancer/:id" component={MyPage_Freelancer} />
                        <Route exact path="/user/company/view/:id" component={ViewCompany} />
                        <Route exact path="/user/freelancer/view/:id" component={ViewFreelancer} />
                        <Route exact path="/myUploads" component={MyUploads} />
                        <Route exact path="/applicantList/:id" component={ApplicantList} />
                        <Route exact path="/myApplications" component={MyApplications} />
                        <Route exact path="/upload" component={UploadPhoto} />
                        <Route exact path="/upload/:id" component={UploadFeed} />
                        <Route exact path="/uploadconnect/:id" component={UploadConnectFeed} />
                        <Route exact path="/upload/Modify/:id" component={UploadPhotoModify} /> {/* 수정 페이지 */}
                        <Route exact path="/notiGetting" component={NotiGetting} />
                        <Route exact path="/hangOutManual" component={HangOutManual} />
                        <Route exact path="/orderPage/:id" component={OrderPage} />
                        <Route exact path="/orderGetPage/:id" component={OrderGetPage} />
                        <Route exact path="/myOrderUpload" component={MyOrderUpload} />
                        <Route exact path="/myOrderLists" component={MyOrderLists} />
                        <Route exact path="/myProcessLists" component={MyProcessLists} />
                        <Route exact path="/process/:id" component={Process} />
                        <Route exact path="/memberterms" component={Memberterms} />
                        <Route exact path="/infoAgreememnt" component={InfoAgreememnt} />

                        {/* 아래는 현재 안쓰는 페이지 */}
                        <Route exact path="/upload/FavorList/:id" component={UploadFavorListPage} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
    isuploadtbtn: state.upload.isuploadtbtn,    // 현재 안쓰고 있음. 나중에 관련 state 지우기 바람
    isKlaytnLoggedIn: state.auth.isKlaytnLoggedIn,
    // isAwsLoggedIn: state.auth.isAwsLoggedIn,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
    integrateWallet: (privateKey) => dispatch(authActions.integrateWallet(privateKey)),
    removeWallet: () => dispatch(authActions.removeWallet()),
    // awsCognitoOn: (email, password) => dispatch(authActions.awsCognitoOn(email, password)),
  })

AppShellBox = withStyles(styles, {withTheme: true})(AppShellBox)
export default connect(mapStateToProps, mapDispatchToProps)(AppShellBox);