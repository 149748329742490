import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import Feed_MyOrderLists from './Feed_MyOrderLists';

// S3 관련
import { API } from "aws-amplify";

import { connect } from 'react-redux'

import SVGIcon from './SVGIcon'

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(7),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  title: {
    flexGrow: 1,
    fontSize : 17,
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '30px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  card: {
    maxWidth: 850,
    marginTop: 15,
    marginBottom: 15,
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 50,
  },
  listItemIcon: {
    minWidth: 0
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class MyOrderLists extends Component {

  constructor(props) {
    super(props);

    this.state = {
      processContents: '',
      processContent: '',
      userId: "",
      completed : 0,
    };
  }

  getProceses() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/process?customerUserId=${currentAuthUserFromSession}`)
    .then(processContents => this.setState({processContents : processContents}));
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    
    await this.getProceses();
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, searchKeyword } = this.props;

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredComponents = (data) => {
      data = data.filter((c) => {
        return c.processTitle.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {        
        return(
          <Feed_MyOrderLists
            stateRefresh={this.stateRefresh}
            key={c.processUUId}
            id={c.id}
            processUserId={c.processUserId}
            customerUserId={c.customerUserId}
            processUUId={c.processUUId}
            applicationUUId={c.applicationUUId}
            processTitle={c.processTitle}
            dateStart={c.dateStart}
            dateEnd={c.dateEnd}
            processToggle={c.processToggle}
          />
        );
      })
    }

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    발주진행체크
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                      <Typography className={classes.typoTitle}>
                        발주진행체크
                      </Typography>
                    </ListItem>
                  </List>
                  {/* filter(검색)기능 */}
                  {this.state.processContents
                    ?
                    filteredComponents(this.state.processContents)
                    :
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                      <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                    </Grid>
                  }
                </Card>
              </Grid>
            </Grid>
          </main>
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  searchKeyword: state.upload.searchKeyword,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
})

MyOrderLists = withStyles(styles, {withTheme: true})(MyOrderLists)
export default connect(mapStateToProps)(MyOrderLists);