import React, { Component } from 'react';

class SVGIcon extends Component {
  state={
    name: '',
    style: {},
    fill: '#000',
    width: '100%',
    className: '',
    height: '100%',
    viewBox: '',
  }
  
  render() {
    const getPath = (name) => {
      switch(name) {
        case 'uploadBackIcon':
          return <path fill={this.props.fill} d="M15 8.25H5.87l4.19-4.19L9 3 3 9l6 6 1.06-1.06-4.19-4.19H15v-1.5z" />;
        case '':
          return <path fill={this.props.fill} d="" />;
        default:
          return <path />;
      }
    }
    
    const getViewBox = name => {
      switch(name) {
        case 'uploadBackIcon':
          return '0 0 18 18'; // Eg. 0 0 32 32
          case '':
          return '0 0 18 18';
        default:
          return '';
      }
    }

    return(
      <div>
        <svg
          width={this.props.width}
          style={this.props.style}
          height={this.props.height}
          viewBox={getViewBox(this.props.name)}
          className={this.props.className}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          {getPath(this.props.name, this.props.fill)}
        </svg>
      </div>
    );
  }
}

export default SVGIcon;