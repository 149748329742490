import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    fontSize: '15px',
    height: '50px',
    backgroundColor: '#E8E8E8',
    color: '#ffffff',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: '#E8E8E8',
      color: 'white'
    }
  },
};

const AWSLoginButtonFalse = ({
  classes,
  title,
  onClick,
  icon,
  disabled,
}) => {
  const iconStyle = {
    paddingLeft: '18px',
    background: `left / 12px no-repeat url('images/${icon}')`,
  };

  return (
    <Button
      className={classes.root}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default withStyles(styles)(AWSLoginButtonFalse);
