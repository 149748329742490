import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';

// MoreVertIcon 관련
import PopupState, { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'

// S3 관련
import { API } from "aws-amplify";
import { s3DeletePublic } from "../libs/awsLib";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  /*
  GridContainer: {
    minHeight: "10vh"
  },
  */
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "0px",
    // maxWidth: 650,
    // minWidth : 500,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  /*
  media: {
    marginTop: 5,
    height: 50,
    width: 130,
    objectFit: 'contain'
  },
  typoName: {
    width: '100%',
    fontSize : 13,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20
  },
  */
  list: {
    width: '100%',
    padding: '0',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemContent: {
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle: {
    width: '100%',
    fontSize : 18,
    paddingTop: 0,
    paddingBottom: 0,
  // background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoContent: {
    width: '100%',
    fontSize : 17,
    paddingTop: 10,
    paddingBottom: 5,
    //paddingLeft: 20
  },
  typoDate: {
    width: '100%',
    fontSize : 17,
    paddingTop: 5,
    paddingBottom: 5,
  },
  typoApplication: {
    width: '100%',
    fontSize : 17,
    paddingTop: 5,
    paddingBottom: 0,
    //paddingLeft: 20
  },
  typoApplicantSee: {
    fontSize : 15,
  },
  /*
  typoAddress: {
    width: '100%',
    fontSize : 13,
    color: '#999',
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 20
  },
  avatar: {
    // backgroundColor: red[500],
    marginTop: 5,
    backgroundColor: '#D3D3D3',
    margin: 10,
    width: 70,
    height: 70,
  },
  */
  uploadSeeButton: {
    width: '80%',
    fontSize: 17,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  applicantSeeButton: {
    height: 23,
    fontSize: 17,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  /*
  iconButton: {
    margin: theme.spacing(0),
  },
  */
  MoreVertIconRoot: {
    position: 'relative',
  },
  MoreVertIconContent: {
    padding: theme.spacing(0),
  },
  popupStateTypography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  popupStateMenu: {
    padding : 0,
  },
  countLink: {
    color:'#0c93d0'
  },
  /*
  starBorderIconButton: {
    padding: 0,
  },
  starIconButton: {
    padding: 0,
  },
  starBorderIcon: {
    width: 19,
    height: 19,
  },
  starIcon: {
    width: 19,
    height: 19,
    color: '#FFD700'  // gold
  },
  */
  dialogTitle: {
    fontSize: 17
  },
  dialogContent: {
    fontSize: 16
  },
});

class Feed_MyUploads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // expanded : false,  // extension 기능
      /*
      avatarAttachmentURL: null,
      attachmentURLs: [],
      attachmentURL_1: null,
      avatarimage: "",
      images: [],
      image_1: "",
      */
      MoreVertIconOpen: false,
      deleteDialogOpen : false, // 다이얼로그 창이 열려 있는지 확인하는 변수
      /*
      submitDialogOpen: false,
      applyDialogOpen: false,
      customerName: '',
      address_3:'',
      applicationTitle: '',
      applicationToggle: '',
      */
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 삭제 API
  deleteUpload() {
    return API.del("upload", `/upload/${this.props.uploadUUId}`);
  }

  /*
  postFavorite(favoriteThumb) {
    return API.post("upload", "/favorite", {
      body: favoriteThumb
    });
  }

  postApplication(application) {
    return API.post("upload", "/application", {
      body: application
    });
  }

  deleteFavorite() {
    return API.del("upload", `/favorite/${this.props.favoriteUUId}`);
  }
  */

  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }

  deleteDialog = async event => {
    event.preventDefault();

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.userId === currentAuthUserFromSession) {
      this.setState({
        deleteDialogOpen : true,
      });
    }
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      deleteDialogOpen : false,
      // submitDialogOpen : false,
      // applyDialogOpen : false,
      // followDialogOpen : false,
    })
  }

  // 실제로 데이터가 삭제되는 것을 도와주기 위한 함수
  handleDelete = async event => {
    event.preventDefault();
    // const { currentUser } = this.props;

    this.setState({ isDeleting: true });

    for(let i=0;i<this.state.images.length;i++) {
      if(this.state.images[i] != 'No_File') {
        await s3DeletePublic(this.state.images[i]);
      }
    }

    try {
      await this.deleteUpload();
        
      await this.putPoint({
        pointEvent: "uploadCancel",
      });
      // this.props.history.push("/");
      window.location.reload();
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  handleMoreVertIconToggle = () => {
    this.setState({MoreVertIconOpen: !this.state.MoreVertIconOpen});
  }

  /*
  handleThumbsUpButton = async event => {
    event.preventDefault();
    const { favoriteloadingtrue, favoriteloadingfalse } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.favoriteContent === '1') {
      if(currentAuthUserFromSession === this.props.favoriteUserId) {
        favoriteloadingtrue();
        try {
          await this.deleteFavorite();
          favoriteloadingfalse();
          
          await this.putPoint({
            pointEvent: "favoriteCancel",
          });
        } catch (e) {
          alert(e);
          favoriteloadingfalse();
        }
      }
    } else {
      favoriteloadingtrue();
      try {
        await this.postFavorite({
          uploadUUId: this.props.uploadUUId,
          favoriteContent: '1',
        });
        favoriteloadingfalse();

        await this.putPoint({
          pointEvent: "favorite",
        });
      } catch (e) {
        alert(e);
        favoriteloadingfalse();
      }
    }
  }
  */

  /*
  // 프로젝트 지원하기 버튼 클릭 시 1
  handleSubmit = () => {
    this.setState({submitDialogOpen : true});
  }

  // 프로젝트 지원하기 버튼 클릭 시 2
  handleApplication = async () => {
    const { applyingloadingtrue, applyingloadingfalse } = this.props;
    
    try {
      applyingloadingtrue();
      
      await this.postApplication({
        applicationTitle: this.props.title,
        uploadUUId: this.props.uploadUUId,
        applicationToggle: '0', // '0': 지원자가 지원 신청 한거
        });
      
      applyingloadingfalse();
      // this.props.history.push("/");
      this.handleClose(); // 기존 dialog 창 닫기
      this.setState({applyDialogOpen: true})  // 이후에 '지원신청 완료' dialog 창 열기
    } catch (e) {
      // lert(e);
      console.log(e)
    }
  }
  */

  async componentDidMount() {
    /*
    // AWS S3 사용시 (user 관련 파일)
    try {
      let avatarAttachmentURL;
      const avatarimage = this.props.avatarimage;

      if (avatarimage != 'No_File') {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
          avatarAttachmentURL = await Storage.get(avatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }

      //  console.log(this.props.avatarimage);
      
      this.setState({
        avatarAttachmentURL,
        avatarimage,
      });
      // console.log(this.state.attachmentURL);
    } catch (e) {
      alert(e);
    }
    */

    /*
    // AWS S3 사용시 (upload 관련 파일).
    try {
      let attachmentURLs = [];
      let images = [];

      for(let i=0;i<this.props.images.length;i++) {

        images[i] = this.props.images[i];

        if (images[i] != 'No_File') {
          // attachmentURL = await Storage.vault.get(image);  // Private하게 볼 수 있는거
          if(this.props.selectTarget === 'public') {
            attachmentURLs[i] = await Storage.get(images[i], { level: 'public' });  // Public하게 볼 수 있는거
          }
          if(this.props.selectTarget === 'protected') {
            if(this.props.followeeUserId) {
              if(this.props.followingToggle === '1') {
                attachmentURLs[i] = await Storage.get(images[i], 
                  { level: 'protected',
                  identityId: this.props.userId   // the identityId of that user. 사진을 업로드한 사용자의 identityId
                });  // Protected 하게 볼 수 있는거
              }
            }
            if(this.props.userId === currentAuthUserFromSession) {
              attachmentURLs[i] = await Storage.get(images[i], 
                { level: 'protected',
                identityId: this.props.userId   // the identityId of that user. 사진을 업로드한 사용자의 identityId
              });  // Protected 하게 볼 수 있는거
            }
          }
          if(this.props.selectTarget === 'private') {
            if(this.props.userId === currentAuthUserFromSession) {
              attachmentURLs[i] = await Storage.get(images[i], {level: 'private'});  // Protected 하게 볼 수 있는거
            }
          }
        }

        this.setState({
          attachmentURLs: this.state.attachmentURLs.concat(attachmentURLs[i]),
          images: this.state.images.concat(images[i]),
        });
      }

      this.setState({ 
        image_1: images[0],
        attachmentURL_1: attachmentURLs[0]
      });
    } catch (e) {
      alert(e);
    }
    */
   /*
    if(this.props.customerType === 'company') {
      this.setState({customerName: this.props.companyName})
    } else if(this.props.customerType === 'freelancer') {
      this.setState({customerName: this.props.name})
    }
    */

    /*
    // 주소 정보 받아오기
    this.setState({
      address_3: this.props.address[2],
    })
    */
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        <Card className={classes.card}>
          <List className={classes.list}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container item xs={8} sm={8} md={8} lg={8} xl={8} justify="flex-start" alignItems="center">
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <ListItem className={classes.listItemTitle}>
                  <Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>
                    <Typography className={classes.typoTitle}>
                      {/* 글자 길이가 20개 이상 넘어가면 '...' 나옴 */}
                      {this.props.title.slice(0,20)}
                        {this.props.title.length > 20 
                        ? '...'
                        : null
                      }
                    </Typography>
                  </Link>
                </ListItem>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                {/*
                <PopupState variant="popover" popupId="MoreVertIconPopover">
                  {popupState => (
                    <div>
                      <IconButton variant="contained" {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Link component={RouterLink} to={`/upload/Modify/${this.props.uploadUUId}`} underline='none' color='inherit'>
                          <MenuItem onClick={popupState.close} className={classes.popupStateMenu}>
                            <Typography variant="body2" style={{ cursor: 'pointer' }} className={classes.popupStateTypography}
                              variant="h6" noWrap>
                              수정
                            </Typography>
                          </MenuItem>
                        </Link>
                      </Menu>
                    </div>
                  )}
                </PopupState>
                */}
              </Grid>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ListItem className={classes.listItemContent}>
                    <Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>
                      <Typography className={classes.typoContent}>
                        {/* 글자 길이가 20개 이상 넘어가면 '...' 나옴 */}
                        {this.props.content.slice(0,20)}
                        {this.props.content.length > 20 
                        ? '...'
                        : null
                        }
                      </Typography>
                    </Link>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ListItem className={classes.listItemContent}>
                    <Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>
                      <Typography className={classes.typoDate}>
                        {moment(this.props.dateStart).format("YYYY-MM-DD")} ~ {moment(this.props.dateEnd).format("YYYY-MM-DD")}
                      </Typography>
                    </Link>
                  </ListItem>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ListItem className={classes.listItemContent}>
                    <Typography className={classes.typoApplication}>
                      지원 현황 : &nbsp;
                      <Link component={RouterLink} to={`/applicantList/${this.props.uploadUUId}`} 
                        underline='none' /*color='inherit'*/ className={classes.countLink}>
                        {this.props.applyingCount} &nbsp;
                        <Button className={classes.applicantSeeButton}>
                          클릭
                       </Button>
                      </Link>
                    </Typography>
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={4} sm={4} md={4} lg={4} xl={4} align='center' justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>
                  <Button className={classes.uploadSeeButton}>
                    내용보기
                 </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          </List>
        </Card>
        <Dialog open={this.state.deleteDialogOpen} /*onClose={this.handleClose}*/>
          <DialogTitle>글을 삭제하시겠습니까?</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.button}
              onClick={this.handleDelete}>확인</Button>
            <Button variant="contained" /*color="primary"*/ className={classes.button}
              onClick={this.handleClose}>취소</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

Feed_MyUploads = withStyles(styles, {withTheme: true})(Feed_MyUploads)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_MyUploads);