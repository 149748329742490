import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SVGIcon from './SVGIcon'

import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(17),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  appBar2: {
    marginTop: '47px',
    background: "#F5F5F5",
  },
  toolBar2: {
    height : 15,
  },
  title: {
    flexGrow: 1,
    fontSize : 16,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '80px',
    flexGrow: 1,
    marginBottom: '60px'
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  card: {
    maxWidth: 700,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  list: {
    width: '100%',
    padding: '0',
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  list2: {
    width: '100%',
    padding: '0',
  },
  listItemTitle2: {
    paddingTop: 10,
    paddingBottom: 10,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle2: {
    width: '100%',
    fontSize : 14,
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  tabText: {
    fontSize : 14,
    color: 'inherit',
    padding: 0,
    // underline: 'none', -> 여기서는 동작 안해서 아래 코드에서 직접 작성
  },
  indicator: {
    backgroundColor: '#0c93d0',
  },
  indiText: {
    textColor: '#0c93d0'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    //width : '100%',
    //height : 400,
    objectFit: 'fill'
  },
  media2: {
    width : '100%',
    //height : 400,
    objectFit: 'fill'
  },
  bottomAppBar: {
    top: 'auto',
    //bottom: 0,
    bottom: 100,
  },
  typoSubmit: {
    fontSize: 14
  },
});

class HangOutManual extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tabLinkValue: 0,
      tabindicator: true,
      isLoading: false,
      completed : 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // tabs 클릭시 바뀌는 state값
  handleChange = (event, tabLinkValue, tabindicator) => {
    this.setState({ tabLinkValue, tabindicator : true });
  };

  handleGoToHangout = () => {
    window.open('https://hangouts.google.com')  // 새로운 탭에서 열림
    // window.location.href = 'https://hangouts.google.com'; // 현재 탭에서 열림
  }

  render() {
    const { classes, theme } = this.props;

    // tabindicator가 true 일때 tabindicator 나오는 코드
    const tabs_1 = (
      <div>
        <Tabs
          value={this.state.tabLinkValue}
          onChange={this.handleChange}
          //indicatorColor="primary"
          classes={{indicator: classes.indicator}}
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="화상 통화 신청" dir={theme.direction} className={classes.tabText} underline='none' 
            /* onClick={() => this.props.history.push({
            pathname: '/',
            //state: {value : this.state.tabLinkValue}
            })} */  
          />
          <Tab label="화상 통화 수락" dir={theme.direction} className={classes.tabText} underline='none' 
            /* onClick={() => this.props.history.push({
            pathname: '/design',
            //state: {value : this.state.tabLinkValue}
            })} */ 
          />
        </Tabs>
      </div>
    );

    // tabindicator가 false 일때 tabindicator 안나오는 코드
    const tabs_2 = (
      <div>
        <Tabs
          classes={{ indicator: classes.hideIndicator }}
          value={this.state.tabLinkValue}
          onChange={this.handleChange}
          // indicatorColor="primary"
          // textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="화상 통화 신청" dir={theme.direction} className={classes.tabText} underline='none' 
            /* onClick={() => this.props.history.push({
            pathname: '/',
            //state: {value : this.state.tabLinkValue}
            })} */  
          />
          <Tab label="화상 통화 수락" dir={theme.direction} className={classes.tabText} underline='none' 
            /* onClick={() => this.props.history.push({
            pathname: '/design',
            //state: {value : this.state.tabLinkValue}
            })} */ 
          />
        </Tabs>
      </div>
    );

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    화상 통화 사용 방법
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <AppBar position="fixed" className={classes.appBar2} color="default">
            <Toolbar className={classes.toolBar2} variant="dense">
              <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {this.state.tabindicator ? tabs_1 : tabs_2}
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  {this.state.tabLinkValue === 0
                  ?
                  <div>
                    <List className={classes.list2}>
                      <ListItem className={classes.listItemTitle2}>
                        <Typography className={classes.typoTitle2}>
                          Step 1. 크롬에서 행아웃으로 들어감
                        </Typography>
                      </ListItem>
                    </List>
                    <List className={classes.list2}>
                      {/*
                      <CardMedia
                        className={classes.media}
                        image={'/images/hangout/hangout1-1.png'}
                      />
                      */}
                      <img
                        className={classes.media2}
                        src={'/images/hangout/hangout1-1.png'}
                      />
                    </List>
                    <Divider />
                    <List className={classes.list2}>
                      <ListItem className={classes.listItemTitle2}>
                        <Typography className={classes.typoTitle2}>
                          Step 2. [화상 통화] 클릭
                        </Typography>
                      </ListItem>
                    </List>
                    <List className={classes.list2}>
                      {/*
                      <CardMedia
                        className={classes.media}
                        image={'/images/hangout/hangout1-2.png'}
                      />
                      */}
                      <img
                        className={classes.media2}
                        src={'/images/hangout/hangout1-2.png'}
                      />
                    </List>
                    <Divider />
                    <List className={classes.list2}>
                      <ListItem className={classes.listItemTitle2}>
                        <Typography className={classes.typoTitle2}>
                          Step 3. [공유할 링크 복사] 클릭하여 문자나 메일로 공유
                        </Typography>
                      </ListItem>
                    </List>
                    <List className={classes.list2}>
                      {/*
                      <CardMedia
                        className={classes.media}
                        image={'/images/hangout/hangout1-3.png'}
                      />
                      */}
                      <img
                        className={classes.media2}
                        src={'/images/hangout/hangout1-3.png'}
                      />
                    </List>
                  </div>
                  :
                  <div>
                    <List className={classes.list2}>
                      <ListItem className={classes.listItemTitle2}>
                        <Typography className={classes.typoTitle2}>
                          Step 1. 크롬에서 공유받은 주소로 들어감
                        </Typography>
                      </ListItem>
                    </List>
                    <List className={classes.list2}>
                      {/*
                      <CardMedia
                        className={classes.media}
                        image={'/images/hangout/hangout2-1.png'}
                      />
                      */}
                      <img
                        className={classes.media2}
                        src={'/images/hangout/hangout2-1.png'}
                      />
                    </List>
                    <Divider />
                    <List className={classes.list2}>
                      <ListItem className={classes.listItemTitle2}>
                        <Typography className={classes.typoTitle2}>
                          Step 2. [행아웃 참여하기] 클릭
                        </Typography>
                      </ListItem>
                    </List>
                    <List className={classes.list2}>
                      {/*
                      <CardMedia
                        className={classes.media}
                        image={'/images/hangout/hangout2-2.png'}
                      />
                      */}
                      <img
                        className={classes.media2}
                        src={'/images/hangout/hangout2-2.png'}
                      />
                    </List>
                  </div>
                  }
                </Card>
              </Grid>
            </Grid>
          </main>
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Divider/>
            <Toolbar>
              <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button variant="contained" className={classes.button}
                    onClick={this.handleGoToHangout}
                  >
                    <Typography className={classes.typoSubmit}>화상통화</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  searchKeyword: state.upload.searchKeyword,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
})

HangOutManual = withStyles(styles, {withTheme: true})(HangOutManual)
export default connect(mapStateToProps)(HangOutManual);