import React from 'react';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

//import './KakaoLoginButton.scss';

const useStyles = makeStyles({
  root: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    fontSize: '15px',
    height: '50px',
    backgroundColor: '#FFEB00',
    color: "#ffffff",
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: '#ffdd00',
      color: 'white'
    },
  }
});

const KakaoLoginButton = ({ className, title, onClick, icon, disabled }) => {
  const classes = useStyles({ icon });
  return (
    <Button
      className={cx(classes.root, className)}
      onClick={onClick}
      disabled={disabled}
      variant="contained"
    >
      {title}
    </Button>
  );
};

export default KakaoLoginButton;
