import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { TextField } from "@material-ui/core";
import moment from 'moment';
//import Checkbox from '@material-ui/core/Checkbox';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

// S3 관련
import { API, Storage } from "aws-amplify";
import config from "../../config";

// '좋아요' 관련 Icon
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  GridContainer: {
    minHeight: "10vh"
  },
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "20px",
    // maxWidth: 650,
    // minWidth : 500,
    [theme.breakpoints.up('xs')]: { // xs보다 up일때 동작
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0.5),
      marginRight : theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: { // xs 보다 down 일때 동작
      mmarginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginLeft : theme.spacing(0.5),
      marginRight : theme.spacing(0.5),
    },
  },
  media: {
    marginTop: 5,
    height: 50,
    width: 130,
    objectFit: 'contain'
  },
  typoName: {
    width: '100%',
    fontSize : 17,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20
  },
  typoCustomerType2: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20
  },
  typoContent: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20
  },
  typoDate: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20
  },
  typoAddress: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 20
  },
  typoAdminconfirm: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 20
  },
  avatar: {
    // backgroundColor: red[500],
    backgroundColor: '#D3D3D3',
    marginTop: 5,
    width: 50,
    height: 50,
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  /*
  buttonChecked: {
    color: '#0c93d0',
    '&$checked': {
      color: '#0c93d0',
    },
  },
  */
  uploadButton: {
    height: 30,
    fontSize: 17,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  applicantButton1 : {
    fontSize: 17,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  applicantButton2 : {
    fontSize: 17,
  },
  MoreVertIconRoot: {
    position: 'relative',
  },
  starBorderIconButton: {
    padding: 0,
  },
  starIconButton: {
    padding: 0,
  },
  starBorderIcon: {
    width: 25,
    height: 25,
  },
  starIcon: {
    width: 25,
    height: 25,
    color: '#FFD700'  // gold
  },
  dialogTitle: {
    fontSize: 17
  },
  dialogContent: {
    fontSize: 17
  },
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldLabelSize:{
    fontSize:17,
  },
  textFieldInputSize:{
    fontSize:17,
  },
});

class Feed_Customer_Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarAttachmentURL: null,
      avatarimage: "",
      customerType1: '',
      customerType2: '',
      stateCustomerType1: '',
      stateCustomerType2: '',
      customerName: '',
      name: '',
      address_2:'',
      address_3:'',
      adminConfirm: '',
      stateAdminConfirm: '',
      // Application
      applicationToggleContent: '',
      processToggleContent: '',
      alarmDialogOpen_1: false,
      fcmTokenIds: [], // 여러명한테 FCM 발송시 시도
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  postUploadConnect(postUploadConnecting) {
    return API.post("upload", "/uploadconnect", {
      body: postUploadConnecting
    });
  }

  /*
  postApplication(application) {
    return API.post("upload", "/application", {
      body: application
    });
  }
  */

  //------------------------------------------------------------------------
  // 지원 버튼 클릭시 알림 발송
  //------------------------------------------------------------------------
  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }
  
  getNotifications() {
    return API.get("upload", `/notification/?userId=${this.props.userId}`)
    .then(notifications => this.setState({notifications : notifications}));
  }
  //------------------------------------------------------------------------

  /*
  // 내가 다른 사람 Following 할때
  postFollowing(postFollowing) {
    return API.post("upload", "/follow", {
      body: postFollowing
    });
  }

  // 내가 Following 했던거를 취소 할때
  deleteFollowing() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    if(currentAuthUserFromSession === this.props.followerUserId) {
      return API.del("upload", `/follow/${this.props.followingUUId}`);
    }
  }
  */

  /*
  // 프로젝트 지원하기 버튼 클릭 시
  handleApplication = async () => {
    const { applyingloadingtrue, applyingloadingfalse } = this.props;

    // 작성 안한 파트 작성하게끔 해주는 코드 (일감 요청 란 에서만 동작)
    if(this.props.subField === '0') {
      if (!this.state.applicationInvoice) {
        this.setState({alarmDialogOpen_1: true});
        return;
      }
    }

    if(this.state.applicationInvoice === '') {
      this.state.applicationInvoice = "default"
    }

    if(this.state.applicationContent === '') {
      this.state.applicationContent = "default"
    }
    
    try {
      applyingloadingtrue();
      
      await this.postApplication({
        uploadUserId: this.props.userId,
        uploadUUId: this.props.uploadUUId,
        applicationSubField: this.props.subField,
        applicationTitle: this.props.title,
        dateStart: moment(this.props.dateStart).format("YYYY-MM-DD"),
        dateEnd: moment(this.props.dateStart).format("YYYY-MM-DD"),
        applicationToggle: '0', // '0': 지원자가 지원 신청 한거
        applicationInvoice: this.state.applicationInvoice,
        applicationContent: this.state.applicationContent,
        orderComment: 'default', 
        orderSign: 'default', 
        applicationSign: 'default'
      });

      if(this.state.fcmTokenIds.length > 0) {
        await this.postNotificationFCM({
          registration_ids: this.state.fcmTokenIds,
          title: this.props.title,
          body: '지원 : ' + this.state.customerName,
        });
      }
      
      applyingloadingfalse();
      // this.props.history.push("/");
      this.handleClose(); // 기존 dialog 창 닫기
      // this.setState({applyDialogOpen: true})  // 이후에 '지원신청 완료' dialog 창 열기
    } catch (e) {
      // lert(e);
      console.log(e)
    }
  }

  // 지원하기 버튼 클릭시
  handleSubmit = () => {
    this.setState({submitDialogOpen : true});
  }

  // 연락하기 버튼 클릭시
  handleConnect = () => {
    this.setState({connectDialogOpen : true});
  }

  handleFollowingButton = async event => {
    event.preventDefault();
    const { followingloadingtrue, followingloadingfalse } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.followingToggle === '1') {
      if(currentAuthUserFromSession === this.props.followerUserId) {
        followingloadingtrue();
        try {
          await this.deleteFollowing();
          followingloadingfalse();
        } catch (e) {
          alert(e);
          followingloadingfalse();
        }
      }
    } else {
      followingloadingtrue();
      try {
        await this.postFollowing({
          followeeUserId: this.props.userId,
          userUUId: this.props.userUUId,
          followingToggle: '1',
        });
        followingloadingfalse();
      } catch (e) {
        alert(e);
        followingloadingfalse();
      }
    }
  }
  */

  uploadConnect = async event => {
    event.preventDefault();
    const { uploadConnectingtrue, uploadConnectingfalse } = this.props;

    try {
      uploadConnectingtrue();
      await this.postUploadConnect({
        orderUserId: this.props.uploadUserId,
        getUserId: this.props.userId,
        uploadUUId: this.props.uploadUUId,
        field: this.props.field,
        subField: this.props.subField,
        title: this.props.title,
        content: this.props.content,
        dateStart: moment(this.props.dateStart).format("YYYY-MM-DD"),
        dateEnd: moment(this.props.dateEnd).format("YYYY-MM-DD"),
        images: this.props.images,
      });

      uploadConnectingfalse();
    } catch (e) {
      console.log(e);
      uploadConnectingfalse();
    }

    try {
      if(this.state.fcmTokenIds.length > 0) {
        await this.postNotificationFCM({
          registration_ids: this.state.fcmTokenIds,
          title: this.state.customerName + '의 요청이 도착했습니다!',
          body: '요청내용 : ' + this.props.title,
        });
      }
    } catch(e) {
      console.log(e)
    }
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      deleteDialogOpen : false,
      submitDialogOpen : false,
      applyDialogOpen : false,
      connectDialogOpen: false,
      alarmDialogOpen_1: false,
    })
  }

  /*
  handleCloseInvoice = () => {
    this.setState({
      alarmDialogOpen_1: false,
    })
  }
  */

  async componentDidMount() {
    // AWS S3 사용시 (user 관련 파일)
    try {
      let avatarAttachmentURL;
      const avatarimage = this.props.avatarimage;

      if (avatarimage != 'No_File') {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
          avatarAttachmentURL = await Storage.get(avatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }

      //  console.log(this.props.avatarimage);
      
      this.setState({
        avatarAttachmentURL,
        avatarimage,
      });
      // console.log(this.state.attachmentURL);
    } catch (e) {
      // alert(e);
      console.log(e)
    }
   
    if(this.props.customerType2 === 'company') {
      this.setState({
        customerName: this.props.companyName,
        stateCustomerType2: "기업"
      })
    } else if(this.props.customerType2 === 'freelancer') {
      this.setState({
        customerName: this.props.name,
        stateCustomerType2: "프리랜서"
      })
    }

    // 주소 정보 받아오기
    this.setState({
      address_2: this.props.address[2],
      address_3: this.props.address[3],
    })

    if(this.props.adminConfirm === 'true') {
      this.setState({stateAdminConfirm: '승인완료'})
    } else if(this.props.adminConfirm === 'false') {
      this.setState({stateAdminConfirm: '승인 X'})
    }

    //------------------------------------------------------------------------------------
    // FCM으로 발송할 데이터를 미리 받아서 fcmTokenId를 배열로 저장.
    // 나중에 발송시 FCM으로 fcmTokenId 배열로 보내면 최대 1,000명한테 한번에 발송 가능
    try {
      await this.getNotifications();
      // fcmTokenIds를 배열로 받기
      if(this.state.notifications) { 
        let fcmTokenId
        Object.keys(this.state.notifications).map(notificationUUId => {
          const notification = this.state.notifications[notificationUUId];
            fcmTokenId = notification.fcmTokenId
            this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
        });
      }
    } catch(e) {
      console.log(e)
    }
    //------------------------------------------------------------------------------------

    // 공급자가 견적 발송후 Application 진행 상황 체크
    try {
      if(this.props.checkedApplying === true) {
        // '0': 지원지가 지원 신청(0이면 견적서 수정 가능), '1': 발주자가 발주 누르면 (견적서 수정 불가능), '2': 발주서 확인 체크(지원자)
        if(this.props.applicationToggle === '0') {
          this.setState({applicationToggleContent: '공급자가 견적 발송 (1/3)'})
        }
        if(this.props.applicationToggle === '1') {
          this.setState({applicationToggleContent: '발주자가 발주 서명 완료 (2/3)'})
        }
        if(this.props.applicationToggle === '2') {
          this.setState({applicationToggleContent: '공급자가 발주받음 서명 완료 (3/3)'})
        }
      }
    } catch(e) {
      console.log(e)
    }

      // 'start': 발주 받으면 시작, '0': 소재 준비 및 제작 시작, '1': 제작 중, '2': 제작 중,
    // '3': 제작 완료, '4': 검수 완료
    try {
      if(this.props.processToggle === 'start') {
        this.setState({processToggleContent: '제작중(1/6)'})
      }
      if(this.props.processToggle === '0') {
        this.setState({processToggleContent: '제작중(2/6)'})
      }
      if(this.props.processToggle === '1') {
        this.setState({processToggleContent: '제작중(3/6)'})
      }
      if(this.props.processToggle === '2') {
        this.setState({processToggleContent: '제작중(4/6)'})
      }
      if(this.props.processToggle === '3') {
        this.setState({processToggleContent: '제작중(5/6)'})
      }
      if(this.props.processToggle === '4') {
        this.setState({processToggleContent: '검수완료(6/6)'})
      }
    } catch(e) {
      console.log(e)
    }

  }

  render() {
    const { classes, theme, isAuthenticated } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentPage = localStorage.getItem("currentPage");

    return (
      <Grid container item xs={6} sm={6} md={3} lg={3} xl={3}>   {/* 한줄에 4개씩 할 때 사용 */}
      {/*<Grid item xs={12} sm={12} md={12} lg={8} xl={8} align="center">*/} {/* 세로로 정렬시 사용 */}
        {isAuthenticated
        && currentAuthUserFromSession === "ap-northeast-2:0eac84f9-d5d6-454a-8468-f46c99cef2ec"
        || currentAuthUserFromSession === "ap-northeast-2:1ef91eaf-bfd0-49eb-91ce-f70191165a07"
        ?
        <Card className={classes.card}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="flex-end">
            <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
              {this.props.customerType2 === 'company'
              ?
                <Link component={RouterLink} to={`/useradmin/company/${this.props.userId}`} underline='none' color='inherit'>
                  {this.state.avatarAttachmentURL
                  ?
                  <img
                    className={classes.media}
                    src={this.state.avatarAttachmentURL}
                  />
                  :
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  }
                </Link>
              :
                <Link component={RouterLink} to={`/useradmin/freelancer/${this.props.userId}`} underline='none' color='inherit'>
                  {this.state.avatarAttachmentURL
                  ?
                  <Avatar src={this.state.avatarAttachmentURL} aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  :
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    <AccountCircleIcon style={{fontSize: '85px'}}/>
                  </Avatar>
                  }
                </Link>
              }
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              {/*
              {currentPage === "uploadConnect_Admin"
              ?
                <Checkbox
                  checked={this.props.checkedConnect}
                  //disabled
                  //color="default"
                  className={classes.buttonChecked}
                  //onChange={this.handleCheckedConnect('checkedConnect')}
                  value="checkedConnect"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              :
              null
              }
              */}
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/useradmin/company/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoName}>
                    {this.state.customerName}
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/useradmin/freelancer/${this.props.userId}`} underline='none' color='inherit'>
                <Typography className={classes.typoName}>
                  {this.state.customerName}
                </Typography>
              </Link>
              }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/useradmin/company/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoCustomerType2}>
                    {this.state.stateCustomerType2}
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/useradmin/freelancer/${this.props.userId}`} underline='none' color='inherit'>
                <Typography className={classes.typoCustomerType2}>
                  {this.state.stateCustomerType2}
                </Typography>
              </Link>
              }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/useradmin/company/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoContent}>
                    {/* 글자 길이가 37개 이상 넘어가면 '...' 나옴 */}
                    {this.props.history.slice(0,20)}
                    {this.props.history.length > 20 
                    ? '...'
                    : null
                    }
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/useradmin/freelancer/${this.props.userId}`} underline='none' color='inherit'>
                <Typography className={classes.typoContent}>
                  {/* 글자 길이가 37개 이상 넘어가면 '...' 나옴 */}
                  {this.props.history.slice(0,20)}
                  {this.props.history.length > 20 
                  ? '...'
                  : null
                  }
                </Typography>
              </Link>
              }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/useradmin/company/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoAddress}>
                     {this.state.address_2} {this.state.address_3}
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/useradmin/freelancer/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoAddress}>
                     {this.state.address_2} {this.state.address_3}
                  </Typography>
              </Link>
              }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/useradmin/company/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoDate}>
                    가입일 : {moment(this.props.date).format("YYYY-MM-DD")}
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/useradmin/freelancer/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoDate}>
                    가입일 : {moment(this.props.date).format("YYYY-MM-DD")}
                  </Typography>
              </Link>
              }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/useradmin/company/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoAdminconfirm}>
                     관리자 인증: {this.state.stateAdminConfirm}
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/useradmin/freelancer/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoAdminconfirm}>
                     관리자 인증: {this.state.stateAdminConfirm}
                  </Typography>
              </Link>
              }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/useradmin/company/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoAdminconfirm}>
                     레벨: {this.props.level}
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/useradmin/freelancer/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoAdminconfirm}>
                    레벨: {this.props.level}
                  </Typography>
              </Link>
              }
              </Grid>
            </Grid>
            {currentPage === "uploadConnect_Admin"
            ?
              this.props.checkedConnect === true
              ?
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button variant="contained" color="secondary">
                    견적요청 완료
                  </Button>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography className={classes.typoAdminconfirm}>
                    견적요청 날짜: {moment(this.props.connectDate).format("YYYY-MM-DD")}
                  </Typography>
                </Grid>
              </Grid>
              :
              this.props.checkedConnect === false
              ?
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button variant="contained" className={classes.uploadButton} onClick={this.uploadConnect}>
                    업체에 견적요청
                  </Button>
                </Grid>
              </Grid>
              :
              null
            :
            null
            }
            {currentPage === "uploadConnect_Admin"
            ?
              this.props.checkedApplying === true 
              ?
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  { this.props.applicationToggle === '2' && this.props.processToggle
                  ?
                  <Link component={RouterLink} to={`/processadmin/${this.props.processUUId}`} underline='none' color='inherit'>
                    <Button variant="contained" className={classes.applicantButton1}>
                      {this.state.processToggleContent} - 클릭
                    </Button>
                  </Link>
                  :
                  <Button variant="contained" color="secondary" className={classes.applicantButton2}>
                    {this.state.applicationToggleContent}
                  </Button>
                  }
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography className={classes.typoAdminconfirm}>
                    견적발송 날짜: {moment(this.props.applicationDate).format("YYYY-MM-DD")}
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography className={classes.typoAdminconfirm}>
                    견적 금액: {this.props.applicationInvoice}
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography className={classes.typoAdminconfirm}>
                    견적 내용: {this.props.applicationContent}
                  </Typography>
                </Grid>
              </Grid>
              :
              null
            :
            null
            }
            {/*
            {this.props.subField === '0'
            ?
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              {currentAuthUserFromSession == this.props.userId
                ?
                <Button variant="contained" color="primary"  
                >
                  <Typography>내 공고</Typography>
                </Button>
                :
                this.props.applicationToggle === '0'
                  ?
                  <Card className={classes.bottomCard}>
                    <Button variant="contained" color="secondary"
                    >
                      <Typography>지원완료</Typography>
                    </Button>
                  </Card>
                  :

                  <Card className={classes.bottomCard}>
                    <Button variant="contained" className={classes.button} 
                      onClick={this.handleSubmit}
                    >
                      <Typography>지원하기</Typography>
                    </Button>
                  </Card>
              }
            </Grid>
            :
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              {currentAuthUserFromSession == this.props.userId
                ?
                <Button variant="contained" color="primary"  
                >
                  <Typography>내 공고</Typography>
                </Button>
                :
                this.props.applicationToggle === '0'
                  ?
                  <Card className={classes.bottomCard}>
                    <Button variant="contained" color="secondary"
                    >
                      <Typography>연락처 완료</Typography>
                    </Button>
                  </Card>
                  :
                  <Card className={classes.bottomCard}>
                    <Button variant="contained" className={classes.button} 
                      onClick={this.handleSubmit}
                    >
                      <Typography>연락처 남기기</Typography>
                    </Button>
                  </Card>
              }
            </Grid>
            }
            */}
          </Grid>
        </Card>
        :
        null
        }
        {/*
        {this.props.subField === '0'
        ?
        <Dialog /fullScreen={true}/ fullWidth={true} /maxWidth="md"/ open={this.state.submitDialogOpen} /onClose={this.handleClose}/>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogTitle}>
              {this.props.title}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogContent}>
                {this.props.content}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="가견적" type="text" name="applicationInvoice" 
              value={this.state.applicationInvoice} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="간단한 내용 및 문의" type="text" name="applicationContent" 
              value={this.state.applicationContent} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleApplication}>지원하기</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        :
        <Dialog /fullScreen={true}/ fullWidth={true} /maxWidth="md"/ open={this.state.submitDialogOpen} /onClose={this.handleClose}/>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogTitle}>
              {this.props.title}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogContent}>
                {this.props.content}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="간단한 내용 및 문의" type="text" name="applicationContent" 
              value={this.state.applicationContent} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleApplication}>연락처 남기기</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        }
        */}
        {/*
        <Dialog open={this.state.applyDialogOpen}>
          <DialogTitle>지원신청이 완료 되었습니다.</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button}
                  onClick={this.handleClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.connectDialogOpen}>
          <DialogTitle>연락하기</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button}
                  onClick={this.handleClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        */}
        {/*
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>가견적을 입력해주세요</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleCloseInvoice}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        */}
      </Grid>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  /*
  isApplyingLoading: state.upload.isApplyingLoading,
  isFollowingLoading: state.upload.isFollowingLoading,
  */
  isUploadConnecting: state.upload.isUploadConnecting,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  /*
  applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
  followingloadingtrue: (isFollowingLoading) => dispatch(uploadActions.followingloadingtrue(isFollowingLoading)),
  followingloadingfalse: (isFollowingLoading) => dispatch(uploadActions.followingloadingfalse(isFollowingLoading)),
  */
 uploadConnectingtrue: (isUploadConnecting) => dispatch(uploadActions.uploadConnectingtrue(isUploadConnecting)),
 uploadConnectingfalse: (isUploadConnecting) => dispatch(uploadActions.uploadConnectingfalse(isUploadConnecting)),
})

Feed_Customer_Admin = withStyles(styles, {withTheme: true})(Feed_Customer_Admin)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_Customer_Admin);