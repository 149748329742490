import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AWSAuthForm from 'components/AWSAuth/AWSAuthForm';

const useStyles = makeStyles((theme) => ({
  authPage: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
  },
}));

const AWSAuthPage = () => {
  const classes = useStyles();

  return (
    <main className={classes.authPage}>
      <AWSAuthForm />
    </main>
  );
};

export default AWSAuthPage;
