import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth, API } from "aws-amplify";
//import config from "../config";
import Amplify from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

//import './UploadButton.scss' // UploadPhoto 버튼 관련
import { DialogContent } from '@material-ui/core';

const styles = theme => ({
  /*
  root: {
      width : '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      //backgroundColor: theme.palette.background.paper,
      marginLeft: 10,
      marginRight: 10,
  },
  */
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(7),
  },
  mainCard1: {
    borderRadius: 0,
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 1000,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    backgroundColor: '#15B8EB'
  },
  mainCard2: {
    borderRadius: 0,
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 1000,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    backgroundColor: '#004C43'
  },
  mainCard3_1: {
    borderRadius: 0,
    width : '100%',
    // maxWidth: 650,
    height: 90,
    //maxHeight: 100,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    backgroundColor: '#FCB04C',
  },
  mainCard3: {
    borderRadius: 0,
    width : '100%',
    // maxWidth: 650,
    // height: 800,
    maxHeight: 1000,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    backgroundColor: '#FCB04C',
  },
  mainCard4_1: {
    borderRadius: 0,
    width : '100%',
    // maxWidth: 650,
    height: 90,
    //maxHeight: 100,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    backgroundColor: '#424242',
  },
  mainCard4: {
    borderRadius: 0,
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 1000,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    backgroundColor: '#424242'
  },
  /*
  mainMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    //width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  // img 사용시
  mainMedia: {
    //height: 0,
    //paddingTop: '56.25%', // 16:9,
    width : '100%',
    height : 1000,
    objectFit: 'fill'
  },
  */
  // cardmedia 사용시
  mainMedia1: {
    height: 0,
    paddingTop: '80%',
    // width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  mainMedia2: {
    height: 0,
    paddingTop: '80%',
    // width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  mainMedia3: {
    height: 0,
    paddingTop: '50%',
    // width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  mainMedia4: {
    height: 0,
    paddingTop: '50%',
    // width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  imageText: {
    fontSize: 100,
    color: '#FFFAFA', // snow
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      top: '140px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '200px',
    },
    left: 0,
    width: '100%',
  },
  cardFrame: {
    width : '100%',
    // maxWidth: 650,
    // height: 30,
    boxShadow: "none",
    background: "#00BFFF",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  cardTitle: {
    width : '100%',
    // maxWidth: 650,
    height: 100,
    boxShadow: "none",
    background: "#2F4F4F",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  typoTitle1: {
    fontSize : 30,
    // fontSize : 20,
  },
  typoTitle2: {
    fontSize : 30,
    // fontSize : 15,
  },
  listIntroduce: {
    width: '100%',
    //height: 1000,
    marginTop: 5,
    padding: 5,
  },
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    // height: 60,
  },
  listItemIntroduceTitle: {
    paddingTop: 5,
    paddingBottom: 5
  },
  listItemIntroduceContent: {
    paddingTop: 2,
    paddingBottom: 0
  },
  typoIntroduceTitle: {
    fontSize : 17,
  },
  typoIntroduceContent: {
    fontSize : 16,
  },
  typoIntroduceConetent2: {
    fontSize : 16,
    // color: 'red'
  },
  typoIntroduceContent3: {
    fontSize : 13,
  },
  invoiceButton: {
    position: 'fixed', 
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    cursor: 'pointer',
    right: 15,
    bottom: 10,
    marginBottom: 10,
    [theme.breakpoints.up('xs')]: {
      width: 100,
      height: 100,
      borderRadius: 50,
    },
    [theme.breakpoints.up('sm')]: {
      width: 100,
      height: 100,
      borderRadius: 50,
    },
    [theme.breakpoints.up('md')]: {
      width: 150,
      height: 150,
      borderRadius: 150,
    },
    [theme.breakpoints.up('lg')]: {
      width: 200,
      height: 200,
      borderRadius: 200,
    },
    [theme.breakpoints.up('xl')]: {
      width: 200,
      height: 200,
      borderRadius: 200,
    },
    backgroundColor: '#F8F8FF',
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#C0C0C0',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#C0C0C0',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  invoiceLogoMedia : {
    position: 'fixed', 
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    cursor: 'pointer',
    [theme.breakpoints.up('xs')]: {
      width: 50,
      height: 50,
      right: 20,
      bottom: 25,
      marginBottom: 20,
    },
    [theme.breakpoints.up('sm')]: {
      width: 50,
      height: 50,
      right: 20,
      bottom: 25,
      marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      width: 75,
      height: 75,
      right: 30,
      bottom: 25,
      marginBottom: 20,
    },
    [theme.breakpoints.up('lg')]: {
      width: 90,
      height: 90,
      right: 50,
      bottom: 35,
      marginBottom: 30,
    },
    [theme.breakpoints.up('xl')]: {
      width: 90,
      height: 90,
      right: 50,
      bottom: 35,
      marginBottom: 30,
    },
  },
  invoiceTypo : {
    fontSize : 25,
    color: 'black',
    [theme.breakpoints.up('xs')]: {
      fontSize : 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize : 23,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize : 30,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize : 30,
    },
  },
  kaissLogoMedia: {
    marginTop: 30,
    marginBottom: 20,
    width: 200,
    height: 60,
  },
  /*
  iconAvatar: {
    backgroundColor: '#0000FF',
    margin: 10,
    width: 100,
    height: 100,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  */
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
});

/*
const uploadContents = [
  {
    'id' : 1,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '홍길동',
    'date' : '2019.09.02',
    'image' : 'http://placeimg.com/64/64/1',
    'content' : '홍길동 바보',
  },
  {
    'id' : 2,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '장발장',
    'date' : '2019.05.05',
    'image' : 'http://placeimg.com/64/64/2',
    'content' : '장발장 멍청이',
  },
  {
    'id' : 3,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '김철수',
    'date' : '2019.03.03',
    'image' : 'http://placeimg.com/64/64/3',
    'content' : '김철수 바보',
  },
  {
    'id' : 4,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '장희빈',
    'date' : '2019.01.02',
    'image' : 'http://placeimg.com/64/64/4',
    'content' : '장희빈 바보',
  },
  {
    'id' : 5,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '김희선',
    'date' : '2018.12.25',
    'image' : 'http://placeimg.com/64/64/5',
    'content' : '김희선 바보',
  }
]
*/

class Home extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        userContents: "",
        getNotifications: "",
        fcmTokenId: "",
        eventToggle : '',
        searchKeyword: '',
        // uploadButtonToggle: false, // UploadPhoto 버튼 관련
        completed : 0,
        alarmDialogOpen_1: false,
      };
  }

  /*
  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  getNotifications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.get("upload", `/notification/?userId=${currentAuthUserFromSession}&fcmTokenId=${currentFCMTokenFromSession}`)
    .then(getNotifications => this.setState({getNotifications : getNotifications}));
  }

  postNotification(postNotification) {
    return API.post("upload", "/notification", {
      body: postNotification
    });
  }

  getNotiSetting() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/notiSetting/${currentAuthUserFromSession}`)
  }

  postNotiSetting(postNotificationSet) {
    return API.post("upload", "/notiSetting", {
      body: postNotificationSet
    });
  }

  // 삭제 API
  deleteNotification() {
    // const currentFCMTokenUUIDFromSession = sessionStorage.getItem("currentFCMTokenUUID");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.del("upload", `/notification/${currentFCMTokenFromSession}`);
  }
  */

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {

    const { isAuthenticated } = this.props

    //console.log("isAuthenticated: ")
    //console.log(isAuthenticated)
    if(isAuthenticated === true) {
      // console.log("isAuthenticated === true")
    } else {
    // Cognito 로그인 없이 S3 읽어 온다.
    Amplify.configure({
      Auth: {
        mandatorySignIn: false, // true이면 로그인 시에만 S3 읽어 올수 있다.
      }
    });
   }

    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;
    localStorage.setItem("currentPage", "home");
    // console.log('subscribe :' + sessionStorage.getItem("subscribe"));
    /*
      // 아래는 예시를 든것이다. 아래와 같이 this._isMounted를 사용해서 그 안에 setState를 담아주기.
      if (this._isMounted) {
        this.setState({
          news: result.data.hits,
        });
      }
    */
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: ");
      // console.log(userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: ");
      // console.log(userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        /*
        sessionStorage.setItem("currentUser_Freelancer", userID6);
        const loginType = localStorage.getItem("loginType");
        if(loginType === "facebook" || loginType === "google") {
          sessionStorage.setItem("currentUser_Freelancer", userID6);
        }
        if(loginType === "email") {
          sessionStorage.setItem("currentUser_Company", userID6);
        }
        */
        
        // currentAuthUser(currentAuthUserFromSession);
        /*const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        */
        /*
        const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
        console.log('currentUser_Freelancer: ' + currentAuthUserFromSession_Freelancer);
        const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
        console.log('currentUser_Company: ' + currentAuthUserFromSession_Company);
        console.log('loginType: ' + loginType);
        */

        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        /*
        // 나중에 삭제하기
        const loginType = localStorage.getItem("loginType");
        if(loginType === "facebook" || loginType === "google") {
          sessionStorage.setItem("currentUser_Freelancer", userID6);
        }
        if(loginType === "email") {
          sessionStorage.setItem("currentUser_Company", userID6);
        }
        */

        
        // currentAuthUser(currentAuthUserFromSession);
        //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        /*
        const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
        console.log('currentUser_Company: ' + currentAuthUserFromSession_Company);
        const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
        console.log('currentUser_Freelancer: ' + currentAuthUserFromSession_Freelancer);
        console.log('loginType: ' + loginType);
        */
        
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          // alert(e);
          console.log(e)
        }
    }

    /*
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    //console.log("currentAuthUserFromSession: ")
    //console.log(currentAuthUserFromSession)

    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    const FCMToggleFromSession = sessionStorage.getItem("FCMLoginToggle");

    // console.log("FCM-currentFCMTokenFromSession :" +  currentFCMTokenFromSession);
    // console.log("FCM-currentAuthUserFromSession :" +  currentAuthUserFromSession);
    // console.log("FCM-FCMToggleFromSession :" +  FCMToggleFromSession);

    const loginType_FromLocal = localStorage.getItem("loginType");
    const customerType1_FromLocal = localStorage.getItem("customerType1");
    const customerType2_FromLocal = localStorage.getItem("customerType2");
    const email_FromLocal = localStorage.getItem("email");
    if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
    || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
    || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
     try {
       const user = await this.getUser();
   
       const { userId, customerType1, customerType2, email } = user;

       if(this.state.userId === currentAuthUserFromSession) {
        localStorage.setItem("customerType1", customerType1);
        localStorage.setItem("customerType2", customerType2);
        localStorage.setItem("email", email);

       } else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          // console.log("userId is undefined");
          // console.log('currentUser: ' + currentUser);
          // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
          // console.log('userId: ' + this.state.userId);
          // this.props.history.push(`/user/${this.props.currentUser}`);
          this.setState({
            alarmDialogOpen_1 : true,
          });
        }
        else {
          console.log("userId is not matched");
          // console.log('currentUser: ' + currentUser);
          // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
          // console.log('userId: ' + this.state.userId);
        } 
      } catch(e) {
        console.log(e)
        // console.log('getUser 없음')
      }
    } else {
      // console.log("User 정보 받아옴")
    }

    if(currentFCMTokenFromSession && currentAuthUserFromSession && FCMToggleFromSession === "true") {
      await this.postNotification({
        fcmTokenId: currentFCMTokenFromSession,
        userId: currentAuthUserFromSession,
      });
      //console.log("FCM 토큰값 DB로 저장(로그인)");
      sessionStorage.setItem("FCMLoginToggle", "false");
     
      Object.keys(this.state.getNotifications).map(notificationUUId => {
        const getNotification = this.state.getNotifications[notificationUUId];
        const currentFCMTokenUUID = getNotification.notificationUUId;
        sessionStorage.setItem("currentFCMTokenUUID", currentFCMTokenUUID);
      });
      
    } else {
      //console.log("FCM 토큰값 없음 또는 이미 토큰값 DB에 저장됨");
      sessionStorage.setItem("FCMLoginToggle", "false");
    }

    // notiSetting 관련
    const notiSettingUserId = localStorage.getItem("notiSetting")
    ? JSON.parse(localStorage.getItem("notiSetting")).userId
    : null

    if(notiSettingUserId === currentAuthUserFromSession) {
      //console.log("localStorge_notiSetting 있음.");
      try{
        const localEventToggle = JSON.parse(localStorage.getItem("notiSetting")).eventToggle
        const localApplicantToggle = JSON.parse(localStorage.getItem("notiSetting")).applicantToggle  // 내가 게재한 공고에 지원자가 지원시 알림 설정
        //const localFollowUploadToggle = JSON.parse(localStorage.getItem("notiSetting")).followUploadToggle
        //const localFollowCommentToggle = JSON.parse(localStorage.getItem("notiSetting")).followCommentToggle
        this.setState({
          eventToggle : localEventToggle,
          applicantToggle : localApplicantToggle,
          // followUploadToggle : localFollowUploadToggle,
          // followCommentToggle : localFollowCommentToggle,
        });
      } catch(e) {
        //console.log("localNoniSetting() 에러.");    
      }
    } else {
      //console.log("localStorge_notiSetting 없음.");
      try{
        const notificationSetting = await this.getNotiSetting();

        const { userId, eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle/ } = notificationSetting;
        
        this.setState({userId});
  
        if(this.state.userId === currentAuthUserFromSession)
        {
          this.setState({ eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle/ });
        }

        localStorage.setItem(
          "notiSetting",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            eventToggle: this.state.eventToggle,
            applicantToggle: this.state.applicantToggle,
            /*
            followUploadToggle: this.state.followUploadToggle,
            followCommentToggle: this.state.followCommentToggle
            /
          })
        )
        //console.log("localStorge_notiSetting 저장됨.");
      } catch(e) {
        // alert(e);        
        await this.postNotiSetting({
          eventToggle: "true",
          applicantToggle: "true",
          /*
          followUploadToggle: "true",
          followCommentToggle: "true",
          /
        });

        localStorage.setItem(
          "notiSetting",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            eventToggle: "true",
            applicantToggle: "true",
            /*
            followUploadToggle: "true",
            followCommentToggle: "true",
            /
          })
        )
        
        //console.log("postNotiSetting() 실행됨.");
      }
    }
    */
  }

  drawerOpen = () => {
    const {drawertoggletrue} = this.props;
    drawertoggletrue();
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 사용자가 삭제 버튼을 눌렀을 때 실행하는 함수
  handleDelete = (id) => {
    this._delete(id);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    completed : 0,
    });
  }

  /*
  handleGoToPage = async() => {
      const loginType = localStorage.getItem("loginType")
      
      const { userHasAuthenticatedFalse } = this.props
      // DB에 FCM Token 삭제 API
      try {
        await this.deleteNotification();
        sessionStorage.setItem("FCMLoginToggle", "false");
        // window.location.reload();
      } catch (e) {
        // alert(e);
        console.log("deleteNotification() 에러")
        sessionStorage.setItem("FCMLoginToggle", "false");
      }
      // 로컬스트로리지 삭제
      // localStorage.removeItem("notiChecking"); // <- 로그아웃해도 notiChecking관련 localstoge는 안 지울것임. 나중에 재 로그인 위해서

      // 로그아웃시 세션 지우기 기능
      await Auth.signOut()

      if (loginType === "facebook") {
        // 페이스북 로그아웃
        window.FB.logout();
        console.log("facebook Log Out!");
        this.props.history.push(`/chooseaccount`);
        window.location.reload();
      } else if (loginType === "google") {
        // 구글 로그아웃
        // this.googleSignOut();
        document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://www.otwogong.com/chooseaccount";
        // document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://localhost:5555";
        console.log("google Log Out!");
      }

      userHasAuthenticatedFalse();
      //sessionStorage.setItem("currentUser", null);

      // console.log("Log Out!");

      sessionStorage.clear()
      localStorage.clear()

    this.setState({
      alarmDialogOpen_1 : false,
    });
  }
  */

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
    })
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme, /*searchKeyword,isApplyingLoading, isFollowingLoading*/ } = this.props;
    const { searchKeyword } = this.state;

    /*
    if(isApplyingLoading) {
      this.getApplications();
    }

    if(isFollowingLoading) {
      this.getFollowees();
    }
    */

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/* 세로로 정렬시 사용 */}
              <Card className={classes.mainCard1}>
                {/*<Typography className={classes.imageText}>려려 안녕</Typography>*/}
                <Grid container item sm={12} md={12} lg={6} xl={6} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <CardMedia
                      className={classes.mainMedia1}
                      image={'/images/main/home_bg_1.png'}  // 테스트시 사용
                      // image={'/images/main/bg_home.jpg'}  // 테스트시 사용
                      // image={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                    />
                    {/*
                    <img
                      className={classes.mainMedia}
                      src={'/images/main/Wall_1.png'}  // 테스트시 사용
                      // src={'/images/main/bg_home.jpg'}  // 테스트시 사용
                      // src={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                      // src={this.state.attachmentURL_1}
                    />
                    */}
                  </Grid>
                </Grid>
              </Card>
            </Grid>             
          </Grid>
          {/*
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card className={classes.cardTitle}>
                <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography className={classes.typoTitle1}>
                      무료로 견적 받아보세요
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Button onClick={this.drawerOpen} className={classes.invoiceButton}>
                      <Typography className={classes.typoTitle2}>
                        견적 요청
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          */}
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/* 세로로 정렬시 사용 */}
              <Card className={classes.mainCard2}>
                {/*<Typography className={classes.imageText}>려려 안녕</Typography>*/}
                <Grid container item sm={12} md={12} lg={6} xl={6} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <CardMedia
                      className={classes.mainMedia2}
                      image={'/images/main/home_bg_2.png'}  // 테스트시 사용
                      // image={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                    />
                    {/*
                    <img
                      className={classes.mainMedia}
                      src={'/images/main/Wall_2.png'}  // 테스트시 사용
                      // src={'/images/main/bg_home.jpg'}  // 테스트시 사용
                      // src={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                      // src={this.state.attachmentURL_1}
                    />
                    */}
                  </Grid>
                </Grid>
              </Card>
            </Grid>             
          </Grid>
          <Card className={classes.mainCard3_1}>
          </Card>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/* 세로로 정렬시 사용 */}
              <Card className={classes.mainCard3}>
                {/*<Typography className={classes.imageText}>려려 안녕</Typography>*/}
                <Grid container item sm={12} md={12} lg={7} xl={7} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <CardMedia
                      className={classes.mainMedia3}
                      image={'/images/main/home_bg_3.png'}  // 테스트시 사용
                      // image={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                    />
                    {/*
                    <img
                      className={classes.mainMedia}
                      src={'/images/main/Wall_2.png'}  // 테스트시 사용
                      // src={'/images/main/bg_home.jpg'}  // 테스트시 사용
                      // src={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                      // src={this.state.attachmentURL_1}
                    />
                    */}  
                  </Grid>
                </Grid>
              </Card>
            </Grid>             
          </Grid>
          <Card className={classes.mainCard3_1}>
          </Card>
          <Card className={classes.mainCard4_1}>
          </Card>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/* 세로로 정렬시 사용 */}
              <Card className={classes.mainCard4}>
                {/*<Typography className={classes.imageText}>려려 안녕</Typography>*/}
                <Grid container item sm={12} md={12} lg={7} xl={7} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <CardMedia
                      className={classes.mainMedia4}
                      image={'/images/main/home_bg_4.png'}  // 테스트시 사용
                      // image={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                    />
                    {/*
                    <img
                      className={classes.mainMedia}
                      src={'/images/main/Wall_2.png'}  // 테스트시 사용
                      // src={'/images/main/bg_home.jpg'}  // 테스트시 사용
                      // src={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                      // src={this.state.attachmentURL_1}
                    />
                    */}  
                  </Grid>
                </Grid>
              </Card>
            </Grid>             
          </Grid>
          <Card className={classes.mainCard4_1}>
          </Card>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardMedia
                className={classes.kaissLogoMedia}
                // image="/static/images/logo/app.svg"
                image="/images/logo/KAISS_LOGO.svg"
                aria-label="Recipe" 
              />
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>카이스컴퍼니</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>사업자등록번호 : 229-34-00829</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>대표 : 김재영</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>고객센터</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>043)714-3378</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>otwogong@kaisscompany.com</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent3}>평일 09:00 ~ 18:00 / 주말·공휴일 휴무</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        {/*
        <Dialog open={this.state.alarmDialogOpen_1} onClose={this.handleGoToPage}>
          <DialogTitle>오투공을 이용하시기 위해서는 사용자 정보가 필요합니다.</DialogTitle>
          <DialogContent>정상적인 회원가입이 아닌 간편로그인으로 들어오셔서 추가적인 회원정보 작성이 필요합니다.</DialogContent>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button}
                  onClick={this.handleGoToPage}>회원정보 페이지로 가기</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        */}
        {/* 업로드 버튼. 단, 현재는 UploadPhoto.js에서 currentpage에 관한 코딩이 안되어 있다.
        // 차후 필요시 UploadPhoto.js 파일 수정하면 됨 */}
        {/*
        {this.state.uploadButtonToggle / 프로젝트에는 기업들만 업로드 할 수 있게 할 예정 /
        ?
        <Link component={RouterLink} to='/upload' underline='none' color='inherit'>
          <button
            className="UploadButton"
            onClick={this.handleUpload}
          >
          </button>
        </Link>
        :
        null
        }
        */}
        <Button onClick={this.drawerOpen} className={classes.invoiceButton}>
          <Typography className={classes.invoiceTypo}>
            무료견적요청
          </Typography>
        </Button>
        <CardMedia
          className={classes.invoiceLogoMedia}
          // image="/static/images/logo/app.svg"
          image="/images/logo/app.svg"
          aria-label="Recipe"
          onClick={this.drawerOpen}
        />
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  //uploadContents: state.upload.uploadContents,
  // currentUser: state.auth.currentUser,
  // searchKeyword: state.upload.searchKeyword,
  isdrawerToggle: state.upload.isdrawerToggle,
  //isApplyingLoading: state.upload.isApplyingLoading,
  //isFollowingLoading: state.upload.isFollowingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  // currentAuthUser: (currentUser) => dispatch(authActions.currentAuthUser(currentUser)),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  // upload: () => dispatch(uploadActions.upload()),  // UploadPhoto 버튼 관련
  // uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
  drawertoggletrue: () => dispatch(uploadActions.drawertoggletrue()),
  drawertogglefalse: () => dispatch(uploadActions.drawertogglefalse()),
  //applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  //applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
  //followingloadingtrue: (isFollowingLoading) => dispatch(uploadActions.followingloadingtrue(isFollowingLoading)),
  //followingloadingfalse: (isFollowingLoading) => dispatch(uploadActions.followingloadingfalse(isFollowingLoading)),
})

Home = withStyles(styles, {withTheme: true})(Home)
export default connect(mapStateToProps, mapDispatchToProps)(Home)