import React, { Component } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from '@material-ui/core/Paper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import moment from 'moment';

import { connect } from 'react-redux'

// MoreVertIcon 관련
import PopupState, { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { API, Storage } from "aws-amplify";

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

// '좋아요' 관련 Icon
/*
import Badge from '@material-ui/core/Badge';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
*/

import SVGIcon from './SVGIcon'

//import './UploadFeedComment.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  paper: {
    width : '100%',
    padding: theme.spacing(2),
    margin: 'auto',
    //maxWidth: 800,
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  card: {
    maxWidth: 800,
    marginTop: 15,
    marginBottom: 15,
  },
  commentNickName: {
    textAlign: 'left',
  },
  commentContent: {
    textAlign: 'left',
  },
  commentDate: {
    textAlign: 'left',
  },
  commentCardActions: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 10,

  },
  commentFavorite: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  commentThumbsUp: {
    marginRight: 7,
  },
  commentThumbsDown: {
    marginLeft: 7,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  videoWrapper: {
    padding: 0,
    //width: "10%",
    //height: "10%",
    width: 100,
    height: 50,
  },
  commentAvatar: {
    // backgroundColor: red[500],
    backgroundColor: '#0000FF',
    margin: 5,
    width: 17,
    height: 17,
  },
  commentAvatarmedia: {
    marginTop: 5,
    height: 50,
    width: "100%",
    padding: 20,
    objectFit: 'contain'
  },
  commentIconButton: {
    padding : 10,
  },
  popupStateTypography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  popupStateMenu: {
    padding : 0,
  },
  Textarea__textarea: {
    width: '80%',
    // height: '3vh',
    fontSize: 17,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '22px',
    paddingRight: '24px',
    resize: 'none;', /* 크기고정 */ 
  },
  button: {
    fontSize: 17,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  /*
  textareaContent: {
    marginTop: '0px',
    marginBottom: '0px',
    padding : 10,
  },
  */
});

class UploadFeedComment extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      // textarea 관련
			rows: 1,
			minRows: 1,
      maxRows: 4,
      //textAreaToggle: false,
      //-------------
      completed : 0,
      commentAavatarAttachmentURL: null,
      commentAvatarimage: "",
      dialogOpen : false, // 다이얼로그 창이 열려 있는지 확인하는 변수
      commentPutToggle: false,  // 댓글 수정 Toggle 확인하는 변수
      comment: '',
      commentPutContent: this.props.commentContent,
      commentName: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    try {
      let commentAavatarAttachmentURL;
      const commentUser = await this.getCommentUser();
      const { customerType2, companyName, name, avatarimage } = commentUser;

      // 첨부 파일이 있으면 키를 사용하여 S3에 업로드 한 파일에 대한 보안 링크를 얻습니다. 
      // 그런 다음이를 구성 요소의 상태로로 저장합니다 attachmentURL.
      // 그 이유는 우리는 왜이 upload와 함께 상태에서 개체를 content하고는 
      // attachmentURL우리가 나중에 사용자가 노트를 편집 할 때 사용되기 때문이다.

      // AWS S3 사용시 (user 관련 파일)
      if (avatarimage) {
        commentAavatarAttachmentURL = await Storage.get(avatarimage, {level: 'public'});
      }

      this.setState({
        commentUser,
        commentAavatarAttachmentURL,
        customerType2,
        companyName,
        name,
        avatarimage,
      });
    } catch (e) {
      //alert(e);
      console.log(e);
    }

    if(this.state.customerType2 === 'company') {
      this.setState({commentName: this.state.companyName})
    } else if(this.state.customerType2 === 'freelancer') {
      this.setState({commentName: this.state.name})
    }

    /*
    // AWS S3 사용시 (comment avatar image 관련)
    try {
      let commentAavatarAttachmentURL;
      const commentAvatarImage = this.props.commentAvatarimage;

      if (commentAvatarImage) {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
        commentAavatarAttachmentURL = await Storage.get(commentAvatarImage, { level: 'public' });  // Public하게 볼 수 있는거
      }

      //  console.log(this.props.avatarimage);
      
      this.setState({
        commentAavatarAttachmentURL,
        commentAvatarImage,
      });
    } catch (e) {
      alert(e);
    }
    */
  }

  /*
  // 경로 ( /user/:id) 
  getUser() {
    return API.get("upload", `/user/${this.state.userId}`);
  }
  */

  // commet를 작성한 유저 정보 받아오기
  getCommentUser() {
    return API.get("upload", `/user/${this.props.userId}`);
  }
 
  // 경로 ( /comment/:commentUUId) 
  getComment() {
    return API.get("upload", `/comment/${this.props.commentUUId}`);
  }

  // 댓들 수정 API
  putComment(comment) {
    return API.put("upload", `/comment/${this.props.commentUUId}`, {
      body: comment
    });
  }

  // 삭제 API
  deleteComment() {
    return API.del("upload", `/comment/${this.props.commentUUId}`);
  }

  /*
  // comment의 favorite 관련
  postCommentFavorite(commentFavoriteThumb) {
    return API.post("upload", "/commentFavorite", {
      body: commentFavoriteThumb
    });
  }

  deleteCommentFavorite() {
    return API.del("upload", `/commentFavorite/${this.props.commFavoriteUUId}`);
  }
  */

  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  handletextAreaChange = (event) => {
		const textareaLineHeight = 24;
		const { minRows, maxRows } = this.state;
		
		const previousRows = event.target.rows;
  	event.target.rows = minRows; // reset number of rows in textarea 
		
		const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);
    
    if (currentRows === previousRows) {
    	event.target.rows = currentRows;
    }
		
		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
    }
    
  	this.setState({
    	commentPutContent: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
	};

  commentDeleteDialog = async event => {
    event.preventDefault();

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.userId === currentAuthUserFromSession) {
      this.setState({
        dialogOpen : true,
      });
    }
  }

  // 실제로 데이터가 삭제되는 것을 도와주기 위한 함수
  handleDelete = async event => {
    event.preventDefault();
    // const { currentUser } = this.props;
    const { commentloadingtrue, commentloadingfalse } = this.props;

    this.setState({ isDeleting: true });
      commentloadingtrue();

      try {
        await this.deleteComment();
        commentloadingfalse();
        
        await this.putPoint({
          pointEvent: "commentCancel",
        });
        // this.props.history.push("/");
        // window.location.reload();
      } catch (e) {
        alert(e);
        this.setState({ isDeleting: false });
        commentloadingfalse();
      }
      
    /*  
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    // if(this.props.userId === currentUser)
    if(this.props.userId === currentAuthUserFromSession)
    {
      
      // 윈도우 confirm함수 실행시 (현재는 dailog로 사용하고 있어서 필요 없음)
      const confirmed = window.confirm(
        "Are you sure you want to delete this content?"
      );
  
      if (!confirmed) {
        return;
      }
      
  
      this.setState({ isDeleting: true });
      commentloadingtrue();

      try {
        await this.deleteComment();
        commentloadingfalse();
        // this.props.history.push("/");
        // window.location.reload();
      } catch (e) {
        alert(e);
        this.setState({ isDeleting: false });
        commentloadingfalse();
      }
    }
    */
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      dialogOpen : false,
    })
  }

  // popup sate의 수정 버튼 클릭시
  commentPut = () => {
    this.setState({
      textAreaToggle: true,
      commentPutToggle : true,
    })
  }

  // 수정 버튼 클릭시(최종 수정)
  handlecommentPut = async event => {
    event.preventDefault();
    const { commentloadingtrue, commentloadingfalse } = this.props;

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.userId === currentAuthUserFromSession)
    {
      commentloadingtrue();
      try {
        await this.putComment({
          // uploadUUId: this.props.uploadUUId,
          commentContent: this.state.commentPutContent,
        });
        this.setState({ commentPutToggle: false });
        commentloadingfalse();
      } catch (e) {
        alert(e);
        this.setState({ commentPutToggle: false });
        commentloadingfalse();
      }
    } else {
      console.log("userId is not matched");
    }
  }

  // 취소 버튼 클릭시(최종 수정)
  handlecommentCancel = () => {
    this.setState({
      commentPutToggle : false,
    })
  }

  /*
  // comment의 favorite 관련 버튼
  handleThumbsUpButton = async event => {
    event.preventDefault();
    const { commfavoriteloadingtrue, commfavoriteloadingfalse } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    /
    console.log("currentAuthUserFromSession : " + currentAuthUserFromSession);
    console.log("this.props.commentFavoriteContent : " + this.props.commentFavoriteContent);
    console.log("this.props.commentFavoriteUserId : " + this.props.commentFavoriteUserId);
    console.log("this.props.commentFavoriteUUId : " + this.props.commFavoriteUUId);
    /

    if(this.props.commFavoriteContent === '2') {
      if(currentAuthUserFromSession === this.props.commFavoriteUserId) {
        commfavoriteloadingtrue();
        try {
          await this.deleteCommentFavorite();
          commfavoriteloadingfalse();

          await this.putPoint({
            pointEvent: "commFavoriteCancel",
          });
        } catch (e) {
          alert(e);
          commfavoriteloadingfalse();
        }
      }
    } else {
      commfavoriteloadingtrue();
      try {
        await this.postCommentFavorite({
          uploadUUId: this.props.uploadUUId,
          commentUUId: this.props.commentUUId,
          commentFavoriteContent: '2',
        });
        commfavoriteloadingfalse();

        await this.putPoint({
          pointEvent: "commFavorite",
        });
      } catch (e) {
        alert(e);
        commfavoriteloadingfalse();
      }
    }
  }
  */

  compressImage = async (imageFile) => {
    try {
      const compressedFile = await imageCompression(imageFile, MAX_IMAGE_SIZE_MB)
      this.setState({
        isCompressing: false,
        file: compressedFile,
        fileName: compressedFile.name,
      })
    } catch (error) {
      this.setState({
        isCompressing: false,
        warningMessage: '* Fail to compress image'
      })
    }
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  /*
  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  }
  */

  render() {
    const { classes, theme } = this.props;
    const { commentPutContent } = this.state;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    // const message = `Truncation should be conditionally applicable on this long line of text as this is a much longer line than what the container can support. `;

    if(this.state.textAreaToggle) {
      const textArea = document.getElementById("commentPutContent");
      if(textArea != null) {
        const lines = textArea.value.split('\n');
        this.setState({
          textAreaToggle: false,
          rows: lines.length,
        })
      }
    }
   
    return (
      <div className={classes.root}>
        {/* 답글 관련 Grid*/}
        <Paper className={classes.paper}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} wrap="nowrap" spacing={0}>
            <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
              {/*
              <Avatar src={this.state.commentAavatarAttachmentURL} aria-label="Recipe" className={classes.commentAvatar}>
                {this.props.commentId}
              </Avatar>
              */}
              {this.state.commentAavatarAttachmentURL
              ?
                this.state.customerType2 === 'company'
                ?
                <img
                  className={classes.commentAvatarmedia}
                  src={this.state.commentAavatarAttachmentURL}
                />
                :
                <Avatar src={this.state.commentAavatarAttachmentURL} aria-label="Recipe" className={classes.commentAvatar}>
                  <AccountCircleIcon style={{fontSize: '37px'}}/>
                </Avatar>
              :
                <Avatar aria-label="Recipe" className={classes.commentAvatar}>
                  <AccountCircleIcon style={{fontSize: '37px'}}/>
                </Avatar>
              }
            </Grid>
            <Grid item xs={7} sm={7} md={8} lg={8} xl={8} align="center">
              { this.state.commentPutToggle
              ?
              <div>
                <textarea
                  id="commentPutContent"
                  name="commentPutContent"
                  rows={this.state.rows}
                  value={commentPutContent}
                  onChange={this.handletextAreaChange}
                  placeholder="댓글 수정"
                  className={classes.Textarea__textarea}
                  autoComplete="off"
                />
              </div>
              :
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" className={classes.commentNickName}>
                  {this.state.commentName}
                </Typography>
                <Typography variant="body2" gutterBottom className={classes.commentContent}>
                  {this.props.commentContent} {/*{message}*/}
                </Typography>
                <Typography variant="body2" color="textSecondary" className={classes.commentDate}>
                  {moment(this.props.commentDate).format("YYYY-MM-DD")}
                </Typography>
              </Grid>
              }
              { this.state.commentPutToggle
              ?
              <Grid item>
                <CardActions disableSpacing className={classes.commentCardActions}>
                  {/*
                  // 나중에 아래의 아이콘 타입으로 바꾸기
                  <IconButton aria-label="Add to favorites">
                    좋아요
                  </IconButton>
                  <IconButton aria-label="Share">
                    싫어요
                  </IconButton>
                  */}
                  <Typography variant="body2" style={{ cursor: 'pointer' }} onClick={this.handlecommentPut} className={classes.commentThumbsUp}>
                    수정
                  </Typography>
                  <Typography variant="body2" style={{ cursor: 'pointer' }} onClick={this.handlecommentCancel} className={classes.commentThumbsDown}>
                    취소
                  </Typography>
                </CardActions>
              </Grid>
              :
              <Grid item>
                <CardActions disableSpacing className={classes.commentCardActions}>
                  {/*
                  // 나중에 아래의 아이콘 타입으로 바꾸기
                  <IconButton aria-label="Add to favorites">
                    좋아요
                  </IconButton>
                  <IconButton aria-label="Share">
                    싫어요
                  </IconButton>
                  */}
                  {/*
                  <Typography variant="body2" style={{ cursor: 'pointer' }} className={classes.commentThumbsUp}>
                    좋아요
                  </Typography>
                  <Typography variant="body2" style={{ cursor: 'pointer' }} className={classes.commentThumbsDown}>
                    싫어요
                  </Typography>
                  */}
                  {/* Comment 좋아요 버튼 관련 Component */}
                  {/*
                  {this.props.commFavoriteContent === '2' /&& currentAuthUserFromSession === this.props.favoriteUserId/
                  ?
                  <IconButton aria-label="Add to favorites" onClick={this.handleThumbsUpButton} 
                    className={classes.commentFavorite} color="primary"
                  >
                    <FavoriteIcon />
                    {/ <ThumbUpIcon /> /}
                  </IconButton>
                  :
                  <IconButton aria-label="Add to favorites" onClick={this.handleThumbsUpButton}
                    className={classes.commentFavorite}
                  >
                    <FavoriteIcon />
                    {/ <ThumbUpIcon /> /}
                  </IconButton>
                  }
                  <Badge badgeContent={this.props.commFavoriteContentNumbers} color="default">
                    <Typography></Typography>
                  </Badge>
                  */}
                </CardActions>
              </Grid>
              }
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                {/*<Typography variant="subtitle1">60분전</Typography>*/}
                {
                currentAuthUserFromSession == this.props.userId
                ?
                <PopupState variant="popover" popupId="MoreVertIconPopover">
                  {popupState => (
                    <div>
                      <IconButton variant="contained" {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <MenuItem onClick={popupState.close} className={classes.popupStateMenu}>
                          <Typography variant="body2" style={{ cursor: 'pointer' }} 
                            onClick={this.commentPut} className={classes.popupStateTypography}
                            variant="h6" noWrap
                          >
                            수정
                          </Typography>
                        </MenuItem>
                        <MenuItem onClick={popupState.close} className={classes.popupStateMenu}>
                          <Typography variant="body2" style={{ cursor: 'pointer' }} 
                            onClick={this.commentDeleteDialog} className={classes.popupStateTypography}
                            variant="h6" noWrap
                          >
                            삭제
                          </Typography>
                        </MenuItem> 
                      </Menu>
                    </div>
                  )}
                </PopupState>
                :
                <PopupState variant="popover" popupId="MoreVertIconPopover">
                  {popupState => (
                    <div>
                      <IconButton variant="contained" {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <MenuItem onClick={popupState.close} className={classes.popupStateMenu}>
                          <Typography variant="body2" style={{ cursor: 'pointer' }} 
                            /* onClick={this.commentPut}*/ className={classes.popupStateTypography}
                            variant="h6" noWrap
                          >
                            신고
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </PopupState>
              }
            </Grid>
          </Grid>
        </Paper>
        <Dialog open={this.state.dialogOpen} /*onClose={this.handleClose}*/>
        <DialogTitle>댓글을 삭제하시겠습니까?</DialogTitle>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                <Button variant="contained" className={classes.button}
                  onClick={this.handleDelete}>확인</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button variant="contained"  className={classes.button}
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isCommentLoading: state.upload.isCommentLoading,
  // isCommFavoriteLoading: state.upload.isCommFavoriteLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  commentloadingtrue: (isCommentLoading) => dispatch(uploadActions.commentloadingtrue(isCommentLoading)),
  commentloadingfalse: (isCommentLoading) => dispatch(uploadActions.commentloadingfalse(isCommentLoading)),
  // commfavoriteloadingtrue: (isCommFavoriteLoading) => dispatch(uploadActions.commfavoriteloadingtrue(isCommFavoriteLoading)),
  // commfavoriteloadingfalse: (isCommFavoriteLoading) => dispatch(uploadActions.commfavoriteloadingfalse(isCommFavoriteLoading)),
})

UploadFeedComment = withStyles(styles, {withTheme: true})(UploadFeedComment)
export default connect(mapStateToProps, mapDispatchToProps)(UploadFeedComment);