import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import imageCompression from 'utils/imageCompression';
import SVGIcon from '../SVGIcon'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CardMedia from '@material-ui/core/CardMedia';

import * as authActions from 'redux/actions/auth'
import * as photoActions from 'redux/actions/photos'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

import CircularProgress from "@material-ui/core/CircularProgress";

// S3 업로드 관련
import { API } from "aws-amplify";
import config from "../../config";
import { s3UploadPublic } from "../../libs/awsLib";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(3),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(17),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
    /*
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    */
  },
  toolBar: {
    height : 10,
  },
  // toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    fontSize : 17,
    color: 'black'
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  content: {
    marginTop: '30px',
    marginBottom: 25,
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  typoSend: {
    fontSize : 17,
  },
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '90px',
  },
  textFieldLabelSize:{
    fontSize:30
  },
  textFieldInputSize:{
    fontSize:30
  },
  Textarea__label: {
    display: 'block',
    fontSize: 20,
    fontWeight: 'bold',
    color: 'brown-grey', //'#3E2723',
    marginBottom: 8,
    paddingTop: 8,
  },
  Textarea__title: {
    width: '100%',
    height: '10vh',
    fontSize: 20,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '22px',
    paddingRight: '24px',
  },
  Textarea__body: {
    width: '100%',
    height: '60vh',
    fontSize: 20,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '22px',
    paddingRight: '24px',
  },
  UploadPhoto__file: {
    marginTop: '0px',
    marginBottom: '16x',
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed', 
    //left: '50%', 
    top: '47%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  hidden: {
    display: 'none'
  },
  bottomAppBar: {
    top: 'auto',
    //bottom: 0,
    bottom: 100,
  },
  UploadPhoto__file: {
    //visibility: 'hidden'
    display: 'none'
  },
  media: {
    width: 50,
    height: 50,
  },
  addPhotoIcon: {
    width: 50,
    height: 50,
  },
});

const MAX_IMAGE_SIZE = 30000 // 30KB
const MAX_IMAGE_SIZE_MB = 0.03 // 30KB

// AWS에서 만든 API 주소를 넣는다.
// 내가 작성한거 사용시 사용. AWS Amplify에서는 사용 안함.
// const awsdatabaseURL = "https://7mesa0l65c.execute-api.ap-northeast-2.amazonaws.com/dev/upload";

class NotiSending_Admin extends Component {

  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      notificationList: '',
      file: '',
      fileName: '',
      warningMessage: '',
      isCompressing: false,
      userId: '',
      customerType: '',
      name: '',
      avatarimage: '',
      title: '',
      body: '',
      image: null,
      isLoading: false,
      dialogOpen : false, // 다이얼로그 창이 열려 있는지 확인하는 변수
      tempNotificationImage: null,
      notiSettings: '',
      notifications: '',
      fcmTokenIds: [], // 여러명한테 FCM 발송시 시도
      // fcmTokenId:'', // 한명한테 FCM 발송시
      completed : 0,
    };
  }
  
  // 관리자 (나중에 더 좋은 방법으로 바꾸기)
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    if(currentAuthUserFromSession === "ap-northeast-2:0eac84f9-d5d6-454a-8468-f46c99cef2ec"
      || currentAuthUserFromSession === "ap-northeast-2:1ef91eaf-bfd0-49eb-91ce-f70191165a07") {
        return API.get("upload", `/user/${currentAuthUserFromSession}`);
      }
  }

  // AWS Amplify 사용한 POST
  postNotificationList(notificationList) {
    return API.post("upload", "/notificationList", {
      body: notificationList
    });
  }

  getNotiSettings() {
    return API.get("upload", "/notiSetting")
    .then(notiSettings => this.setState({notiSettings : notiSettings}));
  }
  
  getNotifications() {
    return API.get("upload", "/notification")
    .then(notifications => this.setState({notifications : notifications}));
  }

  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }

  // 실제로 데이터가 삽입되는 것을 도와주기 위한 함수
  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    // postNotificationList()
    if(this.state.file) {
      try {
        const attachment = this.state.file
        ? await s3UploadPublic(this.state.file)
        : null;

        await this.postNotificationList({
          attachment,
          customerType: this.state.customerType,
          name: this.state.name,
          avatarimage: this.state.avatarimage,
          title: this.state.title,
          body: this.state.body,
          image: attachment,
        });
        //this.props.history.push("/");
      } catch (e) {
        // alert(e);
        console.log("postNotificationList() 동작 안됨");
        this.setState({ isLoading: false });
      }
    } else {
      await this.postNotificationList({
        customerType: this.state.customerType,
        name: this.state.name,
        avatarimage: this.state.avatarimage,
        title: this.state.title,
        body: this.state.body,
        image: "default",
      });
      //this.props.history.push("/");
    }

    // postFCM 실행 (여러명한테 발송)
    if(this.state.file) {
      try {
        const attachment = this.state.file
        ? s3UploadPublic(this.state.file)
        : null;

        await this.postNotificationFCM({
          attachment,
          registration_ids: this.state.fcmTokenIds,
          title: this.state.title,
          body: this.state.body,
          icon: "icon",
          click_action: "https://otwogong.com",
          image: attachment,
        });
        console.log("FCM Post 동작 1");
        this.setState({postFCMs : false});
        this.props.history.push("/");
      } catch (e) {
        // alert(e);
        console.log("FCM Post 동작 안됨");
        this.setState({postFCMs : false});
        this.setState({ isLoading: false });
      }
    } else {
      await this.postNotificationFCM({
        registration_ids: this.state.fcmTokenIds,
        title: this.state.title,
        body: this.state.body,
        icon: "icon",
        click_action: "https://otwogong.com",
      });
      console.log("FCM Post 동작 2");
      this.setState({postFCMs : false});
      this.props.history.push("/");
    } 

    /*
    // postFCM 실행 (한명한테 발송)
    try{
    await this.postNotificationFCM({
      to: this.state.fcmTokenId,
      title: this.state.title,
      body: this.state.body,
    });
    console.log("postNotificationFCM() 동작 됨");
    this.props.history.push("/");
    } catch (e) {
      // alert(e);
      console.log("postNotificationFCM() 동작 안됨");
    }
    */
  }

  /*
  // AWS Amplify 없이 POST 실행
  // 데이터 삽입 시. post방식으로 요청을 하는 함수.
  _post(uploadContent) {
    console.log("POST 실행");
    // REST API 방식으로(POST 방식) 서버에 요청
    return fetch(`${awsdatabaseURL}`, {
        method: 'POST',
        body: JSON.stringify(uploadContent)
    // 만약 200이 아니면 에러 띄우고 맞으면 응답하는 json파일 출력(리턴)
    }).then(res => {
        if(res.status != 200) {
            throw new Error(res.statusText);
        }
        return res.json();
    // 추가된 데이터만 가지고 와서 화면에 보여주는 작업
    // 이후에 인자값(data)에 
    }).then(uploadContents => {

      this.props.uploadcontents(uploadContents);

      // console.log("POST 실행 완료");

      return(
        this.props.history.push("/")  // home 페이지로 돌아감.
      );
    });
  }

  // AWS Amplify 없이 하는 방식 (내가 작성)
  // 실제로 데이터가 삽입되는 것을 도와주기 위한 함수
  handleSubmit = () => {

    this.setState({ isLoading: true });

    const uploadContent = {
      // walletAddress: this.props.address,
      walletAddress: "김재영 월렛 주소",
      // nickName: "김재영",
      title: this.state.title,
      image: "http://placeimg.com/64/64/1",
      content: this.state.content,
      // password: "6"
    }
    // 추가 버튼을 누른 뒤 이니간 dialog가 화면상에서 사라지게 해주어야 함
    // this.handleDialogToggle();
    // 추가 버튼을 누른 뒤 Home 페이지로 돌아가게 해야 됨.
    // 혹여나 사용자가 내용을 입력하지 않은 경우에는 return.
    
    // console.log("Wallet주소 :" + uploadContent.walletAddress);

    if(!uploadContent.title && !uploadContent.content) {
        // console.log("데이터 안 넘어감");
        return;
    }

    // console.log("데이터 넘어감");
    // 정상적인 경우에는 _POST(uploadContent) 함수를 불러와서 실행 되게 끔 한다.
    this._post(uploadContent);
  }
  */

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  handleFileChange = (e) => {
    const file = e.target.files[0]
    this.file = e.target.files[0];

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      this.setState({
        isCompressing: true,
      })
      return this.compressImage(file);
    }

    /*
     /**
     * If image size is bigger than MAX_IMAGE_SIZE(30KB),
     * Compress the image to load it on transaction
     * cf. Maximum transaction input data size: 32KB
    if (file.size > MAX_IMAGE_SIZE) {
      this.setState({
        isCompressing: true,
      })
      return this.compressImage(file)
    }
    */

    return this.setState({
      file,
      // 이미지 업로드의 미리보기 표시
      // URL.createObjectURL(파일) 함수는 링크와 연결된 임시 로컬 URL을 만든다
      tempNotificationImage: URL.createObjectURL (this.file),
      fileName: this.file.name,  
    })
  }

  /*
  handleGoToPage = () => {
    // const { currentUser } = this.props
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    this.setState({
      dialogOpen : false,
    });
    // this.props.history.push(`/user/${currentUser}`);
    this.props.history.push(`/user/${currentAuthUserFromSession}`);
  }
  */

  compressImage = async (imageFile) => {
    try {
      const compressedFile = await imageCompression(imageFile, MAX_IMAGE_SIZE_MB)
      this.setState({
        isCompressing: false,
        file: compressedFile,
        fileName: compressedFile.name,
      })
    } catch (error) {
      this.setState({
        isCompressing: false,
        warningMessage: '* Fail to compress image'
      })
    }
  }

  handleCancelPhoto = () => {
    this.setState({
      file: '',
      fileName: '',
      tempNotificationImage: null
    });
  }

  /*
  handleNotUpload = () => {
    const { notupload } = this.props
    notupload()
  }
  */

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  async componentDidMount() {
    this._isMounted = true;
    // const { currentUser } = this.props

    // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    // getUser() 관련 (uploadUser와 매칭되서 작성된 글의 user를 찾는 것이다.)
    try {
      const adminUser = await this.getUser();

      const { userId, customerType2, companyName, avatarimage } = adminUser;

      this.setState({
        adminUser,
        userId : userId,
        customerType : customerType2,
        name : companyName,
        avatarimage : avatarimage,
      });

    } catch (e) {
      // alert(e);
      console.log("getAdminUser 에러");
    }

    // FCM으로 발송할 데이터를 미리 받아서 fcmTokenId를 배열로 저장.
    // 나중에 발송시 FCM으로 fcmTokenId 배열로 보내면 최대 1,000명한테 한번에 발송 가능
    await this.getNotiSettings();
    await this.getNotifications();

    try {
      // fcmTokenIds를 배열로 받기
      if(this.state.notiSettings && this.state.notifications) { 
        Object.keys(this.state.notiSettings).map(notiSettingUUId => {
          const notiSetting = this.state.notiSettings[notiSettingUUId];

          let fcmTokenId
          Object.keys(this.state.notifications).map(notificationUUId => {
            const notification = this.state.notifications[notificationUUId];
            if(notiSetting.eventToggle === "true") {
              if(notiSetting.userId === notification.userId) {
                fcmTokenId = notification.fcmTokenId
                this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
              }
            }
          });
        })
      }
    } catch(e) {
      console.log(e)
    }

    /*
    // 만약 user관련 서버에서 데이터를 못받아오면 nickName등 작성하게끔 MyPage로 이동 시키기
    try {
      const user = await this.getUser();
      const { userId, walletAddress, nickName, avatarimage } = user;

      this.setState({userId});

      // 첨부 파일이 있으면 키를 사용하여 S3에 업로드 한 파일에 대한 보안 링크를 얻습니다. 
      // 그런 다음이를 구성 요소의 상태로로 저장합니다 attachmentURL.
      // 그 이유는 우리는 왜이 upload와 함께 상태에서 개체를 content하고는 
      // attachmentURL우리가 나중에 사용자가 노트를 편집 할 때 사용되기 때문이다.
      // if(this.state.userId === currentUser)
      if(this.state.userId === currentAuthUserFromSession)
      {  
        this.setState({
          user,
          walletAddress,
          nickName,
          avatarimage,
          expanded : true,
        });
        // console.log("userId is matched");
        // console.log('currentUser: ' + currentUser);
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // console.log('userId: ' + this.state.userId);        
      } else if(this.state.userId === undefined) {
        // console.log("userId is undefined");
        // console.log('currentUser: ' + currentUser);
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // console.log('userId: ' + this.state.userId);
        // this.props.history.push(`/user/${this.props.currentUser}`);
        this.setState({
          dialogOpen : true,
        });
      }
      else {
        console.log("userId is not matched");
        // console.log('currentUser: ' + currentUser);
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // console.log('userId: ' + this.state.userId);
      } 
    } catch (e) {
      alert(e);
      // console.log(nickName);
    }
    */
  }

  render() {
    const { classes /*, theme, container, address, uploadContents*/ } = this.props;
    const { fileName, title, body, tempNotificationImage, isCompressing, warningMessage } = this.state

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default"  className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                  {/*<Button
                    className="NotUploadButton"
                    label="<"
                    onClick={this.handleNotUpload}
                  />*/}
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    알림 발송 페이지
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                  <Button className={classes.button} onClick={this.handleSubmit}>
                    <Typography className={classes.typoSend}>발송</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          {this.state.notiSettings && this.state.notifications
          ?
          <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <div>
              {/* <form className="UploadPhoto" onSubmit={this.handleSubmit}> */}
              {/*
                <TextField className={classes.textfieldTitle}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldInputSize,
                    },
                  }}
                  label="제목" type="text" name="title" value={title} onChange={this.handleValueChange} autoComplete="off"
                />
                <br/>
                */}
                <div>
                  <label className={classes.Textarea__label} htmlFor="title">
                    제목 작성
                  </label>
                  <textarea
                    id="title"
                    name="title"
                    value={title}
                    onChange={this.handleValueChange}
                    placeholder="제목을 작성해주세요."
                    className={classes.Textarea__title}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <label className={classes.Textarea__label} htmlFor="body">
                    내용 작성
                  </label>
                  <textarea
                    id="body"
                    name="body"
                    value={body}
                    onChange={this.handleValueChange}
                    placeholder="내용을 작성해주세요."
                    className={classes.Textarea__body}
                    autoComplete="off"
                  />
                </div>
                {this.state.isLoading 
                  ?
                  <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                    <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                  </Grid>
                  :
                  <div />
                }
                {/*
                <InputFile
                  className={classes.UploadPhoto__file}
                  name="Search file"
                  label="업로드 파일"
                  fileName={isCompressing ? 'Compressing image...' : fileName}
                  onChange={this.handleFileChange}
                  err={warningMessage}
                  accept=".png, .jpg, .jpeg, .mp3, .mp4"
                  required
                />
                */}
                <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
                <DialogTitle>글 작성을 위해서는 닉네임이 필요합니다.</DialogTitle>
                  <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.handleGoToPage}>사용자 페이지로 가기</Button>
                  </DialogActions>
                </Dialog>
                {/*
                <Input
                  className="UploadPhoto__location"
                  name="location"
                  label="Location"
                  value={location}
                  onChange={this.handleValueChange}
                  placeholder="Where did you take this photo?"
                  required
                />
                */}
                {/*}
                <Button
                  className="UploadPhoto__upload"
                  title="등록"
                  icon="loading.png"
                  type="submit"
                />
              */}
              {/* </form> */}
              {/*{this.props.children}*/}
            </div>
          </main>
          :
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          }
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Divider/>
            <Toolbar>
              <Grid container spacing={5} direction="row" justify="flex-start" alignItems="center">
                <Grid item sm={10} md={10} lg={10} xl={10}>
                  <div>
                    <input
                      className={classes.UploadPhoto__file}
                      id="uploadPhoto"
                      type="file"
                      name="Search file"
                      onChange={this.handleFileChange}
                      accept=".png, .jpg, .jpeg"
                      required
                    />
                    <label htmlFor="uploadPhoto">
                      {tempNotificationImage === null
                      ?
                      <AddPhotoAlternateIcon className={classes.addPhotoIcon}/>
                      :
                      <CardMedia
                        className={classes.media}
                        image={tempNotificationImage}
                        aria-label="Recipe" 
                      >
                      </CardMedia>
                      }
                    </label>
                  </div>
                </Grid>
                <Grid item sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    //className={classes.commentIconButton}
                    color="primary"
                    aria-label="back"
                    onClick={this.handleCancelPhoto}
                  >
                    <span>사진취소</span>
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //address: state.auth.address,
  //uploadContents: state.upload.uploadContents,
  // currentUser: state.auth.currentUser,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  //uploadPhoto: (file, fileName, location, caption) =>
    //dispatch(photoActions.uploadPhoto(file, fileName, location, caption)),
    //notupload: () => dispatch(uploadActions.notupload()), // 현재 사용 안함. 나중에 지우기
    //uploadcontents: () => dispatch(uploadActions.uploadcontents()),
})

NotiSending_Admin = withStyles(styles, {withTheme: true})(NotiSending_Admin)
export default connect(mapStateToProps, mapDispatchToProps)(NotiSending_Admin)