import React, { Component } from 'react'
import { connect } from 'react-redux'
//import { isValidPrivateKey } from 'utils/crypto'
import { withStyles } from '@material-ui/core/styles';
import GoogleLoginButton from './GoogleLoginButton'
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth, API } from "aws-amplify";
import Amplify from "aws-amplify";
//import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
//import awsConfig from '../../aws-exports';

import * as authActions from 'redux/actions/auth'

// 라우터 관련
import { withRouter } from 'react-router-dom';

//import { GoogleLogin } from 'react-google-login';

// import './FacebookButton.scss'

const styles = theme => ({
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
});

/*
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(',');

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);
*/

class GoogleButton extends Component {
  constructor(props) {
    super(props);

    this.signGoogleIn = this.signGoogleIn.bind(this);
    
    this.state = {
      email: '',
      warningMessage: '',
      isLoading: true,
      completed : 0,
    }
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  componentDidMount = async event => {
    this._isMounted = true;

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    this.setState({ isLoading: false });

    /*
    // Google 로그인관련 JS SDK 로드
    const ga = window.gapi && window.gapi.auth2 ? 
      window.gapi.auth2.getAuthInstance() : 
      null;
    if (!ga) this.loadGoogleSDK();
    */

    /*
    try {
      if (this.isAndroid()) {
        const receiver = document;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data) 
          //alert('googleLogin1: ' + JSON.stringify(event))
          if (event.type == 'googleLogin') {
            //alert('googleLogin_android: ' + JSON.stringify(event.type))

            this.getExpo_AWSCredentials(event.message);
            userHasAuthenticatedTrue();
          }
          // expo에서 받은 데이터로 구글 로그인 진행
  
        })
      }
    } catch(e) {
      ;
    }

    try {
      if (this.isIOS()) {
        const receiver = window;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data)
          //alert('googleLogin3: ' + JSON.stringify(event))
          if (event.type == 'googleLogin') {
            alert('googleLogin_ios: ' + JSON.stringify(event.type))
          }
        })
      } 
    } catch(e) {
      ;
    }
    */
  }

  isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  signGoogleIn() {
    const { userHasAuthenticatedTrue } = this.props

    try {
      // android가 아니고 ios가 아니면 web 로그인 진행
      if (!this.isAndroid() && !this.isIOS()) {
        //alert("web login")
        //console.log("web login")
        
        const ga = window.gapi.auth2.getAuthInstance();
        ga.signIn().then(
            googleUser => {
                this.getAWSCredentials(googleUser);
                userHasAuthenticatedTrue();
            },
            error => {
                console.log(error);
            }
        );

        //Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })
        //Auth.federatedSignIn({ provider: 'Google' });

        /*
        .then(
          this.getUserInfo()
        );
        */
      }

      // android 또는 ios 이면 로그인 진행
      if (this.isAndroid() || this.isIOS()) {
        //alert('Mobile login');
        try{
          localStorage.setItem("mobileGoogleLoginStart", 'True');

          window.ReactNativeWebView.postMessage(JSON.stringify({ type:'GL' }))
          /*
          const ga = window.gapi.auth2.getAuthInstance();
          ga.signIn().then(
            googleUser => {
                this.getAWSCredentials(googleUser);
                userHasAuthenticatedTrue();
            },
            error => {
                console.log(error);
            }
          );
          */
        } catch(e) {
          ;
        }
      }
    } catch(e) {
      ;
    }
    
  }
  
  /*
  signGoogleIn = () => {
    window.google.accounts.id.initialize({
      client_id: '236778584592-s76fc4g8o7vla3bdugug3g54g7amum2j.apps.googleusercontent.com',  // 'YOUR_CLIENT_ID'
      callback: this.handleGoogleSignIn,
      ux_mode: 'popup', // Open the Google sign-in flow in a popup
    });
    window.google.accounts.id.prompt();
  };
  */

  async getAWSCredentials(googleUser) {
  //handleGoogleSignIn = async (response) => {
    //console.log("googleUser: ", googleUser)
    const { userHasAuthenticatedTrue } = this.props
    
    const { id_token, expires_at } = googleUser.getAuthResponse();
    const profile = googleUser.getBasicProfile();
    let user = {
        email: profile.getEmail(),
        name: profile.getName()
    };

    localStorage.setItem("email", user.email);

    this.setState({ isLoading: true });

    /*    
    console.log("id_token: ")
    console.log(id_token)
    console.log("expires_at: ")
    console.log(expires_at)
    */

    const credentials = await Auth.federatedSignIn(
        'google',
        { token: id_token, expires_at },
        user
    );

    this.setState({ isLoading: false });
    
    /*
    if (response && response.accessToken) {
      const { accessToken: token, expires_at } = response;
      const { email, name } = response.profileObj;
      let user = {
        email: email,
        name: name,
    };

    localStorage.setItem('email', email);
    // Perform AWS Amplify federated sign-in
    try {
      const credentials = await Auth.federatedSignIn(
        'google',
        { token, expires_at },
        user
      );
      // Handle successful sign-in
      console.log('Federated sign-in successful:', credentials);
    } catch (error) {
      // Handle sign-in error
      console.error('Federated sign-in error:', error);
    }

      this.setState({ isLoading: false });
    }
    */

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: ");
      // console.log(userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: ");
      // console.log(userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

        //console.log("google-currentAuthUserFromSession :")
        //console.log(currentAuthUserFromSession)
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          // alert(e);
          console.log(e)
        }
    }

    try {
      const user = await this.getUser();
  
      const { userId, customerType1, customerType2 } = user;

      localStorage.setItem("customerType1", customerType1);
      localStorage.setItem("customerType2", customerType2);
      
      /*
      const customerType1_FromLocal = localStorage.getItem("customerType1");
      const customerType2_FromLocal = localStorage.getItem("customerType2");
      console.log("Login customerType1_FromLocal :")
      console.log(customerType1_FromLocal)
      console.log("Login customerType2_FromLocal :")
      console.log(customerType2_FromLocal)
      */
       // console.log("구글 로그인 완료")
     } catch(e) {
       console.log(e)
       console.log("구글로그인시 유저 정보 못받아옴")
     }
    
    
    //sessionStorage.setItem("FCMLoginToggle", "true");
    localStorage.setItem("loginType", "google");

    this.props.onLogin(credentials);  // 이 코드를 넣어 줘야 로그인 되면서 페이지 넘어간다.

    //this.props.history.push("/"); // 로그인 후 home 페이지로 이동
  }

  /*
  async getExpo_AWSCredentials(expoGoogleUser) {
    //handleGoogleSignIn = async (response) => {
      //console.log("googleUser: ", googleUser)
      const { userHasAuthenticatedTrue } = this.props
      
      //const { uid, email, displayName, stsTokenManager, id_token } = expoGoogleUser;
      const { email, displayName, accessToken, expirationTime, savedIdToken } = expoGoogleUser;

      //alert('googleLogin_android_expoGoogleUser: ' + JSON.stringify(expoGoogleUser))
      alert('googleLogin_android_email_googlebutton: ' + JSON.stringify(email))
      //alert('googleLogin_android_displayName: ' + JSON.stringify(displayName))
      //alert('googleLogin_android_stsTokenManager: ' + JSON.stringify(stsTokenManager))
      //alert('googleLogin_android_stsTokenManager.accessToken: ' + JSON.stringify(stsTokenManager.accessToken))
      //alert('googleLogin_android_stsTokenManager.expirationTime: ' + JSON.stringify(stsTokenManager.expirationTime))
      //alert('googleLogin_android_id_token: ' + JSON.stringify(id_token))
      //alert('googleLogin_android_expirationTime: ' + JSON.stringify(expirationTime))
      //alert('googleLogin_android_accessToken: ' + JSON.stringify(accessToken))
      alert('googleLogin_android_savedIdToken: ' + JSON.stringify(savedIdToken))

      const currentTime = Date.now();
      const expiresIn = expirationTime - currentTime;

      let user = {
          email: email,
          name: displayName
      };
  
      localStorage.setItem("email", email);
  
      this.setState({ isLoading: true });
      
      const credentials = await Auth.federatedSignIn(
          'google',
          //{ token: stsTokenManager.accessToken, expires_at: expirationTime },
          //{ token: savedIdToken, expires_at: currentTime + expiresIn },
          { token: savedIdToken, expires_at: currentTime + expirationTime },
          user
      );

      alert('googleLogin_android_credentials: ' + JSON.stringify(credentials))
  
      this.setState({ isLoading: false });
      
      try {
        const userID = await Auth.currentAuthenticatedUser();
        // console.log("userID: ");
        // console.log(userID);
        const userID2 = await Auth.currentUserInfo(userID);
        // console.log("userID2: ");
        // console.log(userID2);
        if(userID2 === null) {
          const userID3 = JSON.stringify(userID);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          expocurrentuser = sessionStorage.setItem("currentUser", userID6);
          alert('expocurrentuser: ' + JSON.stringify(expocurrentuser))
  
          userHasAuthenticatedTrue();
        } else if (userID2 != null) {
          const userID3 = JSON.stringify(userID2);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          expocurrentuser = sessionStorage.setItem("currentUser", userID6);
          alert('expocurrentuser: ' + JSON.stringify(expocurrentuser))
  
          //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
  
          //console.log("google-currentAuthUserFromSession :")
          //console.log(currentAuthUserFromSession)
          userHasAuthenticatedTrue();
        }
      } catch (e) {
          if (e !== "not authenticated") {
            // alert(e);
            alert('not authenticated: ' + JSON.stringify(e))
            console.log(e)
          }
      }
  
      try {
        const user = await this.getUser();
    
        const { userId, customerType1, customerType2 } = user;
  
        localStorage.setItem("customerType1", customerType1);
        localStorage.setItem("customerType2", customerType2);
        
        /
        const customerType1_FromLocal = localStorage.getItem("customerType1");
        const customerType2_FromLocal = localStorage.getItem("customerType2");
        console.log("Login customerType1_FromLocal :")
        console.log(customerType1_FromLocal)
        console.log("Login customerType2_FromLocal :")
        console.log(customerType2_FromLocal)
        /
         // console.log("구글 로그인 완료")
       } catch(e) {
         console.log(e)
         console.log("구글로그인시 유저 정보 못받아옴")
       }
      
      
      //sessionStorage.setItem("FCMLoginToggle", "true");
      localStorage.setItem("loginType", "google");
  
      this.props.onLogin(credentials);  // 이 코드를 넣어 줘야 로그인 되면서 페이지 넘어간다.
  
      // this.props.history.push("/"); // 로그인 후 home 페이지로 이동
    }
  */

  /*
  loadGoogleSDK() {
    // load the Google SDK
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.async = true;
    script.onload = this.initGapi;
    document.body.appendChild(script);
  }

  initGapi() {
    // init the Google SDK client
    const g = window.gapi;
    g.load('auth2', function() {
        g.auth2.init({
            client_id: config.social.GL,
            // authorized scopes
            scope: 'profile email openid'
        });
    });
  }
  */

  render() {
    const { classes } = this.props
    const { warningMessage } = this.state
    return (
      /*<div className="LoginForm">*/
      <div>
        {this.state.isLoading 
        ?
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
        </Grid>
        :
        <GoogleLoginButton
          title="구글 로그인"
          disabled={this.state.isLoading}
          onClick={this.signGoogleIn}
        />
        /*
        <GoogleLogin
          clientId="236778584592-s76fc4g8o7vla3bdugug3g54g7amum2j.apps.googleusercontent.com" //"YOUR_CLIENT_ID"
          buttonText="Sign in with Google"
          onSuccess={this.handleGoogleSignIn}
          onFailure={this.handleGoogleSignIn}
          cookiePolicy='cookiePolicy'
          uxMode='redirect'
          isSignedIn={true}
          redirectUri='Redirect Uri'
        />
        */
        }
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //email: state.auth.email,
  //password: state.auth.password,
})

const mapDispatchToProps = (dispatch) => ({
  //awslogin: () => dispatch(authActions.awslogin()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

GoogleButton = withStyles(styles, {withTheme: true})(GoogleButton);
GoogleButton = connect(mapStateToProps, mapDispatchToProps)(GoogleButton);
export default withRouter(GoogleButton)