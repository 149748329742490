import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import moment from 'moment';
import Button from '@material-ui/core/Button';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { connect } from 'react-redux'

// S3 관련
import { API ,Storage } from "aws-amplify";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "0px",
    // maxWidth: 650,
    // minWidth : 500,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  list: {
    width: '100%',
    padding: '0',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  media: {
    marginTop: 5,
    height: 50,
    width: 130,
    objectFit: 'contain'
  },
  notiAvatar: {
    backgroundColor: '#D3D3D3',
    margin: 5,
    width: 50,
    height: 50,
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemContent: {
    paddingTop: 2,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
  // background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoName: {
    width: '100%',
    fontSize : 15,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 20
  },
  typoContentTitle: {
    width: '100%',
    fontSize : 15,
    paddingTop: 0,
    paddingBottom: 0,
    //paddingLeft: 30
  },
  typoContentBody: {
    width: '100%',
    fontSize : 14,
    paddingTop: 0,
    paddingBottom: 0,
    //paddingLeft: 30
  },
  typoDate: {
    width: '100%',
    fontSize : 13,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 30
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoButton: {
    fontSize: 13,
  }
});

class Feed_NotiGetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      completed : 0,
      notiAvartarAttachmentURL: null,
      image: "",
      dialogOpen : false, // 다이얼로그 창이 열려 있는지 확인하는 변수
      comment: '',
    };
  }

  async componentDidMount() {
    // console.log("this.props.notiDate :" + this.props.notiDate);
    /*
    // 시간차이 구하기(초 단위)
    const t1 = moment(this.props.notiDate, "YYYY-MM-DD HH:mm");  // DB에 저장되어 있는 notiList의 시간
    const t2 = moment();  // 현재 시간
    console.log('밀리세컨즈 차이: ', moment.duration(t2.diff(t1)).asMilliseconds());
    */

    /*
    // 시간차이 구하기(최근 클릭한 시간 기준. 초 단위)
    const t0 = moment();  // 현재 시간
    //console.log('현재 시간: ', t0.format("YYYY-MM-DD HH:mm"));
    const t1 = moment(this.props.notiDate, "YYYY-MM-DD HH:mm");  // DB에 저장되어 있는 notiList의 시간
    const t2 = moment("2019-11-18 23:28", "YYYY-MM-DD HH:mm");  // 마지막 클릭한 시간
    //console.log('밀리세컨즈 차이: ', moment.duration(t2.diff(t1)).asMilliseconds());
    // 값이 - 이면 클릭한 이후에 된거라서 Badge에 표시 해주면 됨.
    // 값이 + 이면 이미 읽은 것으로 표시해주면 됨.
    */

    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    // Avatar image 관련
    try {
      let notiAvartarAttachmentURL;
      const notiAvatarimage = this.props.notiAvatarimage;

      if (notiAvatarimage != 'No_File') {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
        notiAvartarAttachmentURL = await Storage.get(notiAvatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }

      //  console.log(this.props.avatarimage);
    
      this.setState({
        notiAvartarAttachmentURL,
        notiAvatarimage,
      });
    } catch (e) {
      alert(e);
    }

    // 첨부된 이미지 관련. 이미지가 첨부 안되었으면 'No_File'로 처리 되었다.
    if(this.props.image != 'No_File') {
      // AWS S3 사용시 (comment avatar image 관련)
      try {
        let notiAttachmentURL;
        const notiImage = this.props.notiImage;

        if (notiImage) {
          // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
          notiAttachmentURL = await Storage.get(notiImage, { level: 'public' });  // Public하게 볼 수 있는거
        }

        //  onsole.log(this.props.avatarimage);
      
        this.setState({
          notiAttachmentURL,
          notiImage,
        });
        // console.log(this.state.attachmentURL);
      } catch (e) {
        alert(e);
      }
    }
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      dialogOpen : false,
    })
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        <Card className={classes.card}>
          <List className={classes.list}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
              <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                <ListItem className={classes.listItemTitle}>
                  {this.state.notiAvartarAttachmentURL
                  ?
                    this.props.customerType === 'company'
                    ?
                    <img
                      className={classes.media}
                      src={this.state.notiAvartarAttachmentURL}
                    />
                    :
                    <Avatar src={this.state.notiAvartarAttachmentURL} aria-label="Recipe" className={classes.notiAvatar}>
                      <AccountCircleIcon style={{fontSize: '85px'}}/>
                    </Avatar>
                    :
                    <Avatar aria-label="Recipe" className={classes.notiAvatar}>
                      <AccountCircleIcon style={{fontSize: '85px'}}/>
                    </Avatar>
                  }
                </ListItem>
              </Grid>
              <Grid item xs={8} sm={8} md={9} lg={9} xl={9}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemContent}>
                      {/*<Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>*/}
                        <Typography className={classes.typoName}>
                          {this.props.notiName}
                        </Typography>
                      {/*</Link>*/}
                    </ListItem>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContentTitle}>
                        제목: &nbsp;
                        {this.props.notiTitle}
                      </Typography>
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ListItem className={classes.listItemContent}>
                  <Typography className={classes.typoContentBody}>
                    내용: &nbsp;
                    {this.props.notiBody} {/*{message}*/}
                  </Typography>
                </ListItem>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ListItem className={classes.listItemContent}>
                  {/*<Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>*/}
                    <Typography className={classes.typoDate}>
                      {moment(this.props.notiDate).format("YYYY-MM-DD")}
                    </Typography>
                  {/*</Link>*/}
                </ListItem>
              </Grid>
            </Grid>
            {/*
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ListItem className={classes.listItemContent}>
                  <Button className={classes.button}>
                    <Typography className={classes.typoButton}>
                      할인권 다운 받기
                    </Typography>
                  </Button>
                </ListItem>
              </Grid>
            </Grid>
            */}
          </List>
        </Card>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

Feed_NotiGetting = withStyles(styles, {withTheme: true})(Feed_NotiGetting)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_NotiGetting);