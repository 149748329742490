import React from 'react';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red, yellow } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TextField } from "@material-ui/core";
import moment from 'moment';

/*
// '좋아요' 관련 Icon
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
*/

import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

// 동영상 관련
import ReactPlayer from 'react-player'

// MoreVertIcon 관련
import PopupState, { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

// S3 관련
import { API, Storage } from "aws-amplify";
import config from "../config";
import { s3DeletePublic } from "../libs/awsLib";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  GridContainer: {
    minHeight: "10vh"
  },
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "10px",
    // maxWidth: 650,
    // minWidth : 500,
    [theme.breakpoints.up('xs')]: { // xs보다 up일때 동작
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(1),
      marginRight : theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: { // xs 보다 down 일때 동작
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
      marginLeft : theme.spacing(0),
      marginRight : theme.spacing(0),
    },
  },
  media: {
    marginTop: 5,
    height: 50,
    width: 130,
    objectFit: 'contain'
  },
  typoName: {
    width: '100%',
    fontSize : 15,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20
  },
  typoContent: {
    width: '100%',
    fontSize : 15,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20
  },
  typoDate: {
    width: '100%',
    fontSize : 14,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20
  },
  typoAddress: {
    width: '100%',
    fontSize : 14,
    color: '#999',
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 20
  },
  avatar: {
    // backgroundColor: red[500],
    marginTop: 5,
    backgroundColor: '#D3D3D3',
    margin: 10,
    width: 70,
    height: 70,
  },
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoButton: {
    fontSize : 13,
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  MoreVertIconRoot: {
    position: 'relative',
  },
  MoreVertIconContent: {
    padding: theme.spacing(0),
  },
  popupStateTypography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  popupStateMenu: {
    padding : 0,
  },
  /*
  starBorderIconButton: {
    padding: 0,
  },
  starIconButton: {
    padding: 0,
  },
  starBorderIcon: {
    width: 25,
    height: 25,
  },
  starIcon: {
    width: 25,
    height: 25,
    color: '#FFD700'  // gold
  },
  */
  dialogTitle: {
    fontSize: 13
  },
  dialogContent: {
    fontSize: 12
  },
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldLabelSize:{
    fontSize:15
  },
  textFieldInputSize:{
    fontSize:15
  },
});

class Feed_FieldForm_Company extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // expanded : false,  // extension 기능
      avatarAttachmentURL: null,
      attachmentURLs: [],
      attachmentURL_1: null,
      avatarimage: "",
      images: [],
      image_1: "",
      MoreVertIconOpen: false,
      deleteDialogOpen : false, // 다이얼로그 창이 열려 있는지 확인하는 변수
      submitDialogOpen: false,
      //applyDialogOpen: false,
      customerName: '',
      address_2: '',
      address_3: '',
      alarmDialogOpen_1: false,
      fcmTokenIds: [], // 여러명한테 FCM 발송시 시도
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  postFavorite(favoriteThumb) {
    return API.post("upload", "/favorite", {
      body: favoriteThumb
    });
  }
  
  /*
  deleteFavorite() {
    return API.del("upload", `/favorite/${this.props.favoriteUUId}`);
  }
  */

  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }

  deleteDialog = async event => {
    event.preventDefault();

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.userId === currentAuthUserFromSession) {
      this.setState({
        deleteDialogOpen : true,
      });
    }
  }

  /*
  handleThumbsUpButton = async event => {
    event.preventDefault();
    const { favoriteloadingtrue, favoriteloadingfalse } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.favoriteContent === '1') {
      if(currentAuthUserFromSession === this.props.favoriteUserId) {
        favoriteloadingtrue();
        try {
          await this.deleteFavorite();
          favoriteloadingfalse();
          
          await this.putPoint({
            pointEvent: "favoriteCancel",
          });
        } catch (e) {
          alert(e);
          favoriteloadingfalse();
        }
      }
    } else {
      favoriteloadingtrue();
      try {
        await this.postFavorite({
          uploadUUId: this.props.uploadUUId,
          favoriteContent: '1',
        });
        favoriteloadingfalse();

        await this.putPoint({
          pointEvent: "favorite",
        });
      } catch (e) {
        alert(e);
        favoriteloadingfalse();
      }
    }
  }
  */

  async componentDidMount() {
    // AWS S3 사용시 (user 관련 파일)
    try {
      let avatarAttachmentURL;
      const avatarimage = this.props.avatarimage;

      if (avatarimage != 'No_File') {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
          avatarAttachmentURL = await Storage.get(avatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }

      //  console.log(this.props.avatarimage);
      
      this.setState({
        avatarAttachmentURL,
        avatarimage,
      });
      // console.log(this.state.attachmentURL);
    } catch (e) {
      alert(e);
    }

    if(this.props.customerType2 === 'company') {
      this.setState({customerName: this.props.companyName})
    } else if(this.props.customerType2 === 'freelancer') {
      this.setState({customerName: this.props.name})
    }

    // 주소 정보 받아오기
    this.setState({
      address_2: this.props.address[2],
      address_3: this.props.address[3],
    })
  }

  render() {
    const { classes, theme } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentTapLink = localStorage.getItem("currentTapLink");

    return (
      <Grid container item xs={12} sm={6} md={4} lg={4} xl={4}>   {/* 한줄에 3개씩 할 때 사용 */}
      {/*<Grid item xs={12} sm={12} md={12} lg={8} xl={8} align="center">*/} {/* 세로로 정렬시 사용 */}
        <Card className={classes.card}>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="flex-end">
            <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
              {this.props.customerType2 === 'company'
              ?
              <Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>
                {this.state.avatarAttachmentURL
                ?
                <img
                  className={classes.media}
                  src={this.state.avatarAttachmentURL}
                />
                :
                <Avatar aria-label="Recipe" className={classes.avatar}>
                  <AccountCircleIcon style={{fontSize: '85px'}}/>
                </Avatar>
                }
              </Link>
              :
              <Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>
              {this.state.avatarAttachmentURL
              ?
              <Avatar src={this.state.avatarAttachmentURL} aria-label="Recipe" className={classes.avatar}>
                <AccountCircleIcon style={{fontSize: '85px'}}/>
              </Avatar>
              :
              <Avatar aria-label="Recipe" className={classes.avatar}>
                <AccountCircleIcon style={{fontSize: '85px'}}/>
              </Avatar>
              }
            </Link>
            }
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoName}>
                    {this.state.customerName}
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoName}>
                    {this.state.customerName}
                  </Typography>
                </Link>
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                ?
                <Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoContent}>
                    {/* 글자 길이가 37개 이상 넘어가면 '...' 나옴 */}
                    {this.props.history.slice(0,37)}
                    {this.props.history.length > 37 
                    ? '...'
                    : null
                    }
                  </Typography>
                </Link>
                :
                <Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>
                  <Typography className={classes.typoContent}>
                    {/* 글자 길이가 37개 이상 넘어가면 '...' 나옴 */}
                    {this.props.history.slice(0,37)}
                    {this.props.history.length > 37 
                    ? '...'
                    : null
                    }
                  </Typography>
                </Link>
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                {this.props.customerType2 === 'company'
                  ?
                  <Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>
                    <Typography className={classes.typoAddress}>
                       {this.state.address_2} {this.state.address_3}
                    </Typography>
                  </Link>
                  :
                  <Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>
                    <Typography className={classes.typoAddress}>
                       {this.state.address_2} {this.state.address_3}
                    </Typography>
                </Link>
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  // isFavoriteLoading: state.upload.isFavoriteLoading,
  isApplyingLoading: state.upload.isApplyingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  /*
  favoriteloadingtrue: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingtrue(isFavoriteLoading)),
  favoriteloadingfalse: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingfalse(isFavoriteLoading)),
  */
  applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
})

Feed_FieldForm_Company = withStyles(styles, {withTheme: true})(Feed_FieldForm_Company)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_FieldForm_Company);