import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from "@material-ui/core/CircularProgress";
import imageCompression from 'utils/imageCompression';
import Divider from '@material-ui/core/Divider';
import { red } from '@material-ui/core/colors';
import { TextField } from "@material-ui/core";

// S3 관련
import { API, Storage, Auth } from "aws-amplify";
import config from "../config";
import { s3UploadPublic } from "../libs/awsLib";
import { s3DeletePublic } from "../libs/awsLib";

import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';

// 다음 주소 api
import DaumPostcode from 'react-daum-postcode';

import SVGIcon from './SVGIcon'

import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(12),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  title: {
    flexGrow: 1,
    fontSize : 18,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '40px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  button: {
    width: 150,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  dialogButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  buttonChecked: {
    color: '#0c93d0',
    '&$checked': {
      color: '#0c93d0',
    },
  },
  buttonLine: {
    color: '#0c93d0',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  typoSubmit: {
    fontSize: 18
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  card2: {
    maxWidth: 200,
    marginTop: 5,
    marginBottom: 15,
    boxShadow: "none",
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 50,
  },
  listItemIcon: {
    minWidth: 0
  },
  typoTitle: {
    width: '100%',
    fontSize : 18,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  cardHeader: {
    width: '100%',
    paddingRight: 0,
  },
  textField : {
    width: '100%',
    fontSize: 18,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '20px',
    paddingRight: '24px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
  },
  textFieldSize: {
    fontSize: 18,
  },
  // PC버전에서는 fontSize가 18, 모바일은 13
  typoItemSelect: {
    fontSize: 13,
    paddingTop: '0%',
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  typoTerms: {
    width: '100%',
    fontSize: 18,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '0px',
    paddingRight: '24px',
  },
  avatarMedia: {
    height: 80,
    width: "100%",
    objectFit: 'contain'
    // paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    //backgroundColor: red[500],
    backgroundColor: '#D3D3D3',
    margin: 0,
    width: 80,
    height: 80,
  },
  avatarIconButton: {
    padding: 0,
  },
  addIcon: {
    width: 50,
    height: 50,
  },
  UploadPhoto__file: {
    //visibility: 'hidden'
    display: 'none'
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed',
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  bottomAppBar: {
    top: 'auto',
    //bottom: 0,
    bottom: 100,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  bottomToolBar: {
    height : 10,
  },
  UploadPhoto__file: {
    //visibility: 'hidden'
    display: 'none'
  },
  addPhotoIcon: {
    width: 50,
    height: 50,
  },
  media: {
    width: 50,
    height: 50,
  },
  appbarBottomMedia: {
    width: 50,
    height: 50,
  },
});

const MAX_IMAGE_SIZE = 30000 // 30KB
const MAX_IMAGE_SIZE_MB = 0.03 // 30KB

class MyPage_Company extends Component {

  file = [];
  fileArray = [];

  constructor(props) {
    super(props);

    this.avatarFile = null;
    this.file_1 = null;

    this.state = {
      userContent: '',
      file: '',
      fileName: '',
      warningMessage: '',
      isCompressing: false,
      user: null,
      userId: "",
      avatarAttachmentURL: null,
      customerType1: "",
      customerType2: "",
      walletAddress: "",
      companyName: "",
      avatarimage: "",
      tempAvatarImage: null,
      ceoName: '',
      companyNumber: '',
      companyPhoneNumber: '',
      companyFaxNumber: '',
      zoneCode: '',
      address_1: '',
      address_2: '',
      address_3: '',
      address_4: '',
      address: [],
      homepage: '',
      history: '',
      name: '',
      position: '',
      phoneNumber: '',
      email: '',
      adminConfirm: "",
      level: '',
      isLoading: false,
      postCodeDialogOpen: false,
      checkedMain: [],
      checkedSub: [],
      checked_0_0: false,
      checked_0_1: false,
      checked_0_2: false,
      checked_0_3: false,
      checked_1_0: false,
      checked_1_1: false,
      checked_1_2: false,
      checked_1_3: false,
      checked_1_4: false,
      checked_1_5: false,
      checked_2_0: false,
      checked_2_1: false,
      checked_2_2: false,
      checked_2_3: false,
      checked_2_4: false,
      checked_3_0: false,
      checked_3_1: false,
      checked_3_2: false,
      checked_3_3: false,
      checked_3_4: false,
      checked_3_5: false,
      checked_4_0: false,
      checked_4_1: false,
      checked_4_2: false,
      checked_4_3: false,
      checked_4_4: false,
      checked_4_5: false,
      checked_4_6: false,
      checked_4_7: false,
      checked_5_0: false,
      checked_5_1: false,
      checked_5_2: false,
      checked_5_3: false,
      checked_5_4: false,
      checked_5_5: false,
      checked_5_6: false,
      checked_5_7: false,
      checked_5_8: false,
      checked_6_0: false,
      checked_6_1: false,
      checked_6_2: false,
      checked_6_3: false,
      checked_6_4: false,
      checked_7_0: false,
      checked_7_1: false,
      checked_7_2: false,
      checked_7_3: false,
      checked_7_4: false,
      checked_7_5: false,
      checked_8_0: false,
      checked_8_1: false,
      checked_8_2: false,
      checked_8_3: false,
      checked_8_4: false,
      checked_8_5: false,
      checked_9_0: false,
      checkedTerms: [],
      checkedTerms_all: false,
      checkedTerms_0: false,
      checkedTerms_1: false,
      checkedTerms_2: false,
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
      alarmDialogOpen_5: false,
      alarmDialogOpen_6: false,
      alarmDialogOpen_7: false,
      alarmDialogOpen_8: false,
      alarmDialogOpen_9: false,
      alarmDialogOpen_10: false,
      alarmDialogOpen_11: false,
      alarmDialogOpen_12: false,
      completed : 0,
      attachmentURLs: [],
      images: [],
      tempUploadImage: false,
      tempS3ImageURLs: [],  // S3에서 받은 이미지를 임시로 저장(Cancel 버튼을 위해서)
      tempS3ImageToggle: true,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    // const { currentUser } = this.props
    // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const customerType1_FromLocal = localStorage.getItem("customerType1");
    const customerType2_FromLocal = localStorage.getItem("customerType2");
    const email_FromLocal = localStorage.getItem("email");
    const loginType = localStorage.getItem("loginType");
    localStorage.setItem('currentPage', "mypage_company")

    /*
    if(loginType != "email") {
      this.props.history.push("/");
    }
    */

    if(customerType2_FromLocal != "company") {
      this.props.history.push("/");
    }

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    try {
      let avatarAttachmentURL;
      let attachmentURLs = [];

      const user = await this.getUser();

      const { userId, customerType1, customerType2, walletAddress, companyName, avatarimage, ceoName, companyNumber, 
              companyPhoneNumber, companyFaxNumber, zoneCode, address, homepage, history, 
              name, position, phoneNumber, email, checkedMain, checkedSub, checkedTerms, images, adminConfirm, level, } = user;

      this.setState({userId});

      if(this.state.userId === currentAuthUserFromSession && customerType2_FromLocal === "company")
      {
        if (avatarimage != 'No_File') {
          avatarAttachmentURL = await Storage.get(avatarimage);
        }

        for(let i=0;i<images.length;i++) {
          if (images[i] != 'No_File') {
              attachmentURLs[i] = await Storage.get(images[i], {level: 'public'});
          } else if (images[i] === 'No_File') {
            attachmentURLs[i] = null
          }

          this.setState({
            tempS3ImageURLs: this.state.tempS3ImageURLs.concat(attachmentURLs[i]),
            tempS3ImageToggle: true,
            attachmentURLs: this.state.attachmentURLs.concat(attachmentURLs[i]),
            images: this.state.images.concat(images[i]),
          });
        }

        this.setState({
          user,
          avatarAttachmentURL,
          customerType1, customerType2, walletAddress, companyName, avatarimage, ceoName, companyNumber, 
          companyPhoneNumber, companyFaxNumber, zoneCode, address, homepage, history, 
          name, position, phoneNumber, email, checkedMain, checkedSub, checkedTerms, adminConfirm, level,
          expanded : true,
        });

        this.setState({
          address_1: this.state.address[0],
          address_2: this.state.address[1],
          address_3: this.state.address[2],
          address_4: this.state.address[3]
        })

        // DB에 문자열로 저장 되어 있는 값을 boolean 값으로 바꿔준다.
        this.setState({
          checkedTerms_0: this.state.checkedTerms[0].toLowerCase() == 'true' ? true : false,
          checkedTerms_1: this.state.checkedTerms[1].toLowerCase() == 'true' ? true : false,
          checkedTerms_2: this.state.checkedTerms[2].toLowerCase() == 'true' ? true : false,
        })

        if (this.state.checkedTerms_0 === true && this.state.checkedTerms_1 === true && this.state.checkedTerms_2 === true) {
          this.setState({checkedTerms_all: true})
        }

        // DB에 문자열로 저장 되어 있는 값을 boolean 값으로 바꿔준다.
        this.setState({
        checked_0_0: this.state.checkedMain[0].toLowerCase() == 'true' ? true : false,
        checked_0_1: this.state.checkedSub[0].toLowerCase() == 'true' ? true : false,
        checked_0_2: this.state.checkedSub[1].toLowerCase() == 'true' ? true : false,
        checked_0_3: this.state.checkedSub[2].toLowerCase() == 'true' ? true : false,
        checked_1_0: this.state.checkedMain[1].toLowerCase() == 'true' ? true : false,
        checked_1_1: this.state.checkedSub[3].toLowerCase() == 'true' ? true : false,
        checked_1_2: this.state.checkedSub[4].toLowerCase() == 'true' ? true : false,
        checked_1_3: this.state.checkedSub[5].toLowerCase() == 'true' ? true : false,
        checked_1_4: this.state.checkedSub[6].toLowerCase() == 'true' ? true : false,
        checked_1_5: this.state.checkedSub[7].toLowerCase() == 'true' ? true : false,
        checked_2_0: this.state.checkedMain[2].toLowerCase() == 'true' ? true : false,
        checked_2_1: this.state.checkedSub[8].toLowerCase() == 'true' ? true : false,
        checked_2_2: this.state.checkedSub[9].toLowerCase() == 'true' ? true : false,
        checked_2_3: this.state.checkedSub[10].toLowerCase() == 'true' ? true : false,
        checked_2_4: this.state.checkedSub[11].toLowerCase() == 'true' ? true : false,
        checked_3_0: this.state.checkedMain[3].toLowerCase() == 'true' ? true : false,
        checked_3_1: this.state.checkedSub[12].toLowerCase() == 'true' ? true : false,
        checked_3_2: this.state.checkedSub[13].toLowerCase() == 'true' ? true : false,
        checked_3_3: this.state.checkedSub[14].toLowerCase() == 'true' ? true : false,
        checked_3_4: this.state.checkedSub[15].toLowerCase() == 'true' ? true : false,
        checked_3_5: this.state.checkedSub[16].toLowerCase() == 'true' ? true : false,
        checked_4_0: this.state.checkedMain[4].toLowerCase() == 'true' ? true : false,
        checked_4_1: this.state.checkedSub[17].toLowerCase() == 'true' ? true : false,
        checked_4_2: this.state.checkedSub[18].toLowerCase() == 'true' ? true : false,
        checked_4_3: this.state.checkedSub[19].toLowerCase() == 'true' ? true : false,
        checked_4_4: this.state.checkedSub[20].toLowerCase() == 'true' ? true : false,
        checked_4_5: this.state.checkedSub[21].toLowerCase() == 'true' ? true : false,
        checked_4_6: this.state.checkedSub[22].toLowerCase() == 'true' ? true : false,
        checked_4_7: this.state.checkedSub[23].toLowerCase() == 'true' ? true : false,
        checked_5_0: this.state.checkedMain[5].toLowerCase() == 'true' ? true : false,
        checked_5_1: this.state.checkedSub[24].toLowerCase() == 'true' ? true : false,
        checked_5_2: this.state.checkedSub[25].toLowerCase() == 'true' ? true : false,
        checked_5_3: this.state.checkedSub[26].toLowerCase() == 'true' ? true : false,
        checked_5_4: this.state.checkedSub[27].toLowerCase() == 'true' ? true : false,
        checked_5_5: this.state.checkedSub[28].toLowerCase() == 'true' ? true : false,
        checked_5_6: this.state.checkedSub[29].toLowerCase() == 'true' ? true : false,
        checked_5_7: this.state.checkedSub[30].toLowerCase() == 'true' ? true : false,
        checked_5_8: this.state.checkedSub[31].toLowerCase() == 'true' ? true : false,
        checked_6_0: this.state.checkedMain[6].toLowerCase() == 'true' ? true : false,
        checked_6_1: this.state.checkedSub[32].toLowerCase() == 'true' ? true : false,
        checked_6_2: this.state.checkedSub[33].toLowerCase() == 'true' ? true : false,
        checked_6_3: this.state.checkedSub[34].toLowerCase() == 'true' ? true : false,
        checked_6_4: this.state.checkedSub[35].toLowerCase() == 'true' ? true : false,
        checked_7_0: this.state.checkedMain[7].toLowerCase() == 'true' ? true : false,
        checked_7_1: this.state.checkedSub[36].toLowerCase() == 'true' ? true : false,
        checked_7_2: this.state.checkedSub[37].toLowerCase() == 'true' ? true : false,
        checked_7_3: this.state.checkedSub[38].toLowerCase() == 'true' ? true : false,
        checked_7_4: this.state.checkedSub[39].toLowerCase() == 'true' ? true : false,
        checked_7_5: this.state.checkedSub[40].toLowerCase() == 'true' ? true : false,
        checked_8_0: this.state.checkedMain[8].toLowerCase() == 'true' ? true : false,
        checked_8_1: this.state.checkedSub[41].toLowerCase() == 'true' ? true : false,
        checked_8_2: this.state.checkedSub[42].toLowerCase() == 'true' ? true : false,
        checked_8_3: this.state.checkedSub[43].toLowerCase() == 'true' ? true : false,
        checked_8_4: this.state.checkedSub[44].toLowerCase() == 'true' ? true : false,
        checked_8_5: this.state.checkedSub[45].toLowerCase() == 'true' ? true : false,
        checked_9_0: this.state.checkedMain[9].toLowerCase() == 'true' ? true : false,
        })

      } else if(this.state.userId && this.state.userId != currentAuthUserFromSession && customerType2_FromLocal != "company")
      {
        // userId가 존재 하면서 currentId와 같지 않으면 home페이지로 리턴
        console.log("currentUser is different");
        this.props.history.push("/");
      } else if(this.state.userId === undefined && loginType != "email") {
        console.log("userId is undefined");

        this.setState({
          alarmDialogOpen_12 : true,
        });

        /*
        //--------------------------------------------------
        // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
        let arrayMain = [];
        let arraySub = [];

        // true, false 값을 문자열로 변환후 저장
        // (쿼리문은 문자열 밖에 검색이 안되기 때문에)
        arrayMain[0] = this.state.checked_0_0.toString();
        arraySub[0] = this.state.checked_0_1.toString();
        arraySub[1] = this.state.checked_0_2.toString();
        arraySub[2] = this.state.checked_0_3.toString();

        arrayMain[1] = this.state.checked_1_0.toString();
        arraySub[3] = this.state.checked_1_1.toString();
        arraySub[4] = this.state.checked_1_2.toString();
        arraySub[5] = this.state.checked_1_3.toString();
        arraySub[6] = this.state.checked_1_4.toString();
        arraySub[7] = this.state.checked_1_5.toString();

        arrayMain[2] = this.state.checked_2_0.toString();
        arraySub[8] = this.state.checked_2_1.toString();
        arraySub[9] = this.state.checked_2_2.toString();
        arraySub[10] = this.state.checked_2_3.toString();
        arraySub[11] = this.state.checked_2_4.toString();

        arrayMain[3] = this.state.checked_3_0.toString();
        arraySub[12] = this.state.checked_3_1.toString();
        arraySub[13] = this.state.checked_3_2.toString();
        arraySub[14] = this.state.checked_3_3.toString();
        arraySub[15] = this.state.checked_3_4.toString();
        arraySub[16] = this.state.checked_3_5.toString();

        arrayMain[4] = this.state.checked_4_0.toString();
        arraySub[17] = this.state.checked_4_1.toString();
        arraySub[18] = this.state.checked_4_2.toString();
        arraySub[19] = this.state.checked_4_3.toString();
        arraySub[20] = this.state.checked_4_4.toString();
        arraySub[21] = this.state.checked_4_5.toString();
        arraySub[22] = this.state.checked_4_6.toString();
        arraySub[23] = this.state.checked_4_7.toString();

        arrayMain[5] = this.state.checked_5_0.toString();
        arraySub[24] = this.state.checked_5_1.toString();
        arraySub[25] = this.state.checked_5_2.toString();
        arraySub[26] = this.state.checked_5_3.toString();
        arraySub[27] = this.state.checked_5_4.toString();
        arraySub[28] = this.state.checked_5_5.toString();
        arraySub[29] = this.state.checked_5_6.toString();
        arraySub[30] = this.state.checked_5_7.toString();
        arraySub[31] = this.state.checked_5_8.toString();

        arrayMain[6] = this.state.checked_6_0.toString();
        arraySub[32] = this.state.checked_6_1.toString();
        arraySub[33] = this.state.checked_6_2.toString();
        arraySub[34] = this.state.checked_6_3.toString();
        arraySub[35] = this.state.checked_6_4.toString();

        arrayMain[7] = this.state.checked_7_0.toString();
        arraySub[36] = this.state.checked_7_1.toString();
        arraySub[37] = this.state.checked_7_2.toString();
        arraySub[38] = this.state.checked_7_3.toString();
        arraySub[39] = this.state.checked_7_4.toString();
        arraySub[40] = this.state.checked_7_5.toString();

        arrayMain[8] = this.state.checked_8_0.toString();
        arraySub[41] = this.state.checked_8_1.toString();
        arraySub[42] = this.state.checked_8_2.toString();
        arraySub[43] = this.state.checked_8_3.toString();
        arraySub[44] = this.state.checked_8_4.toString();
        arraySub[45] = this.state.checked_8_5.toString();

        arrayMain[9] = this.state.checked_9_0.toString();

        this.setState({
          checkedMain: arrayMain,
          checkedSub: arraySub,
        });
        //--------------------------------------------------

        let array2 = [];
        array2[0] = "default"
        array2[1] = "default"
        array2[2] = "default"
        array2[3] = "default"
        this.setState({address: array2});
        //--------------------------------------------------

        //--------------------------------------------------
        // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
        let arrayTerms = [];

        // true, false 값을 문자열로 변환후 저장
        // (쿼리문은 문자열 밖에 검색이 안되기 때문에)
        arrayTerms[0] = this.state.checkedTerms_0.toString();
        arrayTerms[1] = this.state.checkedTerms_1.toString();
        arrayTerms[2] = this.state.checkedTerms_2.toString();

        this.setState({
          checkedTerms: arrayTerms,
        });

        //--------------------------------------------------
        let array3 = []
        array3[0] = "No_File" // 1
        array3[1] = "No_File" // 2
        array3[2] = "No_File" // 3
        array3[3] = "No_File" // 4
        array3[4] = "No_File" // 5
        array3[5] = "No_File" // 6
        array3[6] = "No_File" // 7
        array3[7] = "No_File" // 8
        array3[8] = "No_File" // 9
        this.setState({images: array3});
        //--------------------------------------------------
        
        await this.postUser({
          customerType1: customerType1_FromLocal,
          customerType2: customerType2_FromLocal,
          walletAddress: "default",
          companyName: "default", 
          avatarimage: 'No_File', 
          ceoName: "default", 
          companyNumber: "default", 
          companyPhoneNumber: "default", 
          companyFaxNumber: "default",
          zoneCode: "default", 
          address: this.state.address, 
          homepage: "default", 
          history: "default", 
          name: "default", 
          position: "default", 
          phoneNumber: "default", 
          email: email_FromLocal,
          adminConfirm: "false",
          level: '0',
          checkedMain: this.state.checkedMain,
          checkedSub: this.state.checkedSub,
          checkedTerms: this.state.checkedTerms,
          images: this.state.images,
        });
        window.location.reload();
        */
      }
      else if(this.state.userId === null && loginType != "email") {
        console.log("userId is null");

        this.setState({
          alarmDialogOpen_12 : true,
        });

        /*
        //--------------------------------------------------
        // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
        let arrayMain = [];
        let arraySub = [];

        arrayMain[0] = this.state.checked_0_0.toString();
        arraySub[0] = this.state.checked_0_1.toString();
        arraySub[1] = this.state.checked_0_2.toString();
        arraySub[2] = this.state.checked_0_3.toString();

        arrayMain[1] = this.state.checked_1_0.toString();
        arraySub[3] = this.state.checked_1_1.toString();
        arraySub[4] = this.state.checked_1_2.toString();
        arraySub[5] = this.state.checked_1_3.toString();
        arraySub[6] = this.state.checked_1_4.toString();
        arraySub[7] = this.state.checked_1_5.toString();

        arrayMain[2] = this.state.checked_2_0.toString();
        arraySub[8] = this.state.checked_2_1.toString();
        arraySub[9] = this.state.checked_2_2.toString();
        arraySub[10] = this.state.checked_2_3.toString();
        arraySub[11] = this.state.checked_2_4.toString();

        arrayMain[3] = this.state.checked_3_0.toString();
        arraySub[12] = this.state.checked_3_1.toString();
        arraySub[13] = this.state.checked_3_2.toString();
        arraySub[14] = this.state.checked_3_3.toString();
        arraySub[15] = this.state.checked_3_4.toString();
        arraySub[16] = this.state.checked_3_5.toString();

        arrayMain[4] = this.state.checked_4_0.toString();
        arraySub[17] = this.state.checked_4_1.toString();
        arraySub[18] = this.state.checked_4_2.toString();
        arraySub[19] = this.state.checked_4_3.toString();
        arraySub[20] = this.state.checked_4_4.toString();
        arraySub[21] = this.state.checked_4_5.toString();
        arraySub[22] = this.state.checked_4_6.toString();
        arraySub[23] = this.state.checked_4_7.toString();

        arrayMain[5] = this.state.checked_5_0.toString();
        arraySub[24] = this.state.checked_5_1.toString();
        arraySub[25] = this.state.checked_5_2.toString();
        arraySub[26] = this.state.checked_5_3.toString();
        arraySub[27] = this.state.checked_5_4.toString();
        arraySub[28] = this.state.checked_5_5.toString();
        arraySub[29] = this.state.checked_5_6.toString();
        arraySub[30] = this.state.checked_5_7.toString();
        arraySub[31] = this.state.checked_5_8.toString();

        arrayMain[6] = this.state.checked_6_0.toString();
        arraySub[32] = this.state.checked_6_1.toString();
        arraySub[33] = this.state.checked_6_2.toString();
        arraySub[34] = this.state.checked_6_3.toString();
        arraySub[35] = this.state.checked_6_4.toString();

        arrayMain[7] = this.state.checked_7_0.toString();
        arraySub[36] = this.state.checked_7_1.toString();
        arraySub[37] = this.state.checked_7_2.toString();
        arraySub[38] = this.state.checked_7_3.toString();
        arraySub[39] = this.state.checked_7_4.toString();
        arraySub[40] = this.state.checked_7_5.toString();

        arrayMain[8] = this.state.checked_8_0.toString();
        arraySub[41] = this.state.checked_8_1.toString();
        arraySub[42] = this.state.checked_8_2.toString();
        arraySub[43] = this.state.checked_8_3.toString();
        arraySub[44] = this.state.checked_8_4.toString();
        arraySub[45] = this.state.checked_8_5.toString();

        arrayMain[9] = this.state.checked_9_0.toString();

        this.setState({
          checkedMain: arrayMain,
          checkedSub: arraySub,
        });
        //--------------------------------------------------

        let array2 = [];
        array2[0] = "default"
        array2[1] = "default"
        array2[2] = "default"
        array2[3] = "default"
        this.setState({address: array2});
        //--------------------------------------------------

        //--------------------------------------------------
        // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
        let arrayTerms = [];

        // true, false 값을 문자열로 변환후 저장
        // (쿼리문은 문자열 밖에 검색이 안되기 때문에)
        arrayTerms[0] = this.state.checkedTerms_0.toString();
        arrayTerms[1] = this.state.checkedTerms_1.toString();
        arrayTerms[2] = this.state.checkedTerms_2.toString();

        this.setState({
          checkedTerms: arrayTerms,
        });

        //--------------------------------------------------
        let array3 = []
        array3[0] = "No_File" // 1
        array3[1] = "No_File" // 2
        array3[2] = "No_File" // 3
        array3[3] = "No_File" // 4
        array3[4] = "No_File" // 5
        array3[5] = "No_File" // 6
        array3[6] = "No_File" // 7
        array3[7] = "No_File" // 8
        array3[8] = "No_File" // 9
        this.setState({images: array3});
        //--------------------------------------------------

        await this.postUser({
          customerType1: customerType1_FromLocal,
          customerType2: customerType2_FromLocal,
          walletAddress: "default",
          companyName: "default", 
          avatarimage: 'No_File', 
          ceoName: "default", 
          companyNumber: "default", 
          companyPhoneNumber: "default", 
          companyFaxNumber: "default",
          zoneCode: "default", 
          address: this.state.address, 
          homepage: "default", 
          history: "default", 
          name: "default", 
          position: "default", 
          phoneNumber: "default", 
          email: email_FromLocal,
          adminConfirm: "false",
          level: '0',
          checkedMain: this.state.checkedMain,
          checkedSub: this.state.checkedSub,
          checkedTerms: this.state.checkedTerms,
          images: this.state.images,
        });
        window.location.reload();
        */
      } 
    } catch (e) {
      // alert(e);
      console.log("getUser() 에러");
    }

    if(this.state.walletAddress === "default") {
      this.setState({ walletAddress: "" });
    }
    if(this.state.companyName === "default") {
      this.setState({ companyName: "" });
    }
    if(this.state.avatarimage === 'No_File') {
      this.setState({ avatarimage: "" });
    }
    if(this.state.ceoName === "default") {
      this.setState({ ceoName: "" });
    }
    if(this.state.companyNumber === "default") {
      this.setState({ companyNumber: '' });
    }
    if(this.state.companyPhoneNumber === "default") {
      this.setState({ companyPhoneNumber: '' });
    }
    if(this.state.companyFaxNumber === "default") {
      this.setState({ companyFaxNumber: '' });
    }
    if(this.state.zoneCode === "default") {
      this.setState({ zoneCode: '' });
    }
    if(this.state.address[0] === "default") {
      this.setState({ address_1: ''});
    }
    if(this.state.address[1] === "default") {
      this.setState({ address_2: ''});
    }
    if(this.state.address[2] === "default") {
      this.setState({ address_3: ''});
    }
    if(this.state.address[3] === "default") {
      this.setState({ address_4: ''});
    }
    if(this.state.homepage === "default") {
      this.setState({ homepage: "" });
    }
    if(this.state.history === "default") {
      this.setState({ history: "" });
    }
    if(this.state.name === "default") {
      this.setState({ name: "" });
    }
    if(this.state.position === "default") {
      this.setState({ position: "" });
    }
    if(this.state.phoneNumber === "default") {
      this.setState({ phoneNumber: '' });
    }
    if(this.state.email === "default") {
      this.setState({ email: "" });
    }
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const customerType1_FromLocal = localStorage.getItem("customerType1");
    const customerType2_FromLocal = localStorage.getItem("customerType2");
    if(this.props.match.params.id === currentAuthUserFromSession && customerType2_FromLocal === "company") {
      // return API.get("upload", `/user/${this.props.match.params.id}`);
      return API.get("upload", `/user/${currentAuthUserFromSession}`);
    } else {
      console.log("currentUser is different");
      this.props.history.push("/");
    }
  }

  postUser(userContent) {
    return API.post("upload", "/user", {
      body: userContent
    });
  }

  putUser(userContent) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const customerType1_FromLocal = localStorage.getItem("customerType1");
    const customerType2_FromLocal = localStorage.getItem("customerType2");
    if(this.props.match.params.id === currentAuthUserFromSession && customerType2_FromLocal === "company") {
      // return API.put("upload", `/user/${this.props.match.params.id}`, {
      return API.put("upload", `/user/${currentAuthUserFromSession}`, {
        body: userContent
      });
    } else {
      console.log("currentUser is different");
      this.props.history.push("/");
    }
  }

  // 삭제 API
  deleteNotification() {
    // const currentFCMTokenUUIDFromSession = sessionStorage.getItem("currentFCMTokenUUID");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.del("upload", `/notification/${currentFCMTokenFromSession}`);
  }

  // 실제로 데이터가 수정되는 것을 도와주기 위한 함수
  handlePutSubmit = async event => {
    event.preventDefault();
    // const { currentUser } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const customerType1_FromLocal = localStorage.getItem("customerType1");
    const customerType2_FromLocal = localStorage.getItem("customerType2");
    const email_FromLocal = localStorage.getItem("email");

    // 작성 안한 파트 작성하게끔 해주는 코드
    if (!this.state.companyName) {
      this.setState({alarmDialogOpen_1: true});
      return;
    }
    if (!this.state.companyNumber) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }
    if (!this.state.companyPhoneNumber) {
      this.setState({alarmDialogOpen_3: true});
      return;
    }
    if (!this.state.address_1) {
      this.setState({alarmDialogOpen_4: true});
      return;
    }
    if (!this.state.history) {
      this.setState({alarmDialogOpen_5: true});
      return;
    }
    if (!this.state.name) {
      this.setState({alarmDialogOpen_6: true});
      return;
    }
    if (!this.state.phoneNumber) {
      this.setState({alarmDialogOpen_7: true});
      return;
    }
    if (!this.state.email) {
      this.setState({alarmDialogOpen_8: true});
      return;
    }

    //--------------------------------------------------
    // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
    let arrayMain = [];
    let arraySub = [];

    arrayMain[0] = this.state.checked_0_0.toString();
    arraySub[0] = this.state.checked_0_1.toString();
    arraySub[1] = this.state.checked_0_2.toString();
    arraySub[2] = this.state.checked_0_3.toString();

    arrayMain[1] = this.state.checked_1_0.toString();
    arraySub[3] = this.state.checked_1_1.toString();
    arraySub[4] = this.state.checked_1_2.toString();
    arraySub[5] = this.state.checked_1_3.toString();
    arraySub[6] = this.state.checked_1_4.toString();
    arraySub[7] = this.state.checked_1_5.toString();

    arrayMain[2] = this.state.checked_2_0.toString();
    arraySub[8] = this.state.checked_2_1.toString();
    arraySub[9] = this.state.checked_2_2.toString();
    arraySub[10] = this.state.checked_2_3.toString();
    arraySub[11] = this.state.checked_2_4.toString();

    arrayMain[3] = this.state.checked_3_0.toString();
    arraySub[12] = this.state.checked_3_1.toString();
    arraySub[13] = this.state.checked_3_2.toString();
    arraySub[14] = this.state.checked_3_3.toString();
    arraySub[15] = this.state.checked_3_4.toString();
    arraySub[16] = this.state.checked_3_5.toString();

    arrayMain[4] = this.state.checked_4_0.toString();
    arraySub[17] = this.state.checked_4_1.toString();
    arraySub[18] = this.state.checked_4_2.toString();
    arraySub[19] = this.state.checked_4_3.toString();
    arraySub[20] = this.state.checked_4_4.toString();
    arraySub[21] = this.state.checked_4_5.toString();
    arraySub[22] = this.state.checked_4_6.toString();
    arraySub[23] = this.state.checked_4_7.toString();

    arrayMain[5] = this.state.checked_5_0.toString();
    arraySub[24] = this.state.checked_5_1.toString();
    arraySub[25] = this.state.checked_5_2.toString();
    arraySub[26] = this.state.checked_5_3.toString();
    arraySub[27] = this.state.checked_5_4.toString();
    arraySub[28] = this.state.checked_5_5.toString();
    arraySub[29] = this.state.checked_5_6.toString();
    arraySub[30] = this.state.checked_5_7.toString();
    arraySub[31] = this.state.checked_5_8.toString();

    arrayMain[6] = this.state.checked_6_0.toString();
    arraySub[32] = this.state.checked_6_1.toString();
    arraySub[33] = this.state.checked_6_2.toString();
    arraySub[34] = this.state.checked_6_3.toString();
    arraySub[35] = this.state.checked_6_4.toString();

    arrayMain[7] = this.state.checked_7_0.toString();
    arraySub[36] = this.state.checked_7_1.toString();
    arraySub[37] = this.state.checked_7_2.toString();
    arraySub[38] = this.state.checked_7_3.toString();
    arraySub[39] = this.state.checked_7_4.toString();
    arraySub[40] = this.state.checked_7_5.toString();

    arrayMain[8] = this.state.checked_8_0.toString();
    arraySub[41] = this.state.checked_8_1.toString();
    arraySub[42] = this.state.checked_8_2.toString();
    arraySub[43] = this.state.checked_8_3.toString();
    arraySub[44] = this.state.checked_8_4.toString();
    arraySub[45] = this.state.checked_8_5.toString();

    arrayMain[9] = this.state.checked_9_0.toString();

    this.state.checkedMain = arrayMain;
    this.state.checkedSub = arraySub;

    let trueCountNumMain = 0;
    let trueCountNumSub = 0;

    for(let i=0;i<10;i++) {
      if(this.state.checkedMain[i].toLowerCase() == 'true' ? true : false === true) {
        trueCountNumMain = trueCountNumMain + 1
      }
    }

    if (trueCountNumMain === 0) {
      this.setState({alarmDialogOpen_9: true});
      return;
    }

    for(let i=0;i<46;i++) {
      if(this.state.checkedSub[i].toLowerCase() == 'true' ? true : false === true) {
        trueCountNumSub = trueCountNumSub + 1
      }
    }

    if(this.state.checkedMain[0].toLowerCase() == 'false' && this.state.checkedMain[1].toLowerCase() == 'false'
      && this.state.checkedMain[2].toLowerCase() == 'false' && this.state.checkedMain[3].toLowerCase() == 'false'
      && this.state.checkedMain[4].toLowerCase() == 'false' && this.state.checkedMain[5].toLowerCase() == 'false'
      && this.state.checkedMain[6].toLowerCase() == 'false' && this.state.checkedMain[7].toLowerCase() == 'false'
      && this.state.checkedMain[8].toLowerCase() == 'false' && this.state.checkedMain[9].toLowerCase() == 'true'
    ) {
      //console.log("pass")
    } else {
      if (trueCountNumSub === 0) {
        this.setState({alarmDialogOpen_10: true});
        return;
      }
    }

    //--------------------------------------------------
    // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
    if (this.state.checkedTerms_0 === false || this.state.checkedTerms_1 === false) {
      this.setState({alarmDialogOpen_11: true});
      return;
    }

    let arrayTerms = [];

    arrayTerms[0] = this.state.checkedTerms_0.toString();
    arrayTerms[1] = this.state.checkedTerms_1.toString();
    arrayTerms[2] = this.state.checkedTerms_2.toString();

    this.state.checkedTerms = arrayTerms;

    //--------------------------------------------------
    if(this.state.walletAddress === '') {
      this.state.walletAddress = "default"
    }
    if(this.state.avatarimage === '') {
      this.state.avatarimage = 'No_File'
    }
    if(this.state.ceoName === '') {
      this.state.ceoName = "default"
    }
    if(this.state.companyFaxNumber === '') {
      this.state.companyFaxNumber = "default"
    }
    if(this.state.address_2 === '') {
      this.state.address_2 = "default"
    }
    if(this.state.homepage === '') {
      this.state.homepage = "default"
    }
    if(this.state.history === '') {
      this.state.history = "default"
    }
    if(this.state.position === '') {
      this.state.position = "default"
    }
    
    let array2 = [];
    array2[0] = this.state.address_1
    array2[1] = this.state.address_2
    array2[2] = this.state.address_3
    array2[3] = this.state.address_4

    this.state.address = array2
    //--------------------------------------------------

    if(this.state.userId === currentAuthUserFromSession && customerType2_FromLocal === "company")
    {
      let avatarAttachment;
      let attachments = []
      let noFiles = []
  
      this.setState({ isLoading: true });

      try {
        if (this.avatarFile) {
          if(this.state.avatarimage != 'No_File') {
            await s3DeletePublic(this.state.avatarimage);
          }
          avatarAttachment = await s3UploadPublic(this.avatarFile);

          if (this.file_1) {
            for(let i=0;i<9;i++) {  // 사진 총 9장 업로드 할 수 있음
              if(this.state.images[i] != 'No_File') {
                await s3DeletePublic(this.state.images[i]);
              }

              this.file[0][i]
              ? attachments.push(await s3UploadPublic(this.file[0][i]))
              : attachments.push('No_File');
            }
            
            await this.putUser({
              avatarAttachment,
              attachments,
              customerType1: customerType1_FromLocal,
              customerType2: customerType2_FromLocal,
              walletAddress: this.state.walletAddress, 
              companyName: this.state.companyName, 
              avatarimage: avatarAttachment, 
              ceoName: this.state.ceoName, 
              companyNumber: this.state.companyNumber, 
              companyPhoneNumber: this.state.companyPhoneNumber, 
              companyFaxNumber: this.state.companyFaxNumber, 
              zoneCode: this.state.zoneCode, 
              address: this.state.address, 
              homepage: this.state.homepage, 
              history: this.state.history, 
              name: this.state.name, 
              position: this.state.position,
              phoneNumber: this.state.phoneNumber, 
              email: email_FromLocal,
              adminConfirm: this.state.adminConfirm,
              level: this.state.level,
              checkedMain: this.state.checkedMain,
              checkedSub: this.state.checkedSub,
              checkedTerms: this.state.checkedTerms,
              images: attachments
            });
          } else if(!this.file_1) {
            if(this.state.tempS3ImageToggle === true) {
              await this.putUser({
                avatarAttachment,
                customerType1: customerType1_FromLocal,
                ustomerType2: customerType2_FromLocal,
                walletAddress: this.state.walletAddress, 
                companyName: this.state.companyName, 
                avatarimage: avatarAttachment, 
                ceoName: this.state.ceoName, 
                companyNumber: this.state.companyNumber, 
                companyPhoneNumber: this.state.companyPhoneNumber, 
                companyFaxNumber: this.state.companyFaxNumber, 
                zoneCode: this.state.zoneCode, 
                address: this.state.address, 
                homepage: this.state.homepage, 
                history: this.state.history, 
                name: this.state.name, 
                position: this.state.position,
                phoneNumber: this.state.phoneNumber, 
                email: email_FromLocal,
                adminConfirm: this.state.adminConfirm,
                level: this.state.level,
                checkedMain: this.state.checkedMain,
                checkedSub: this.state.checkedSub,
                checkedTerms: this.state.checkedTerms,
                images: this.state.images
              });
            } else if(this.state.tempS3ImageToggle === false) {
              for(let i=0;i<9;i++) {
                if(this.state.images[i] != 'No_File') {
                  await s3DeletePublic(this.state.images[i]);
                }
                noFiles.push('No_File')
              }
              await this.putUser({
                avatarAttachment,
                customerType1: customerType1_FromLocal,
                customerType2: customerType2_FromLocal,
                walletAddress: this.state.walletAddress, 
                companyName: this.state.companyName, 
                avatarimage: avatarAttachment, 
                ceoName: this.state.ceoName, 
                companyNumber: this.state.companyNumber, 
                companyPhoneNumber: this.state.companyPhoneNumber, 
                companyFaxNumber: this.state.companyFaxNumber, 
                zoneCode: this.state.zoneCode, 
                address: this.state.address, 
                homepage: this.state.homepage, 
                history: this.state.history, 
                name: this.state.name, 
                position: this.state.position,
                phoneNumber: this.state.phoneNumber, 
                email: email_FromLocal,
                adminConfirm: this.state.adminConfirm,
                level: this.state.level,
                checkedMain: this.state.checkedMain,
                checkedSub: this.state.checkedSub,
                checkedTerms: this.state.checkedTerms,
                images: noFiles
              });
            }
          }
        } else if(!this.avatarFile) {
          if (this.file_1) {
            for(let i=0;i<9;i++) {
              if(this.state.images[i] != 'No_File') {
                await s3DeletePublic(this.state.images[i]);
              }

              this.file[0][i]
              ? attachments.push(await s3UploadPublic(this.file[0][i]))
              : attachments.push('No_File');
            }

            await this.putUser({
              attachments,
              customerType1: customerType1_FromLocal,
              customerType2: customerType2_FromLocal,
              walletAddress: this.state.walletAddress, 
              companyName: this.state.companyName, 
              avatarimage: this.state.avatarimage, 
              ceoName: this.state.ceoName, 
              companyNumber: this.state.companyNumber, 
              companyPhoneNumber: this.state.companyPhoneNumber, 
              companyFaxNumber: this.state.companyFaxNumber, 
              zoneCode: this.state.zoneCode, 
              address: this.state.address, 
              homepage: this.state.homepage, 
              history: this.state.history, 
              name: this.state.name, 
              position: this.state.position,
              phoneNumber: this.state.phoneNumber, 
              email: email_FromLocal,
              adminConfirm: this.state.adminConfirm,
              level: this.state.level,
              checkedMain: this.state.checkedMain,
              checkedSub: this.state.checkedSub,
              checkedTerms: this.state.checkedTerms,
              images: attachments
            });
          } else if(!this.file_1) {
            if(this.state.tempS3ImageToggle === true) {
              await this.putUser({
                customerType1: customerType1_FromLocal,
                customerType2: customerType2_FromLocal,
                walletAddress: this.state.walletAddress, 
                companyName: this.state.companyName, 
                avatarimage: this.state.avatarimage, 
                ceoName: this.state.ceoName, 
                companyNumber: this.state.companyNumber, 
                companyPhoneNumber: this.state.companyPhoneNumber, 
                companyFaxNumber: this.state.companyFaxNumber, 
                zoneCode: this.state.zoneCode, 
                address: this.state.address, 
                homepage: this.state.homepage, 
                history: this.state.history, 
                name: this.state.name, 
                position: this.state.position,
                phoneNumber: this.state.phoneNumber, 
                email: email_FromLocal,
                adminConfirm: this.state.adminConfirm,
                level: this.state.level,
                checkedMain: this.state.checkedMain,
                checkedSub: this.state.checkedSub,
                checkedTerms: this.state.checkedTerms,
                images: this.state.images
              });
            } else if(this.state.tempS3ImageToggle === false) {
              for(let i=0;i<9;i++) {
                if(this.state.images[i] != 'No_File') {
                  await s3DeletePublic(this.state.images[i]);
                }
                noFiles.push('No_File')
              }
              await this.putUser({
                customerType1: customerType1_FromLocal,
                customerType2: customerType2_FromLocal,
                walletAddress: this.state.walletAddress, 
                companyName: this.state.companyName, 
                avatarimage: this.state.avatarimage, 
                ceoName: this.state.ceoName, 
                companyNumber: this.state.companyNumber, 
                companyPhoneNumber: this.state.companyPhoneNumber, 
                companyFaxNumber: this.state.companyFaxNumber, 
                zoneCode: this.state.zoneCode, 
                address: this.state.address, 
                homepage: this.state.homepage, 
                history: this.state.history, 
                name: this.state.name, 
                position: this.state.position,
                phoneNumber: this.state.phoneNumber, 
                email: email_FromLocal,
                adminConfirm: this.state.adminConfirm,
                level: this.state.level,
                checkedMain: this.state.checkedMain,
                checkedSub: this.state.checkedSub,
                checkedTerms: this.state.checkedTerms,
                images: noFiles
              });
            }
          }
        }
        this.props.history.push("/");
        // window.location.reload();
      } catch (e) {
        // alert(e);
        console.log(e)
        this.setState({ isLoading: false });
      }
    } else
    {
      console.log("userId is not matched");
    }
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  handleFileChange_Avatar = (e) => {
    const { fileName, avatarimage } = this.state

    const avatarFile = e.target.files[0]
    this.avatarFile = e.target.files[0];

    console.log('handleFileChange 동작');
    // console.log('file: ' + JSON.stringify(e.target.files[0]));
    // console.log('this.file: ' + JSON.stringify(e.target.files[0]));

    if (this.avatarFile && this.avatarFile.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      this.setState({
        isCompressing: true,
      })

      console.log('handleFileChange 파일 사이즈 큼');
      return this.compressImage(avatarFile);
    }

    //return this.setState({
    this.setState({
      avatarFile,
      // 이미지 업로드의 미리보기 표시
      // URL.createObjectURL(파일) 함수는 링크와 연결된 임시 로컬 URL을 만든다
      tempAvatarImage: URL.createObjectURL (this.avatarFile),
      fileName: this.avatarFile.name,  
    })

    /*
    console.log('avatarimage: ' + avatarimage);
    console.log('file: ' + file);
    console.log('JSON.stringify(this.file): ' + JSON.stringify(this.file));
    console.log('this.file: ' + this.file);
    console.log('file.name: ' + file.name);
    console.log('fileName: ' + fileName);
    */
  }

  // 다음 주소 api
  handleAddress = (data) => {
    let fullAddress = data.address;
    let zonecode = data.zonecode;
    let sido = data.sido;
    let sigungu = data.sigungu;
    let extraAddress = ''; 

    // const { zonecode, address_1 } = this.props;
  
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }

    this.setState({
      zoneCode: zonecode,
      address_1: fullAddress,
      address_3: sido,
      address_4: sigungu,
      postCodeDialogOpen: false
    })

    // console.log(zonecode);  // e.g. '07067'
    // console.log(fullAddress);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    // console.log(sido);  // e.g. '경기'
    // console.log(sigungu);  // '성남시 분당구'
    // console.log(this.state.address_3);  // '경기 성남시 분당구'
  }

  handlePostCodeDialog = () => {
    this.setState({postCodeDialogOpen: true})
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      postCodeDialogOpen: false,
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
      alarmDialogOpen_5: false,
      alarmDialogOpen_6: false,
      alarmDialogOpen_7: false,
      alarmDialogOpen_8: false,
      alarmDialogOpen_9: false,
      alarmDialogOpen_10: false,
      alarmDialogOpen_11: false,
    })
  }

  handleCheckedChange = name => event => {
    this.setState({ [name] : event.target.checked });

    if(name === 'checked_0_0') {
      if(this.state.checked_0_0 === false) {
        this.setState({
          checked_0_1 : false,
          checked_0_2 : false,
          checked_0_3 : false,       
        })
      }
    }
    if(name === 'checked_1_0') {
      if(this.state.checked_1_0 === false) {
        this.setState({
          checked_1_1 : false,
          checked_1_2 : false,
          checked_1_3 : false,    
          checked_1_4 : false,    
          checked_1_5 : false,    
        })
      }
    }
    if(name === 'checked_2_0') {
      if(this.state.checked_2_0 === false) {
        this.setState({
          checked_2_1 : false,
          checked_2_2 : false,
          checked_2_3 : false,    
          checked_2_4 : false,      
        })
      }
    }
    if(name === 'checked_3_0') {
      if(this.state.checked_3_0 === false) {
        this.setState({
          checked_3_1 : false,
          checked_3_2 : false,
          checked_3_3 : false,    
          checked_3_4 : false,   
          checked_3_5 : false,   
        })
      }
    }
    if(name === 'checked_4_0') {
      if(this.state.checked_4_0 === false) {
        this.setState({
          checked_4_1 : false,
          checked_4_2 : false,
          checked_4_3 : false,    
          checked_4_4 : false,   
          checked_4_5 : false,  
          checked_4_6 : false,  
          checked_4_7 : false,  
        })
      }
    }
    if(name === 'checked_5_0') {
      if(this.state.checked_5_0 === false) {
        this.setState({
          checked_5_1 : false,
          checked_5_2 : false,
          checked_5_3 : false,    
          checked_5_4 : false,   
          checked_5_5 : false,  
          checked_5_6 : false,  
          checked_5_7 : false,  
          checked_5_8 : false,  
        })
      }
    }
    if(name === 'checked_6_0') {
      if(this.state.checked_4_0 === false) {
        this.setState({
          checked_6_1 : false,
          checked_6_2 : false,
          checked_6_3 : false,    
          checked_6_4 : false,   
        })
      }
    }
    if(name === 'checked_7_0') {
      if(this.state.checked_7_0 === false) {
        this.setState({
          checked_7_1 : false,
          checked_7_2 : false,
          checked_7_3 : false,    
          checked_7_4 : false,   
          checked_7_5 : false,  
        })
      }
    }

    if(name === 'checked_8_0') {
      if(this.state.checked_8_0 === false) {
        this.setState({
          checked_8_1 : false,
          checked_8_2 : false,
          checked_8_3 : false,    
          checked_8_4 : false,   
          checked_8_5 : false,  
        })
      }
    }

    //--------------------------------------------------
    // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
    let arrayMain = [];
    let arraySub = [];

    arrayMain[0] = this.state.checked_0_0.toString();
    arraySub[0] = this.state.checked_0_1.toString();
    arraySub[1] = this.state.checked_0_2.toString();
    arraySub[2] = this.state.checked_0_3.toString();

    arrayMain[1] = this.state.checked_1_0.toString();
    arraySub[3] = this.state.checked_1_1.toString();
    arraySub[4] = this.state.checked_1_2.toString();
    arraySub[5] = this.state.checked_1_3.toString();
    arraySub[6] = this.state.checked_1_4.toString();
    arraySub[7] = this.state.checked_1_5.toString();

    arrayMain[2] = this.state.checked_2_0.toString();
    arraySub[8] = this.state.checked_2_1.toString();
    arraySub[9] = this.state.checked_2_2.toString();
    arraySub[10] = this.state.checked_2_3.toString();
    arraySub[11] = this.state.checked_2_4.toString();

    arrayMain[3] = this.state.checked_3_0.toString();
    arraySub[12] = this.state.checked_3_1.toString();
    arraySub[13] = this.state.checked_3_2.toString();
    arraySub[14] = this.state.checked_3_3.toString();
    arraySub[15] = this.state.checked_3_4.toString();
    arraySub[16] = this.state.checked_3_5.toString();

    arrayMain[4] = this.state.checked_4_0.toString();
    arraySub[17] = this.state.checked_4_1.toString();
    arraySub[18] = this.state.checked_4_2.toString();
    arraySub[19] = this.state.checked_4_3.toString();
    arraySub[20] = this.state.checked_4_4.toString();
    arraySub[21] = this.state.checked_4_5.toString();
    arraySub[22] = this.state.checked_4_6.toString();
    arraySub[23] = this.state.checked_4_7.toString()
    arrayMain[5] = this.state.checked_5_0.toString();
    arraySub[24] = this.state.checked_5_1.toString();
    arraySub[25] = this.state.checked_5_2.toString();
    arraySub[26] = this.state.checked_5_3.toString();
    arraySub[27] = this.state.checked_5_4.toString();
    arraySub[28] = this.state.checked_5_5.toString();
    arraySub[29] = this.state.checked_5_6.toString();
    arraySub[30] = this.state.checked_5_7.toString();
    arraySub[31] = this.state.checked_5_8.toString();

    arrayMain[6] = this.state.checked_6_0.toString();
    arraySub[32] = this.state.checked_6_1.toString();
    arraySub[33] = this.state.checked_6_2.toString();
    arraySub[34] = this.state.checked_6_3.toString();
    arraySub[35] = this.state.checked_6_4.toString();

    arrayMain[7] = this.state.checked_7_0.toString();
    arraySub[36] = this.state.checked_7_1.toString();
    arraySub[37] = this.state.checked_7_2.toString();
    arraySub[38] = this.state.checked_7_3.toString();
    arraySub[39] = this.state.checked_7_4.toString();
    arraySub[40] = this.state.checked_7_5.toString();

    arrayMain[8] = this.state.checked_8_0.toString();
    arraySub[41] = this.state.checked_8_1.toString();
    arraySub[42] = this.state.checked_8_2.toString();
    arraySub[43] = this.state.checked_8_3.toString();
    arraySub[44] = this.state.checked_8_4.toString();
    arraySub[45] = this.state.checked_8_5.toString();

    arrayMain[9] = this.state.checked_9_0.toString();

    this.state.checkedMain = arrayMain;
    this.state.checkedSub = arraySub;
  }

  handleCheckedTerms = name => event => {
    this.setState({ [name] : event.target.checked });

    if(name === 'checkedTerms_all') {
      if(this.state.checkedTerms_all === true) {
        this.setState({
          checkedTerms_0 : false,
          checkedTerms_1 : false,
          checkedTerms_2 : false,       
        })
      } else if(this.state.checkedTerms_all === false) {
        this.setState({
          checkedTerms_0 : true,
          checkedTerms_1 : true,
          checkedTerms_2 : true,       
        })
      }
    }

    let arrayTerms = [];

    arrayTerms[0] = this.state.checkedTerms_0.toString();
    arrayTerms[1] = this.state.checkedTerms_1.toString();
    arrayTerms[2] = this.state.checkedTerms_2.toString();

    this.state.checkedTerms = arrayTerms;
  }

  compressImage = async (imageFile) => {
    try {
      const compressedFile = await imageCompression(imageFile, MAX_IMAGE_SIZE_MB)
      this.setState({
        isCompressing: false,
        file: compressedFile,
        fileName: compressedFile.name,
      })
    } catch (error) {
      this.setState({
        isCompressing: false,
        warningMessage: '* Fail to compress image'
      })
    }
  }

  handleFileChange = (e) => {

    this.file = []
    this.fileArray = []
    this.tempFile = null;

    this.file.push(e.target.files)
    // for (let i = 0; i < this.file[0].length; i++) {  // 업로드 사진 수량 상관 없는 코드
    for (let i = 0; i < 9; i++) { // 사진 9장까지만 업로드 하기 위한 코드 
      // 만약 동영상이 한개라도 있으면 제일 첫 동영상 1개만 업로드 하기 위한 코드
      if(this.file[0][i]) {
        if(this.file[0][i].name.substr(this.file[0][i].name.length-4, 4) == (".mp3" && ".mp4")) 
        {
          this.tempFile = this.file[0][i]
          this.file = []
          this.fileArray = []
          this.file.push(this.tempFile)
          this.file[0][0] = this.file[0]

          this.fileArray.push(URL.createObjectURL(this.file[0][0]))

          this.setState({ 
            tempUploadImage: true,
            tempS3ImageURLs: [],
            tempS3ImageToggle: false
          })

          this.file_1 = this.file[0][0]

          return;
        }
        this.fileArray.push(URL.createObjectURL(this.file[0][i]))
        this.file_1 = this.file[0][0]
      }
    }
    this.setState({ 
      tempUploadImage: true,
      tempS3ImageURLs: [],
      tempS3ImageToggle: false
    })
  }

  handleCancelPhoto = () => {
    this.setState({
      //file: '',
      //fileName: '',
      tempS3ImageURLs: [],
      tempS3ImageToggle: false
    });

    this.file = []
    this.fileArray = []
    this.file_1 = null
  }

  handleMemberTerms = () => {
    window.open('/memberterms')  // 새로운 탭에서 열림
  }

  handleInfoAgreememnt = () => {
    window.open('/infoAgreememnt')  // 새로운 탭에서 열림
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  handleGoToPage = async() => {
    const loginType = localStorage.getItem("loginType")
    
    const { userHasAuthenticatedFalse } = this.props
    // DB에 FCM Token 삭제 API
    try {
      await this.deleteNotification();
      sessionStorage.setItem("FCMLoginToggle", "false");
      // window.location.reload();
    } catch (e) {
      // alert(e);
      console.log("deleteNotification() 에러")
      sessionStorage.setItem("FCMLoginToggle", "false");
    }
    // 로컬스트로리지 삭제
    // localStorage.removeItem("notiChecking"); // <- 로그아웃해도 notiChecking관련 localstoge는 안 지울것임. 나중에 재 로그인 위해서

    // 로그아웃시 세션 지우기 기능
    await Auth.signOut()

    if (loginType === "facebook") {
      // 페이스북 로그아웃
      window.FB.logout();
      console.log("facebook Log Out!");
      this.props.history.push(`/chooseaccount`);
      window.location.reload();
    } else if (loginType === "google") {
      // 구글 로그아웃
      // this.googleSignOut();
      document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://www.otwogong.com/chooseaccount";
      // document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://localhost:5555";
      console.log("google Log Out!");
    }

    userHasAuthenticatedFalse();
    //sessionStorage.setItem("currentUser", null);

    // console.log("Log Out!");

    sessionStorage.clear()
    localStorage.clear()

    this.setState({
      alarmDialogOpen_12 : false,
    });
  }

  /*
  handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded});
  }
  */

  render() {
    const { classes /*, currentUser */ } = this.props;
    const { tempAvatarImage, avatarAttachmentURL, tempUploadImage } = this.state
    // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const customerType1_FromLocal = localStorage.getItem("customerType1");
    const customerType2_FromLocal = localStorage.getItem("customerType2");
    const email_FromLocal = localStorage.getItem("email");

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                  {/*<Button
                    className="NotUploadButton"
                    label="<"
                    onClick={this.handleNotUpload}
                  />*/}
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    마이 페이지
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                  {/*
                  // 등록 버튼을 맨 아래로 놓았다(사람들이 다 쓰고 밑에서 클릭 하게끔)
                  <Button variant="contained" color="primary" 
                    onClick={this.handlePutSubmit}
                  >
                    <Typography className={classes.typoSubmit}>등록</Typography>
                  </Button>
                  */}
                  {/*
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handlePutSubmit}
                  >
                    <span>등록</span>
                    // <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" />  // 주석처리
                  </IconButton>
                  */}
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          {/* {currentUser */}
          {currentAuthUserFromSession && customerType2_FromLocal === "company" && this.state.user /*&& this.state.attachmentURL*/
          ?
          <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                      <Typography className={classes.typoTitle}>기업고객님 업체명(*) - 로고사진이 있으면 기업 신뢰도가 올라갑니다.</Typography>
                    </ListItem>
                    <Divider/>  
                    <ListItem>
                      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                        <div>
                        <input
                          className={classes.UploadPhoto__file}
                          id="upload"
                          type="file"
                          name="Search file"
                          onChange={this.handleFileChange_Avatar}
                          accept=".png, .jpg, .jpeg"
                          required
                        />
                        <label htmlFor="upload">
                          {tempAvatarImage === null
                          ?
                          this.state.avatarimage &&
                          this.state.avatarimage != 'No_File'
                          ?
                          <img
                            className={classes.avatarMedia}
                            src={avatarAttachmentURL}
                          />
                          :
                          <Avatar
                            aria-label="Recipe" 
                            className={classes.avatar}
                          >
                            <AddIcon className={classes.addIcon}/>
                          </Avatar>
                          :
                          <img
                            className={classes.avatarMedia}
                            src={tempAvatarImage}
                          />
                          }
                        </label>
                        </div>
                      </Grid>
                      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                        <div>
                          <TextField
                            className={classes.textField}
                            id="companyName"
                            name="companyName"
                            autoFocus
                            value={this.state.companyName}
                            onChange={this.handleValueChange}
                            placeholder="'(주)'없이 입력바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <textarea
                            className={classes.Textarea__textarea}
                            id="companyName"
                            name="companyName"
                            value={this.state.companyName}
                            onChange={this.handleValueChange}
                            placeholder="업체명을 입력해주세요"
                            autoComplete="off"
                          />
                          */}
                        </div>
                      </Grid>
                    </ListItem>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>성명(대표자님)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="ceoName"
                            name="ceoName"
                            autoFocus
                            value={this.state.ceoName}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <textarea
                            className={classes.Textarea__textarea}
                            id="ceoName"
                            name="ceoName"
                            value={this.state.ceoName}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                          />
                          */}
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>사업자 등록번호(*)</Typography>
                        </ListItem>
                        <Divider/>  
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="companyNumber"
                            name="companyNumber"
                            // type="number"
                            autoFocus
                            value={this.state.companyNumber}
                            onChange={this.handleValueChange}
                            placeholder="'-'없이 입력 바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <input
                            className={classes.companyNumber}
                            id="companyNumber"
                            type="number"
                            name="companyNumber"
                            autoFocus
                            value={this.state.companyNumber}
                            onChange={this.handleValueChange}
                            placeholder="'-' 없이 입력 바랍니다."
                            autoComplete="off"
                          />
                          */}
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>대표번호(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="companyPhoneNumber"
                            name="companyPhoneNumber"
                            // type="number"
                            autoFocus
                            value={this.state.companyPhoneNumber}
                            onChange={this.handleValueChange}
                            placeholder="'-'없이 입력 바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <input
                            className={classes.companyNumber}
                            id="companyPhoneNumber"
                            type="number"
                            name="companyPhoneNumber"
                            autoFocus
                            value={this.state.companyPhoneNumber}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                          />
                          */}
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>팩스번호</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="companyFaxNumber"
                            name="companyFaxNumber"
                            // type="number"
                            autoFocus
                            value={this.state.companyFaxNumber}
                            onChange={this.handleValueChange}
                            placeholder="'-'없이 입력 바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <input
                            className={classes.companyNumber}
                            id="companyFaxNumber"
                            type="number"
                            name="companyFaxNumber"
                            autoFocus
                            value={this.state.companyFaxNumber}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                          />
                          */}
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>주소(업체와 매칭을 위해서 필요합니다*)</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="zoneCode"
                            name="zoneCode"
                            // type="number"
                            autoFocus
                            value={this.state.zoneCode}
                            onChange={this.handleValueChange}
                            placeholder="우편번호"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItem}>
                          <Button variant="contained" /*color="primary"*/ className={classes.button}
                            onClick={this.handlePostCodeDialog}
                          >
                            우편번호 검색
                          </Button>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="address_1"
                            name="address_1"
                            autoFocus
                            value={this.state.address_1}
                            onChange={this.handleValueChange}
                            placeholder="회사 주소"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="address_2"
                            name="address_2"
                            autoFocus
                            value={this.state.address_2}
                            onChange={this.handleValueChange}
                            placeholder="상세 주소"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>홈페이지</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="homepage"
                            name="homepage"
                            autoFocus
                            value={this.state.homepage}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>일감 분야(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="history"
                            name="history"
                            autoFocus
                            value={this.state.history}
                            onChange={this.handleValueChange}
                            placeholder="예) 정밀 원통연마 외주, 유휴 MCT 설비 1대"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <ListItem className={classes.listItem}>
                    </ListItem>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>담당자 이름(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <textarea
                            className={classes.Textarea__textarea}
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                          />
                          */}
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>직책</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="position"
                            name="position"
                            value={this.state.position}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <textarea
                            className={classes.Textarea__textarea}
                            id="Position"
                            name="Position"
                            value={this.state.position}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                          />
                          */}
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>연락처(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="phoneNumber"
                            name="phoneNumber"
                            // type="number"
                            autoFocus
                            value={this.state.phoneNumber}
                            onChange={this.handleValueChange}
                            placeholder="'-'없이 입력 바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <input
                            className={classes.companyNumber}
                            id="phoneNumber"
                            type="number"
                            name="phoneNumber"
                            autoFocus
                            value={this.state.phoneNumber}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                          />
                          */}
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>이메일(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="email"
                            name="email"
                            // type="email"
                            autoFocus
                            disabled
                            value={email_FromLocal}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                          {/*
                          <textarea
                            className={classes.Textarea__textarea}
                            id="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                          />
                          */}
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <ListItem className={classes.listItem}>
                    </ListItem>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>대표 보유기술 및 관심 분야(*)</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_0_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_0_0')}
                              value="checked_0_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>4차 산업분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_0')}
                              value="checked_1_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>설계 분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_0')}
                              value="checked_2_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>PLC 자동화</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_0')}
                              value="checked_3_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>조립, 유지보수</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_0')}
                              value="checked_4_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>가공 분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_0')}
                              value="checked_5_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>연마 분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_0')}
                              value="checked_6_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>용접 및 마킹분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_0')}
                              value="checked_7_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타 (가공공정 관련)</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_0')}
                              value="checked_8_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>통역, 번역</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_9_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_9_0')}
                              value="checked_9_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>공장 공유</Typography>}/>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>상세 분야(*)</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    { this.state.checked_0_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_0_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_0_1')}
                              value="checked_0_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>3D 프린터</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_0_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_0_2')}
                              value="checked_0_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>IoT, 센서</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_0_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_0_3')}
                              value="checked_0_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>스마트 팩토리</Typography>}/>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_1_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_1')}
                              value="checked_1_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>오토 캐드</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_2')}
                              value="checked_1_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>솔리드 웍스</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_3')}
                              value="checked_1_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>인벤터</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_4')}
                              value="checked_1_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>카티아</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_5')}
                              value="checked_1_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_2_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_1')}
                              value="checked_2_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>melsec</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_2')}
                              value="checked_2_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>LS 산전</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_3')}
                              value="checked_2_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>지멘스</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_4')}
                              value="checked_2_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_3_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_1')}
                              value="checked_3_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>장비 조립</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_2')}
                              value="checked_3_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>장비 배선</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_3')}
                              value="checked_3_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>공장 배선</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_4')}
                              value="checked_3_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>장비 유지,보수</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_5')}
                              value="checked_3_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>부품 수리</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_4_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_1')}
                              value="checked_4_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>범용 선반, 밀링</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_2')}
                              value="checked_4_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>CNC 선반</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_3')}
                              value="checked_4_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>MCT</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_4')}
                              value="checked_4_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>조각기</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_5')}
                              value="checked_4_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>프레스,판금</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_6}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_6')}
                              value="checked_4_6"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>cam 프로그램</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_7}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_7')}
                              value="checked_4_7"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_5_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_1')}
                              value="checked_5_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>원통 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_2')}
                              value="checked_5_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>평면 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_3')}
                              value="checked_5_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>로터리 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_4')}
                              value="checked_5_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>센터리스 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_5')}
                              value="checked_5_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>공구 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_6}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_6')}
                              value="checked_5_6"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>호닝</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_7}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_7')}
                              value="checked_5_7"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>프로파일</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_8}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_8')}
                              value="checked_5_8"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_6_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_1')}
                              value="checked_6_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>알곤 용접</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_2')}
                              value="checked_6_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>레이저 용접</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_3')}
                              value="checked_6_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>레이저 마킹</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_4')}
                              value="checked_6_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_7_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_1')}
                              value="checked_7_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>건드릴</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_2')}
                              value="checked_7_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>열처리</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_3')}
                              value="checked_7_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>도금,아노다이징</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_4')}
                              value="checked_7_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>3차원 측정</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_5')}
                              value="checked_7_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_8_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_1')}
                              value="checked_8_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>영어</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_2')}
                              value="checked_8_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>중국어</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_3')}
                              value="checked_8_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>일본어</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_4')}
                              value="checked_8_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>베트남어</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_5')}
                              value="checked_8_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>언어 기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    <ListItem className={classes.listItem}>
                    </ListItem>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>약관동의(*)</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checkedTerms_all}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedTerms('checkedTerms_all')}
                              value="checkedTerms_all"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoTerms}>전체동의</Typography>}/>
                        </ListItem>
                        <Divider/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <ListItem className={classes.listItem}>
                              <ListItemIcon className={classes.listItemIcon}>
                                <Checkbox
                                  checked={this.state.checkedTerms_0}
                                  color="default"
                                  className={classes.buttonChecked}
                                  onChange={this.handleCheckedTerms('checkedTerms_0')}
                                  value="checkedTerms_0"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={<Typography className={classes.typoTerms}>회원약관(*)</Typography>}/>                  
                            </ListItem>
                            <Divider/>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <ListItem className={classes.listItem}>
                              <Button variant="contained" className={classes.button} 
                                onClick={this.handleMemberTerms}>
                                <Typography className={classes.typoSubmit}>전문보기</Typography>  
                              </Button>
                            </ListItem>
                            <Divider/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <ListItem className={classes.listItem}>
                              <ListItemIcon className={classes.listItemIcon}>
                                <Checkbox
                                  checked={this.state.checkedTerms_1}
                                  color="default"
                                  className={classes.buttonChecked}
                                  onChange={this.handleCheckedTerms('checkedTerms_1')}
                                  value="checkedTerms_1"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={<Typography className={classes.typoTerms}>개인정보 수집 및 이용동의(*)</Typography>}/>
                            </ListItem>
                            <Divider/>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <ListItem className={classes.listItem}>
                              <Button variant="contained" className={classes.button} 
                                onClick={this.handleInfoAgreememnt}>
                                <Typography className={classes.typoSubmit}>전문보기</Typography>  
                              </Button>
                            </ListItem>
                            <Divider/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checkedTerms_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedTerms('checkedTerms_2')}
                              value="checkedTerms_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoTerms}>마케팅/홍보의 수집 및 이용동의</Typography>}/>
                        </ListItem>
                      </Grid>
                    </Grid>
                  </List>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card2}>
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handlePutSubmit}
                  >
                    <Typography className={classes.typoSubmit}>등록</Typography>
                  </Button>
                </Card>
              </Grid>
            </Grid>
          </main>
          :
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          }
        </div>
        <Dialog open={this.state.postCodeDialogOpen} fullWidth={true} maxWidth = {'md'}>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.button} 
              onClick={this.handleClose}>취소</Button>
          </DialogActions>
          <DialogContent>
            <DaumPostcode
              onComplete={this.handleAddress}
              /* ...props */
            />
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>업체명을 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2}>
        <DialogTitle>사업자 등록번호를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_3}>
        <DialogTitle>대표번호를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_4}>
        <DialogTitle>회사주소를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_5}>
        <DialogTitle>일감 분야를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_6}>
        <DialogTitle>당당자 이름을 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_7}>
        <DialogTitle>연락처를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_8}>
        <DialogTitle>이메일을 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_9}>
        <DialogTitle>보유기술에 체크해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_10}>
        <DialogTitle>상세 분야에 체크해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_11}>
        <DialogTitle>약관동의에 체크해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
          <Divider/>
            <Toolbar className={classes.bottomToolBar}>
              <Grid container spacing={5} direction="row" justify="flex-start" alignItems="center">
                <Grid item sm={10} md={10} lg={10} xl={10}>
                  <div>
                    <input
                      className={classes.UploadPhoto__file}
                      id="uploadPhoto"
                      type="file"
                      name="Search file"
                      onChange={this.handleFileChange}
                      accept=".png, .jpg, .jpeg, .mp3, .mp4"
                      required
                      multiple
                    />
                    <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
                      <label htmlFor="uploadPhoto">
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <AddPhotoAlternateIcon className={classes.addPhotoIcon}/>
                        </Grid>                      
                      </label>
                      {tempUploadImage === false
                      ?
                      this.state.tempS3ImageURLs &&
                      this.state.images &&
                      (this.state.tempS3ImageURLs || []).map((tempS3ImageURL, index) => (
                        tempS3ImageURL
                        ?
                        this.state.images[index].substr(this.state.images[index].length-4, 4) == (".mp3" && ".mp4")
                          ?
                          <Grid key={tempS3ImageURL} item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <Typography>
                              {this.state.images[index]}
                            </Typography>
                          </Grid>
                          :
                          <Grid key={tempS3ImageURL} item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <CardMedia className={classes.appbarBottomMedia}
                              image={tempS3ImageURL}
                            />
                          </Grid>
                        :
                        null
                      ))
                      :
                      (this.fileArray || []).map(url => (
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={url}>
                          {this.file[0][0].name.substr(this.file[0][0].name.length-4, 4) == (".mp3" && ".mp4")
                          ?
                          <Typography>
                            {this.file[0][0].name}
                          </Typography>
                          :
                          <CardMedia
                            className={classes.media}
                            image={url}
                            aria-label="Recipe" 
                          >
                          </CardMedia>
                          }
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Grid>
                <Grid item sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    //className={classes.commentIconButton}
                    /*color="primary"*/ className={classes.buttonLine}
                    //aria-label="back"
                    onClick={this.handleCancelPhoto}
                  >
                    <span>사진취소</span>
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider/>
        </AppBar>
        <Dialog open={this.state.alarmDialogOpen_12} onClose={this.handleGoToPage}>
          <DialogTitle>오투공을 이용하시기 위해서는 사용자 정보가 필요합니다.</DialogTitle>
          <DialogContent>정상적인 회원가입이 아닌 간편로그인으로 들어오셔서 추가적인 회원정보 작성이 필요합니다.</DialogContent>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.button}
                  onClick={this.handleGoToPage}>회원정보 페이지로 가기</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({

})

MyPage_Company = withStyles(styles, {withTheme: true})(MyPage_Company)
export default connect(mapStateToProps)(MyPage_Company);