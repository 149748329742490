import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogContent } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';

import Feed_HomePromotion from './Feed_HomePromotion';
//import UploadButton from './UploadButton'

import { API } from "aws-amplify";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";
//import config from "../config";
import Amplify from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'
import { cold } from 'react-hot-loader';

// 동영상 관련
import ReactPlayer from 'react-player'

//import './UploadButton.scss' // UploadPhoto 버튼 관련

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(14),  // original 8
    marginBottom : theme.spacing(0),  // original 13
    backgroundColor : 'white'
  },
  mainLogo: {
    paddingTop: 20,
    [theme.breakpoints.up('xs')]: {
      width: 130,
      height: 130,
    },
    [theme.breakpoints.up('sm')]: {
      width: 160,
      height: 160,
    },
    [theme.breakpoints.up('md')]: {
      width: 180,
      height: 180,
    },
    [theme.breakpoints.up('lg')]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.up('xl')]: {
      width: 200,
      height: 200,
    },
  },
  mainCard: {
    width: '100%',
    maxHeight: 1000,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  mainMedia1: {
    width: "100%",
    height: 0,
    paddingTop: '15%',
    objectFit: 'cover', // Change 'fill' to 'cover' for better image display
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%', /* 720 / 1280 = 0.5625 */
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle1: {
    /*
    // 기존
    paddingTop: 15,
    paddingBottom: 0,
    paddingLeft: 23,
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    backgroundColor : 'white',
    */
    paddingTop: 40,
    paddingBottom: 0,
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    backgroundColor : 'white'
  },
  typoTitle1: {
    width: '100%',
    textAlign: 'center', // Center-align horizontally
    backgroundColor : '#B22222',
    color : 'white',
    fontWeight: 'bold',
    [theme.breakpoints.up('xs')]: {
      fontSize : 23,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 28,
    },
    [theme.breakpoints.up('md')]: {
      fontSize : 30,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize : 30,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize : 30,
    },
  },
  listItemTitle2: {
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor : 'white'
  },
  typoTitle2: {
    backgroundColor : 'white',
    color : 'black',
    fontWeight: 'bold',
    [theme.breakpoints.up('xs')]: {
      fontSize : 18,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize : 23,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize : 23,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize : 23,
    },
  },
  listItemTitle3: {
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor : 'white'
  },
  listItemTitle4: {
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor : 'white'
  },
  typoTitle3: {
    backgroundColor : 'white',
    color : 'black',
    //fontWeight: 'bold',
    [theme.breakpoints.up('xs')]: {
      fontSize : 14,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 16,
    },
    [theme.breakpoints.up('md')]: {
      fontSize : 19,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize : 19,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize : 19,
    },
  },
  listItemTitle5: {
    paddingTop: 40,
    paddingBottom: 5,
    backgroundColor : 'white'
  },
  typoTitle5: {
    backgroundColor : '#2F4F4F',
    color : 'white',
    fontWeight: 'bold',
    [theme.breakpoints.up('xs')]: {
      fontSize : 18,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 21,
    },
    [theme.breakpoints.up('md')]: {
      fontSize : 24,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize : 24,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize : 24,
    },
  },
  listIntroduce: {
    width: '100%',
    marginTop: 5,
    padding: 5,
  },
  listItemIntroduceTitle: {
    paddingTop: 5,
    paddingBottom: 5
  },
  listItemIntroduceContent: {
    paddingTop: 2,
    paddingBottom: 0
  },
  typoIntroduceTitle: {
    fontSize : 17,
  },
  typoIntroduceContent: {
    fontSize : 16,
  },
  typoIntroduceConetent2: {
    fontSize : 16,
    // color: 'red'
  },
  typoIntroduceContent3: {
    fontSize : 13,
  },
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});


class Home_Culture extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        goodSuppliers: '',
        newSuppliers: '',
        image: "",
        userId: "",
        notiUserId: "",
        searchKeyword: '',
        // user page 방식
        //page1: 1,
        page2: 1,
        //pagelimit1: 4,
        pagelimit2: 8,
        hasNextPage1: false,
        hasNextPage2: false,
        userContents: "",
        getNotifications: "",
        fcmTokenId: "",
        alarmDialogOpen_1: false,
        alarmDialogOpen_2: false,
        completed : 0,
        notiTrigger : false,  // false 일때만 서버로 전송
      };

      this.imageChangeInterval = null;
  }

  getGoodSuppliers() {
    //return API.get("upload", `/user?page=${this.state.page1}&pagelimit=${this.state.pagelimit1}&level=1`)
    return API.get("upload", `/user?level=1`)
    .then(goodSuppliers => this.setState({goodSuppliers : goodSuppliers}));
  }

  getNewSuppliers() {
    return API.get("upload", `/user?page=${this.state.page2}&pagelimit=${this.state.pagelimit2}&adminConfirm=true&level=0`)
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  /*
  getNotifications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.get("upload", `/notification/?userId=${currentAuthUserFromSession}&fcmTokenId=${currentFCMTokenFromSession}`)
    .then(getNotifications => this.setState({getNotifications : getNotifications}));
  }
  */

  getNotification() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/notification/${currentAuthUserFromSession}`)
  }

  postNotification(postNotification) {
    return API.post("upload", "/notification", {
      body: postNotification
    });
  }

  putNotification(putNotification) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/notification/${currentAuthUserFromSession}`, {
      body: putNotification
    });
  }

  getNotiSetting() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/notiSetting/${currentAuthUserFromSession}`)
  }

  postNotiSetting(postNotificationSet) {
    return API.post("upload", "/notiSetting", {
      body: postNotificationSet
    });
  }

  // 삭제 API
  deleteNotification() {
    // const currentFCMTokenUUIDFromSession = sessionStorage.getItem("currentFCMTokenUUID");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.del("upload", `/notification/${currentFCMTokenFromSession}`);
  }

  isAndroid() {
    //alert('android')
    return /Android/i.test(navigator.userAgent);
  }

  isIOS() {
    //alert('ios')
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  handleMobileGoogleLogin = async() => {
    localStorage.setItem("mobileGoogleLoginStart", 'False');
    try{
      window.ReactNativeWebView.postMessage(JSON.stringify({ type:'GLRedirect', message:'GLRedirectmessage' }))
    } catch(e) {
      ;
    }
    this.setState({
      alarmDialogOpen_2 : false,
    });
  }

  handleMobileKakaoLogin = async() => {
    localStorage.setItem("mobileKakaoLoginStart", 'False');
    try{
      window.ReactNativeWebView.postMessage(JSON.stringify({ type:'KKRedirect', message:'KKRedirectmessage' }))
    } catch(e) {
      ;
    }
    this.setState({
      alarmDialogOpen_2 : false,
    });
  }

  
  async getExpo_AWSCredentials(expoGoogleUser) {
    //handleGoogleSignIn = async (response) => {
      //console.log("googleUser: ", googleUser)
      const { userHasAuthenticatedTrue } = this.props
      
      //const { uid, email, displayName, stsTokenManager, id_token } = expoGoogleUser;
      const { email, displayName, accessToken, expirationTime, savedIdToken } = expoGoogleUser;

      //alert('googleLogin_android_expoGoogleUser: ' + JSON.stringify(expoGoogleUser))
      //alert('googleLogin_android_email_home: ' + JSON.stringify(email))
      //alert('googleLogin_android_displayName: ' + JSON.stringify(displayName))
      //alert('googleLogin_android_stsTokenManager: ' + JSON.stringify(stsTokenManager))
      //alert('googleLogin_android_stsTokenManager.accessToken: ' + JSON.stringify(stsTokenManager.accessToken))
      //alert('googleLogin_android_stsTokenManager.expirationTime: ' + JSON.stringify(stsTokenManager.expirationTime))
      //alert('googleLogin_android_id_token: ' + JSON.stringify(id_token))
      //alert('googleLogin_android_expirationTime: ' + JSON.stringify(expirationTime))
      //alert('googleLogin_android_accessToken: ' + JSON.stringify(accessToken))
      //alert('googleLogin_android_savedIdToken: ' + JSON.stringify(savedIdToken))

      const currentTime = Date.now();
      const expiresIn = expirationTime - currentTime;

      let user = {
          email: email,
          name: displayName
      };
  
      localStorage.setItem("email", email);
      //alert('email: ' + JSON.stringify(email))
  
      this.setState({ isLoading: true });
      
      try {
        const credentials = await Auth.federatedSignIn(
          'google',
          //{ token: stsTokenManager.accessToken, expires_at: expirationTime },
          { token: savedIdToken, expires_at: currentTime + expiresIn },
          user
        );

        //alert('googleLogin_android_credentials: ' + JSON.stringify(credentials))
      } catch(e) {
        console.log(e)
        //alert('googleLogin_android_credentials_error: ' + JSON.stringify(e))
      }
      
      this.setState({ isLoading: false });
      
      try {
        const userID = await Auth.currentAuthenticatedUser();
        // console.log("userID: ");
        // console.log(userID);
        const userID2 = await Auth.currentUserInfo(userID);
        // console.log("userID2: ");
        // console.log(userID2);
        if(userID2 === null) {
          const userID3 = JSON.stringify(userID);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          //alert('expocurrentuser: ' + JSON.stringify(expocurrentuser))
  
          userHasAuthenticatedTrue();
        } else if (userID2 != null) {
          const userID3 = JSON.stringify(userID2);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          //alert('expocurrentuser: ' + JSON.stringify(expocurrentuser))
  
          //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
  
          //console.log("google-currentAuthUserFromSession :")
          //console.log(currentAuthUserFromSession)
          userHasAuthenticatedTrue();
        }
      } catch (e) {
          if (e !== "not authenticated") {
            // alert(e);
            alert('not authenticated: ' + JSON.stringify(e))
            console.log(e)
          }
      }
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      //alert('currentAuthUserFromSession: ' + JSON.stringify(currentAuthUserFromSession))

      localStorage.setItem("loginType", "google");

      const loginType_FromLocal = localStorage.getItem("loginType");
      const customerType1_FromLocal = localStorage.getItem("customerType1");
      const customerType2_FromLocal = localStorage.getItem("customerType2");
      const email_FromLocal = localStorage.getItem("email");
  
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId_google: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "google");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(this.state.userId === undefined || this.state.userId === 'undefined' || this.state.userId === null) {
            console.log("userId is undefined_google");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            /*
            this.setState({
              alarmDialogOpen_1 : true,
            });
            */
          }
          else {
            console.log("userId is not matched_google");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.props.history.push("/signupbuyerfreelancergoogle"); // google 회원 가입 필요
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
      
      //sessionStorage.setItem("FCMLoginToggle", "true");
  
      //this.props.onLogin(credentials);  // 이 코드를 넣어 줘야 로그인 되면서 페이지 넘어간다.
  
      this.props.history.push("/"); // 로그인 후 home 페이지로 이동
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    const { isAuthenticated } = this.props

    if(isAuthenticated === true) {
      //console.log("isAuthenticated === true")
    } else {
      // Cognito 로그인 없이 S3 읽어 온다.
      Amplify.configure({
        Auth: {
          mandatorySignIn: false, // true이면 로그인 시에만 S3 읽어 올수 있다.
        }
      });
    }

    //localStorage.removeItem("loginType");

    const mobileGoogleLoginStart = localStorage.getItem("mobileGoogleLoginStart");

    if (mobileGoogleLoginStart == "True") {
      this.setState({
        alarmDialogOpen_2 : true,
      });
    }

    
    try {
      if (this.isAndroid()) {
        const receiver = document;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data) 
          //alert('googleLogin1: ' + JSON.stringify(event))
          if (event.type == 'googleLoginFinish') {
            //alert('googleLogin_android: ' + JSON.stringify(event.type))

            this.getExpo_AWSCredentials(event.message);
            userHasAuthenticatedTrue();
          }
          // expo에서 받은 데이터로 구글 로그인 진행

        })
      }
    } catch(e) {
      ;
    }
    
    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;
    localStorage.setItem("currentPage", "homePromotion");

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: ");
      // console.log(userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: ");
      // console.log(userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          // alert(e);
          console.log(e)
        }
    }

    await this.getGoodSuppliers();
    /*
    const getGoodSupplier =  await this.getGoodSuppliers();
    //const { docs, hasNextPage } = getGoodSupplier;
    const docs1 = getGoodSupplier.docs
    const hasNextPage1 = getGoodSupplier.hasNextPage
    this.setState({goodSuppliers: docs1})
    this.setState({hasNextPage1: hasNextPage1})
    */

    const getNewSupplier =  await this.getNewSuppliers();
    // const { docs, hasNextPage } = getNewSupplier;
    const docs2 = getNewSupplier.docs
    const hasNextPage2 = getNewSupplier.hasNextPage
    this.setState({newSuppliers: docs2})
    this.setState({hasNextPage2: hasNextPage2})

    // home 업로드시 관련 내용들
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    //console.log("currentAuthUserFromSession: ")
    //console.log(currentAuthUserFromSession)
    //alert('currentAuthUserFromSession: ' + JSON.stringify(currentAuthUserFromSession))

    const loginType_FromLocal = localStorage.getItem("loginType");
    const customerType1_FromLocal = localStorage.getItem("customerType1");
    const customerType2_FromLocal = localStorage.getItem("customerType2");
    const email_FromLocal = localStorage.getItem("email");
    
    /*
    console.log("loginType_FromLocal: ")
    console.log(loginType_FromLocal)
    console.log("customerType1_FromLocal: ")
    console.log(customerType1_FromLocal)
    console.log("customerType2_FromLocal: ")
    console.log(customerType2_FromLocal)
    console.log("email_FromLocal: ")
    console.log(email_FromLocal)
    */

    if (loginType_FromLocal == 'google') {
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId_google: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "google");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(userId === undefined || userId === 'undefined' || userId === null) {
            console.log("userId is undefined_google");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            this.props.history.push("/signupformbuyerfreelancerfederatedlogin"); // google 회원 가입 필요
            /*
            this.setState({
              alarmDialogOpen_1 : true,
            });
            */
          }
          else {
            console.log("userId is not matched_google");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.props.history.push("/signupformbuyerfreelancerfederatedlogin"); // google 회원 가입 필요
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
    }

    if (loginType_FromLocal == 'kakaotalk') {
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId_kakaotalk: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "kakaotalk");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(userId === undefined || userId === 'undefined' || userId === null) {
            console.log("userId is undefined_kakaotalk");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            /*
            this.setState({
              alarmDialogOpen_1 : true,
            });
            */
          }
          else {
            console.log("userId is not matched_kakaotalk");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.props.history.push("/signupbuyerfreelancergoogle"); // google 회원 가입 필요
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
          console.log("userId is not matched_kakaotalk");
          //console.log('currentUser: ' + currentUser);
          //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
          //console.log('userId: ' + this.state.userId);
          this.props.history.push("/signupformbuyerfreelancerfederatedlogin"); // google 회원 가입 필요
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
    }

    if (loginType_FromLocal == 'email') {
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "email");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(userId === undefined) {
            // console.log("userId is undefined");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            this.setState({
              alarmDialogOpen_1 : true,
            });
          }
          else {
            console.log("userId is not matched");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.setState({
              alarmDialogOpen_1 : true,
            });
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
    }

    if (loginType_FromLocal != 'google' && loginType_FromLocal != 'email') {
      if(customerType1_FromLocal === undefined || customerType1_FromLocal === 'undefined' || customerType1_FromLocal === null
      || customerType1_FromLocal === undefined || customerType2_FromLocal === 'undefined' || customerType2_FromLocal === null
      || email_FromLocal === undefined || email_FromLocal === 'undefined' || email_FromLocal === null) {
        try {
          const user = await this.getUser();
        
          const { userId, customerType1, customerType2, email } = user;

          //console.log("userId: ")
          //console.log(userId)

          if(userId === currentAuthUserFromSession) {
            localStorage.setItem("loginType", "email");
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            localStorage.setItem("email", email);
            /*
            const customerType1_FromLocal = localStorage.getItem("customerType1");
            const customerType2_FromLocal = localStorage.getItem("customerType2");
            const email_FromLocal = localStorage.getItem("email");
            console.log("customerType1_FromLocal :")
            console.log(customerType1_FromLocal)
            console.log("customerType2_FromLocal :")
            console.log(customerType2_FromLocal)
            console.log("email_FromLocal :")
            console.log(email_FromLocal)
            */
          //} else if(this.state.userId === undefined && loginType_FromLocal != 'email') {
          } else if(userId === undefined) {
            // console.log("userId is undefined");
            // console.log('currentUser: ' + currentUser);
            // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            // console.log('userId: ' + this.state.userId);
            // this.props.history.push(`/user/${this.props.currentUser}`);
            this.setState({
              alarmDialogOpen_1 : true,
            });
          }
          else {
            console.log("userId is not matched");
            //console.log('currentUser: ' + currentUser);
            //console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
            //console.log('userId: ' + this.state.userId);
            this.setState({
              alarmDialogOpen_1 : true,
            });
          } 
        } catch(e) {
          console.log(e)
          // console.log('getUser 없음')
        }
      } else {
        // console.log("User 정보 받아옴")
        /*
         console.log("customerType1_FromLocal :")
         console.log(customerType1_FromLocal)
         console.log("customerTyp2_FromLocal :")
         console.log(customerType2_FromLocal)
         console.log("email_FromLocal :")
         console.log(email_FromLocal)
         */
      }
    }

    //-----------------------------------------------------------------------------------------
    // FCM notification (fcm token)
    //-----------------------------------------------------------------------------------------
    //const FCMToggleFromSession = sessionStorage.getItem("FCMLoginToggle");
    // react native 통신 (android)
    try {
      if (this.isAndroid()) {
        const receiver = document;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data) 
          window.ReactNativeWebView.postMessage(JSON.stringify(event));
          //alert('data_android: ' + JSON.stringify(event))
          //alert('data2: ' + JSON.stringify(event.data))
          if (event.type == 'fcmtoken' && this.state.notiTrigger == false) {
            //alert('reactjs_fcmtoken_android: ' + JSON.stringify(event.message))            
            // notification에 fcmtoken 저장 하기
            //alert('check1: ' + JSON.stringify(event.type))
            try {
              //sessionStorage.removeItem("currentFCMToken")  // 테스트용
              //sessionStorage.setItem("currentFCMToken", 'abcde') // 테스트용
              const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
              //alert('check2: ' + JSON.stringify(currentFCMTokenFromSession))
              if(currentFCMTokenFromSession == null) {
                //alert('check3')
                const getnotification = await this.getNotification();
                //alert('getnotification: ' + getnotification)
                const { userId } = getnotification;
                this.setState({notiUserId : userId});
                //alert('userId_checked: ' + JSON.stringify(this.state.notiUserId))
              
                // 만약 userId가 겁색이 되어서 현재 userId와 같으면 수정
                if(this.state.notiUserId == currentAuthUserFromSession) {
                  //alert('check4: ' + JSON.stringify(event.message))
                  await this.putNotification({
                    fcmTokenId: event.message,
                  });
                  sessionStorage.setItem("currentFCMToken", event.message)
                  //alert('check5: ' + JSON.stringify(event.message))
                  this.setState({notiTrigger : true});
                }
                //alert('check6')
              }
              if (currentFCMTokenFromSession != null && currentFCMTokenFromSession != event.message && currentAuthUserFromSession) {
                //alert('check7: ' + JSON.stringify(event.message))
                await this.putNotification({
                  fcmTokenId: currentFCMTokenFromSession,
                });
                sessionStorage.setItem("currentFCMToken", event.message)
                //alert('check8: ' + JSON.stringify(event.message))
                this.setState({notiTrigger : true});
              }
            } catch(e) {
              //alert('check9')
              try {
                // 만약 userId가 검색 되지 않으면 post
                const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
                if(currentFCMTokenFromSession == null && !this.state.notiUserId && currentAuthUserFromSession){
                  //alert('check10: ' + JSON.stringify(event.message))
                  // 만약 검색이 되지 않으면 post로 새로 저장하기
                  await this.postNotification({
                    fcmTokenId: event.message,
                    userId: currentAuthUserFromSession,
                  });
                  sessionStorage.setItem("currentFCMToken", event.message)
                  //alert('check11: ' + JSON.stringify(event.message))
                  this.setState({notiTrigger : true});
                }
              } catch(e) {
                //alert('check12')
              }
              //alert('check13')
            }
          }
        });
      }
    } catch(e) {
      ;
    }
    
    // react native 통신 (ios)
    try{
      if (this.isIOS()) {
        const receiver = window;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data) 
          window.ReactNativeWebView.postMessage(JSON.stringify(event));
          //alert('data_ios: ' + JSON.stringify(event))
          //alert('data2: ' + JSON.stringify(event.data))
          if (event.type == 'fcmtoken' && this.state.notiTrigger == false) {
            //alert('reactjs_fcmtoken_ios: ' + JSON.stringify(event.message))
            // notification에 fcmtoken 저장 하기
            //alert('check1: ' + JSON.stringify(event.type))
            try {
              //sessionStorage.removeItem("currentFCMToken")  // 테스트용
              //sessionStorage.setItem("currentFCMToken", 'abcde') // 테스트용
              const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
              //alert('check2: ' + JSON.stringify(currentFCMTokenFromSession))
              if(currentFCMTokenFromSession == null) {
                //alert('check3')
                const getnotification = await this.getNotification();
                //alert('getnotification: ' + getnotification)
                const { userId } = getnotification;
                this.setState({notiUserId : userId});
                //alert('userId_checked: ' + JSON.stringify(this.state.notiUserId))
              
                // 만약 userId가 겁색이 되어서 현재 userId와 같으면 수정
                if(this.state.notiUserId == currentAuthUserFromSession) {
                  //alert('check4: ' + JSON.stringify(event.message))
                  await this.putNotification({
                    fcmTokenId: event.message,
                  });
                  sessionStorage.setItem("currentFCMToken", event.message)
                  //alert('check5: ' + JSON.stringify(event.message))
                  this.setState({notiTrigger : true});
                }
                //alert('check6')
              }
              if (currentFCMTokenFromSession != null && currentFCMTokenFromSession != event.message && currentAuthUserFromSession) {
                //alert('check7: ' + JSON.stringify(event.message))
                await this.putNotification({
                  fcmTokenId: currentFCMTokenFromSession,
                });
                sessionStorage.setItem("currentFCMToken", event.message)
                //alert('check8: ' + JSON.stringify(event.message))
                this.setState({notiTrigger : true});
              }
            } catch(e) {
              //alert('check9')
              try {
                // 만약 userId가 검색 되지 않으면 post
                const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
                if(currentFCMTokenFromSession == null && !this.state.notiUserId && currentAuthUserFromSession){
                  //alert('check10: ' + JSON.stringify(event.message))
                  // 만약 검색이 되지 않으면 post로 새로 저장하기
                  await this.postNotification({
                    fcmTokenId: event.message,
                    userId: currentAuthUserFromSession,
                  });
                  sessionStorage.setItem("currentFCMToken", event.message)
                  //alert('check11: ' + JSON.stringify(event.message))
                  this.setState({notiTrigger : true});
                }
              } catch(e) {
                //alert('check12')
              }
              //alert('check13')
            }
          } 
        });
      }
    } catch(e) {
      ;
    }

    /*
    // 기존 web기반 코드. 나중에 삭제하기
    if(currentFCMTokenFromSession && currentAuthUserFromSession && FCMToggleFromSession === "true") {
      await this.postNotification({
        fcmTokenId: currentFCMTokenFromSession,
        userId: currentAuthUserFromSession,
      });
      //console.log("FCM 토큰값 DB로 저장(로그인)");
      sessionStorage.setItem("FCMLoginToggle", "false");
     
      Object.keys(this.state.getNotifications).map(notificationUUId => {
        const getNotification = this.state.getNotifications[notificationUUId];
        const currentFCMTokenUUID = getNotification.notificationUUId;
        sessionStorage.setItem("currentFCMTokenUUID", currentFCMTokenUUID);
      });
      
    } else {
      //console.log("FCM 토큰값 없음 또는 이미 토큰값 DB에 저장됨");
      sessionStorage.setItem("FCMLoginToggle", "false");
    }
    */
    //-----------------------------------------------------------------------------------------
    // FCM notification (end)
    //-----------------------------------------------------------------------------------------

    // notiSetting 관련
    const notiSettingUserId = localStorage.getItem("notiSetting")
    ? JSON.parse(localStorage.getItem("notiSetting")).userId
    : null

    if(notiSettingUserId === currentAuthUserFromSession) {
      //console.log("localStorge_notiSetting 있음.");
      try{
        const localEventToggle = JSON.parse(localStorage.getItem("notiSetting")).eventToggle
        const localApplicantToggle = JSON.parse(localStorage.getItem("notiSetting")).applicantToggle  // 내가 게재한 공고에 지원자가 지원시 알림 설정
        //const localFollowUploadToggle = JSON.parse(localStorage.getItem("notiSetting")).followUploadToggle
        //const localFollowCommentToggle = JSON.parse(localStorage.getItem("notiSetting")).followCommentToggle
        this.setState({
          eventToggle : localEventToggle,
          applicantToggle : localApplicantToggle,
          // followUploadToggle : localFollowUploadToggle,
          // followCommentToggle : localFollowCommentToggle,
        });
      } catch(e) {
        //console.log("localNoniSetting() 에러.");    
      }
    } else {
      //console.log("localStorge_notiSetting 없음.");
      try{
        const notificationSetting = await this.getNotiSetting();

        const { userId, eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ } = notificationSetting;
        
        this.setState({userId});
  
        if(this.state.userId === currentAuthUserFromSession)
        {
          this.setState({ eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ });
        }

        localStorage.setItem(
          "notiSetting",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            eventToggle: this.state.eventToggle,
            applicantToggle: this.state.applicantToggle,
            /*
            followUploadToggle: this.state.followUploadToggle,
            followCommentToggle: this.state.followCommentToggle
            */
          })
        )
        //console.log("localStorge_notiSetting 저장됨.");
      } catch(e) {
        // alert(e);        
        await this.postNotiSetting({
          eventToggle: "true",
          applicantToggle: "true",
          /*
          followUploadToggle: "true",
          followCommentToggle: "true",
          */
        });

        localStorage.setItem(
          "notiSetting",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            eventToggle: "true",
            applicantToggle: "true",
            /*
            followUploadToggle: "true",
            followCommentToggle: "true",
            */
          })
        )
        
        //console.log("postNotiSetting() 실행됨.");
      }
    }
  }

  drawerOpen = () => {
    const {drawertoggletrue} = this.props;
    drawertoggletrue();
    try{
      window.ReactNativeWebView.postMessage(JSON.stringify({ type:'fcmtokentrigger', message:'fcmtokentriggerdata' }))
      //window.ReactNativeWebView.postMessage(JSON.stringify({ type:'GLRedirect', message:'GLRedirectmessage' }))
    } catch(e) {
      ;
    }
  }

  goToNaver = () => {
    window.open('https://blog.naver.com/cjchiness')  // 새로운 탭에서 열림
    // window.location.href = 'https://hangouts.google.com'; // 현재 탭에서 열림
  }

  goToYoutube = () => {
    window.open('https://youtube.com/@mingmenschool')  // 새로운 탭에서 열림
    // window.location.href = 'https://hangouts.google.com'; // 현재 탭에서 열림
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    uploadContents : '',
    userContents : '',
    completed : 0,
    });
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  handleGoToPage = async() => {
    const loginType = localStorage.getItem("loginType")
    
    const { userHasAuthenticatedFalse } = this.props
    /*
    // DB에 FCM Token 삭제 API
    try {
      await this.deleteNotification();
      sessionStorage.setItem("FCMLoginToggle", "false");
      // window.location.reload();
    } catch (e) {
      // alert(e);
      console.log("deleteNotification() 에러")
      sessionStorage.setItem("FCMLoginToggle", "false");
    }
    // 로컬스트로리지 삭제
    // localStorage.removeItem("notiChecking"); // <- 로그아웃해도 notiChecking관련 localstoge는 안 지울것임. 나중에 재 로그인 위해서
    */

    // android가 아니고 ios가 아니면 web 로그아웃 진행
    if (!this.isAndroid() && !this.isIOS()) {
      // 로그아웃시 세션 지우기 기능
      await Auth.signOut()

      if (loginType === "facebook") {
        // 페이스북 로그아웃
        window.FB.logout();
        console.log("facebook Log Out!");
        this.props.history.push(`/chooseaccount`);
        window.location.reload();
      } else if (loginType === "google") {
        // 구글 로그아웃
        // this.googleSignOut();
        document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://www.otwogong.com/chooseaccount";
        // document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://localhost:5555";
        console.log("google Log Out!");
      }
    }

    // android 또는 ios 이면 로그아웃 진행
    if (this.isAndroid() || this.isIOS()) {
      //alert('Mobile login');
    }
    

    userHasAuthenticatedFalse();
    //sessionStorage.setItem("currentUser", null);

    // console.log("Log Out!");

    sessionStorage.clear()
    localStorage.clear()

  this.setState({
    alarmDialogOpen_1 : false,
  });
}

  render() {
    const { classes, theme, } = this.props;
    const { searchKeyword } = this.state;

    const isXs = window.innerWidth < 600; // You can adjust this breakpoint
    const isSm = window.innerWidth >= 600 && window.innerWidth < 960;
    const isMd = window.innerWidth >= 960 && window.innerWidth < 1280;
    const isLg = window.innerWidth >= 1280 && window.innerWidth < 1920;
    const isXl = window.innerWidth >= 1920;

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" justify="center" alignItems="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <CardMedia
                className={classes.mainLogo}
                image="/images/logo/mmlogo1.png"
              />
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" justify="center" alignItems="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} align="center">
              <List className={classes.list}>
                <ListItem className={classes.listItemTitle1}>
                  <Typography className={classes.typoTitle1}>
                    문화체험
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid> 
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" justify="center" alignItems="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} align="center">
              <div className={classes.videoWrapper}>
                <ReactPlayer
                  className={classes.reactPlayer} 
                  url={"/images/main/promotionvideo.mp4"}
                  width='100%'
                  height='100%'
                  controls
                  playing // Autoplay is enabled
                  volume={0.5} // Set sound volume to 50% = 0.5
                  // 다운로드 금지 코드
                  config={{ file: {
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  }}}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" justify="center" alignItems="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} align="center">
              <CardMedia
                className={classes.mainMedia1}
                image="/images/main/culture.png"
              />
            </Grid>
          </Grid>
          {/*
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" justify="center" alignItems="center" spacing={0}>
            <Grid item xs={0} sm={0} md={1} lg={2} xl={2} align="center">
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={8} xl={8} align="center">
              <List className={classes.list}>
                <ListItem className={classes.listItemTitle5}>
                  <Typography className={classes.typoTitle5}>
                    문화체험
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItemTitle3}>
                  <Typography className={classes.typoTitle3}>
                    • 상담 시간은 약 30~40분정도 소요됩니다.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItemTitle4}>
                  <Typography className={classes.typoTitle3}>
                    • 자녀분 수강이면 자녀분과 함께 방문해주셔야 합니다.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItemTitle5}>
                  <Typography className={classes.typoTitle5}>
                    입학테스트
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItemTitle3}>
                  <Typography className={classes.typoTitle3}>
                    • 해법중국어, HSK, 중국어 회화반, 1:1 강의로 나누어서 수업을 진행하고 있습니다.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItemTitle4}>
                  <Typography className={classes.typoTitle3}>
                    • 수업전에 기본 중국어 레벨테스트를 진행을 합니다.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItemTitle4}>
                  <Typography className={classes.typoTitle3}>
                    • 중국어 레벨테스트는 수업에 참여하는 학생에게 어느 부분이 필요한지를 알아보기 위한 것에 있습니다.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItemTitle5}>
                  <Typography className={classes.typoTitle5}>
                    상담예약
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItemTitle3}>
                  <Typography className={classes.typoTitle3}>
                    • 유선상담예약 : 043)900-0515
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={2} xl={2} align="center">
            </Grid>
          </Grid>
          */}
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>명문중국어전문학원</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>사업자등록번호 : 563-90-02209</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent}>원장 : 장리리</Typography>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <List className={classes.listIntroduce}>
                <Divider/>
                <ListItem className={classes.listItemIntroduceTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceTitle}>충북 청주시 흥덕구 가경로 5 502호</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>043)900-0515</Typography>
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceConetent2}>mygg777@naver.com</Typography>
                  </Grid>
                </ListItem>
                {/*
                <ListItem className={classes.listItemIntroduceContent}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoIntroduceContent3}>평일 09:00 ~ 18:00 / 주말·공휴일 휴무</Typography>
                  </Grid>
                </ListItem>
                */}
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={this.state.alarmDialogOpen_1} onClose={this.handleGoToPage}>
          <DialogTitle>웹(앱)을 이용하시기 위해서는 사용자 정보가 필요합니다.</DialogTitle>
          <DialogContent>정상적인 회원가입이 아닌 간편로그인으로 들어오셔서 추가적인 회원정보 작성이 필요합니다.</DialogContent>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.submitButton}
                  onClick={this.handleGoToPage}>회원정보 페이지로 가기</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2} onClose={this.handleMobileGoogleLogin}>
        <DialogTitle>구글 로그인</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.submitButton}
                  onClick={this.handleMobileGoogleLogin}>구글 로그인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isdrawerToggle: state.upload.isdrawerToggle,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  drawertoggletrue: () => dispatch(uploadActions.drawertoggletrue()),
  drawertogglefalse: () => dispatch(uploadActions.drawertogglefalse()),
})

Home_Culture = withStyles(styles, {withTheme: true})(Home_Culture)
export default connect(mapStateToProps, mapDispatchToProps)(Home_Culture)