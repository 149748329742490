import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
//import './AWSInput.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  label: {
    display: 'block',
    fontSize: '15px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: '8px',
  },
  input: {
    width: '100%',
    fontSize: '15px',
    //borderWidth: '0',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
    padding: '22px 24px',
    '&::placeholder': {
      color: theme.palette.grey[500],
    },
    '&.Input__input--err': {
      borderColor: theme.palette.error.main,
    },
  },
  error: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: '12px',
    color: theme.palette.error.main,
  },
}));

const AWSInput = ({
  className,
  type,
  name,
  label,
  value,
  onChange,
  placeholder,
  err,
  readOnly,
  keyPress,
}) => {
  const classes = useStyles();
  return (
    <div className={cx('Input', className)}>
      {label && (
        <label className={classes.label} htmlFor={name}>
          {label}
        </label>
      )}
      <TextField
        id={name}
        type={type || 'text'}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        onKeyPress={keyPress}
        variant="outlined"
        autoComplete="off"
        className={cx(classes.root, 'Input__input', { 'Input__input--err': err })}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {err && <p className={classes.error}>{err}</p>}
    </div>
  );
};

export default AWSInput;
