import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from '@material-ui/core/Divider';
import ReactPlayer from 'react-player';
//import MoreVertIcon from '@material-ui/icons/MoreVert';

import Feed_ViewFollow from './Feed_ViewFollow';

// S3 관련
import { API, Storage } from "aws-amplify";
import config from "../config";

import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';

import SVGIcon from './SVGIcon'

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

/*
// MoreVertIcon 관련
import PopupState, { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
*/

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

//import './Mypage.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(7),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  title: {
    flexGrow: 1,
    fontSize : 16,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '40px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  iconButton: {
    margin: theme.spacing(0),
    //paddingLeft: 0
  },
  /*
  moreVertIcon: {
    width: 20,
    height: 20,
  },
  popupStateTypography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  popupStateMenu: {
    padding : 0,
  },
  */
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  typoSubmit: {
    fontSize: 15
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  card2: {
    maxWidth: 64,
    marginTop: 5,
    marginBottom: 15,
    boxShadow: "none",
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 50,
  },
  listItemIcon: {
    minWidth: 0
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  cardHeader: {
    width: '100%',
    paddingRight: 0,
  },
  textFieldName : {
    width: '100%',
    fontSize: 18,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '20px',
    paddingRight: '24px',
  },
  textField : {
    width: '100%',
    fontSize: 16,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '0px',
    paddingRight: '24px',
  },
  avatarMedia: {
    height: 80,
    width: "100%",
    objectFit: 'contain'
    // paddingTop: '56.25%', // 16:9
  },
  avatar: {
    //backgroundColor: red[500],
    backgroundColor: '#D3D3D3',
    margin: 0,
    width: 80,
    height: 80,
  },
  avatarIconButton: {
    padding: 0,
  },
  media: {
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    width : '100%',
    height: '100%'
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%', /* 720 / 1280 = 0.5625 */
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
    // bottom: 20,
  },
});

class ViewCompany extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userContent: '',
      getFollowees: '',
      getFollowee: '',
      user: null,
      userId: "",
      userUUId: '',
      avatarAttachmentURL: null,
      customerType: "",
      walletAddress: "",
      companyName: "",
      avatarimage: "",
      ceoName: '',
      companyNumber: '',
      companyPhoneNumber: '',
      companyFaxNumber: '',
      zoneCode: '',
      address_1: '',
      address_2: '',
      address_3: '',
      address_4: '',
      address: [],
      homepage: '',
      history: '',
      name: '',
      position: '',
      phoneNumber: '',
      email: '',
      isLoading: false,
      completed : 0,
      attachmentURLs: [],
      images: [],
      warningMessage: '',
    };
  }

  async componentDidMount() {
    const { followingloadingtrue, followingloadingfalse } = this.props;

    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    try {
      let avatarAttachmentURL;
      let attachmentURLs = [];

      const user = await this.getUser();

      const { userId, userUUId, customerType1, customerType2, walletAddress, companyName, avatarimage, ceoName, companyNumber, 
              companyPhoneNumber, companyFaxNumber, zoneCode, address, homepage, history, 
              name, position, phoneNumber, email, images } = user;

      this.setState({userId, userUUId});


      if (avatarimage != 'No_File') {
        avatarAttachmentURL = await Storage.get(avatarimage);
      }

      for(let i=0;i<images.length;i++) {
        if (images[i] != 'No_File') {
            attachmentURLs[i] = await Storage.get(images[i], {level: 'public'});
        } else if (images[i] === 'No_File') {
          attachmentURLs[i] = null
        }

        this.setState({
          attachmentURLs: this.state.attachmentURLs.concat(attachmentURLs[i]),
          images: this.state.images.concat(images[i]),
        });
      }

      this.setState({
        user,
        avatarAttachmentURL,
        customerType1, customerType2, walletAddress, companyName, avatarimage, ceoName, companyNumber, 
        companyPhoneNumber, companyFaxNumber, zoneCode, address, homepage, history, 
        name, position, phoneNumber, email,
        expanded : true,
      });

      this.setState({
        address_1: this.state.address[0],
        address_2: this.state.address[1],
        address_3: this.state.address[2],
        address_4: this.state.address[3]
      })
    } catch (e) {
      console.log("getUser() 에러");
    }

    if(this.state.walletAddress === "default") {
      this.setState({ walletAddress: "" });
    }
    if(this.state.companyName === "default") {
      this.setState({ companyName: "" });
    }
    if(this.state.avatarimage === 'No_File') {
      this.setState({ avatarimage: "" });
    }
    if(this.state.ceoName === "default") {
      this.setState({ ceoName: "" });
    }
    if(this.state.companyNumber === "default") {
      this.setState({ companyNumber: '' });
    }
    if(this.state.companyPhoneNumber === "default") {
      this.setState({ companyPhoneNumber: '' });
    }
    if(this.state.companyFaxNumber === "default") {
      this.setState({ companyFaxNumber: '' });
    }
    if(this.state.zoneCode === "default") {
      this.setState({ zoneCode: '' });
    }
    if(this.state.address[0] === "default") {
      this.setState({ address_1: ''});
    }
    if(this.state.address[1] === "default") {
      this.setState({ address_2: ''});
    }
    if(this.state.address[2] === "default") {
      this.setState({ address_3: ''});
    }
    if(this.state.address[3] === "default") {
      this.setState({ address_4: ''});
    }
    if(this.state.homepage === "default") {
      this.setState({ homepage: "" });
    }
    if(this.state.history === "default") {
      this.setState({ history: "" });
    }
    if(this.state.name === "default") {
      this.setState({ name: "" });
    }
    if(this.state.position === "default") {
      this.setState({ position: "" });
    }
    if(this.state.phoneNumber === "default") {
      this.setState({ phoneNumber: '' });
    }
    if(this.state.email === "default") {
      this.setState({ email: "" });
    }

    /*
    followingloadingtrue();
    followingloadingfalse();
    */
  }

  // 경로 ( /user/:id) 
  getUser() {
    return API.get("upload", `/user/${this.props.match.params.id}`)
  }

  /*
  // 내가 Following한 사람들 리스트
  getFollowees() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/follow/?followerUserId=${currentAuthUserFromSession}&followeeUserId=${this.props.match.params.id}`)
    .then(getFollowees => this.setState({getFollowees : getFollowees}));
  }
  */

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  connect = () => {
    console.log("연락 기능")
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  /*
  handlePutClick = () => {
    const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
    const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
    const loginType = localStorage.getItem("loginType");

    if(currentAuthUserFromSession_Company && loginType==='email') 
    {
      this.props.history.push(`/user/company/${currentAuthUserFromSession_Company}`);
    }
    if(currentAuthUserFromSession_Freelancer && loginType != 'email') 
    {
      this.props.history.push(`/user/freelancer/${currentAuthUserFromSession_Freelancer}`);
    }    
  }
  */

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
      userContent: '',
      getFollowees: '',
      completed : 0,
    });
    
    this.getUsers();
    // 초기화 이후 uploadContents 목록을 불러온다.
    // this.getComments();
    //this.getCommentsUploadUUId();
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, isFollowingLoading } = this.props;
    const { avatarAttachmentURL } = this.state;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
    const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
    const loginType = localStorage.getItem("loginType");

    if(isFollowingLoading) {
      this.getFollowees();
    }

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} variant="h6" align='center' noWrap>
                    기업 정보
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          {this.state.user
          ?
          <main className={classes.content}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                      <Typography className={classes.typoTitle}>업체명</Typography>
                    </ListItem>
                    <Divider/>  
                    <ListItem>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        {
                        this.state.avatarimage &&
                        this.state.avatarimage != 'No_File'
                        ?
                        <img
                          className={classes.avatarMedia}
                          src={avatarAttachmentURL}
                        />
                        :
                        <Avatar
                          aria-label="Recipe" 
                          className={classes.avatar}
                        >
                          <AccountCircleIcon style={{fontSize: '90px'}}/>
                        </Avatar>
                        }
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography className={classes.textFieldName}>{this.state.companyName}</Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        {/*
                        { this.state.userId === currentAuthUserFromSession
                        ?
                        null
                        /
                        <PopupState variant="popover" popupId="MoreVertIconPopover">
                        {popupState => (
                          <div>
                            <IconButton className={classes.iconButton} variant="contained" {...bindTrigger(popupState)}>
                              <MoreVertIcon className={classes.moreVertIcon}/>
                            </IconButton>
                            <Menu {...bindMenu(popupState)}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <MenuItem onClick={popupState.close} className={classes.popupStateMenu}>
                              {currentAuthUserFromSession_Freelancer && loginType !='email'
                              ?
                                <Link component={RouterLink} to={`/user/freelancer/${currentAuthUserFromSession_Freelancer}`} underline='none' color='inherit'>
                                  <Typography variant="body2" style={{ cursor: 'pointer' }} 
                                    /onClick={this.handlePutClick}/ className={classes.popupStateTypography}
                                  variant="h6" noWrap
                                  >
                                    수정
                                  </Typography>
                                </Link>
                                :
                                null
                              }
                              {currentAuthUserFromSession_Company && loginType==='email'
                              ?
                                <Link component={RouterLink} to={`/user/company/${currentAuthUserFromSession_Company}`} underline='none' color='inherit'>
                                  <Typography variant="body2" style={{ cursor: 'pointer' }} 
                                    /onClick={this.handlePutClick}/ className={classes.popupStateTypography}
                                    variant="h6" noWrap
                                  >
                                    수정
                                  </Typography>
                                </Link>
                                :
                                null
                              }
                              </MenuItem>
                            </Menu>
                          </div>
                        )}
                        </PopupState>
                        /
                        :
                        this.state.getFollowees.length > 0
                        ?
                        Object.keys(this.state.getFollowees).map(followingUUId => {
                          const getFollowee = this.state.getFollowees[followingUUId];
                          if(getFollowee.followeeUserId === this.state.userId) {
                            return(
                              <Feed_ViewFollow
                                stateRefresh={this.stateRefresh}
                                key={getFollowee.followingUUId}
                                followerUserId={getFollowee.followerUserId}
                                followeeUserId={getFollowee.followeeUserId}
                                followingUUId={getFollowee.followingUUId}
                                followingToggle={getFollowee.followingToggle}
                                date={getFollowee.date}
                              />
                            );
                          }
                        })
                        :
                        <Feed_ViewFollow
                          userId={this.state.userId}
                          userUUId={this.state.userUUId}
                        />
                        }
                        */}
                      </Grid>
                    </ListItem>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>대표자</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography>{this.state.ceoName}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>대표번호</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textField}>{this.state.companyPhoneNumber}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>팩스번호</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textField}>{this.state.companyFaxNumber}</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>회사 주소</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textField}>{this.state.address_3} {this.state.address_4}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>홈페이지</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textField}>{this.state.homepage}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>일감 분야</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                        <Typography className={classes.textField}>{this.state.history}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>담당자 이름</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textField}>{this.state.name}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>직책</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.textField}>{this.state.position}</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>연락처</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                        <Typography className={classes.textField}>{this.state.phoneNumber}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>이메일</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                        <Typography className={classes.textField}>{this.state.email}</Typography>
                        </ListItem>
                      </Grid>
                      <Divider/>
                    </Grid>
                    <Divider/>
                    <ListItem className={classes.listItem}>
                    </ListItem>
                    <Divider/>
                  </List>
                  {/* AWS S3 사용시 */}
                  {this.state.images &&
                    (this.state.images || []).map((image, index) => (
                      image != 'No_File'
                      ?
                      <CardActionArea key={image} disabled={image.length === 1 ? true : false}>
                        {image.substr(image.length-4, 4) == (".mp3" && ".mp4")
                        ?
                        <div className={classes.videoWrapper}>
                          <ReactPlayer
                            className={classes.reactPlayer} 
                            url={this.state.attachmentURLs[index]}
                            width='100%'
                            height='100%'
                            controls
                            playing
                            // 다운로드 금지 코드
                            config={{ file: {
                              attributes: {
                                controlsList: 'nodownload'
                              }
                            }}}
                          />
                        </div>
                        :
                       <img
                        className={classes.media}
                        src={this.state.attachmentURLs[index]}
                       />
                        }    
                      </CardActionArea>
                      :
                      null
                    ))
                  }
                </Card>
              </Grid>
            </Grid>
          </main>
          :
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          }
          {/*
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Toolbar>
              <Grid container spacing={0} direction="column" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {currentAuthUserFromSession == this.state.userId
                  ?
                  <Button variant="contained" color="primary" /className={classes.button}/  
                  >
                    <Typography className={classes.typoSubmit}>내 정보</Typography>
                  </Button>
                  :
                  <Button variant="contained" /color="primary"/ className={classes.button} 
                    onClick={this.connect}
                  >
                    <Typography className={classes.typoSubmit}>연락하기</Typography>
                  </Button>
                }
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          */}
        </div>         
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isFollowingLoading: state.upload.isFollowingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  followingloadingtrue: (isFollowingLoading) => dispatch(uploadActions.followingloadingtrue(isFollowingLoading)),
  followingloadingfalse: (isFollowingLoading) => dispatch(uploadActions.followingloadingfalse(isFollowingLoading)),
})

ViewCompany = withStyles(styles, {withTheme: true})(ViewCompany)
export default connect(mapStateToProps, mapDispatchToProps)(ViewCompany);