import React, { Component } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    //paddingBottom: 60,
    paddingBottom : theme.spacing(7),
  },
  topAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
    /*
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    */
  },
  toolBar: {
    height : 10,
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  title: {
    flexGrow: 1,
    fontSize : 16,
    color: 'black'
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  content: {
    marginTop: '30px',
    flexGrow: 1,
    //padding: theme.spacing(0),
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemTitle2: {
    paddingTop: 15,
    paddingBottom: 2,
  },
  listItemContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle2: {
    width: '100%',
    fontSize : 15,
    paddingTop: 0,
    paddingBottom: 0,
  },
  typoContent: {
    width: '100%',
    fontSize : 12,
    paddingTop: 0,
    paddingBottom: 0,
  },
});

class InfoAgreememnt extends Component {
  
  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.topAppBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography className={classes.title} variant="h6" noWrap>
                    개인정보 보호방침
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                      <Typography className={classes.typoTitle}>
                        개인정보 보호방침
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        일감은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 
                        고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있습니다. 회사는 개인정보 보호방침을 
                        개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 1조 (기본 원칙)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        일감(이하 “회사”)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법, 
                        개인정보보호법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 
                        관련 법령에 의거한 개인정보 보호방침을 정하여 회원 권익 보호에 최선을 다하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 2조 (최초 회원가입 시 수집하는 개인정보)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 회원가입 및 관리, 원활한 고충처리, 각종 서비스의 제공을 위해 최초 회원가입 당시 회원으로부터 
                        다음의 개인정보를 수집하고 있습니다. (단, 회원의 부정확한 정보는 보다 나은 서비스의 제공을 위하여 
                        일감에서 임의로 변경할 수 있습니다.)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 개인정보 수집 항목
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        처리한 개인정보는 다음의 목적이 외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 
                        구할 예정입니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 3조 (서비스 이용 시 수집하는 개인정보)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 원활하고 신뢰할 수 있는 서비스의 제공을 위해 회원가입 후 프로젝트의 등록, 지원 절차에 
                        앞서 회원으로부터 다음 각 호의 개인정보를 수집하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 기업회원의 프로젝트 등록 시 필수 항목 : 일감명, 일감 기간, 전문기술 등의 정보
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        나) 개인회원의 일감 지원 시 필수 항목 : 이름, 회사명, 담당자명, 성별, 나이, 휴대폰번호 등의 정보
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 원활하고 신뢰할 수 있는 서비스의 제공을 위해 계약 체결시 회원으로부터 다음의 개인정보를 수집하고 있습니다. 
                        사업자등록증, 주민등록증, 여권, 기타 신분증 등 신원을 확인할 수 있는 서류에 기재된 주민등록번호
                        (단, 주민등록번호의 경우 관련 법령에서 회원의 주민등록번호 수집·이용을 허용하는 경우에 한하여 수집합니다), 
                        사업자등록번호 등의 정보
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        - 본적으로 수집되는 정보 : IP주소, 쿠키, 서비스 이용 기록, 방문 기록, 접속 로그, 불량 이용 기록 등 정보
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                       - 서비스 이용 시 수집되는 정보 : 휴대전화번호 등 정보
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 서비스 이용과정에서 추가로 생성되는 다음 각 호의 회원의 정보들을 수집할 수 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 4조 (민감한 개인정보의 수집 금지 등)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 회원의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종, 사상, 신조, 정치적 성향, 범죄기록, 
                        의료정보 등)는 수집하지 않으며, 회원이 선택 항목의 정보를 입력하지 않은 경우에도 회사가 제공하는 서비스 
                        이용에 제한은 없습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 5조 (개인정보 수집 방법)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 다음 각 호의 방법으로 개인정보를 수집합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        - 회사가 제공하는 플랫폼, 서면 양식, 전화/팩스 등 서비스 가입이나 사용 중 회원의 자발적 제공을 통한 수집
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 6조 (개인정보 수집 및 이용 목적)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 다음 각 호의 목적으로 회원의 개인정보를 수집 및 이용합니다. 수집한 개인정보는 다음의 목적 이외의 용도로 
                        사용되지 않으며 이용 목적이 변경될 시에는 별도의 사전동의를 구합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 플랫폼 회원가입 및 관리: 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 
                        제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 
                        동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등의 목적
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        나) 민원사무의 처리: 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등의 목적
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        다) 서비스의 제공: 서비스 제공, 본인 인증, 연령 인증, 채권 추심 등의 목적
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        라) 마케팅 및 광고에의 활용: 신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 
                        인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 
                        대한 통계 등을 목적으로 개인정보를 처리합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 7조 (개인정보 제3자 제공의 기본 원칙)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 회원들의 개인정보를 제6조에 고지한 범위 내에서 사용하며, 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나 
                        원칙적으로 회원의 개인정보를 제3자에 제공하거나 외부에 공개하지 않습니다. 다만, 다음 각 호의 경우는 예외로 합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 회원들이 사전에 공개에 동의한 경우
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        나) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        다)  회사가 제공하는 서비스를 통해 거래가 성사되었을 때에는 쌍방 당사자간의 계약과 관련한 정보를 필요한 
                        범위 내에서 쌍방당사자에게 제공합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 8조 (예외적 개인정보 제3자 제공)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 현재 개인정보를 제3자에게 제공하거나 외부에 공개하고 있지 않습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 9조 (개인정보의 취급 위탁)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계 법령에 따라 위탁 처리되는 개인정보가 
                        안전하게 관리될 수 있도록 필요한 조치를 다하고 있습니다. 위탁 처리하는 정보는 서비스 제공에 필요한 최소한의 
                        범위에 국한되고, 회사에서 위탁처리 되고 있는 업무와 수탁업체는 아래와 같습니다. 위탁사항이 변경되는 경우 해당 
                        사실을 알려드리겠습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 10조 (개인정보 보유 및 이용기간의 기본 원칙)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        원칙적으로 회원의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 11조 (회사 내부 방침에 의한 개인정보의 보유)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        제10조에도 불구하고, 회사는 거래 관련 분쟁 방지를 위하여 회사 내부 방침에 따라 회원 가입 
                        및 관리 관련 정보를 다음 각 호의 기간 동안 보존합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 원칙 : 회원 탈퇴 시까지
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        나) 회원에 대하여 관계 법령 위반에 따른 수사·조사 등이 진행중인 경우: 해당 수사·조사 종료 시까지
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        다) 회사과 회원 사이에 채권·채무관계가 잔존하는 경우: 해당 채권·채무관계 정산 완료 시까지
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        라) 회원에서 탈퇴한 후 회원 재가입, 임의 해지 등을 반복적으로 행하여 정당하지 않은 경제상의 이익을 
                        취하거나 이 과정에서 명의를 무단으로 사용하는 편법과 불법행위를 하는 회원을 차단 하고자 회원 탈퇴 후 
                        1개월 동안 회원의 성명, 주민등록번호(또는 CI), DI, 아이디(ID), 비밀번호(Password), 이메일(E-mail)정보를 
                        보관 하며, 로그기록, 접속 아이피(IP)정보는 12개월간 보관합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 12조 (관련 법령에 의한 개인정보의 보유)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 다음 각 호에 따라 회원의 
                        해당 개인정보를 보관하며, 그 목적의 범위 내에서만 이를 이용합니다. 단, 회사 이용계약(이용약관)에 의해 이용계약이 
                        존속 중인 회원(탈퇴하지 아니한 회원)의 경우 보관기간은 보존의무기간 이상 보관할 수 있으며, 해당 기간이 경과된 
                        기록에 대해서는 회원의 삭제 요청이 있는 경우 파기합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 계약 또는 청약철회 등에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 5년간 보존
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        나) 대금결제 및 재화 등의 공급에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 5년간 보존
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        다) 소비자의 불만 또는 분쟁 처리에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 3년간 보존
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        라)  표시·광고에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 6개월간 보존
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        마) 본인확인에 관한 기록: 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 6개월간 보존
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        바) 전기통신일시, 개시·종료시간, 가입자 번호, 사용 도수, 발신 기지국 위치추적자료에 관한 기록: 
                        통신비밀보호법에 따라 1년간 보존
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        사) 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적 자료: 통신비밀보호법에 따라 3개월간 보존
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        아) 신용정보의 수집/처리 및 이용 등에 관한 기록: 3년
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 13조 (개인정보의 파기 절차 및 방법)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 원칙적으로 개인정보 이용 목적이 달성된 경우에는 파기 사유가 발생한 개인정보를 선정하고, 
                        회사의 개인정보 보호책임자의 승인을 받아 지체 없이 해당 개인정보를 파기합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        파기의 절차, 기한 및 방법은 다음과 같습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 파기 절차: 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 
                        내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 
                        개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        나) 파기 기한: 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 
                        5일 이내에, 개인정보의 이용 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 
                        되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        다) 파기 방법: 종이에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 
                        형태로 저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 사용하여 삭제합니다. 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 14조 (개인정보의 수집, 이용, 제공에 대한 동의 철회)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원은 회원 가입 등을 통해 개인정보의 수집, 이용, 제공에 대하여 동의한 내용을 언제든지 철회할 수 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 15조 (회원의 권리와 행사방법)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원은 회사에 대해 언제든지 다음과 각 호와 같은 권리를 행사할 수 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 개인정보 열람 요구
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        나) 오류 등이 있을 경우 정정 요구
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        다) 삭제 요구
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        라) 처리 정지 요구
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 
                        모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 본인 확인을 위한 요청인의 신분증 사본 등의 증표를 제시 받아 
                        해당 요구가 진정한 본인의 의사인지 여부를 확인할 수 있으며, 본인으로 확인되고 개인정보에 오류가 있거나 
                        보존기간을 경과한 것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우 지체 없이 그에 따른 조치를 
                        취합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 
                        개인정보를 이용하거나 제공하지 않습니다. 제1항에 따른 권리 행사는 회원의 법정대리인이나 위임을 받은 자 등 
                        대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 
                        합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 16조 (법정대리인의 권리)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        법정대리인은 만 14세 미만 아동의 개인정보 수집·이용 또는 제공에 대한 동의를 철회할 수 있으며, 해당 아동이 제공한 
                        개인정보에 대한 열람 또는 오류의 정정을 요구할 수 있습니다.
                        유선 또는 서면을 통하여 만 14세 미만 아동인 회원의 법정대리인이 열람, 증명을 요구하는 경우, 회사는 대리관계를 
                        증명하는 위임장 및 인감증명서, 요청인 및 대리인의 신분증 사본 등의 증표를 제시 받아 해당 요구를 하는 자가 진정한
                        법정대리인인지 여부를 확인합니다. 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 17조 (회사의 개인정보 열람 및 이용 제한)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원 또는 법정대리인의 요청에 의해 해지 또는 삭제, 정정된 개인정보는 제10조 내지 제12조에 명시된 바에 따라 
                        처리되고, 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 
                        있으며 가입 해지를 요청할 수도 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 18조 (쿠키의 운용)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 회원에게 특화된 맞춤서비스를 제공하기 위해서 회원들의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 
                        등을 운용합니다. 쿠키란 웹사이트를 운영하는 데 이용되는 서버가 회원의 브라우저에 보내는 아주 작은 텍스트 
                        파일로서 회원의 컴퓨터 하드디스크에 저장되기도 합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 회원의 취향과 관심분야를 파악 및 자취 추적, 각종 
                        이벤트 참여 정도 및 방문 횟수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스 제공 등의 목적으로 쿠키를 사용합니다. 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 
                        허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        라) 제3항에 따라 쿠키 설정을 거부하는 방법으로, 회원은 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 
                        허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 인터넷 익스플로러의 
                        경우를 예로 들면, 웹 브라우저 상단의 도구 - 인터넷 옵션 - 개인정보 메뉴를 통하여 쿠키 설정의 거부가 가능합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원이 쿠키 설치를 거부하는 경우 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 19조 (해킹 등에 대비한 대책)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 
                        관리하는 대책을 시행하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        정기적인 자체 감사 실시: 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        내부관리계획의 수립 및 시행: 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        개인정보의 암호화: 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 
                        중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 
                        있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        해킹 등에 대비한 기술적 대책: 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 
                        보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 
                        기술적/물리적으로 감시 및 차단하고 있습니다. 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        개인정보에 대한 접근 제한: 개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 
                        개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 
                        통제하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        접속 기록의 보관 및 위변조 방지: 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 
                        접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        문서보안을 위한 잠금 장치 사용: 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 
                        보관하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        비인가자에 대한 출입 통제: 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 
                        수립, 운영하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        기타: 그 외 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 20조 (취급 직원의 최소화 및 교육)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사의 개인정보 관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 
                        갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사는 개인정보 처리방침의 준수를 항상 강조하고 있습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 21조 (개인정보보호전담기구의 운영)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 사내 개인정보보호전담기구 등을 통하여 개인정보 처리방침의 이행사항 및 담당자의 준수여부를 확인하여 
                        문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 인터넷상의 
                        문제로 개인 정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 22조 (개인정보관리 책임자 및 담당자)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원은 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호에 대한 문의, 불만처리, 피해구제 등 관련 민원을 
                        다음 각 호의 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 
                        답변 및 처리해드릴 것입니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 23조 (개인정보 열람 창구)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회원은 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 회원의 개인정보 열람 청구가 신속하게 
                        처리되도록 노력하겠습니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 24조 (개인정보 처리방침 관련 고지 및 통지 방법)
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 현행 개인정보 처리방침에 대한 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 플랫폼을 
                        통해 변경 이유 및 그 내용을 고지합니다. 다만, 개인정보의 수집 및 활용 등에 있어 이용자 권리의 중요한 변경이 
                        있을 경우에는 최소 30일 전에 고지합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 회원이 동의한 범위를 넘어 회원의 개인정보를 이용하거나 제3자에게 취급위탁하기 위해 회원의 추가적인 
                        동의를 얻고자 하는 때에는 미리 회원에게 개별적으로 서면, 전자우편, 전화 등으로 해당사항을 고지합니다. 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        회사는 개인정보의 수집, 보관, 처리, 이용, 제공, 관리, 파기 등을 제3자에게 위탁하는 경우에는 개인정보 처리방침 
                        등을 통하여 그 사실을 회원에게 고지합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        만 14세 미만 아동의 개인정보를 수집, 이용하거나 제3자에게 제공하기 위하여 법정대리인의 동의를 받고자 하는 
                        경우에는 전화, 팩스, 우편, 아동으로 하여금 통지 내용을 법정대리인에게 제출하게 하는 방법, 법정대리인에게 
                        이메일을 보내 본 개인정보 처리방침을 볼 수 있도록 연결(하이퍼링크)시키는 방법 또는 기타 법정대리인에게 
                        고지 내용이 도달할 수 있는 합리적인 방법을 통하여 법정대리인에게 고지합니다.
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        이 약관은 가입한 날로부터 적용됩니다.
                      </Typography>
                    </ListItem>
                    {/*
                    <ListItem className={classes.listItemTitle2}>
                      <Typography className={classes.typoTitle2}>
                        제 몇 조
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        가) 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        나) 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        다) 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        라) 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        마) 
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoContent}>
                        바) 
                      </Typography>
                    </ListItem>
                    */}
                  </List>
                </Card>
              </Grid>
            </Grid>
          </main>
        </div>
      </div>
    );
  }
}

export default InfoAgreememnt = withStyles(styles, {withTheme: true})(InfoAgreememnt)