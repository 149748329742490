import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SVGIcon from '../SVGIcon'
import { connect } from 'react-redux'
import { isValidPrivateKey } from 'utils/crypto'
import AWSInput from './AWSInput'
import { withStyles } from '@material-ui/core/styles';
import AWSLoginButton from './AWSLoginButton'
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from '@material-ui/core/FormControl';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";

import * as authActions from 'redux/actions/auth'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

//import './AWSResetPassword.scss'
import AWSLoginForm from './AWSLoginForm';
//import './AWSInput.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#F5F5F5",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  appbarTitle: {
    flexGrow: 1,
    fontSize : 18,
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  content: {
    marginTop: '60px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  typoMainTitle: {
    width: '100%',
    fontSize : 30,
    paddingTop: 25,
    paddingBottom: 35,
  },
  button: {
    fontSize: 15,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  dialogButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  labelTop: {
    marginTop: 20,
    display: 'block',
    fontSize: 15,
    marginBottom: 8,
  },
  label: {
    display: 'block',
    fontSize: 15,
    marginBottom: 8,
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  LoginForm__input: {
    marginBottom: '16px',
    '& input': {
      paddingLeft: '48px',
      background: "url('/images/icon-lock.png') 16px center / 24px no-repeat",
    },
  },
  Input__input: {
    width: '100%',
    //borderWidth: 0,
    boxSizing: 'border-box',
    fontSize: '15px',
    border: '1px solid #c4c4c4', // replace $light-grey with #c4c4c4
    padding: '22px 24px',
    borderRadius: '5px',
    '&::placeholder': {
      color: '#8e8e8e', // replace $middle-grey with #8e8e8e
    },
    '&--err': {
      borderColor: '#f44336', // replace $alert-red with #f44336
    },
  }
});

class AWSResetPassword extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      code: "",
      email: "",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false,
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      completed : 0,
    }
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
    })
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    return (
      this.state.code.length > 5 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSendCodeClick = async event => {
    // event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true });

    } catch (e) {
      alert(e.message);
      this.setState({ isSendingCode: false });
    }
  };

  handleConfirmClick = async event => {
    // event.preventDefault();

    this.setState({ isConfirming: true });

    if(this.state.code.length != 6) {
      this.setState({alarmDialogOpen_1 : true});
    }

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });

    } catch (e) {
      // alert(e.message);
      console.log(e.message);
      this.setState({ 
        isConfirming: false,
        alarmDialogOpen_2: true
      });
    }
  };

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  componentDidMount() {
    this._isMounted = true;

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  handleRequestKeyPress = (e) => {
    // console.log("e.charCode :");
    // console.log(e.charCode);

    // key 13 = 엔터를 의미
    if(e.charCode === 13) {
      this.handleSendCodeClick()
    }
  }

  renderRequestCodeForm() {
    const { classes } = this.props
    const { warningMessage } = this.state
    return (
      <div className={classes.root}>
        {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolBar} variant="dense">
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  color="inherit"
                  aria-label="back"
                  onClick={this.handleGoBack}
                >
                  <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                </IconButton>
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <CardMedia
                  className={classes.logoMedia}
                  // image="/static/images/logo/app.svg"
                  image="/images/logo/app.svg"
                  aria-label="Recipe" 
                />
              </Grid>
              <Grid item xs={9} sm={9} md={4} lg={4} xl={4}>
                <Typography className={classes.appbarTitle} align='center' noWrap>
                  오투공 - Online To 공장
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
        </AppBar>
        <main className={classes.content}>
          <Grid container spacing={1} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Typography className={classes.typoMainTitle}>
                비밀번호 재설정
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={4} xl={4}>
              {/*<div className="LoginForm">*/}
              <div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.labelTop} /*className="Input__label"*/ htmlFor="email">
                      이메일 주소
                    </label>
                  }
                  <input
                    className={classes.Input__input}
                    id="email"
                    type="email"
                    name="email"
                    autoFocus
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="이메일주소 작성해주세요"
                    onKeyPress={(e) => this.handleRequestKeyPress(e)}
                    autoComplete="off"
                  />
                </div>
                {this.state.isLoading 
                ?
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                </Grid>
                :
                <AWSLoginButton
                  className={classes.button}
                  title="확인코드 발송"
                  disabled={!this.validateCodeForm()}
                  onClick={this.handleSendCodeClick}
                />
                }
              </div>
            </Grid>
          </Grid>
        </main>
      </div>
    )
  }

  handleConfirmKeyPress = (e) => {
    // console.log("e.charCode :");
    // console.log(e.charCode);

    // key 13 = 엔터를 의미
    if(e.charCode === 13) {
      this.handleConfirmClick()
    }
  }

  renderConfirmationForm() {
    const { classes } = this.props
    const { warningMessage } = this.state
    return (
      <div className={classes.root}>
        {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolBar} variant="dense">
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  color="inherit"
                  aria-label="back"
                  onClick={this.handleGoBack}
                >
                  <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                </IconButton>
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <CardMedia
                  className={classes.logoMedia}
                  // image="/static/images/logo/app.svg"
                  image="/images/logo/app.svg"
                  aria-label="Recipe" 
                />
              </Grid>
              <Grid item xs={9} sm={9} md={4} lg={4} xl={4}>
                <Typography className={classes.appbarTitle} align='center' noWrap>
                  오투공 - Online To 공장
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
        </AppBar>
        <main className={classes.content}>
          <Grid container spacing={1} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Typography className={classes.typoMainTitle}>
                비밀번호 재설정
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={4} xl={4}>
              {/*<div className="LoginForm">*/}
              <div>
                <span>
                  ({this.state.email})로 발송된 확인코드 체크해주세요.
                </span>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.labelTop} htmlFor="code">
                      확인코드
                    </label>
                  }
                  <input
                    className={classes.Input__input}
                    id="code"
                    type="tel"
                    name="code"
                    autoFocus
                    value={this.state.code}
                    onChange={this.handleChange}
                    placeholder="확인된 코드번호를 작성해주세요."
                    onKeyPress={(e) => this.handleConfirmKeyPress(e)}
                    autoComplete="off"
                  />
                </div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.label} htmlFor="password">
                      비밀번호 (숫자, 특수문자 포함)
                    </label>
                  }
                  <input
                    className={classes.Input__input}
                    id="password"
                    type="password"
                    name="password"
                    autoFocus
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder="새 비밀번호 작성 해주세요(숫자, 특수문자 포함)"
                    onKeyPress={(e) => this.handleConfirmKeyPress(e)}
                    autoComplete="off"
                  />
                </div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.label} htmlFor="confirmPassword">
                      비밀번호 확인 (숫자, 특수문자 포함)
                    </label>
                  }
                  <input
                    className={classes.Input__input}
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    autoFocus
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    placeholder="비밀번호 작성 해주세요(숫자, 특수문자 포함)"
                    onKeyPress={(e) => this.handleConfirmKeyPress(e)}
                    autoComplete="off"
                  />
                </div>
                {this.state.isLoading 
                ?
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                </Grid>
                :
                <AWSLoginButton
                  className={classes.button}
                  title="Confirm"
                  disabled={!this.validateResetForm()}
                  onClick={this.handleConfirmClick}
                />
                }
              </div>
            </Grid>
          </Grid>
        </main>
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>코드를 정확하게 다시 입력해주세요</DialogTitle>
        <DialogContent>코드 숫자는 6자리 입니다.</DialogContent>
        <DialogContent>빈칸도 글자로 간주 합니다.</DialogContent>
          <DialogActions>
            <Button variant="contained" className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2}>
        <DialogTitle>비밀번호 작성 방법</DialogTitle>
        <DialogContent>1. 6자리 이상 입력해주세요</DialogContent>
        <DialogContent>2. 소문자를 포함시켜주세요</DialogContent>
        <DialogContent>3. 숫자를 포함시켜주세요</DialogContent>
        <DialogContent>4. 특수문자를 포함시켜주세요</DialogContent>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  renderSuccessMessage() {
    return (
      <div className="success">
          {/*}
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
          */}
          <AWSLoginForm />
          <p>새 비밀번호로 저장 되었습니다.</p>
      </div>
    );
  }

  render() {
    const { classes } = this.props
    const { warningMessage } = this.state
    return (
      <div>
        {!this.state.codeSent
          ? this.renderRequestCodeForm()
          : !this.state.confirmed
            ? this.renderConfirmationForm()
            : this.renderSuccessMessage()}
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //email: state.auth.email,
  //password: state.auth.password,
})

const mapDispatchToProps = (dispatch) => ({
  //awslogin: () => dispatch(authActions.awslogin()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

AWSResetPassword = withStyles(styles, {withTheme: true})(AWSResetPassword);
export default connect(mapStateToProps, mapDispatchToProps)(AWSResetPassword);