import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SVGIcon from '../SVGIcon'
import { connect } from 'react-redux'
//import { isValidPrivateKey } from 'utils/crypto'
//import AWSInput from './AWSInput'
import { withStyles } from '@material-ui/core/styles';
import AWSLoginButton from './AWSLoginButton'
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";

//import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import KakaoButton from "./KakaoButton"

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth, API } from "aws-amplify";

import * as authActions from 'redux/actions/auth'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

//import './AWSLoginForm.scss'
//import './AWSInput.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(7),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#F5F5F5",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  appbarTitle: {
    flexGrow: 1,
    fontSize : 18,
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  content: {
    marginTop: '60px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  typoMainTitle: {
    width: '100%',
    fontSize : 30,
    paddingTop: 25,
    paddingBottom: 35,
  },
  button: {
    fontSize: 15,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  dialogButton: {
    width: "100%",
    fontSize: 16,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  label: {
    display: 'block',
    fontSize: 15,
    marginBottom: 8,
  },
  typoResetPassword1: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  typoResetPassword2: {
    color:'#0c93d0',
  },
  typoSignup1: {
    width: '100%',
    fontSize : 16,
    paddingTop: 10,
    //marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  typoSignup2: {
    color:'#0c93d0',
  },
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  LoginForm__input: {
    marginBottom: '16px',
    '& input': {
      paddingLeft: '48px',
      background: "url('/images/icon-lock.png') 16px center / 24px no-repeat",
    },
  },
  Input__input: {
    width: '100%',
    //borderWidth: 0,
    boxSizing: 'border-box',
    fontSize: '15px',
    border: '1px solid #c4c4c4', // replace $light-grey with #c4c4c4
    padding: '22px 24px',
    borderRadius: '5px',
    '&::placeholder': {
      color: '#8e8e8e', // replace $middle-grey with #8e8e8e
    },
    '&--err': {
      borderColor: '#f44336', // replace $alert-red with #f44336
    },
  }
});

class AWSLoginForm extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      privateKey: '',
      warningMessage: '',
      isLoading: false,
      email: "",
      password: "",
      completed : 0,
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
    }
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  // 뒤로 Back
  handleGoBack = () => {
    //this.props.history.goBack();
    this.props.history.push('/');
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  } 

  // Facebook 로그인 관련 메소드
  handleFbLogin = () => {
    const { userHasAuthenticatedTrue } = this.props
    userHasAuthenticatedTrue();
    this.props.history.push("/"); // 로그인 후 home 페이지로 이동
  };

  // Google 로그인 관련 메소드
  handleGlLogin = () => {
    const { userHasAuthenticatedTrue } = this.props
    userHasAuthenticatedTrue();
    this.props.history.push("/"); // 로그인 후 home 페이지로 이동
  };

  // 카카오톡 로그인 관련 메소드
  handleKKLogin = () => {
    const { userHasAuthenticatedTrue } = this.props
    userHasAuthenticatedTrue();
  };

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleLogin = async event => {
    //event.preventDefault();
    //console.log("handleLogin");

    const { /*awslogin,*/ userHasAuthenticatedTrue } = this.props
    const { email, password } = this.state
    this.setState({ isLoading: true });
    /* const { privateKey } = this.state */

    /*
    isValidPrivateKey(privateKey)
      ? klaytnlogin(privateKey)
      : this.setState({ warningMessage: '* Invalid Private Key' })
    */

    // awslogin();

    try {
      await Auth.signIn(email, password);
      // alert("Logged in"); // 로그인 하면 로그인 됬다는 창 띄워준다.

      localStorage.setItem("email", email);

      try {
        const userID = await Auth.currentAuthenticatedUser();
        // console.log("userID: ");
        // console.log(userID);
        const userID2 = await Auth.currentUserInfo(userID);
        // console.log("userID2: ");
        // console.log(userID2);
        if(userID2 === null) {
          const userID3 = JSON.stringify(userID);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
  
          userHasAuthenticatedTrue();
        } else if (userID2 != null) {
          const userID3 = JSON.stringify(userID2);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          
          userHasAuthenticatedTrue();
        }
      } catch (e) {
          if (e !== "not authenticated") {
            // alert(e);
            console.log(e)
          }
      }

      try {
        const user = await this.getUser();
    
        const { userId, customerType1, customerType2 } = user;
  
        localStorage.setItem("customerType1", customerType1);
        localStorage.setItem("customerType2", customerType2);
        //const customerType1_FromLocal = localStorage.getItem("customerType1");
        //console.log("Login customerType1_FromLocal :")
        //console.log(customerType1_FromLocal)
        
       } catch(e) {
         console.log(e)
       }

      //sessionStorage.setItem("FCMLoginToggle", "true");
      localStorage.setItem("loginType", "email");

      this.props.history.push("/"); // 로그인 후 home 페이지로 이동
    } catch (e) {
      // alert(e.message);
      console.log(e.message)
      if(e.message === "User does not exist.") {
        this.setState({alarmDialogOpen_1: true})
      }
      if(e.message === "Incorrect username or password.") {
        this.setState({alarmDialogOpen_2: true})
      }
      this.setState({ isLoading: false });
    }
  }

  handleDialClose = () => {
    this.setState({      
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
    })
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  componentDidMount() {
    this._isMounted = true;

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    const { isAuthenticated } = this.state
    // console.log('isAuthenticated: ' + isAuthenticated);
  }

  handleKeyPress = (e) => {
    // console.log("e.charCode :");
    // console.log(e.charCode);

    // key 13 = 엔터를 의미
    if(e.charCode === 13) {
      this.handleLogin()
    }
  }

  render() {
    const { classes } = this.props
    const { warningMessage } = this.state
    return (
      <div className={classes.root}>
        {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolBar} variant="dense">
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  color="inherit"
                  aria-label="back"
                  onClick={this.handleGoBack}
                >
                  <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                </IconButton>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <CardMedia
                  className={classes.logoMedia}
                  // image="/static/images/logo/app.svg"
                  image="/images/logo/app.svg"
                  aria-label="Recipe" 
                />
              </Grid>
              <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                <Typography className={classes.appbarTitle} align='center' noWrap>
                  오투공 - Online To 공장
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
        </AppBar>
        <main className={classes.content}>
          <Grid container spacing={1} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Typography className={classes.typoMainTitle}>
                오투공에 오신 것을 환영합니다.
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={4} xl={4}>
              {/*<div className="LoginForm">*/}
              <div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.label} /*className="Input__label"*/ htmlFor="email">
                      이메일
                    </label>
                  }
                  <input
                    className={classes.Input__input}
                    id="email"
                    type="email"
                    name="email"
                    onChange={this.handleChange}
                    placeholder="이메일주소"
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    autoComplete="off"
                  />
                </div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.label} /*className="Input__label"*/ htmlFor="password">
                      비밀번호
                    </label>
                  }
                  <input
                    className={classes.Input__input}
                    id="password"
                    type="password"
                    name="password"
                    onChange={this.handleChange}
                    placeholder="비밀번호"
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    autoComplete="off"
                  />
                </div>
                <Typography className={classes.typoResetPassword1}>
                  <Link component={RouterLink} to="/resetpassword" underline='none' className={classes.typoResetPassword2}>
                    비밀번호 찾기
                  </Link>
                </Typography>
                <AWSLoginButton
                  //className="LoginForm__button"
                  className={classes.button}
                  title="로그인"
                  disabled={!this.validateForm()}
                  onClick={this.handleLogin}
                />
                <GoogleButton
                  onLogin={this.handleGlLogin}
                />
                {/*
                <FacebookButton
                  onLogin={this.handleFbLogin}
                />
                */}
                {/*
                <KakaoButton
                  onLogin={this.handleKKLogin}
                />
                */}
                <Divider />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Typography className={classes.typoSignup1}>
                계정이 없으신가요? &nbsp;
                <Link component={RouterLink} to="/chooseaccount" underline='none' className={classes.typoSignup2}>
                  회원가입
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </main>
        <Dialog open={this.state.alarmDialogOpen_1} onClose={this.handleDialClose}>
          <DialogTitle>등록되어 있지 않은 이메일 주소 입니다.</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.dialogButton}
                  onClick={this.handleDialClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2} onClose={this.handleDialClose}>
          <DialogTitle>이메일 주소나 비빌번호를 잘못 입력하셨습니다.</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.dialogButton}
                  onClick={this.handleDialClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //email: state.auth.email,
  //password: state.auth.password,
})

const mapDispatchToProps = (dispatch) => ({
  //awslogin: () => dispatch(authActions.awslogin()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

AWSLoginForm = withStyles(styles, {withTheme: true})(AWSLoginForm);
AWSLoginForm = connect(mapStateToProps, mapDispatchToProps)(AWSLoginForm);
// 하위 컴포넌트에서 this.props.history.push("/") 사용시 withRouter 써야 한다.
 export default withRouter(AWSLoginForm)