import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(14),   // original 8
    marginBottom : theme.spacing(0),
    backgroundColor: '#FCB04C',
  },
  mainMedia: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '75%',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '45%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '45%',
    },
  },
  imageOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageText: {
    color: 'black', // Set text color to black
    fontSize: '24px', // Adjust font size as needed
    //fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Set background color to transparent white
    padding: theme.spacing(2),
  },
});

class NewPagePrepare extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
      };
  }

  render() {
    const { classes, } = this.props;
    const {  } = this.state;

    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/* 세로로 정렬시 사용 */}
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <CardMedia className={classes.mainMedia} image={'/images/main/white_bg.png'}>
                      <div className={classes.imageOverlay}>
                        <Typography className={classes.imageText}>새 패이지로 단장중입니다.</Typography>
                      </div>
                    </CardMedia>
                  </Grid>
                </Grid>
            </Grid>             
          </Grid>
        </Grid>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

NewPagePrepare = withStyles(styles, {withTheme: true})(NewPagePrepare)
export default connect(mapStateToProps, mapDispatchToProps)(NewPagePrepare)