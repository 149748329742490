import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import Feed_FieldForm_Admin from './Feed_FieldForm_Admin';
import Button from '@material-ui/core/Button';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import { API } from "aws-amplify";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    [theme.breakpoints.up('xs')]: { // xs보다 up일때 동작
      marginTop : theme.spacing(7),
    },
    [theme.breakpoints.down('xs')]: { // xs 보다 down 일때 동작
      marginTop : theme.spacing(7),
    },
  },
  button: {
    width: '100%',
    backgroundColor: '#0c93d0',
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoMoreSee: {
    fontSize: 17,
  },
  expandMoreOutlinedIcon: {
    width: 30,
    height: 30
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class FieldFormProject_Admin extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);

      this.state = {
        publicUploadContents: '',
        publicUploadContent: '',
        userContents: '',
        userContent: '',
        image: "",
        getNotifications: "",
        fcmTokenId: "",
        userId: "",
        getFollowees: '',
        //favoriteThumbs: '',
        //favoriteThumb: '',
        getApplications: '',
        getApplication: '',
        pointSum: '',
        // scroll 관련
        page: 1,
        pagelimit: 9,
        searchContent: '',  // DB 검색 키워드
        hasNextPage: false, // 다음 페이지가 false이면 DB에서 더이상 가지고 올 자료 없다는 뜻
        completed : 0,
      };
  }

  getPublicUploads() {
    const currentPage = localStorage.getItem('currentPage')
    const searchKeyword = sessionStorage.getItem("searchKeyword")
    // const { searchKeyword } = this.props;

    if(currentPage === "fieldForm_home_admin") {
      // 만약 검색 키워드 없으면 실행
      if(!searchKeyword) {
        return API.get("upload", `/upload/?&subField=0&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
      }
      // 검색 키워드 있을때 실행
      if(currentPage === 'search') {
        return API.get("upload", `/upload/?subField=0&content=${searchKeyword}&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
      }
    } else if (currentPage != "fieldForm_home_admin") {
      // 만약 검색 키워드 없으면 실행
      if(!searchKeyword) {
        return API.get("upload", `/upload/?field=${currentPage}&subField=0&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
      }
      // 검색 키워드 있을때 실행
      if(currentPage === 'search') {
        return API.get("upload", `/upload/?subField=0&content=${searchKeyword}&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
      } else {
        return API.get("upload", `/upload/?field=${currentPage}&subField=0&content=${searchKeyword}&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
      }
    }
  }

  getUsers() {
    return API.get("upload", "/user")
    .then(userContents => this.setState({userContents : userContents}));
  }

  getPoint() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/point/${currentAuthUserFromSession}`)
  }

  /*
  getFavorites() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/favorite/?userId=${currentAuthUserFromSession}`)
    .then(favoriteThumbs => this.setState({favoriteThumbs : favoriteThumbs}));
  }
  */

  // 내가 지원한 프로젝트 리스트
  getApplications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/application/?userId=${currentAuthUserFromSession}`)
    .then(getApplications => this.setState({getApplications : getApplications}));
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(!(currentAuthUserFromSession === "ap-northeast-2:0eac84f9-d5d6-454a-8468-f46c99cef2ec"
    || currentAuthUserFromSession === "ap-northeast-2:1ef91eaf-bfd0-49eb-91ce-f70191165a07"
    )) {
      this.props.history.push("/");
    }

    // localStorage.setItem("currentPage", "fieldForm_Project");

    //----------------------------------------------------------------------------------

    const currentPage = localStorage.getItem("currentPage");

    const currentTapLink = localStorage.getItem("currentTapLink");

    if(currentPage === '3dprinter_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '3D프린터 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '3D프린터 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '3D프린터 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '3D프린터 기술자홍보')
      }
    }
    if(currentPage === 'iot_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'IoT 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'IoT 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'IoT 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'IoT 기술자홍보')
      }
    }
    if(currentPage === 'smartfactory_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '스마트팩토리 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '스마트팩토리 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '스마트팩토리 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '스마트팩토리 기술자홍보')
      }
    }
    if(currentPage === 'arvr_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'AR,VR 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'AR,VR 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'AR,VR 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'AR,VR 기술자홍보')
      }
    }
    if(currentPage === 'autocad_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '오토 캐드 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '오토 캐드 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '오토 캐드 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '오토 캐드 기술자홍보')
      }
    }
    if(currentPage === 'solidworks_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '솔리드 웍스 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '솔리드 웍스 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '솔리드 웍스 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '솔리드 웍스 기술자홍보')
      }
    }
    if(currentPage === 'inventor_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '인벤터 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '인벤터 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '인벤터 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '인벤터 기술자홍보')
      }
    }
    if(currentPage === 'catia_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '카티아 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '카티아 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '카티아 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '카티아 기술자홍보')
      }
    }
    if(currentPage === 'designetc_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '설계 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '설계 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '설계 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '설계 기타 기술자홍보')
      }
    }
    if(currentPage === 'melsec_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'Melsec 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'Melsec 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'Melsec 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'Melsec 기술자홍보')
      }
    }
    if(currentPage === 'ls_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'LS 산전 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'LS 산전 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'LS 산전 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'LS 산전 기술자홍보')
      }
    }
    if(currentPage === 'siemens_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '지멘스 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '지멘스 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '지멘스 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '지멘스 기술자홍보')
      }
    }
    if(currentPage === 'plcetc_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'PLC 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'PLC 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'PLC 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'PLC 기타 기술자홍보')
      }
    }
    if(currentPage === 'machineassembly_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '장비 조립 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '장비 조립 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '장비 조립 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '장비 조립 기술자홍보')
      }
    }
    if(currentPage === 'machineharness_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '장비 배선 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '장비 배선 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '장비 배선 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '장비 배선 기술자홍보')
      }
    }
    if(currentPage === 'factoryharness_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '공장 배선 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '공장 배선 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '공장 배선 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '공장 배선 기술자홍보')
      }
    }
    if(currentPage === 'machinerepair_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '장비 유지,보수 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '장비 유지,보수 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '장비 유지,보수 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '장비 유지,보수 기술자홍보')
      }
    }
    if(currentPage === 'partsrepair_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '부품수리 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '부품수리 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '부품수리 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '부품수리 기술자홍보')
      }
    }
    if(currentPage === 'lathemilling_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '범용 선반,밀링 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '범용 선반,밀링 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '범용 선반,밀링 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '범용 선반,밀링 기술자홍보')
      }
    }
    if(currentPage === 'cnclathe_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'CNC 선반 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'CNC 선반 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'CNC 선반 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'CNC 선반 기술자홍보')
      }
    }
    if(currentPage === 'mct_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', 'MCT 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', 'MCT 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', 'MCT 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', 'MCT 기술자홍보')
      }
    }
    if(currentPage === 'engravingmachine_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '조각기 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '조각기 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '조각기 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '조각기 기술자홍보')
      }
    }
    if(currentPage === 'press_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '프레스,판금 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '프레스,판금 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '프레스,판금 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '프레스,판금 기술자홍보')
      }
    }
    if(currentPage === 'cam_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '캠 프로그램 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '캠 프로그램 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '캠 프로그램 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '캠 프로그램 기술자홍보')
      }
    }
    if(currentPage === 'processingetc_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '가공 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '가공 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '가공 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '가공 기타 기술자홍보')
      }
    }
    if(currentPage === 'cylindricalgrinding_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '원통 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '원통 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '원통 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '원통 연마 기술자홍보')
      }
    }
    if(currentPage === 'planegrinding_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '평면 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '평면 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '평면 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '평면 연마 기술자홍보')
      }
    }
    if(currentPage === 'rotarygrinding_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '로터리 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '로터리 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '로터리 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '로터리 연마 기술자홍보')
      }
    }
    if(currentPage === 'centerlessgrinding_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '센터리스 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '센터리스 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '센터리스 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '센터리스 연마 기술자홍보')
      }
    }
    if(currentPage === 'toolgrinding_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '공구 연마 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '공구 연마 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '공구 연마 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '공구 연마 기술자홍보')
      }
    }
    if(currentPage === 'honing_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '호닝 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '호닝 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '호닝 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '호닝 기술자홍보')
      }
    }
    if(currentPage === 'profile_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '프로파일 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '프로파일 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '프로파일 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '프로파일 기술자홍보')
      }
    }
    if(currentPage === 'grindingetc_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '연마 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '연마 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '연마 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '연마 기타 기술자홍보')
      }
    }
    if(currentPage === 'Argonwelding_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '알곤 용접 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '알곤 용접 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '알곤 용접 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '알곤 용접 기술자홍보')
      }
    }
    if(currentPage === 'laserwelding_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '레이저 용접 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '레이저 용접 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '레이저 용접 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '레이저 용접 기술자홍보')
      }
    }
    if(currentPage === 'lasermarking_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '레이저 마킹 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '레이저 마킹 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '레이저 마킹 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '레이저 마킹 기술자홍보')
      }
    }
    if(currentPage === 'weldingetc_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '용접 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '용접 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '용접 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '용접 기타 기술자홍보')
      }
    }
    if(currentPage === 'gundrill_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '건드릴 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '건드릴 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '건드릴 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '건드릴 기술자홍보')
      }
    }
    if(currentPage === 'heattreatment_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '열처리 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '열처리 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '열처리 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '열처리 기술자홍보')
      }
    }
    if(currentPage === 'plated_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '도금,아노다이징 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '도금,아노다이징 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '도금,아노다이징 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '도금,아노다이징 기술자홍보')
      }
    }
    if(currentPage === '3dmeasuring_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '3차원 측정 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '3차원 측정 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '3차원 측정 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '3차원 측정 기술자홍보')
      }
    }
    if(currentPage === 'etcetc_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '가공 기타 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '가공 기타 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '가공 기타 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '가공 기타 기술자홍보')
      }
    }
    if(currentPage === 'english_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '영어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '영어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '영어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '영어 통,번역 기술자홍보')
      }
    }
    if(currentPage === 'chinese_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '중국어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '중국어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '중국어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '중국어 통,번역 기술자홍보')
      }
    }
    if(currentPage === 'japanese_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '일본어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '일본어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '일본어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '일본어 통,번역 기술자홍보')
      }
    }
    if(currentPage === 'vietnamese_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '베트남어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '베트남어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '베트남어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '베트남어 통,번역 기술자홍보')
      }
    }

    if(currentPage === 'languageetc_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '기타 언어 통,번역 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '기타 언어 통,번역 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '기타 언어 통,번역 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '기타 언어 통,번역 기술자홍보')
      }
    }
    if(currentPage === 'factoryshare_admin') {
      if(currentTapLink === '0') {
        localStorage.setItem('currentFieldName', '공장 공유 일감요청')
      } else if(currentTapLink === '1') {
        localStorage.setItem('currentFieldName', '공장 공유 교육요청')
      } else if(currentTapLink === '2') {
        localStorage.setItem('currentFieldName', '공장 공유 기업홍보')
      } else if(currentTapLink === '3') {
        localStorage.setItem('currentFieldName', '공장 공유 기술자홍보')
      }
    }
    //----------------------------------------------------------------------------------

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: " + userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: " + userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
        // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // currentAuthUser(currentAuthUserFromSession);
        userHasAuthenticatedTrue();
      } else {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
        // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // currentAuthUser(currentAuthUserFromSession);
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          alert(e);
        }
    }

    await this.getUsers();
    // await this.getFavorites();
    await this.getApplications();

    const publicUploads = await this.getPublicUploads();

    const { docs, hasNextPage } = publicUploads;
    this.setState({publicUploadContents: docs})
    this.setState({hasNextPage: hasNextPage})

    /*
    //-------------------------------------------------------------------------------
    // 스크롤 방식. PC 및 모바일 삼성인터넷에서는 잘 동작됨. 단, 모바일 크롬에서 잘 안됨.
    // 일단 '더 보기' 버튼 방식으로 바꿈
    //-------------------------------------------------------------------------------
    window.addEventListener("scroll", this.infiniteScroll, true);
    // 스크롤 이벤트는 꼭 삭제해줍니다!
    return () => window.removeEventListener("scroll", this.infiniteScroll, false);
    */
  }

  handleUpload = () => {
    const { upload } = this.props
    upload()
  }

  moreSeeContents = async() => {
    if(this.state.hasNextPage === true) {
      this.state.pagelimit = this.state.pagelimit + 9;
        
      const publicUploads = await this.getPublicUploads();
    
      const { docs, hasNextPage } = publicUploads;
      this.setState({publicUploadContents: docs})
      this.setState({hasNextPage: hasNextPage})
    }
  }

  /*
  infiniteScroll = async() => {
    // let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    // let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    let scrollHeight = Math.ceil(document.documentElement.scrollHeight);
    let scrollTop = Math.ceil(document.documentElement.scrollTop);
    let clientHeight = Math.ceil(document.documentElement.clientHeight);

    console.log("scrollHeight :")
    console.log(scrollHeight)
    console.log("scrollTop + clientHeight :")
    console.log(scrollTop + clientHeight)

    if(this.state.hasNextPage === true) {
      if(scrollTop + clientHeight === scrollHeight) {
        this.state.pagelimit = this.state.pagelimit + 3;
        
        const publicUploads = await this.getPublicUploads();

        const { docs, hasNextPage } = publicUploads;
        this.setState({publicUploadContents: docs})
        this.setState({hasNextPage: hasNextPage})
      }  
    }
  }
  */

  // MainAppShellField에서 검색시 동작하는 함수
  searchUpload = async() => {

    const { searchkeywordfalse } = this.props;
    
    this.state.pagelimit = 9; // 검색 실행시 초기화

    const publicUploads = await this.getPublicUploads();

    const { docs, hasNextPage } = publicUploads;
    this.setState({publicUploadContents: docs})
    this.setState({hasNextPage: hasNextPage})

    searchkeywordfalse();
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 사용자가 삭제 버튼을 눌렀을 때 실행하는 함수
  handleDelete = (id) => {
    this._delete(id);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    uploadContents : '',
    userContents : '',
    // favoriteThumbs : '',
    completed : 0,
    });
    
    this.getUsers();
    this.getPublicUploads();
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, /*isFavoriteLoading,*/ isApplyingLoading, isSearchKeyword } = this.props;

    /*
    if(isFavoriteLoading) {
      this.getFavorites();
    }
    */

    if(isApplyingLoading) {
      this.getApplications();
    }

    if(isSearchKeyword) {
      this.searchUpload();
    }

    /*
    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredComponents = (data) => {
      data = data.filter((c) => {
        return c.content.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {

        let WalletAddress;
        let Avatarimage;
        let CustomerType;
        let CompanyName;
        let CompanyPhoneNumber;
        let Address;
        let Homepage;
        let Name;
        let PhoneNumber;
        let Email;
        
        Object.keys(this.state.userContents).map(userId => {
          const userContent = this.state.userContents[userId];
          if(userContent.userId === c.userId) {
            WalletAddress = userContent.walletAddress;
            Avatarimage = userContent.avatarimage;
            CustomerType = userContent.customerType;
            CompanyName = userContent.companyName;
            CompanyPhoneNumber = userContent.companyPhoneNumber;
            Address = userContent.address;
            Homepage = userContent.homepage;
            Name = userContent.name;
            PhoneNumber = userContent.phoneNumber;
            Email = userContent.email;
          }
        });

        /*
        let favoriteUserId;
        let favoriteUUId;
        let favoriteContent;
        let favoriteContentNumbers = 0;
        Object.keys(this.state.favoriteThumbs).map(uploadUUId => {
          const favoriteThumb = this.state.favoriteThumbs[uploadUUId];
          if(favoriteThumb.uploadUUId === c.uploadUUId) {
            if(favoriteThumb.userId === currentAuthUserFromSession) {
              favoriteUserId = favoriteThumb.userId;
              favoriteUUId = favoriteThumb.favoriteUUId;
              favoriteContent = favoriteThumb.favoriteContent;
            }
            favoriteContentNumbers = favoriteContentNumbers + 1;
          }
        });
        /

        let applicationTitle;
        let applicationToggle;
        Object.keys(this.state.getApplications).map(applicationUUId => {
          const getApplication = this.state.getApplications[applicationUUId];
          if(getApplication.uploadUUId === c.uploadUUId) {
            applicationTitle=getApplication.applicationTitle;
            applicationToggle=getApplication.applicationToggle;
            }
        });

        return(
          <Feed_FieldForm
            stateRefresh={this.stateRefresh}
            key={c.uploadUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
            id={c.id}
            userId={c.userId}
            uploadUUId={c.uploadUUId}
            walletAddress={WalletAddress}
            customerType={CustomerType}
            companyName={CompanyName}
            companyPhoneNumber={CompanyPhoneNumber}
            address={Address}
            homepage={Homepage}
            name={Name}
            phoneNumber={PhoneNumber}
            email={Email}
            avatarimage={Avatarimage}
            date={c.date}
            images={c.images}  // AWS S3 사용시
            field={c.field}
            subField={c.subField}
            title={c.title}
            content={c.content}
            dateStart={c.dateStart}
            dateEnd={c.dateEnd}
            /*
            favoriteUserId={favoriteUserId}
            favoriteUUId={favoriteUUId}
            favoriteContent={favoriteContent}
            favoriteContentNumbers={favoriteContentNumbers}
            /
            applicationTitle={applicationTitle}
            applicationToggle={applicationToggle}
          />
        );
      })
    }
    */

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <Grid container spacing={0} item xs={12} sm={12} md={10} lg={10} xl={10}>
            {/* filter(검색)기능 */}
            {this.state.publicUploadContents && this.state.userContents
            /*&& this.state.favoriteThumbs*/ && this.state.getApplications
            ?
            // filteredComponents(this.state.publicUploadContents)
            Object.keys(this.state.publicUploadContents).map(uploadUUId => {
              // uploadContent가 데이터 베이스에 있는데 id값을 이용해서 각각 uploadContent 변수 
              const c = this.state.publicUploadContents[uploadUUId];

              let WalletAddress;
              let Avatarimage;
              let CustomerType1;
              let CustomerType2;
              let CompanyName;
              let CompanyPhoneNumber;
              let Address;
              let Homepage;
              let Name;
              let PhoneNumber;
              let Email;
        
              Object.keys(this.state.userContents).map(userId => {
                const userContent = this.state.userContents[userId];
                if(userContent.userId === c.userId) {
                  WalletAddress = userContent.walletAddress;
                  Avatarimage = userContent.avatarimage;
                  CustomerType1 = userContent.customerType1;
                  CustomerType2 = userContent.customerType2;
                  CompanyName = userContent.companyName;
                  CompanyPhoneNumber = userContent.companyPhoneNumber;
                  Address = userContent.address;
                  Homepage = userContent.homepage;
                  Name = userContent.name;
                  PhoneNumber = userContent.phoneNumber;
                  Email = userContent.email;
                }
              });

              /*
              let favoriteUserId;
              let favoriteUUId;
              let favoriteContent;
              let favoriteContentNumbers = 0;
              Object.keys(this.state.favoriteThumbs).map(uploadUUId => {
                const favoriteThumb = this.state.favoriteThumbs[uploadUUId];
                if(favoriteThumb.uploadUUId === c.uploadUUId) {
                  if(favoriteThumb.userId === currentAuthUserFromSession) {
                    favoriteUserId = favoriteThumb.userId;
                    favoriteUUId = favoriteThumb.favoriteUUId;
                    favoriteContent = favoriteThumb.favoriteContent;
                  }
                  favoriteContentNumbers = favoriteContentNumbers + 1;
                }
              });
              */

              let applicationTitle;
              let applicationToggle;
              Object.keys(this.state.getApplications).map(applicationUUId => {
                const getApplication = this.state.getApplications[applicationUUId];
                if(getApplication.uploadUUId === c.uploadUUId) {
                  applicationTitle=getApplication.applicationTitle;
                  applicationToggle=getApplication.applicationToggle;
                  }
              });

              return(
                <Feed_FieldForm_Admin
                  stateRefresh={this.stateRefresh}
                  key={c.uploadUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                  id={c.id}
                  userId={c.userId}
                  uploadUUId={c.uploadUUId}
                  walletAddress={WalletAddress}
                  customerType1={CustomerType1}
                  customerType2={CustomerType2}
                  companyName={CompanyName}
                  companyPhoneNumber={CompanyPhoneNumber}
                  address={Address}
                  homepage={Homepage}
                  name={Name}
                  phoneNumber={PhoneNumber}
                  email={Email}
                  avatarimage={Avatarimage}
                  date={c.date}
                  images={c.images}  // AWS S3 사용시
                  field={c.field}
                  subField={c.subField}
                  title={c.title}
                  content={c.content}
                  dateStart={c.dateStart}
                  dateEnd={c.dateEnd}
                  /*
                  favoriteUserId={favoriteUserId}
                  favoriteUUId={favoriteUUId}
                  favoriteContent={favoriteContent}
                  favoriteContentNumbers={favoriteContentNumbers}
                  */
                  applicationTitle={applicationTitle}
                  applicationToggle={applicationToggle}
                />
              );
            })
            :
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
            </Grid>
            }
          </Grid>
          {this.state.hasNextPage
          ?
          <Grid container spacing={0} item xs={12} sm={12} md={10} lg={10} xl={10}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button} 
                  onClick={this.moreSeeContents}
                >
                  <Typography className={classes.typoMoreSee}>더보기</Typography>
                  <ExpandMoreOutlinedIcon className={classes.expandMoreOutlinedIcon}/>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          :
          null
          }
        </Grid>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  uploadPage: state.upload.uploadPage,
  uploadContents: state.upload.uploadContents,
  // searchKeyword: state.upload.searchKeyword, // 세션스토리지로 대체 됨
  isSearchKeyword: state.upload.isSearchKeyword,
  /*
  isFavoriteLoading: state.upload.isFavoriteLoading,
  */
  isApplyingLoading: state.upload.isApplyingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  // currentAuthUser: (currentUser) => dispatch(authActions.currentAuthUser(currentUser)),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  upload: () => dispatch(uploadActions.upload()),
  /*
  favoriteloadingtrue: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingtrue(isFavoriteLoading)),
  favoriteloadingfalse: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingfalse(isFavoriteLoading)),
  */
  applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
  // 세션스토리지로 대체 됨
  // uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
  searchkeywordtrue: () => dispatch(uploadActions.searchkeywordtrue()),
  searchkeywordfalse: () => dispatch(uploadActions.searchkeywordfalse()),
})

FieldFormProject_Admin = withStyles(styles, {withTheme: true})(FieldFormProject_Admin)
export default connect(mapStateToProps, mapDispatchToProps)(FieldFormProject_Admin)