import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { connect } from 'react-redux'

// S3 관련
import { API, Storage } from "aws-amplify";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "0px",
    // maxWidth: 650,
    // minWidth : 500,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  list: {
    width: '100%',
    padding: '0',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  listItemName: {
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemContent: {
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemPreInvoice: {
    paddingTop: 7,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  media: {
    marginTop: 5,
    width: 100,
    height: 50,
    objectFit: 'contain'
  },
  avatar: {
    // backgroundColor: red[500],
    backgroundColor: '#D3D3D3',
    marginTop: 5,
    width: 50,
    height: 50,
  },
  typoName: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
  // background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoHistory: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    //paddingLeft: 20
  },
  typoAddress: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 20
  },
  typoPreInvoice: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 20
  },
  typoContent: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 20
  },
  upperButton: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  underButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button: {
    width: '80%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button2: {
    width: '80%',
  },
  dialogButton: {
    fontSize: 17,
    width: '80%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoDialogTitle: {
    fontSize: 17,
  },
  typoDialogContent: {
    fontSize: 17,
  },
});

class Feed_ApplicantList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarAttachmentURL: null,
      avatarimage: "",
      customerName: '',
      address_2:'',
      MoreVertIconOpen: false,
      callDialogOpen: false,
      emailDialogOpen: false,
      videoMeetingDialogOpen: false,
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  /*
  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }
  */

  handleCall = () => {
    this.setState({callDialogOpen: true});
  }

  handleEmail = () => {
    this.setState({emailDialogOpen: true});
  }

  handleVideoMeeting = () => {
    this.setState({videoMeetingDialogOpen: true});
  }

  handlehangoutManual = () => {
    this.setState({videoMeetingDialogOpen: false})
  }

  handleGoToHangout = () => {
    this.setState({videoMeetingDialogOpen: false})
    window.open('https://hangouts.google.com')  // 새로운 탭에서 열림
    // window.location.href = 'https://hangouts.google.com'; // 현재 탭에서 열림
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      callDialogOpen: false,
      emailDialogOpen: false,
      videoMeetingDialogOpen: false,
    })
  }

  handleUserIdSave = () => {
    // 지원자의 userId -> OrderPage에서 사용하기 위해서
    sessionStorage.setItem('applicantUserId', this.props.userId)
  }

  async componentDidMount() {
    // AWS S3 사용시 (user 관련 파일)
    try {
      let avatarAttachmentURL;
      const avatarimage = this.props.avatarimage;

      if (avatarimage != 'No_File') {
        // avatarAttachmentURL = await Storage.vault.get(avatarimage);  // Private하게 볼 수 있는거
          avatarAttachmentURL = await Storage.get(avatarimage, { level: 'public' });  // Public하게 볼 수 있는거
      }

      //  console.log(this.props.avatarimage);
      
      this.setState({
        avatarAttachmentURL,
        avatarimage,
      });
      // console.log(this.state.attachmentURL);
    } catch (e) {
      alert(e);
    }

    if(this.props.customerType2 === 'company') {
      this.setState({customerName: this.props.companyName})
    } else if(this.props.customerType2 === 'freelancer') {
      this.setState({customerName: this.props.name})
    }

    // 주소 정보 받아오기
    this.setState({
      address_2: this.props.address[2],
    })
  }

  render() {
    const { classes, theme } = this.props;
    const myUploadPage = localStorage.getItem("myUploadPage")

    return (
      <div>
        <Card className={classes.card}>
          <List className={classes.list}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                { this.props.customerType2 === 'company'
                  ?
                    /*<Link component={RouterLink} to={`/user/company/view/${this.props.userId}`} underline='none' color='inherit'>*/
                      this.state.avatarAttachmentURL
                      ?
                      <img
                        className={classes.media}
                        src={this.state.avatarAttachmentURL}
                      />
                      :
                      <Avatar aria-label="Recipe" className={classes.avatar}>
                        <AccountCircleIcon style={{fontSize: '65px'}}/>
                      </Avatar>
                    /*</Link>*/
                  :
                    /*<Link component={RouterLink} to={`/user/freelancer/view/${this.props.userId}`} underline='none' color='inherit'>*/
                      this.state.avatarAttachmentURL
                      ?
                      <Avatar src={this.state.avatarAttachmentURL} aria-label="Recipe" className={classes.avatar}/>
                      :
                      <Avatar aria-label="Recipe" className={classes.avatar}>
                        <AccountCircleIcon style={{fontSize: '65px'}}/>
                      </Avatar>
                    /*</Link>*/
                }
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemName}>
                      <Typography className={classes.typoName}>
                        {this.state.customerName}
                      </Typography>
                    </ListItem>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoHistory}>
                        {/* 글자 길이가 10개 이상 넘어가면 '...' 나옴 */}
                        {this.props.history.slice(0,10)}
                        {this.props.history.length > 10 
                        ? '...'
                        : null
                        }
                    </Typography>
                    </ListItem>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoAddress}>
                        {this.state.address_2}
                      </Typography>
                    </ListItem>
                  </Grid>
                </Grid>
                { myUploadPage === 'project'
                ?
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider/>
                    <ListItem className={classes.listItemPreInvoice}>
                      <Typography className={classes.typoPreInvoice}>
                        견적 : &nbsp;
                        {this.props.applicationInvoice.slice(0,10)}
                        {this.props.applicationInvoice.length > 10 
                        ? '...'
                        : null
                        }
                      </Typography>
                    </ListItem>
                  </Grid>
                </Grid>
                :
                null
                }
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemContent}>
                      { this.props.applicationContent != 'default'
                      ?
                      <Typography className={classes.typoContent}>
                        내용 : &nbsp; 
                        {this.props.applicationContent.slice(0,30)}
                        {this.props.applicationContent.length > 30 
                        ? '...'
                        : null
                        }
                      </Typography>
                      :
                      <Typography className={classes.typoContent}>
                        내용 : &nbsp; 없음
                      </Typography>
                      }
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="center">
                    <Button variant="contained" className={classes.upperButton} 
                      onClick={this.handleCall}
                    >
                      <Typography>전화</Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="center">
                    <Button variant="contained" className={classes.upperButton} 
                      onClick={this.handleEmail}
                    >
                      <Typography>이메일</Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="center">
                    <Button variant="contained" className={classes.underButton} 
                      onClick={this.handleVideoMeeting}
                    >
                      <Typography>화상통화</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    {this.props.applicationToggle === '0'
                    ?
                    <Link component={RouterLink} to={`/orderPage/${this.props.applicationUUId}`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.handleUserIdSave}>
                        <Typography>상세견적체크</Typography>
                      </Button>
                    </Link>
                    :
                    this.props.applicationToggle === '1'
                    ?
                    <Link component={RouterLink} to={`/orderPage/${this.props.applicationUUId}`} underline='none' color='inherit'>
                      <Button variant="contained" color="primary" className={classes.button2} onClick={this.handleUserIdSave}>
                        <Typography>발주취소</Typography>
                      </Button>
                    </Link>
                    :
                    <Link component={RouterLink} to={`/orderPage/${this.props.applicationUUId}`} underline='none' color='inherit'>
                      <Button variant="contained" color="secondary" className={classes.button2} onClick={this.handleUserIdSave}>
                        <Typography>발주완료</Typography>
                      </Button>
                    </Link>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </List>
        </Card>
        <Dialog open={this.state.callDialogOpen}>
          {/*
          <DialogTitle>담당자 전화번호</DialogTitle>
          <DialogContent>{this.props.phoneNumber}</DialogContent>
          */}
          <DialogTitle className={classes.typoDialogTitle}>오투공 전화번호</DialogTitle>
          <DialogContent className={classes.typoDialogContent}>043) 714-3378</DialogContent>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.dialogButton} 
                  onClick={this.handleClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.emailDialogOpen}>
          {/*
          <DialogTitle>담당자 이메일 주소</DialogTitle>
          <DialogContent>{this.props.email}</DialogContent>
          */}
          <DialogTitle className={classes.typoDialogTitle}>오투공 이메일</DialogTitle>
          <DialogContent className={classes.typoDialogContent}>otwogong@kaisscompany.com</DialogContent>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.dialogButton} 
                  onClick={this.handleClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.videoMeetingDialogOpen}>
          <DialogTitle>구글 행아웃 화상미팅 사용해 보셨나요?</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" className={classes.dialogButton} 
                  onClick={this.handleGoToHangout}>예</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Link component={RouterLink} to={`/hangOutManual`} underline='none' color='inherit'>
                  <Button variant="contained" className={classes.dialogButton} 
                    onClick={this.handlehangoutManual}>아니오</Button>
                </Link>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

Feed_ApplicantList = withStyles(styles, {withTheme: true})(Feed_ApplicantList)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_ApplicantList);