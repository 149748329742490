import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import * as authActions from 'redux/actions/auth'

import { API } from "aws-amplify";

const styles = theme => ({
    root: {
        width : '100%',
        //minWidth: 1080,
        marginTop : theme.spacing(7),
        overFlowX : "auto",
        display: 'flex',
    },
    card: {
        width : '100%',
        marginTop: 5,
        marginBottom: 15,
        marginLeft : theme.spacing(1),
        marginRight : theme.spacing(1),
    },
    list: {
      width: '100%',
      padding: 0,
    },
    listItemTitle: {
      height: 40,
      background: "#E8E8E8"  // 살짝 짙은 grey
    },
    listItem: {
      height: 90,
    },
    typoTitle: {
      width: '100%',
      fontSize : 16,
      paddingTop: 0,
      paddingBottom: 0,
      //background: "#E8E8E8"  // 살짝 짙은 grey
    },
    switchRoot: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1.5,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#067fb5',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#067fb5',
        border: '6px solid #fff',
      },
    },
    switchThumb: {
      width: 24,
      height: 24,
      //color: '#067fb5',
    },
    switchTrack: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      //backgroundColor: theme.palette.grey[50],
      backgroundColor: '#a6c7d6',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    switchChecked: {},
    switchFocusVisible: {},
});

class NotiSetting extends React.Component {
    constructor(props) {
        super(props);
    
        this.file = null;
        // const { isSubscribe } = this.props
    
        this.state = {
          // eventPush: isSubscribe,
          eventPush: true,
          applicantPush: true,
          /*
          followUploadPush: true,
          followCommentPush: true,
          */
          userId: '', 
          eventToggle : '',
          applicantToggle : '',
          /*
          followUploadToggle : '',
          followCommentToggle : '',
          */
        };
    }

    /*
    // App.js에서 pushManager 활용시 사용하는 코드 (DB에 저장할거라서 사용 안할 예정.)
    urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
  
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
  
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    subscribeUser() {
      const applicationServerPublicKey = 'BIwjUMTOPt511hGkhM6w_kLyA8TdEYN84aK9mwMPS88q2rAJALinfpmTPkYgLOPiDmIMZC0iemKNOQBz-MEWBXs';
      const applicationServerKey = this.urlB64ToUint8Array(applicationServerPublicKey);
      const { isSwRegistration } = this.props
      isSwRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      })
      .then(function(subscription) {
        console.log('User is subscribed :' + subscription);
        
        //this.updateSubscriptionOnServer(subscription);
        
        // subscribeTrue();
        // console.log("isSubscribe :" + isSubscribe);
        sessionStorage.setItem("subscribe", true);
        console.log('subscribe :' + sessionStorage.getItem("subscribe"));

        //this.updateBtn();
      })
      .catch(function(err) {
        console.log('Failed to subscribe the user: ', err);
        // this.updateBtn();
      });
    }

    unsubscribeUser() {
      const { isSwRegistration } = this.props
      isSwRegistration.pushManager.getSubscription()
      .then(function(subscription) {
        if (subscription) {
          return subscription.unsubscribe();
        }
      })
      .catch(function(error) {
        console.log('Error unsubscribing', error);
      })
      .then(function() {
        //this.updateSubscriptionOnServer(null);
        
        console.log('User is unsubscribed.');
        // subscribeFalse();
        // console.log("isSubscribe :" + isSubscribe);
        sessionStorage.setItem("subscribe", false);
        console.log('subscribe :' + sessionStorage.getItem("subscribe"));
        
        //this.updateBtn();
      });
    }

    handleSwitchChange = name => event => {
      // const { subscribeTrue, subscribeFalse, isSubscribe } = this.props
      this.setState({ [name] : event.target.checked });
      if(this.state.eventPush === true) {
        //subscribeFalse()
        this.unsubscribeUser();
        //console.log("isSubscribe :" + isSubscribe);
      } else if(this.state.eventPush === false) {
        // subscribeTrue()
        this.subscribeUser();
        //console.log("isSubscribe :" + isSubscribe);
      }
    }
    */

    getNotiSetting() {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      return API.get("upload", `/notiSetting/${currentAuthUserFromSession}`)
    }

    /*
    postNotiSetting(postNotificationSet) {
      return API.post("upload", "/notiSetting", {
        body: postNotificationSet
      });
    }
    */

    putNotiSetting(putNotificationSet) {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      return API.put("upload", `/notiSetting/${currentAuthUserFromSession}`, {
        body: putNotificationSet
      });
    }

    /*
    subscribeFCM = () => {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      if(this.state.userId === currentAuthUserFromSession)
      {
        try {
            this.putNotiSetting({
              eventToggle: "true",
              followUploadToggle: "true",
              followCommentToggle: "true",
            });
        } catch (e) {
          // alert(e);
          console.log("putNotiSetting is not working");
        }
      } else
      {
        console.log("userId is not matched");
      }
    }

    unsubscribeFCM = () => {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      if(this.state.userId === currentAuthUserFromSession)
      {
        try {
            this.putNotiSetting({
              eventToggle: "false",
              followUploadToggle: "false",
              followCommentToggle: "false",
            });
        } catch (e) {
          // alert(e);
          console.log("putNotiSetting is not working");
        }
      } else
      {
        console.log("userId is not matched");
      }
    }
    */

    subscribeFCM_1 = () => {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      if(this.state.userId === currentAuthUserFromSession)
      {
        let eventPush;
        let applicantPush;
        /*
        let followUploadPush;
        let followCommentPush;
        */

        if(this.state.eventPush === true) {
          eventPush = "false"
        } else if(this.state.eventPush === false) {
          eventPush = "true"
        }
        if(this.state.applicantPush === true) {
          applicantPush = "true"
        } else if(this.state.applicantPush === false) {
          applicantPush = "false"
        }
        /*
        if(this.state.followUploadPush === true) {
          followUploadPush = "true"
        } else if(this.state.followUploadPush === false) {
          followUploadPush = "false"
        }
        if(this.state.followCommentPush === true) {
          followCommentPush = "true"
        } else if(this.state.followCommentPush === false) {
          followCommentPush = "false"
        }
        */

        try {
            this.putNotiSetting({
              eventToggle: eventPush,
              applicantToggle: applicantPush,
              /*
              followUploadToggle: followUploadPush,
              followCommentToggle: followCommentPush,
              */
            });
        } catch (e) {
          // alert(e);
          console.log("putNotiSetting is not working");
        }
      } else
      {
      console.log("userId is not matched");
      }
    }

    subscribeFCM_2 = () => {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      if(this.state.userId === currentAuthUserFromSession)
      {
        let eventPush;
        let applicantPush;
        /*
        let followUploadPush;
        let followCommentPush;
        */

        if(this.state.eventPush === true) {
          eventPush = "true"
        } else if(this.state.eventPush === false) {
          eventPush = "false"
        }
        if(this.state.applicantPush === true) {
          applicantPush = "false"
        } else if(this.state.applicantPush === false) {
          applicantPush = "true"
        }
        /*
        if(this.state.followUploadPush === true) {
          followUploadPush = "false"
        } else if(this.state.followUploadPush === false) {
          followUploadPush = "true"
        }
        if(this.state.followCommentPush === true) {
          followCommentPush = "true"
        } else if(this.state.followCommentPush === false) {
          followCommentPush = "false"
        }
        */
        try {
            this.putNotiSetting({
              eventToggle: eventPush,
              applicantToggle: applicantPush,
              /*
              followUploadToggle: followUploadPush,
              followCommentToggle: followCommentPush,
              */
            });
        } catch (e) {
          // alert(e);
          console.log("putNotiSetting is not working");
        }
      } else
      {
      console.log("userId is not matched");
      }
    }

    /*
    subscribeFCM_3 = () => {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      if(this.state.userId === currentAuthUserFromSession)
      {
        let eventPush;
        let followUploadPush;
        let followCommentPush;

        if(this.state.eventPush === true) {
          eventPush = "true"
        } else if(this.state.eventPush === false) {
          eventPush = "false"
        }
        if(this.state.followUploadPush === true) {
          followUploadPush = "true"
        } else if(this.state.followUploadPush === false) {
          followUploadPush = "false"
        }
        if(this.state.followCommentPush === true) {
          followCommentPush = "false"
        } else if(this.state.followCommentPush === false) {
          followCommentPush = "true"
        }

        try {
            this.putNotiSetting({
              eventToggle: eventPush,
              followUploadToggle: followUploadPush,
              followCommentToggle: followCommentPush,
            });
        } catch (e) {
          // alert(e);
          console.log("putNotiSetting is not working");
        }
      } else
      {
      console.log("userId is not matched");
      }
    }
    */

    handleSwitchChange_1 = name => event => {
      this.setState({ [name] : event.target.checked });
      this.subscribeFCM_1();
    }

    handleSwitchChange_2 = name => event => {
      this.setState({ [name] : event.target.checked });
      this.subscribeFCM_2();
    }

    /*
    handleSwitchChange_3 = name => event => {
      this.setState({ [name] : event.target.checked });
      this.subscribeFCM_3();
    }
    */

    // render() 실행 후 동작. 새로 고침시 render()함수 실행후 이 함수 실행
    async componentDidMount() {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

      const notiSettingUserId = localStorage.getItem("notiSetting")
      ? JSON.parse(localStorage.getItem("notiSetting")).userId
      : null

      if(notiSettingUserId === currentAuthUserFromSession) {
        console.log("localStorge_notiSetting 있음.");
        try{
          const notificationSetting = await this.getNotiSetting();

          const { userId, eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ } = notificationSetting;
          
          this.setState({userId});
    
          if(this.state.userId === currentAuthUserFromSession)
          {
            this.setState({ eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ });
            if(this.state.eventToggle === "true") {
              this.setState({ eventPush: true });
            } else if(this.state.eventToggle === "false") {
              this.setState({ eventPush: false });
            }
            if(this.state.applicantToggle === "true") {
              this.setState({ applicantPush: true });
            } else if(this.state.applicantToggle === "false") {
              this.setState({ applicantPush: false });
            }
            /*
            if(this.state.followUploadToggle === "true") {
              this.setState({ followUploadPush: true });
            } else if(this.state.followUploadToggle === "false") {
              this.setState({ followUploadPush: false });
            }
            if(this.state.followCommentToggle === "true") {
              this.setState({ followCommentPush: true });
            } else if(this.state.followCommentToggle === "false") {
              this.setState({ followCommentPush: false });
            }
            */
          }

          localStorage.setItem(
            "notiSetting",
            JSON.stringify({
              eventToggle: this.state.eventToggle,
              applicantToggle: this.state.applicantToggle,
              /*
              followUploadToggle: this.state.followUploadToggle,
              followCommentToggle: this.state.followCommentToggle
              */
            })
          )
        } catch(e) {
          const localEventToggle = JSON.parse(localStorage.getItem("notiSetting")).eventToggle
          const localApplicantToggle = JSON.parse(localStorage.getItem("notiSetting")).applicantToggle
          /*
          const localFollowUploadToggle = JSON.parse(localStorage.getItem("notiSetting")).followUploadToggle
          const localFollowCommentToggle = JSON.parse(localStorage.getItem("notiSetting")).followCommentToggle
          */
          this.setState({
            userId: currentAuthUserFromSession,
            eventToggle : localEventToggle,
            applicantToggle : localApplicantToggle,
            /*
            followUploadToggle : localFollowUploadToggle,
            followCommentToggle : localFollowCommentToggle,
            */
          });
          if(this.state.eventToggle === "true") {
            this.setState({ eventPush: true });
          } else if(this.state.eventToggle === "false") {
            this.setState({ eventPush: false });
          }
          if(this.state.applicantToggle === "true") {
            this.setState({ applicantPush: true });
          } else if(this.state.applicantToggle === "false") {
            this.setState({ applicantPush: false });
          }
          /*
          if(this.state.followUploadToggle === "true") {
            this.setState({ followUploadPush: true });
          } else if(this.state.followUploadToggle === "false") {
            this.setState({ followUploadPush: false });
          }
          if(this.state.followCommentToggle === "true") {
            this.setState({ followCommentPush: true });
          } else if(this.state.followCommentToggle === "false") {
            this.setState({ followCommentPush: false });
          }
          */
          console.log("localNoniSetting() 실행");   
        }
      } else {
        console.log("localStorge_notiSetting 없음.");
        try{
          const notificationSetting = await this.getNotiSetting();

          const { userId, eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ } = notificationSetting;
          
          this.setState({userId});
    
          if(this.state.userId === currentAuthUserFromSession)
          {
            this.setState({ eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ });
            if(this.state.eventToggle === "true") {
              this.setState({ eventPush: true });
            } else if(this.state.eventToggle === "false") {
              this.setState({ eventPush: false });
            }
            if(this.state.applicantToggle === "true") {
              this.setState({ applicantPush: true });
            } else if(this.state.applicantToggle === "false") {
              this.setState({ applicantPush: false });
            }
            /*
            if(this.state.followUploadToggle === "true") {
              this.setState({ followUploadPush: true });
            } else if(this.state.followUploadToggle === "false") {
              this.setState({ followUploadPush: false });
            }
            if(this.state.followCommentToggle === "true") {
              this.setState({ followCommentPush: true });
            } else if(this.state.followCommentToggle === "false") {
              this.setState({ followCommentPush: false });
            }
            */
          }

          localStorage.setItem(
            "notiSetting",
            JSON.stringify({
              userId: currentAuthUserFromSession,
              eventToggle : localEventToggle,
              applicantToggle : localApplicantToggle,
              /*
              followUploadToggle : localFollowUploadToggle,
              followCommentToggle : localFollowCommentToggle,
              */
            })
          )
        } catch(e) {
          // alert(e);
          /*
          await this.postNotiSetting({
            eventToggle: "true",
            followUploadToggle: "true",
            followCommentToggle: "true",
          });
          */

          localStorage.setItem(
            "notiSetting",
            JSON.stringify({
              userId: currentAuthUserFromSession,
              eventToggle: "true",
              applicantToggle: "true",
              /*
              followUploadToggle: "true",
              followCommentToggle: "true",
              */
            })
          )
          if(this.state.eventToggle === "true") {
            this.setState({ eventPush: true });
          } else if(this.state.eventToggle === "false") {
            this.setState({ eventPush: false });
          }
          if(this.state.applicantToggle === "true") {
            this.setState({ applicantPush: true });
          } else if(this.state.applicantToggle === "false") {
            this.setState({ applicantPush: false });
          }
          /*
          if(this.state.followUploadToggle === "true") {
            this.setState({ followUploadPush: true });
          } else if(this.state.followUploadToggle === "false") {
            this.setState({ followUploadPush: false });
          }
          if(this.state.followCommentToggle === "true") {
            this.setState({ followCommentPush: true });
          } else if(this.state.followCommentToggle === "false") {
            this.setState({ followCommentPush: false });
          }
          */
          console.log("postNotiSetting() 실행됨.");
          window.location.reload();
        }
      }
    }
    
    render() {
      const { classes, theme } = this.props;
      return (
        <div className={classes.root}>
          <Card className={classes.card}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <List className={classes.list}>
                <ListItem className={classes.listItemTitle}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <Typography className={classes.typoTitle}>알림 세팅</Typography>
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                    <Typography className={classes.typoTitle}>이벤트 알람</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    <Switch checked={this.state.eventPush} 
                      onChange={this.handleSwitchChange_1('eventPush')} 
                      value="eventPush"
                      color='primary'
                      focusVisibleClassName={classes.switchFocusVisible}
                      disableRipple
                      classes={{
                        root: classes.switchRoot,
                        switchBase: classes.switchBase,
                        thumb: classes.switchThumb,
                        track: classes.switchTrack,
                        checked: classes.switchChecked,
                      }}
                    />
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                    <Typography className={classes.typoTitle}>게재한 공고의 지원자 알람</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    <Switch checked={this.state.applicantPush} 
                      onChange={this.handleSwitchChange_2('applicantPush')} 
                      value="applicantPush"
                      color='primary'
                      focusVisibleClassName={classes.switchFocusVisible}
                      disableRipple
                      classes={{
                        root: classes.switchRoot,
                        switchBase: classes.switchBase,
                        thumb: classes.switchThumb,
                        track: classes.switchTrack,
                        checked: classes.switchChecked,
                      }}
                    />
                  </Grid>
                </ListItem>
                {/*
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                    <Typography className={classes.typoTitle}>팔로우 글 알람</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    <Switch checked={this.state.followUploadPush} 
                      onChange={this.handleSwitchChange_2('followUploadPush')} 
                      value="followUploadPush"  
                      color="primary"
                    />
                  </Grid>
                </ListItem>
                <Divider/>
                <ListItem className={classes.listItem}>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                    <Typography className={classes.typoTitle}>팔로우 댓글 알람</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    <Switch checked={this.state.followCommentPush} 
                      onChange={this.handleSwitchChange_3('followCommentPush')} 
                      value="followCommentPush"  
                      color="primary"
                    />
                  </Grid>
                </ListItem>
                */}
              </List>
            </Grid>
          </Card>
        </div>
      );
    }
}


// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
    isSwRegistration: state.auth.isSwRegistration,
})
  
// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
    swRegistration: (isSwRegistration) => dispatch(authActions.swRegistration(isSwRegistration)),
})
  
NotiSetting = withStyles(styles, {withTheme: true})(NotiSetting)
export default connect(mapStateToProps, mapDispatchToProps)(NotiSetting)