import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { TextField } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'

// S3 관련
import { API } from "aws-amplify";
import { s3DeletePublic } from "../libs/awsLib";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  /*
  GridContainer: {
    minHeight: "10vh"
  },
  */
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "0px",
    // maxWidth: 650,
    // minWidth : 500,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  /*
  media: {
    marginTop: 5,
    height: 50,
    width: 130,
    objectFit: 'contain'
  },
  typoName: {
    width: '100%',
    fontSize : 13,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20
  },
  */
  list: {
    width: '100%',
    padding: '0',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 5,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemContent: {
    paddingTop: 5,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle: {
    width: '100%',
    fontSize : 18,
    paddingTop: 0,
    paddingBottom: 0,
  // background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoInvoice: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 20
  },
  typoContent: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 20
  },
   /*
  typoApplication: {
    width: '100%',
    fontSize : 14,
    paddingTop: 0,
    paddingBottom: 0,
    //paddingLeft: 20
  },
  typoAddress: {
    width: '100%',
    fontSize : 13,
    color: '#999',
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 20
  },
  avatar: {
    // backgroundColor: red[500],
    marginTop: 5,
    backgroundColor: '#D3D3D3',
    margin: 10,
    width: 70,
    height: 70,
  },
  */
  upperButton: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  upperButton2: {
    width: '100%',
    marginBottom: 10,
  },
  underButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button: {
    width: '80%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button2: {
    width: '80%',
  },
  /*
  upperButton: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  underButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  */
  /*
  iconButton: {
    margin: theme.spacing(0),
  },
  
  MoreVertIconRoot: {
    position: 'relative',
  },
  MoreVertIconContent: {
    padding: theme.spacing(0),
  },
  popupStateTypography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  popupStateMenu: {
    padding : 0,
  },
  countLink: {
    color:'#0c93d0'
  },
  */
  /*
  starBorderIconButton: {
    padding: 0,
  },
  starIconButton: {
    padding: 0,
  },
  starBorderIcon: {
    width: 19,
    height: 19,
  },
  starIcon: {
    width: 19,
    height: 19,
    color: '#FFD700'  // gold
  },
  */
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldLabelSize:{
    fontSize:17
  },
  textFieldInputSize:{
    fontSize:17
  },
  dialogTitle: {
    fontSize: 17
  },
  dialogContent: {
    fontSize: 17
  },
});

class Feed_MyApplications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applicationInvoice: '',
      applicationContent: '',
      putDialogOpen : false,
      deleteDialogOpen : false,
      alarmDialogOpen_1: false,
      videoMeetingDialogOpen: false,
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  putApplication(applyingContent) {
    return API.put("upload", `/application/${this.props.applicationUUId}`, {
      body: applyingContent
    });
  }

  // 삭제 API
  deleteApplication() {
    return API.del("upload", `/application/${this.props.applicationUUId}`);
  }

  //------------------------------------------------------------------------
  // 지원 버튼 클릭시 알림 발송
  //------------------------------------------------------------------------
  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }
  
  getNotifications() {
    return API.get("upload", `/notification/?userId=${this.props.uploadUserId}`)
    .then(notifications => this.setState({notifications : notifications}));
  }
  //------------------------------------------------------------------------

  /*
  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }
  */

  putDialog = () => {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.userId === currentAuthUserFromSession) {
      this.setState({
        putDialogOpen : true,
      });
    }
  }

  deleteDialog = async event => {
    event.preventDefault();

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.userId === currentAuthUserFromSession) {
      this.setState({
        deleteDialogOpen : true,
      });
    }
  }

  handleVideoMeeting = () => {
    this.setState({videoMeetingDialogOpen: true});
  }

  handlehangoutManual = () => {
    this.setState({videoMeetingDialogOpen: false})
  }

  handleGoToHangout = () => {
    this.setState({videoMeetingDialogOpen: false})
    window.open('https://hangouts.google.com')  // 새로운 탭에서 열림
    // window.location.href = 'https://hangouts.google.com'; // 현재 탭에서 열림
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    if(this.props.applicationContent != 'default') {
      this.setState({
        applicationInvoice: this.props.applicationInvoice,
        applicationContent: this.props.applicationContent,
      });
    }
    if(this.props.applicationContent === 'default') {
      this.setState({
        applicationInvoice: this.props.applicationInvoice,
        applicationContent: '',
      });
    }

    this.setState({
      putDialogOpen: false,
      deleteDialogOpen : false,
      alarmDialogOpen_1: false,
      videoMeetingDialogOpen: false,
    })
  }

  handleCloseInvoice = () => {
    this.setState({
      alarmDialogOpen_1: false,
    })
  }

  // 실제로 데이터가 삭제되는 것을 도와주기 위한 함수
  handlePut = async event => {
    event.preventDefault();

    // 작성 안한 파트 작성하게끔 해주는 코드 (일감 요청 란 에서만 동작)
    if(this.props.applicationSubField === '0') {
      if (!this.state.applicationInvoice) {
        this.setState({alarmDialogOpen_1: true});
        return;
      }
    }

    if(this.state.applicationContent === '') {
      this.state.applicationContent === 'default'
    }

    if(this.props.applicationToggle === '0')
    {
      try {
        await this.putApplication({
          applicationToggle: this.props.applicationToggle,
          applicationInvoice: this.state.applicationInvoice,
          applicationContent: this.state.applicationContent,
          orderComment: this.props.orderComment,
          orderSign: this.props.orderSign,
          applicationSign: this.props.applicationSign
        });
      } catch (e) {
        // alert(e);
        console.log(e);
      }

      try {
        if(this.state.fcmTokenIds.length > 0) {
          await this.postNotificationFCM({
            registration_ids: this.state.fcmTokenIds,
            title: '견적을 수정했습니다.',
            body: '제목 : ' + this.props.applicationTitle,
          });
        }
      } catch(e) {
        console.log(e)
      }

      // this.props.history.push("/");
      window.location.reload();
    }
  }

  // 실제로 데이터가 삭제되는 것을 도와주기 위한 함수
  handleDelete = async event => {
    event.preventDefault();

    this.setState({ isDeleting: true });

    if(this.props.applicationToggle === '0')
      {
      try {
        await this.deleteApplication();

        /*
        await this.putPoint({
          pointEvent: "uploadCancel",
        });
        */
      } catch (e) {
        // alert(e);
        console.log(e);
        this.setState({ isDeleting: false });
      }

      try {
        if(this.state.fcmTokenIds.length > 0) {
          await this.postNotificationFCM({
            registration_ids: this.state.fcmTokenIds,
            title: '지원을 취소했습니다.',
            body: '제목 : ' + this.props.applicationTitle,
          });
        }
      } catch(e) {
        console.log(e)
      }

      // this.props.history.push("/");
      window.location.reload();
    }
  }

  async componentDidMount() {
    if(this.props.applicationContent != 'default') {
      this.setState({
        applicationPreInvoice: this.props.applicationPreInvoice,
        applicationContent: this.props.applicationContent,
      });
    }
    if(this.props.applicationContent === 'default') {
      this.setState({
        applicationPreInvoice: this.props.applicationPreInvoice,
        applicationContent: '',
      });
    }

    //------------------------------------------------------------------------------------
    // FCM으로 발송할 데이터를 미리 받아서 fcmTokenId를 배열로 저장.
    // 나중에 발송시 FCM으로 fcmTokenId 배열로 보내면 최대 1,000명한테 한번에 발송 가능
    try {
      await this.getNotifications();
      // fcmTokenIds를 배열로 받기
      if(this.state.notifications) { 
        let fcmTokenId
        Object.keys(this.state.notifications).map(notificationUUId => {
          const notification = this.state.notifications[notificationUUId];
            fcmTokenId = notification.fcmTokenId
            this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
        });
      }
    } catch(e) {
      console.log(e)
    }
    //------------------------------------------------------------------------------------
  }

  handleUserIdSave = () => {
    // 지원자의 userId -> OrderPage에서 사용하기 위해서
    sessionStorage.setItem('uploadUserId', this.props.uploadUserId)
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Card className={classes.card}>
          <List className={classes.list}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemTitle}>
                      <Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>
                        <Typography className={classes.typoTitle}>
                          받은요청 : &nbsp;
                          {/* 글자 길이가 20개 이상 넘어가면 '...' 나옴 */}
                          {this.props.applicationTitle.slice(0,20)}
                            {this.props.applicationTitle.length > 20 
                            ? '...'
                            : null
                          }
                        </Typography>
                      </Link>
                    </ListItem>
                  </Grid>
                </Grid>
                {this.props.applicationSubField === '0'
                ?
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider/>
                    <ListItem className={classes.listItemContent}>
                      <Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>
                        <Typography className={classes.typoInvoice}>
                          견적 : &nbsp;
                          {this.props.applicationInvoice.slice(0,10)}
                          {this.props.applicationInvoice.length > 10 
                          ? '...'
                          : null
                          }
                        </Typography>
                      </Link>
                    </ListItem>
                  </Grid>
                </Grid>
                :
                null
                }
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider/>
                    <ListItem className={classes.listItemContent}>
                      <Link component={RouterLink} to={`/upload/${this.props.uploadUUId}`} underline='none' color='inherit'>
                        <Typography className={classes.typoContent}>
                          내용 : &nbsp; 
                          {this.state.applicationContent.slice(0,30)}
                          {this.state.applicationContent.length > 30 
                          ? '...'
                          : null
                          }
                        </Typography>
                      </Link>
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
              { this.props.applicationToggle === '0'
              ?
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="end">
                    <Button variant="contained" className={classes.upperButton} 
                      onClick={this.putDialog}
                    >
                      <Typography>수정</Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="end">
                    <Button variant="contained" className={classes.upperButton} 
                      onClick={this.deleteDialog}
                    >
                      <Typography>취소</Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="end">
                    <Button variant="contained" className={classes.upperButton} 
                      onClick={this.handleVideoMeeting}
                    >
                      <Typography>화상통화</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="end">
                    <Button variant="contained" className={classes.upperButton2}>
                      <Typography>수정</Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="end">
                    <Button variant="contained" className={classes.upperButton2}>
                      <Typography>취소</Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={10} sm={10} md={10} lg={6} xl={6} align="end">
                    <Button variant="contained" className={classes.upperButton} 
                      onClick={this.handleVideoMeeting}
                    >
                      <Typography>화상통화</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              }
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    {this.props.applicationToggle === '1'
                    ?
                    <Link component={RouterLink} to={`/orderGetPage/${this.props.applicationUUId}`} underline='none' color='inherit'>
                      <Button variant="contained" className={classes.button} onClick={this.handleUserIdSave}>
                        <Typography>발주받기</Typography>
                      </Button>
                    </Link>
                    :
                    this.props.applicationToggle === '2'
                    ?
                    <Link component={RouterLink} to={`/orderGetPage/${this.props.applicationUUId}`} underline='none' color='inherit'>
                      <Button variant="contained" color="secondary" className={classes.button2} onClick={this.handleUserIdSave}>
                        <Typography>발주받음</Typography>
                      </Button>
                    </Link>
                    :
                    <Button variant="contained" color="default" className={classes.button2} onClick={this.handleUserIdSave}>
                      <Typography>발주대기</Typography>
                    </Button>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </List>
        </Card>
        {this.props.applicationSubField === '0'
        ?
        <Dialog /*fullScreen={true}*/ fullWidth={true} /*maxWidth="md"*/ open={this.state.putDialogOpen} /*onClose={this.handleClose}*/>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogTitle}>
              {this.props.applicationTitle}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="견적" type="text" name="applicationInvoice" 
              value={this.state.applicationInvoice} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="간단한 내용 및 문의" type="text" name="applicationContent" 
              value={this.state.applicationContent} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handlePut}>수정하기</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        :
        <Dialog /*fullScreen={true}*/ fullWidth={true} /*maxWidth="md"*/ open={this.state.putDialogOpen} /*onClose={this.handleClose}*/>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogTitle}>
              {this.props.applicationTitle}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="간단한 내용 및 문의" type="text" name="applicationContent" 
              value={this.state.applicationContent} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handlePut}>수정하기</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        }
        {this.props.applicationSubField === '0'
        ?
        <Dialog open={this.state.deleteDialogOpen} /*onClose={this.handleClose}*/>
          <DialogTitle>지원을 취소 하시겠습니까?</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.button}
                  onClick={this.handleDelete}>확인</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.button}
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        :
        <Dialog open={this.state.deleteDialogOpen} /*onClose={this.handleClose}*/>
          <DialogTitle>연락을 취소 하시겠습니까?</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.button}
                  onClick={this.handleDelete}>확인</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" /*color="primary"*/ className={classes.button}
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        }
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>견적을 입력해주세요</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleCloseInvoice}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.videoMeetingDialogOpen}>
          <DialogTitle>구글 행아웃 화상미팅 사용해 보셨나요?</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleGoToHangout}>예</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                <Link component={RouterLink} to={`/hangOutManual`} underline='none' color='inherit'>
                  <Button variant="contained" className={classes.button} 
                    onClick={this.handlehangoutManual}>아니오</Button>
                </Link>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

Feed_MyApplications = withStyles(styles, {withTheme: true})(Feed_MyApplications)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_MyApplications);