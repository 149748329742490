import React, { Component, } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import imageCompression from 'utils/imageCompression';
import ui from 'utils/ui'
import InputFile from './InputFile'
import SVGIcon from './SVGIcon'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';

import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

import * as authActions from 'redux/actions/auth'
import * as photoActions from 'redux/actions/photos'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

// S3 관련
import { API, Storage } from "aws-amplify";
import config from "../config";
import { s3UploadPublic, s3UploadProtected, s3UploadPrivate } from "../libs/awsLib";
import { s3DeletePublic, s3DeleteProtected, s3DeletePrivate } from "../libs/awsLib";

/*
// select 관련(공개, 비공개)
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
*/

// 달력
import Calendar from 'react-calendar';
import moment from 'moment';

//import './UploadPhoto.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(17),
  },
  appBar: {
    background: "#FFFFFF",
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    /*
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    */
  },
  // toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    fontSize : 17,
    color: '#000000'
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content: {
    marginTop: '70px',
    flexGrow: 1,
    padding: theme.spacing(0),
    marginBottom: '70px',
  },
  button: {
    width: 50,
    margin: theme.spacing(0),
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  buttonLine: {
    color: '#0c93d0',
  },
  typoAppBar: {
    fontSize: 17
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '50px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldLabelSize:{
    fontSize:25
  },
  textFieldInputSize:{
    fontSize:20
  },
  typoCalendar: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  textfieldCalendar: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '30px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
    "& label.Mui-focused": {
      color: "#0c93d0"
    },
  },
  textFieldCalendarLabelSize:{
    fontSize:18
  },
  textFieldCalendarInputSize:{
    fontSize:18
  },
  calendar: {
    marginBottom: '30px',
  },
  Textarea__label: {
    display: 'block',
    fontSize: 20,
    fontWeight: 'bold',
    color: 'brown-grey', //'#3E2723',
    marginBottom: 8,
  },
  Textarea__textarea: {
    width: '100%',
    height: '40vh',
    fontSize: 20,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '22px',
    paddingRight: '24px',
  },
  bottomAppBar: {
    top: 'auto',
    //bottom: 0,
    bottom: 100,
  },
  UploadPhoto__file: {
    //visibility: 'hidden'
    display: 'none'
  },
  media: {
    //marginTop: '0px',
    //marginBottom: '30px',
    //height: 0,
    //width: 500,
    //paddingTop: '56.25%', // 16:9
    width: 50,
    height: 50,
  },
  appbarBottomMedia: {
    width: 50,
    height: 50,
  },
  addPhotoIcon: {
    width: 50,
    height: 50,
  },
  /*
  selectFormControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    //paddingTop: 0,
    height : 80,
    minWidth: 200,
  },
  selectSelect: {
    fontSize: 20,
  },
  selectInputlabel: {
    fontSize: 20,
  },
  selectMenuItem: {
    fontSize: 20,
  },
  */
  STChanged: {
    paddingTop: 10,
    fontSize: 10,
    color: 'red'
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '47%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

const MAX_IMAGE_SIZE = 30000 // 30KB
const MAX_IMAGE_SIZE_MB = 0.03 // 30KB

// AWS에서 만든 API 주소를 넣는다.
// 내가 작성한거 사용시 사용. AWS Amplify에서는 사용 안함.
// const awsdatabaseURL = "https://7mesa0l65c.execute-api.ap-northeast-2.amazonaws.com/dev/upload";

class UploadPhotoModify extends Component {

  file = [];
  fileArray = [];

  constructor(props) {
    super(props);

    this.file_1 = null;

    this.state = {
      uploadContent: '',
      file: '',
      fileName: '',
      warningMessage: '',
      isCompressing: false,
      upload: null,
      userId: "",
      attachmentURLs: [],
      currentField: '',
      title: '',
      content: '',
      images: [],
      isLoading: false,
      completed : 0,
      tempUploadImage: false,
      tempS3ImageURLs: [],  // S3에서 받은 이미지를 임시로 저장(Cancel 버튼을 위해서)
      tempS3ImageToggle: true,
      // 달력 관련
      calendarOpen_1: false,
      calendarOpen_2: false,
      dateOriginal_1: new Date(), // 오늘 날짜 (시작 캘린더용)
      dateOriginal_2: new Date(), // 오늘 날짜 (종료 캘린더용)
      dateStart: null, // 실제 선택한 일감 시작 날짜 (서버에 저장 날짜)
      dateEnd: null, // 실제 선택한 일감 종료 날짜 (서버에 저장 날짜)
      // 다이얼 로그
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
      deleteDialogOpen: false,
    };
  }

  putUpload(uploadContent) {
    return API.put("upload", `/upload/${this.props.match.params.id}`, {
      body: uploadContent
    });
  }

  deleteUpload() {
    return API.del("upload", `/upload/${this.props.match.params.id}`);
  }

  /*
  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }
  */

  // 실제로 데이터가 수정되는 것을 도와주기 위한 함수
  handlePutSubmit = async event => {
    event.preventDefault();

    // 작성 안한 파트 작성하게끔 해주는 코드
    if (!this.state.title) {
      this.setState({alarmDialogOpen_1: true});
      return;
    }
    if (!this.state.dateStart) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }
    if (!this.state.dateEnd) {
      this.setState({alarmDialogOpen_3: true});
      return;
    }
    if (!this.state.content) {
      this.setState({alarmDialogOpen_4: true});
      return;
    }

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.state.userId === currentAuthUserFromSession)
    {
      if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
        alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
        return;
      }
  
      this.setState({ isLoading: true });

      let attachments = []
      let noFiles = []

      try {
        // 항상 첫 파일은 this.file_1(=this.file[0][0])이기 때문에
        // 첫 파일이 첨부가 되었으면 뒤에 나머지 파일도 바뀌었다고 보면 됨
        if (this.file_1) {
          for(let i=0;i<9;i++) {
            if(this.state.images[i] != 'No_File') {
              await s3DeletePublic(this.state.images[i]);
            }

            this.file[0][i]
            ? attachments.push(await s3UploadPublic(this.file[0][i]))
            : attachments.push('No_File');
          }

          await this.putUpload({
            attachments,
            title: this.state.title,
            content: this.state.content,
            dateStart: moment(this.state.dateStart).format("YYYY-MM-DD"),
            dateEnd: moment(this.state.dateEnd).format("YYYY-MM-DD"),
            images: attachments,
          });
        } else if(!this.file_1) {
          if(this.state.tempS3ImageToggle === true) {
              await this.putUpload({
                title: this.state.title,
                content: this.state.content,
                dateStart: moment(this.state.dateStart).format("YYYY-MM-DD"),
                dateEnd: moment(this.state.dateEnd).format("YYYY-MM-DD"),
                images: this.state.images,
              });
          } else if(this.state.tempS3ImageToggle === false) {
            for(let i=0;i<9;i++) {
              if(this.state.images[i] != 'No_File') {
                await s3DeletePublic(this.state.images[i]);
              }
              noFiles.push('No_File')
            }
            await this.putUpload({
              title: this.state.title,
              content: this.state.content,
              dateStart: moment(this.state.dateStart).format("YYYY-MM-DD"),
              dateEnd: moment(this.state.dateEnd).format("YYYY-MM-DD"),
              images: noFiles,
            });
          }
        }
        this.props.history.push("/myUploads");
      } catch (e) {
        //alert(e);
        console.log("error: " + e);
        this.setState({ isLoading: false });
      }
    } else {
      console.log("userId is not matched");
    }    
  }

  deleteDialog = async event => {
    event.preventDefault();

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.state.userId === currentAuthUserFromSession) {
      this.setState({
        deleteDialogOpen : true,
      });
    }
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      dialogOpen : false,
    })
  }

  // 실제로 데이터가 삭제되는 것을 도와주기 위한 함수
  handleDelete = async event => {
    event.preventDefault();
    // const { currentUser } = this.props;

    this.setState({ isDeleting: true });

    for(let i=0;i<this.state.images.length;i++) {
      if(this.state.images[i] != 'No_File') {
        await s3DeletePublic(this.state.images[i]);
      }
    }

    try {
      await this.deleteUpload();

      /*
      await this.putPoint({
        pointEvent: "uploadCancel",
      });
      */
      this.props.history.push("/myUploads");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }

    /*
    Dialog 사용 안 할시. 나중에 지우기.
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
  
    // if(this.state.userId === currentUser)
    if(this.state.userId === currentAuthUserFromSession)
    {
      const confirmed = window.confirm(
        "Are you sure you want to delete this content?"
      );
  
      if (!confirmed) {
        return;
      }
  
      this.setState({ isDeleting: true });

      if(this.state.image) {
        await s3DeletePublic(this.state.image);
      }

      try {
        await this.deleteUpload();
        this.props.history.push("/");
      } catch (e) {
        alert(e);
        this.setState({ isDeleting: false });
      }
    } else
    {
      console.log("userId is not matched");
    }
    */
  }

  handleCalendarOpen_1 = () => {
    if(this.state.calendarOpen_1 === false) {
      this.setState({calendarOpen_1: true})
    } else if (this.state.calendarOpen_1 === true) {
      this.setState({calendarOpen_1: false})
    }
    if(this.state.calendarOpen_2 === true) {
      this.setState({calendarOpen_2: false})
    }
  }

  handleCalendarOpen_2 = () => {
    if(this.state.calendarOpen_2 === false) {
      this.setState({calendarOpen_2: true})
    } else if (this.state.calendarOpen_2 === true) {
      this.setState({calendarOpen_2: false})
    }
    if(this.state.calendarOpen_1 === true) {
      this.setState({calendarOpen_1: false})
    }
  }
  
  onDateChange_1 = dateOriginal_1 => { 
    this.setState({
      dateOriginal_1,
      dateStart : dateOriginal_1,
      calendarOpen_1: false,
    })

    /*
    console.log(moment(date_1).format("YYYY-MM-DD"))
    console.log(moment(date_1).format("YYYY" + "년"))
    console.log(moment(date_1).format("MM" + "월"))
    console.log(moment(date_1).format("DD" + "일"))
    */
  }

  onDateChange_2 = dateOriginal_2 => { 
    this.setState({
      dateOriginal_2,
      dateEnd : dateOriginal_2,
      calendarOpen_2: false,
    })
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
      deleteDialogOpen: false,
    })
  }

  async componentDidMount() {
    this._isMounted = true;
    // const { currentUser } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    try {
      let attachmentURLs = [];

      const upload = await this.getUpload();
      const { userId, field, subField, title, content, dateStart, dateEnd, images } = upload;

      this.setState({userId});

      // 첨부 파일이 있으면 키를 사용하여 S3에 업로드 한 파일에 대한 보안 링크를 얻습니다. 
      // 그런 다음이를 구성 요소의 상태로로 저장합니다 attachmentURL.
      // 그 이유는 우리는 왜이 upload와 함께 상태에서 개체를 content하고는 
      // attachmentURL우리가 나중에 사용자가 노트를 편집 할 때 사용되기 때문이다.
      // if(this.state.userId === currentUser)
      if(this.state.userId === currentAuthUserFromSession)
      {
        for(let i=0;i<images.length;i++) {
          if (images[i] != 'No_File') {
            attachmentURLs[i] = await Storage.get(images[i], {level: 'public'});
          } else if (images[i] === 'No_File') {
            attachmentURLs[i] = null
          }

          this.setState({
            upload,
            field,
            subField,
            title,
            content,
            dateStart,
            dateEnd,
            tempS3ImageURLs: this.state.tempS3ImageURLs.concat(attachmentURLs[i]),
            tempS3ImageToggle: true,
            attachmentURLs: this.state.attachmentURLs.concat(attachmentURLs[i]),
            images: this.state.images.concat(images[i]),
            expanded : true,
          });
        }
      } else {
        console.log("userId is not matched");
      } 
    } catch (e) {
      //alert(e);
      console.log(e);
    }

    this.setState({dateOriginal_1: moment(this.state.dateStart).toDate()})
    this.setState({dateOriginal_2: moment(this.state.dateEnd).toDate()})
  }

  // 경로 ( /upload/:id) 
  getUpload() {
    return API.get("upload", `/upload/${this.props.match.params.id}`);
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  handleFileChange = (e) => {

    this.file = []
    this.fileArray = []
    this.tempFile = null;

    this.file.push(e.target.files)
    // for (let i = 0; i < this.file[0].length; i++) {  // 업로드 사진 수량 상관 없는 코드
    for (let i = 0; i < 9; i++) { // 사진 9장까지만 업로드 하기 위한 코드 
      // 만약 동영상이 한개라도 있으면 제일 첫 동영상 1개만 업로드 하기 위한 코드
      if(this.file[0][i]) {
        if(this.file[0][i].name.substr(this.file[0][i].name.length-4, 4) == (".mp3" && ".mp4")) 
        {
          this.tempFile = this.file[0][i]
          this.file = []
          this.fileArray = []
          this.file.push(this.tempFile)
          this.file[0][0] = this.file[0]

          this.fileArray.push(URL.createObjectURL(this.file[0][0]))

          this.setState({ 
            tempUploadImage: true,
            tempS3ImageURLs: [],
            tempS3ImageToggle: false
          })

          this.file_1 = this.file[0][0]

          return;
        }
        this.fileArray.push(URL.createObjectURL(this.file[0][i]))
        this.file_1 = this.file[0][0]
      }
    }
    this.setState({ 
      tempUploadImage: true,
      tempS3ImageURLs: [],
      tempS3ImageToggle: false
    })
  }

  compressImage = async (imageFile) => {
    try {
      const compressedFile = await imageCompression(imageFile, MAX_IMAGE_SIZE_MB)
      this.setState({
        isCompressing: false,
        file: compressedFile,
        fileName: compressedFile.name,
      })
    } catch (error) {
      this.setState({
        isCompressing: false,
        warningMessage: '* Fail to compress image'
      })
    }
  }

  handleCancelPhoto = () => {
    this.setState({
      //file: '',
      //fileName: '',
      tempS3ImageURLs: [],
      tempS3ImageToggle: false
    });

    this.file = []
    this.fileArray = []
    this.file_1 = null
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes } = this.props;
    const { fileName, field, subField, title, content,  dateOriginal_1, dateOriginal_2, dateStart, dateEnd, tempUploadImage } = this.state
    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar>
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#000000" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} align='center' variant="h6" noWrap>
                    견적요청 수정
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          {this.state.upload
          ?
          <main className={classes.content}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>              
                {/*
                <div>
                  <FormControl className={classes.selectFormControl}>
                    <InputLabel id="controlled-open-select-label" className={classes.selectInputlabel}>공개여부</InputLabel>
                    <Select className={classes.selectSelect}
                      // labelId="controlled-open-select-label"
                      id="controlled-open-select"
                      open={this.state.selectOpen}
                      onClose={this.handleSelectClose}
                      onOpen={this.handleSelectOpen}
                      value={this.state.selectTarget}
                      onChange={this.handleSelectChange}
                    >
                      <MenuItem value={"public"} className={classes.selectMenuItem}>전체 공개</MenuItem>
                      <MenuItem value={"protected"} className={classes.selectMenuItem}>친구만</MenuItem>
                      <MenuItem value={"private"} className={classes.selectMenuItem}>나만 보기</MenuItem>
                    </Select>
                    {this.state.selectTargetChanged === true
                    ?
                    <Typography className={classes.STChanged} variant="h6" noWrap>
                      공개여부를 수정하면 보안상의 이유로 기존의 사진을 다시 첨부하셔야 합니다.
                    </Typography>
                    :
                    null
                    }
                  </FormControl>
                </div>
                */}
                <TextField className={classes.textfieldTitle}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldInputSize,
                    },
                  }}
                  label="제목" type="text" name="title" value={title} onChange={this.handleValueChange} autoComplete="off"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography style={{ cursor: 'pointer' }} component={'span'} className={classes.typoCalendar} onClick={this.handleCalendarOpen_1}>
                { this.state.dateStart
                ?
                <TextField className={classes.textfieldCalendar}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldCalendarLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldCalendarInputSize,
                    },
                    }}
                  label="일감 시작 날짜" type="dateStart" name="dateStart" value={moment(dateStart).format("YYYY-MM-DD") || ''} 
                  autoComplete="off"
                />
                :
                <TextField className={classes.textfieldCalendar}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldCalendarLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldCalendarInputSize,
                    },
                    }}
                  label="일감 시작 날짜" type="dateStart" name="dateStart" value={undefined || ''} autoComplete="off"
                />
                }
                </Typography>               
              </Grid>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography style={{ cursor: 'pointer' }} component={'span'} className={classes.typoCalendar} onClick={this.handleCalendarOpen_2}>
                { this.state.dateEnd
                ?
                <TextField className={classes.textfieldCalendar}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldCalendarLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldCalendarInputSize,
                    },
                    }}
                  label="일감 종료 날짜" type="dateEnd" name="dateEnd" value={moment(dateEnd).format("YYYY-MM-DD") || ''} 
                  autoComplete="off"
                />
                :
                <TextField className={classes.textfieldCalendar}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldCalendarLabelSize
                    },
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textFieldCalendarInputSize,
                    },
                    }}
                    label="일감 종료 날짜" type="dateEnd" name="dateEnd" value={undefined || ''} autoComplete="off"
                />
                }
                </Typography>               
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={11} lg={10} xl={10} align='center'>
                {this.state.calendarOpen_1
                ?
                <Calendar
                  className={classes.calendar}
                  onChange={this.onDateChange_1}
                  value={dateOriginal_1}
                  />
                :
                null
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={11} lg={10} xl={10} align='center'>
                {this.state.calendarOpen_2
                ?
                <Calendar
                  className={classes.calendar}
                  onChange={this.onDateChange_2}
                  value={dateOriginal_2}
                  />
                :
                null
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
                {this.state.upload &&
                  <div>
                    <label className={classes.Textarea__label} htmlFor="content">
                      내용 작성
                    </label>
                    <textarea
                      id="content"
                      name="content"
                      value={content}
                      onChange={this.handleValueChange}
                      placeholder="내용을 작성해주세요."
                      className={classes.Textarea__textarea}
                      autoComplete="off"
                    />
                  </div>
                }
                {/*
                // content 안에서 사진이 나타난다.
                // 현재는 Bottm App Bar에서 사진이 나타나게 했기 때문에 주석처리.
                {this.state.image.length > 0 &&
                  <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                    <Grid item sm={4} md={4} lg={4} xl={4}>
                    <CardActionArea disabled={this.state.image.length === 1 ? true : false}>
                      <CardMedia
                       className={classes.media}
                       image={this.state.attachmentURL}
                      />
                    </CardActionArea>
                    </Grid>
                  </Grid>
                }
                <InputFile
                  className={classes.UploadPhoto__file}
                  name="Search file"
                  label="업로드 파일"
                  fileName={isCompressing ? 'Compressing image...' : fileName}
                  onChange={this.handleFileChange}
                  err={warningMessage}
                   accept=".png, .jpg, .jpeg"
                  required
                />
                */}
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} 
              direction="row" justify="center" alignItems="center">
              <Grid item sm={6} md={6} lg={6} xl={6} align="end">
                <Button
                  variant="contained"
                  /*color="primary"*/ className={classes.button}
                  onClick={this.handlePutSubmit}
                >
                  <Typography className={classes.typoAppBar}>수정</Typography>
                  {/*<SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" />*/}
                </Button>
              </Grid>
              <Grid item sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button
                  variant="contained"
                  /*color="primary"*/ className={classes.button}
                  onClick={this.deleteDialog}
                >
                  <Typography className={classes.typoAppBar}>삭제</Typography>
                  {/*<SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" />*/}
                </Button>
              </Grid>
            </Grid>   
          </main>
          :
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          }
          <AppBar position="fixed" color="default" className={classes.bottomAppBar}>
            <Divider/>
            <Toolbar>
              <Grid container spacing={5} direction="row" justify="flex-start" alignItems="center">
                <Grid item sm={10} md={10} lg={10} xl={10}>
                  <div>
                    <input
                      className={classes.UploadPhoto__file}
                      id="uploadPhoto"
                      type="file"
                      name="Search file"
                      onChange={this.handleFileChange}
                      accept=".png, .jpg, .jpeg, .mp3, .mp4"
                      required
                      multiple
                    />
                    <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
                      <label htmlFor="uploadPhoto">
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <AddPhotoAlternateIcon className={classes.addPhotoIcon}/>
                        </Grid>                      
                      </label>
                      {tempUploadImage === false
                      ?
                      this.state.tempS3ImageURLs &&
                      this.state.images &&
                      (this.state.tempS3ImageURLs || []).map((tempS3ImageURL, index) => (
                        tempS3ImageURL
                        ?
                        this.state.images[index].substr(this.state.images[index].length-4, 4) == (".mp3" && ".mp4")
                          ?
                          <Grid key={tempS3ImageURL} item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <Typography>
                              {this.state.images[index]}
                            </Typography>
                          </Grid>
                          :
                          <Grid key={tempS3ImageURL} item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <CardMedia className={classes.appbarBottomMedia}
                              image={tempS3ImageURL}
                            />
                          </Grid>
                        :
                        null
                      ))
                      :
                      (this.fileArray || []).map(url => (
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={url}>
                          {this.file[0][0].name.substr(this.file[0][0].name.length-4, 4) == (".mp3" && ".mp4")
                          ?
                          <Typography>
                            {this.file[0][0].name}
                          </Typography>
                          :
                          <CardMedia
                            className={classes.media}
                            image={url}
                            aria-label="Recipe" 
                          >
                          </CardMedia>
                          }
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Grid>
                <Grid item sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    //className={classes.commentIconButton}
                    /*color="primary"*/ className={classes.buttonLine}
                    aria-label="back"
                    onClick={this.handleCancelPhoto}
                  >
                    <span>사진취소</span>
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Dialog open={this.state.deleteDialogOpen} /*onClose={this.handleClose}*/>
            <DialogTitle>글을 삭제하시겠습니까?</DialogTitle>
            <DialogActions>
              <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                  <Button variant="contained" /*color="primary"*/ className={classes.button}
                    onClick={this.handleDelete}>확인</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                  <Button variant="contained" /*color="primary"*/ className={classes.button}
                    onClick={this.handleClose}>취소</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_1}>
            <DialogTitle>제목을 입력해주세요</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_2}>
            <DialogTitle>일감 시작 날짜를 입력해주세요</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_3}>
            <DialogTitle>일감 종료 날짜를 입력해주세요</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.alarmDialogOpen_4}>
            <DialogTitle>내용을 작성해주세요</DialogTitle>
            <DialogActions>
              <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                  <Button variant="contained" /*color="primary"*/ className={classes.button} 
                    onClick={this.handleClose}>확인</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //address: state.auth.address,
  //uploadContents: state.upload.uploadContents,
  // currentUser: state.auth.currentUser,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  // currentAuthUser: (currentUser) => dispatch(authActions.currentAuthUser(currentUser)),
})

UploadPhotoModify = withStyles(styles, {withTheme: true})(UploadPhotoModify)
export default connect(mapStateToProps, mapDispatchToProps)(UploadPhotoModify)