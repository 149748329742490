// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import App from './App'

// AWS
import Amplify from "aws-amplify";
import config from "./config";

// 내가 쓴거
//import * as serviceWorker from './serviceWorker';
//import registerServiceWorker from './registerServiceWorker';

// 내가 쓴거
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

/* notosans 폰트 임포트 */
// https://www.hyungjoo.me/한글-웹폰트-모음-나눔-제주-한나-kopub바탕-noto-sans/
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Nanum Gothic',
    // fontFamily: '"Noto Sans KR", serif',
    // fontFamily: 'Nanum Brush Script',
      
  }
})

const rootElement = document.getElementById('root')

// AWS
/*
Amplify는 Cognito를 Auth, S3을 Storage, API Gateway를 API라고 부릅니다.
Auth에 대한 mandatorySignIn 플래그는 true로 설정되어 있습니다. 사용자들이 앱과 상호 작용하기 전에 로그인해야 하기 때문입니다.
name : "upload"는 Amplify에 기본적으로 우리의 API의 이름을 지정하도록하고 있습니다. 
        Amplify를 사용하면 앱에서 사용할 여러 API를 추가 할 수 있습니다. 우리의 경우, 전체 백엔드는 단 하나의 API입니다.
Amplify.configure()는 상호 작용하고자하는 다양한 AWS 리소스를 설정하는 것입니다. 
      여기서는 구성과 관련하여 특별한 설정을 하지 않습니다. 
      따라서 필요치 않은 것일 수도 있지만 설정을 초기화하기 위한 것이니 기억해두십시오.
*/
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "upload",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ],
  }
});

/* notosans 폰트 임포트 <MuiThemeProvider>로 <App />를 감싸주어야 한다 */
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();   // <- 나중에 serviceworker 쓸 때 주석 풀기
// registerServiceWorker();