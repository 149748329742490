import React, { Component } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
// import Textarea from './Textarea'
import Feed_NotiGetting from './Feed_NotiGetting'
import { connect } from 'react-redux'

import SVGIcon from './SVGIcon'

import { API } from "aws-amplify";

// import './UploadFeed.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    //paddingBottom: 60,
    paddingBottom : theme.spacing(7),
  },
  topAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    boxShadow: "none",
    /*
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    */
  },
  toolBar: {
    height : 10,
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  title: {
    flexGrow: 1,
    fontSize : 16,
    color: 'black'
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  content: {
    marginTop: '30px',
    flexGrow: 1,
    //padding: theme.spacing(0),
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 50,
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class NotiGetting extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      eventNotificationLists: '',
      //getFollowees: '',
      notificationLists: '',
      adminUserContents: '',
      adminUser: '',
      adminUserId: '',
      adminWalletAddress: '',
      adminName: '',
      adminAvatarimage: '',
      completed : 0,
    };
  }

  // 이벤트 받는 코드
  getEventNotificationLists() {
    return API.get("upload", `/notificationList`)
    .then(eventNotificationLists => this.setState({eventNotificationLists : eventNotificationLists}));
  }

  // 관리자 (나중에 더 좋은 방법으로 바꾸기)
  getAdminUser() {
    return API.get("upload", "/user/?userId=ap-northeast-2:5c47086a-d50f-4914-9f3c-9e91904eed46")
    .then(adminUserContents => this.setState({adminUserContents : adminUserContents}));
  }

  //-----------------------------------------------------------------------------------------------------------
  /*
  // 내가 Following한 사람들 리스트
  getFollowees() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/follow/?followerUserId=${currentAuthUserFromSession}`)
    .then(getFollowees => this.setState({getFollowees : getFollowees}));
  }

  getUsers() {
    return API.get("upload", "/user")
    .then(userContents => this.setState({userContents : userContents}));
  }

  // Follow 관련 받는 코드
  getNotificationLists() {
    return API.get("upload", `/notificationList`)
    .then(notificationLists => this.setState({notificationLists : notificationLists}));
  }
  */
  //-----------------------------------------------------------------------------------------------------------

  async componentDidMount() {
    this._isMounted = true;

    const {  } = this.props;
    // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    // const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    /*
    // getUser() 관련 (uploadUser와 매칭되서 작성된 글의 user를 찾는 것이다.)
    try {
      const adminUser = await this.getUser();

      const { userId, customerType, companyName, avatarimage } = adminUser;

      this.setState({
        adminUser,
        adminUserId : userId,
        customerType : customerType,
        adminName : companyName,
        adminAvatarimage : avatarimage,
      });

    } catch (e) {
      // alert(e);
      console.log("getAdminUser 에러");
    }
    */

    await this.getEventNotificationLists();
    await this.getAdminUser();

    /*
    await this.getNotificationLists();
    await this.getFollowees();
    await this.getUsers();
    */
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  /*
  handleGoToPage = () => {
    // const { currentUser } = this.props
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    this.setState({
      pageDialogOpen : false,
    });
    // this.props.history.push(`/user/${currentUser}`);
    this.props.history.push(`/user/${currentAuthUserFromSession}`);
  }
  */

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      pageDialogOpen: false,
      deleteDialogOpen : false,
    })
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    completed : 0,
    });
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.topAppBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                  {/*<Button
                    className="NotUploadButton"
                    label="<"
                    onClick={this.handleNotUpload}
                  />*/}
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.title} align='center' variant="h6" noWrap>
                    알림
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                      <Typography className={classes.typoTitle}>
                        이벤트
                      </Typography>
                    </ListItem>
                  </List>
                  {/* filter(검색)기능 */}
                  {          
                    this.state.eventNotificationLists && this.state.adminUserContents
                    ?
                    Object.keys(this.state.eventNotificationLists).map(notiListUUId => {
                      const adminNotificationList = this.state.eventNotificationLists[notiListUUId];

                      let Name;
                      let Avatarimage;
                      Object.keys(this.state.adminUserContents).map(userId => {
                        const adminUserContent = this.state.adminUserContents[userId];
                        if(adminUserContent.userId = "ap-northeast-2:5c47086a-d50f-4914-9f3c-9e91904eed46") {
                          Name = adminUserContent.companyName
                          Avatarimage = adminUserContent.avatarimage;
                        }
                      })

                      return(
                        <Feed_NotiGetting
                          stateRefresh={this.stateRefresh}
                          key={adminNotificationList.notiListUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                          notiId={adminNotificationList.id}
                          customerType={adminNotificationList.customerType}
                          notiName={Name}
                          notiAvatarimage={Avatarimage}
                          //notiName={adminNotificationList.name}
                          //notiAvatarimage={adminNotificationList.avatarimage}
                          userId={adminNotificationList.userId}
                          notiListUUId={adminNotificationList.notiListUUId}
                          notiTitle={adminNotificationList.title}
                          notiBody={adminNotificationList.body}
                          notiImage={adminNotificationList.image}
                          notiDate={adminNotificationList.date}
                        />
                      );
                    })
                    :
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                      <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                    </Grid>
                  }
                </Card>
                {/*
                // Follow 알림
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItemTitle}>
                      <Typography className={classes.typoTitle}>
                        알림
                      </Typography>
                    </ListItem>
                  </List>
                  {          
                    this.state.notificationLists && this.state.getFollowees && this.state.userContents
                    ?
                    Object.keys(this.state.notificationLists).map(notiListUUId => {
                      const notificationList = this.state.notificationLists[notiListUUId];

                      let Title;
                      let Body;
                      let Image;
                      let date;
                      let followingTrue = "false";
                      let FolloweeUserId;
                      let WalletAddress;
                      let NickName;
                      let Avatarimage;
                      Object.keys(this.state.getFollowees).map(followingUUId => {
                        const getFollowee = this.state.getFollowees[followingUUId];
                        if(getFollowee.followeeUserId === notificationList.userId) {
                          Title = notificationList.title;
                          Body = notificationList.body;
                          Image = notificationList.image;
                          date = notificationList.date;
                          followingTrue = "true";
                          FolloweeUserId = getFollowee.followeeUserId;
                        }

                        /
                        // 안에 3중 반복문 사용 했더니 속도 느려지는거 같아서 한개의 반복문을 바깥으로 뺐음
                        Object.keys(this.state.userContents).map(userId => {
                          const userContent = this.state.userContents[userId];
                          if(userContent.userId === getFollowee.followeeUserId) {
                            WalletAddress = userContent.walletAddress;
                            NickName = userContent.nickName;
                            Avatarimage = userContent.avatarimage;
                          }
                        })
                        /
                      })

                      if(followingTrue === "true") {
                        Object.keys(this.state.userContents).map(userId => {
                          const userContent = this.state.userContents[userId];
                          if(userContent.userId === FolloweeUserId) {
                            WalletAddress = userContent.walletAddress;
                            NickName = userContent.nickName;
                            Avatarimage = userContent.avatarimage;
                          }
                        })

                        return(
                          <Feed_NotiGetting
                            stateRefresh={this.stateRefresh}
                            key={notificationList.notiListUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                            notiId={notificationList.id}
                            notiNickName={NickName}
                            notiAvatarimage={Avatarimage}
                            userId={notificationList.userId}
                            notiListUUId={notificationList.notiListUUId}
                            notiTitle={Title}
                            notiBody={Body}
                            notiImage={Image}
                            notiDate={date}
                          />
                        );
                      }
                    })
                    :
                    <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                      <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                    </Grid>
                  }
                </Card>
                */}
              </Grid>
            </Grid>
          </main>
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

NotiGetting = withStyles(styles, {withTheme: true})(NotiGetting)
export default connect(mapStateToProps, mapDispatchToProps)(NotiGetting);