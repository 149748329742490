// Firebase Cloud Messaging 관련
export default {
  apiKey: "AIzaSyBzNf1Au_KUBXcKBd_WNWgbZ1OvzuXWfZ0",
  authDomain: "ilgam-fcm.firebaseapp.com",
  databaseURL: "https://ilgam-fcm.firebaseio.com",
  projectId: "ilgam-fcm",
  storageBucket: "ilgam-fcm.appspot.com",
  messagingSenderId: "228887467012",
  appId: "1:228887467012:web:facbf6bce55d81a38c9151",
  measurementId: "G-3JF4J5SZJ6"
};