import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import Feed_HomeGetProjects from './Feed_HomeGetProjects';
import Button from '@material-ui/core/Button';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { API } from "aws-amplify";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

//import './UploadButton.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    [theme.breakpoints.up('xs')]: { // xs보다 up일때 동작
      marginTop : theme.spacing(7),
    },
    [theme.breakpoints.down('xs')]: { // xs 보다 down 일때 동작
      marginTop : theme.spacing(7),
    },
    marginBottom : theme.spacing(13),
  },
  button: {
    width: '100%',
    backgroundColor: '#0c93d0',
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoMoreSee: {
    fontSize: 16,
  },
  expandMoreOutlinedIcon: {
    width: 30,
    height: 30
  },
  list: {
    width: '100%',
    padding: 0,
  },
  listItem: {
    height: 60,
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'fixed', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class HomeGetProjects extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);

      this.state = {
        getUploadConnects: '',
        getUploadConnect: '',
        getOrderUsers: '',
        getOrderUser: '',
        image: "",
        getNotifications: "",
        fcmTokenId: "",
        userId: "",
        getFollowees: '',
        //favoriteThumbs: '',
        //favoriteThumb: '',
        getApplications: '',
        getApplication: '',
        pointSum: '',
        // scroll 관련
        page: 1,
        pagelimit: 9,
        searchContent: '',  // DB 검색 키워드
        hasNextPage: false, // 다음 페이지가 false이면 DB에서 더이상 가지고 올 자료 없다는 뜻
        completed : 0,
      };
  }

  getUploadConnects() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    return API.get("upload", `/uploadconnect/?getUserId=${currentAuthUserFromSession}`)
    .then(getUploadConnects => this.setState({getUploadConnects : getUploadConnects}));
    /*
    const currentPage = localStorage.getItem('currentPage')
    const searchKeyword = sessionStorage.getItem("searchKeyword")

    // 만약 검색 키워드 없으면 실행
    if(!searchKeyword) {
      return API.get("upload", `/upload/?field=${currentPage}&subField=0&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
    }
    // 검색 키워드 있을때 실행
    if(currentPage === 'search') {
      return API.get("upload", `/upload/?subField=0&content=${searchKeyword}&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
    } else {
      return API.get("upload", `/upload/?field=${currentPage}&subField=0&content=${searchKeyword}&page=${this.state.page}&pagelimit=${this.state.pagelimit}`)
    }
    */
  }

  getOrderUsers() {
    return API.get("upload", `/user`)
    .then(getOrderUsers => this.setState({getOrderUsers : getOrderUsers}));
  }

  /*
  getPoint() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/point/${currentAuthUserFromSession}`)
  }
  */

  /*
  getFavorites() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/favorite/?userId=${currentAuthUserFromSession}`)
    .then(favoriteThumbs => this.setState({favoriteThumbs : favoriteThumbs}));
  }
  */

  // 내가 지원한 프로젝트 리스트
  getApplications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/application/?userId=${currentAuthUserFromSession}`)
    .then(getApplications => this.setState({getApplications : getApplications}));
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;

    localStorage.setItem("currentPage", "homeGetProjects");
    localStorage.setItem('currentTapLink', '0');

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: " + userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: " + userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
        // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // currentAuthUser(currentAuthUserFromSession);
        userHasAuthenticatedTrue();
      } else {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
        // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // currentAuthUser(currentAuthUserFromSession);
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          alert(e);
        }
    }

    await this.getOrderUsers();
    await this.getUploadConnects();
    await this.getApplications();
    // await this.getFavorites();
  }

  handleUpload = () => {
    const { upload } = this.props
    upload()
  }

  /*
  // MainAppShellField에서 검색시 동작하는 함수
  searchUpload = async() => {

    const { searchkeywordfalse } = this.props;
    
    this.state.pagelimit = 9; // 검색 실행시 초기화

    const publicUploads = await this.getPublicUploads();

    const { docs, hasNextPage } = publicUploads;
    this.setState({publicUploadContents: docs})
    this.setState({hasNextPage: hasNextPage})

    searchkeywordfalse();
  }
  */

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 사용자가 삭제 버튼을 눌렀을 때 실행하는 함수
  handleDelete = (id) => {
    this._delete(id);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    getUploadConnects: '',
    getOrderUsers: '',
    // favoriteThumbs : '',
    completed : 0,
    });

    this.getOrderUsers();
    this.getUploadConnects();
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, /*isFavoriteLoading,*/ isApplyingLoading, /*isSearchKeyword*/ } = this.props;

    /*
    if(isFavoriteLoading) {
      this.getFavorites();
    }
    */

    if(isApplyingLoading) {
      this.getApplications();
    }

    /*
    if(isSearchKeyword) {
      this.searchUpload();
    }
    */

    /*
    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredComponents = (data) => {
      data = data.filter((c) => {
        return c.content.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {

        let WalletAddress;
        let Avatarimage;
        let CustomerType;
        let CompanyName;
        let CompanyPhoneNumber;
        let Address;
        let Homepage;
        let Name;
        let PhoneNumber;
        let Email;
        
        Object.keys(this.state.userContents).map(userId => {
          const userContent = this.state.userContents[userId];
          if(userContent.userId === c.userId) {
            WalletAddress = userContent.walletAddress;
            Avatarimage = userContent.avatarimage;
            CustomerType = userContent.customerType;
            CompanyName = userContent.companyName;
            CompanyPhoneNumber = userContent.companyPhoneNumber;
            Address = userContent.address;
            Homepage = userContent.homepage;
            Name = userContent.name;
            PhoneNumber = userContent.phoneNumber;
            Email = userContent.email;
          }
        });

        /*
        let favoriteUserId;
        let favoriteUUId;
        let favoriteContent;
        let favoriteContentNumbers = 0;
        Object.keys(this.state.favoriteThumbs).map(uploadUUId => {
          const favoriteThumb = this.state.favoriteThumbs[uploadUUId];
          if(favoriteThumb.uploadUUId === c.uploadUUId) {
            if(favoriteThumb.userId === currentAuthUserFromSession) {
              favoriteUserId = favoriteThumb.userId;
              favoriteUUId = favoriteThumb.favoriteUUId;
              favoriteContent = favoriteThumb.favoriteContent;
            }
            favoriteContentNumbers = favoriteContentNumbers + 1;
          }
        });
        /

        let applicationTitle;
        let applicationToggle;
        Object.keys(this.state.getApplications).map(applicationUUId => {
          const getApplication = this.state.getApplications[applicationUUId];
          if(getApplication.uploadUUId === c.uploadUUId) {
            applicationTitle=getApplication.applicationTitle;
            applicationToggle=getApplication.applicationToggle;
            }
        });

        return(
          <Feed_FieldForm
            stateRefresh={this.stateRefresh}
            key={c.uploadUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
            id={c.id}
            userId={c.userId}
            uploadUUId={c.uploadUUId}
            walletAddress={WalletAddress}
            customerType={CustomerType}
            companyName={CompanyName}
            companyPhoneNumber={CompanyPhoneNumber}
            address={Address}
            homepage={Homepage}
            name={Name}
            phoneNumber={PhoneNumber}
            email={Email}
            avatarimage={Avatarimage}
            date={c.date}
            images={c.images}  // AWS S3 사용시
            field={c.field}
            subField={c.subField}
            title={c.title}
            content={c.content}
            dateStart={c.dateStart}
            dateEnd={c.dateEnd}
            /*
            favoriteUserId={favoriteUserId}
            favoriteUUId={favoriteUUId}
            favoriteContent={favoriteContent}
            favoriteContentNumbers={favoriteContentNumbers}
            /
            applicationTitle={applicationTitle}
            applicationToggle={applicationToggle}
          />
        );
      })
    }
    */

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <Grid container spacing={0} item xs={12} sm={12} md={10} lg={10} xl={10}>
            {/* filter(검색)기능 */}
            {this.state.getUploadConnects && this.state.getOrderUsers
             && this.state.getApplications /*&& this.state.favoriteThumbs*/
            ?
            // filteredComponents(this.state.getUploadConnects)
            Object.keys(this.state.getUploadConnects).map(uploadConnectUUId => {
              // uploadContent가 데이터 베이스에 있는데 id값을 이용해서 각각 uploadContent 변수 
              const c = this.state.getUploadConnects[uploadConnectUUId];

              let Avatarimage;
              let CustomerType2;
              let CompanyName;
              let Name;
              let History;
              let Address = [];
        
              Object.keys(this.state.getOrderUsers).map(userUUId => {
                const getOrderUser = this.state.getOrderUsers[userUUId];
                if(getOrderUser.userId === c.orderUserId) {
                  Avatarimage = getOrderUser.avatarimage;
                  CustomerType2 = getOrderUser.customerType2;
                  CompanyName = getOrderUser.companyName;
                  Name = getOrderUser.name;
                  History = getOrderUser.history;
                  Address = getOrderUser.address;
                }
              });

              /*
              let favoriteUserId;
              let favoriteUUId;
              let favoriteContent;
              let favoriteContentNumbers = 0;
              Object.keys(this.state.favoriteThumbs).map(uploadUUId => {
                const favoriteThumb = this.state.favoriteThumbs[uploadUUId];
                if(favoriteThumb.uploadUUId === c.uploadUUId) {
                  if(favoriteThumb.userId === currentAuthUserFromSession) {
                    favoriteUserId = favoriteThumb.userId;
                    favoriteUUId = favoriteThumb.favoriteUUId;
                    favoriteContent = favoriteThumb.favoriteContent;
                  }
                  favoriteContentNumbers = favoriteContentNumbers + 1;
                }
              });
              */

              let ApplicationTitle;
              let ApplicationToggle;
              Object.keys(this.state.getApplications).map(applicationUUId => {
                const getApplication = this.state.getApplications[applicationUUId];
                if(getApplication.uploadUUId === c.uploadUUId) {
                  ApplicationTitle=getApplication.applicationTitle;
                  ApplicationToggle=getApplication.applicationToggle;
                  }
              });

              return(
                <Feed_HomeGetProjects
                  stateRefresh={this.stateRefresh}
                  key={c.uploadConnectUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                  id={c.id}
                  orderUserId={c.orderUserId}
                  getUserId={c.getUserId}
                  uploadUUId={c.uploadUUId}
                  uploadConnectUUId={c.uploadConnectUUId}
                  avatarimage={Avatarimage}
                  customerType2={CustomerType2}
                  companyName={CompanyName}
                  name={Name}
                  history={History}
                  address={Address}
                  date={c.date}
                  field={c.field}
                  subField={c.subField}
                  title={c.title}
                  content={c.content}
                  dateStart={c.dateStart}
                  dateEnd={c.dateEnd}
                  images={c.images}  // AWS S3 사용시
                  applicationTitle={ApplicationTitle}
                  applicationToggle={ApplicationToggle}
                  /*
                  favoriteUserId={favoriteUserId}
                  favoriteUUId={favoriteUUId}
                  favoriteContent={favoriteContent}
                  favoriteContentNumbers={favoriteContentNumbers}
                  */
                />
              );
            })
            :
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
            </Grid>
            }
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <List className={classes.listIntroduce}>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    {/* empty */}
                  </Grid>
                </ListItem>
                {/*<Divider/>*/}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //uploadPage: state.upload.uploadPage,
  //uploadContents: state.upload.uploadContents,
  // searchKeyword: state.upload.searchKeyword, // 세션스토리지로 대체 됨
  //isSearchKeyword: state.upload.isSearchKeyword,
  /*
  isFavoriteLoading: state.upload.isFavoriteLoading,
  */
  isApplyingLoading: state.upload.isApplyingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  // currentAuthUser: (currentUser) => dispatch(authActions.currentAuthUser(currentUser)),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  //upload: () => dispatch(uploadActions.upload()),
  /*
  favoriteloadingtrue: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingtrue(isFavoriteLoading)),
  favoriteloadingfalse: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingfalse(isFavoriteLoading)),
  */
  applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
  // 세션스토리지로 대체 됨
  // uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
  //searchkeywordtrue: () => dispatch(uploadActions.searchkeywordtrue()),
  //searchkeywordfalse: () => dispatch(uploadActions.searchkeywordfalse()),
})

HomeGetProjects = withStyles(styles, {withTheme: true})(HomeGetProjects)
export default connect(mapStateToProps, mapDispatchToProps)(HomeGetProjects)