import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'

// S3 관련
import { API } from "aws-amplify";
import { s3DeletePublic } from "../libs/awsLib";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(0),
    overFlowX : "auto",
    display: 'flex',
  },
  /*
  GridContainer: {
    minHeight: "10vh"
  },
  */
  card: {
    width : '100%',
    display: 'flex',
    borderRadius: "0px",
    // maxWidth: 650,
    // minWidth : 500,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  /*
  media: {
    marginTop: 5,
    height: 50,
    width: 130,
    objectFit: 'contain'
  },
  typoName: {
    width: '100%',
    fontSize : 13,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20
  },
  */
  list: {
    width: '100%',
    padding: '0',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItemContent: {
    paddingTop: 0,
    paddingBottom: 0,
    //background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
  // background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoDate: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
  },
  typoProcess: {
    color:'#0c93d0',
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 5,
    //paddingLeft: 20
  },
  typoApplication: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
    //paddingLeft: 20
  },
  /*
  typoAddress: {
    width: '100%',
    fontSize : 13,
    color: '#999',
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 20
  },
  avatar: {
    // backgroundColor: red[500],
    marginTop: 5,
    backgroundColor: '#D3D3D3',
    margin: 10,
    width: 70,
    height: 70,
  },
  */
  button: {
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoButton: {
    width: '100%',
    fontSize : 17,
    paddingTop: 0,
    paddingBottom: 0,
  },
  /*
  iconButton: {
    margin: theme.spacing(0),
  },
  */
  MoreVertIconRoot: {
    position: 'relative',
  },
  MoreVertIconContent: {
    padding: theme.spacing(0),
  },
  popupStateTypography: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 15,
    marginRight: 15
  },
  popupStateMenu: {
    padding : 0,
  },
  countLink: {
    color:'#0c93d0'
  },
  /*
  starBorderIconButton: {
    padding: 0,
  },
  starIconButton: {
    padding: 0,
  },
  starBorderIcon: {
    width: 19,
    height: 19,
  },
  starIcon: {
    width: 19,
    height: 19,
    color: '#FFD700'  // gold
  },
  */
  dialogTitle: {
    fontSize: 17
  },
  dialogContent: {
    fontSize: 17
  },
});

class Feed_MyProcessLists extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processStep: '',
      deleteDialogOpen : false, // 다이얼로그 창이 열려 있는지 확인하는 변수
    };
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  /*
  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }
  */

  deleteDialog = async event => {
    event.preventDefault();

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.userId === currentAuthUserFromSession) {
      this.setState({
        deleteDialogOpen : true,
      });
    }
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      deleteDialogOpen : false,
    })
  }

  async componentDidMount() {
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Card className={classes.card}>
          <List className={classes.list}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ListItem className={classes.listItemTitle}>
                    <Typography className={classes.typoTitle}>
                      {/* 글자 길이가 20개 이상 넘어가면 '...' 나옴 */}
                      {this.props.processTitle.slice(0,20)}
                        {this.props.processTitle.length > 20 
                        ? '...'
                        : null
                      }
                    </Typography>
                  </ListItem>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemContent}>
                      <Typography className={classes.typoDate}>
                        {moment(this.props.dateStart).format("YYYY-MM-DD")} ~ {moment(this.props.dateEnd).format("YYYY-MM-DD")}
                      </Typography>
                    </ListItem>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItem className={classes.listItemContent}>
                      {this.props.processToggle === 'start'
                      ?
                      <Typography className={classes.typoProcess}>
                        단계 0 (발주 받음)
                      </Typography>
                      :
                      this.props.processToggle === '0'
                      ?
                      <Typography className={classes.typoProcess}>
                        단계 1 (소재 준비 및 제작 시작) 완료
                      </Typography>
                      :
                      this.props.processToggle === '1'
                      ?
                      <Typography className={classes.typoProcess}>
                        단계 2 (제작 중) 완료
                      </Typography>
                      :
                      this.props.processToggle === '2'
                      ?
                      <Typography className={classes.typoProcess}>
                        단계 3 (제작 중) 완료
                      </Typography>
                      :
                      this.props.processToggle === '3'
                      ?
                      <Typography className={classes.typoProcess}>
                        단계 4 (제작 완료) 완료
                      </Typography>
                      :
                      this.props.processToggle === '4'
                      ?
                      <Typography className={classes.typoProcess}>
                        단계 5 (검수 완료) 완료
                      </Typography>
                      :
                      null
                      }
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} align="center">
                <Link component={RouterLink} to={`/process/${this.props.processUUId}`} underline='none' color='inherit'>
                  <Button variant="contained" className={classes.button}>
                    <Typography className={classes.typoButton}>업로드 보기</Typography>
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </List>
        </Card>
        <Dialog open={this.state.deleteDialogOpen} /*onClose={this.handleClose}*/>
          <DialogTitle>글을 삭제하시겠습니까?</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.button}
              onClick={this.handleDelete}>확인</Button>
            <Button variant="contained" /*color="primary"*/ className={classes.button}
              onClick={this.handleClose}>취소</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
})

Feed_MyProcessLists = withStyles(styles, {withTheme: true})(Feed_MyProcessLists)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_MyProcessLists);