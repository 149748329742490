import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import FaceBookLoginButton from './FaceBookLoginButton'
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth, API } from "aws-amplify";

import * as authActions from 'redux/actions/auth'

// 라우터 관련
import { withRouter } from 'react-router-dom';

// import './FacebookButton.scss'

const styles = theme => ({
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
});

// Facebook JS SDK가로드 될 때까지 기다립니다. 
// 일단로드되면 Facebook으로 로그인 버튼을 활성화 합니다.
function waitForInit() {
  return new Promise((res, rej) => {
    const hasFbLoaded = () => {
      if (window.FB) {
        res();
      } else {
        setTimeout(hasFbLoaded, 300);
      }
    };
    hasFbLoaded();
  });
}

class FacebookButton extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      email: '',
      warningMessage: '',
      isLoading: true,
      completed : 0,
    }
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  componentDidMount = async event => {
    this._isMounted = true;

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    await waitForInit();
    this.setState({ isLoading: false });

    /*
    try {
      if (this.isAndroid()) {
        const receiver = document;
        receiver.addEventListener('message', async (e) => {
          const event = JSON.parse(e.data) 
          //alert('googleLogin1: ' + JSON.stringify(event))
          if (event.type == 'facebookLogin') {
            //alert('googleLogin_android: ' + JSON.stringify(event.type))

            this.handleExpoResponse(event.message);
            userHasAuthenticatedTrue();
          }
          // expo에서 받은 데이터로 구글 로그인 진행
  
        })
      }
    } catch(e) {
      ;
    }
    */
  }

  // 사용자가 버튼을 클릭하면를 사용하여 로그인 프로세스를 시작 FB.login하고에서 로그인 상태가 변경 될 때까지 듣습니다.
  // statusChangeCallback. 이 메소드를 호출하는 동안을 설정하여 사용자의 공개 프로필과 이메일 주소를 원한다고 지정합니다 
  // {scope: "public_profile,email"}.
  statusChangeCallback = response => {
    if (response.status === "connected") {
      this.handleResponse(response.authResponse);
    } else {
      this.handleError(response);
    }
  };

  checkLoginState = () => {
    window.FB.getLoginStatus(this.statusChangeCallback);
  };

  isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  handleClick = () => {
    // android가 아니고 ios가 아니면 web 로그인 진행
    if (!this.isAndroid() && !this.isIOS()) {
      //alert("web login")
      window.FB.login(this.checkLoginState, {scope: "public_profile,email"});
    }

    // android 또는 ios 이면 로그인 진행
    if (this.isAndroid() || this.isIOS()) {
      //alert('Mobile login');
      //window.ReactNativeWebView.postMessage(JSON.stringify({ type:'FB' }))
      window.FB.login(this.checkLoginState, {scope: "public_profile,email"});
    }
  };

  handleError(error) {
    // alert(error);
    console.log(error);
  }

  // 사용자가 앱에 권한을 부여한 경우 Facebook (사용자의 이메일)에서받은 정보를 사용하여 
  // Auth.federatedSignInAWS Amplify 메서드를 호출합니다. 
  // 이것은 효과적으로 사용자를 로그인합니다.
  async handleResponse(data) {
    const { userHasAuthenticatedTrue } = this.props
    const { email, accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();
    const user = { email };

    this.setState({ isLoading: true });

    await FB.api('/me', {fields: 'name,email'}, (response) => {
      this.state.email  = response.email;
    });

    try {
      const response = await Auth.federatedSignIn(
        "facebook",
        { token, expires_at },
        user
      );
      this.setState({ isLoading: false });
      
      try {
        const userID = await Auth.currentAuthenticatedUser();
        // console.log("userID: ");
        // console.log(userID);
        const userID2 = await Auth.currentUserInfo(userID);
        // console.log("userID2: ");
        // console.log(userID2);
        if(userID2 === null) {
          const userID3 = JSON.stringify(userID);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
  
          userHasAuthenticatedTrue();
        } else if (userID2 != null) {
          const userID3 = JSON.stringify(userID2);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          
          //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
          //console.log("Facebook-currentAuthUserFromSession :")
          //console.log(currentAuthUserFromSession)
          
          userHasAuthenticatedTrue();
        }
      } catch (e) {
          if (e !== "not authenticated") {
            // alert(e);
            console.log(e)
          }
      }

      try {
        const user = await this.getUser();
    
        const { userId, customerType1, customerType2 } = user;
  
        localStorage.setItem("customerType1", customerType1);
        localStorage.setItem("customerType2", customerType2);
        //const customerType1_FromLocal = localStorage.getItem("customerType1");
        //console.log("Login customerType1_FromLocal :")
        //console.log(customerType1_FromLocal)
       } catch(e) {
         console.log(e)
       }


      //sessionStorage.setItem("FCMLoginToggle", "true");
      localStorage.setItem("loginType", "facebook");
      localStorage.setItem("email", this.state.email);

      this.props.onLogin(response);

      // this.props.history.push("/"); // 로그인 후 home 페이지로 이동
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleError(e);
    }
  }

  // expo에서 받은 로그인 정보
  async handleExpoResponse(data) {
    const { userHasAuthenticatedTrue } = this.props
    //const { email, accessToken: token, expiresIn } = data;
    const { email, access_token, expires_in } = data
    const expires_at = expires_in * 1000 + new Date().getTime();
    const user = { email };

    this.setState({ isLoading: true });

    alert('facebookLogin_android_email: ' + JSON.stringify(email))
    alert('facebookLogin_android_access_token: ' + JSON.stringify(access_token))
    alert('facebookLogin_android_expires_in: ' + JSON.stringify(expires_in))

    /*
    await FB.api('/me', {fields: 'name,email'}, (response) => {
      this.state.email  = response.email;
    });
    */

    try {
      const response = await Auth.federatedSignIn(
        "facebook",
        { access_token, expires_at },
        user
      );
      this.setState({ isLoading: false });
      
      try {
        const userID = await Auth.currentAuthenticatedUser();
        // console.log("userID: ");
        // console.log(userID);
        const userID2 = await Auth.currentUserInfo(userID);
        // console.log("userID2: ");
        // console.log(userID2);
        if(userID2 === null) {
          const userID3 = JSON.stringify(userID);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
  
          userHasAuthenticatedTrue();
        } else if (userID2 != null) {
          const userID3 = JSON.stringify(userID2);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          
          //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
          //console.log("Facebook-currentAuthUserFromSession :")
          //console.log(currentAuthUserFromSession)
          
          userHasAuthenticatedTrue();
        }
      } catch (e) {
          if (e !== "not authenticated") {
            // alert(e);
            console.log(e)
          }
      }

      try {
        const user = await this.getUser();
    
        const { userId, customerType1, customerType2 } = user;
  
        localStorage.setItem("customerType1", customerType1);
        localStorage.setItem("customerType2", customerType2);
        //const customerType1_FromLocal = localStorage.getItem("customerType1");
        //console.log("Login customerType1_FromLocal :")
        //console.log(customerType1_FromLocal)
       } catch(e) {
         console.log(e)
       }


      //sessionStorage.setItem("FCMLoginToggle", "true");
      localStorage.setItem("loginType", "facebook");
      localStorage.setItem("email", this.state.email);

      this.props.onLogin(response);

      // this.props.history.push("/"); // 로그인 후 home 페이지로 이동
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleError(e);
    }
  }


  render() {
    const { classes } = this.props
    const { warningMessage } = this.state
    return (
      /*<div className="LoginForm">*/
      <div>
        {this.state.isLoading 
        ?
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
        </Grid>
        :
        <FaceBookLoginButton
          title="페이스북 로그인"
          disabled={this.state.isLoading}
          onClick={this.handleClick}
        />
        }
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //email: state.auth.email,
  //password: state.auth.password,
})

const mapDispatchToProps = (dispatch) => ({
  //awslogin: () => dispatch(authActions.awslogin()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

FacebookButton = withStyles(styles, {withTheme: true})(FacebookButton); 
FacebookButton = connect(mapStateToProps, mapDispatchToProps)(FacebookButton);
export default withRouter(FacebookButton)