import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";
import moment from 'moment';

import { API } from "aws-amplify";

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

const styles = theme => ({
  root: {
    //marginTop: 5,
    //height: 100,
    width: "100%",
    //padding: 20,
  },
  button1: {
    //width: 500,
    height: 40,
  },
  button2: {
    //width: 500,
    height: 40,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  button: {
    fontSize: 17,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  typoSubmit: {
    fontSize: 17,
  },
  dialogTitle: {
    fontSize: 13
  },
  dialogContent: {
    fontSize: 12
  },
  textfieldTitle: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
  },
  textFieldLabelSize:{
    fontSize:15
  },
  textFieldInputSize:{
    fontSize:15
  },
});

class UploadFeedApplication extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      submitDialogOpen: false,
      applyDialogOpen: false,
      applicationTitle: '',
      applicationToggle: '',
      applicationInvoice: '',
      applicationContent: '',
      alarmDialogOpen_1: false,
      fcmTokenIds: [], // 여러명한테 FCM 발송시 시도
      completed : 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    //------------------------------------------------------------------------------------
    // FCM으로 발송할 데이터를 미리 받아서 fcmTokenId를 배열로 저장.
    // 나중에 발송시 FCM으로 fcmTokenId 배열로 보내면 최대 1,000명한테 한번에 발송 가능
    try {
      await this.getNotifications();
      // fcmTokenIds를 배열로 받기
      if(this.state.notifications) { 
        let fcmTokenId
        Object.keys(this.state.notifications).map(notificationUUId => {
          const notification = this.state.notifications[notificationUUId];
            fcmTokenId = notification.fcmTokenId
            this.setState({ fcmTokenIds: this.state.fcmTokenIds.concat(fcmTokenId) })
        });
      }
    } catch(e) {
      console.log(e)
    }
    //------------------------------------------------------------------------------------
  }

  postApplication(application) {
    return API.post("upload", "/application", {
      body: application
    });
  }

  //------------------------------------------------------------------------
  // 지원 버튼 클릭시 알림 발송
  //------------------------------------------------------------------------
  postNotificationFCM(notificationFCM) {
    return API.post("upload", "/notificationFCM", {
      body: notificationFCM
    });
  }
  
  getNotifications() {
    return API.get("upload", `/notification/?userId=${this.props.uploadUserId}`)
    .then(notifications => this.setState({notifications : notifications}));
  }
  //------------------------------------------------------------------------

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      submitDialogOpen : false,
      //applyDialogOpen : false,
      alarmDialogOpen_1: false,
    })
  }

  handleClosePreInvoice = () => {
    this.setState({
      alarmDialogOpen_1: false,
    })
  }

  // 프로젝트 지원하기 버튼 클릭 시 1
  handleSubmit = () => {
    this.setState({submitDialogOpen : true});
  }

  // 프로젝트 지원하기 버튼 클릭 시 2
  handleApplication = async () => {
    const { applyingloadingtrue, applyingloadingfalse } = this.props;

    // 작성 안한 파트 작성하게끔 해주는 코드 (일감 요청 란 에서만 동작)
    if(this.props.subField === '0') {
      if (!this.state.applicationInvoice) {
        this.setState({alarmDialogOpen_1: true});
        return;
      }
    }

    if(this.state.applicationInvoice === '') {
      this.state.applicationInvoice = "default"
    }

    if(this.state.applicationContent === '') {
      this.state.applicationContent = "default"
    }
    
    try {
      applyingloadingtrue();
      
      await this.postApplication({
        uploadUserId: this.props.uploadUserId,
        uploadUUId: this.props.uploadUUId,
        applicationSubField: this.props.subField,
        applicationTitle: this.props.title,
        dateStart: moment(this.props.dateStart).format("YYYY-MM-DD"),
        dateEnd: moment(this.props.dateStart).format("YYYY-MM-DD"),
        applicationToggle: '0', // '0': 지원자가 지원 신청 한거
        applicationInvoice: this.state.applicationInvoice,
        applicationContent: this.state.applicationContent,
        orderComment: 'default', 
        orderSign: 'default', 
        applicationSign: 'default'
        });
      
      applyingloadingfalse();
      // this.props.history.push("/");
      this.handleClose(); // 기존 dialog 창 닫기
      //this.setState({applyDialogOpen: true})  // 이후에 '지원신청 완료' dialog 창 열기
    } catch (e) {
      // alert(e);
      console.log(e)
    }

    try {
      if(this.state.fcmTokenIds.length > 0) {
        await this.postNotificationFCM({
          registration_ids: this.state.fcmTokenIds,
          title: this.props.title + '의 견적이 도착했습니다!',
          body: '견적 : ' + this.state.applicationInvoice,
        });
      }
    } catch(e) {
      console.log(e)
    }
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    return (
      <div className={classes.root}>
        {this.props.subField === '0'
        ?
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
          { currentAuthUserFromSession == this.props.uploadUserId
          ?
          <Button variant="contained" color="primary"className={classes.button1}  
          >
            <Typography className={classes.typoSubmit}>내 공고</Typography>
          </Button>
          :
          this.props.applicationToggle === '0' || this.props.applicationToggle === '1' || this.props.applicationToggle === '2'
          ?
          <Button variant="contained" color="secondary" className={classes.button1} 
          >
            <Typography className={classes.typoSubmit}>견적 완료</Typography>
          </Button>
          :
          <Button variant="contained" /*color="primary"*/ className={classes.button2} 
            onClick={this.handleSubmit}
          >
            <Typography className={classes.typoSubmit}>견적 발송</Typography>
          </Button>
          }
        </Grid>
        :
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
          { currentAuthUserFromSession == this.props.uploadUserId
          ?
          <Button variant="contained" color="primary"className={classes.button1}  
          >
            <Typography className={classes.typoSubmit}>내 공고</Typography>
          </Button>
          :
          this.props.applicationToggle === '0' || this.props.applicationToggle === '1' || this.props.applicationToggle === '2'
          ?
          <Button variant="contained" color="secondary" className={classes.button1} 
          >
            <Typography className={classes.typoSubmit}>연락처 완료</Typography>
          </Button>
          :
          <Button variant="contained" /*color="primary"*/ className={classes.button2} 
            onClick={this.handleSubmit}
          >
            <Typography className={classes.typoSubmit}>연락처 남기기</Typography>
          </Button>
          }
        </Grid>
        }
        {this.props.subField === '0'
        ? 
        <Dialog /*fullScreen={true}*/ fullWidth={true} /*maxWidth="md"*/ open={this.state.submitDialogOpen} /*onClose={this.handleClose}*/>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogTitle}>
              {this.props.title}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogContent}>
                {this.props.content}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="가견적" type="text" name="applicationInvoice" 
              value={this.state.applicationInvoice} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="간단한 내용 및 문의" type="text" name="applicationContent" 
              value={this.state.applicationContent} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleApplication}>견적 발송</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        :
        <Dialog /*fullScreen={true}*/ fullWidth={true} /*maxWidth="md"*/ open={this.state.submitDialogOpen} /*onClose={this.handleClose}*/>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogTitle}>
              {this.props.title}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.dialogContent}>
                {this.props.content}
            </Typography>
          </DialogContent>
          <DialogContent dividers>
            <TextField className={classes.textfieldTitle}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabelSize
                },
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldInputSize,
                },
              }}
              label="간단한 내용 및 문의" type="text" name="applicationContent" 
              value={this.state.applicationContent} onChange={this.handleValueChange} autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="end">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleApplication}>연락처 남기기</Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align="flex-start">
                <Button variant="contained" /*color="primary"*/ className={classes.button} 
                  onClick={this.handleClose}>취소</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        }
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>가견적을 입력해주세요</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button} 
                  onClick={this.handleClosePreInvoice}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        {/*
        <Dialog open={this.state.applyDialogOpen}>
          <DialogTitle>지원신청이 완료 되었습니다.</DialogTitle>
          <DialogActions>
            <Grid container spacing={0} item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Button variant="contained" className={classes.button}
                  onClick={this.handleClose}>확인</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        */}
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isApplyingLoading: state.upload.isApplyingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
})

UploadFeedApplication = withStyles(styles, {withTheme: true})(UploadFeedApplication)
export default connect(mapStateToProps, mapDispatchToProps)(UploadFeedApplication);