import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import auth from './auth'
import ui from './ui'
import photos from './photos'
import upload from './upload'

const reducer = combineReducers({
  routing: routerReducer,
  auth,
  ui,
  photos,
  upload,
})

export default reducer
