import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import Feed_Followee from './Feed_Followee';

import { API } from "aws-amplify";

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(6),
    overFlowX : "auto",
    display: 'flex',
    marginBottom : theme.spacing(13),
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 15,
    paddingBottom: 15,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

/*
const uploadContents = [
  {
    'id' : 1,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '홍길동',
    'date' : '2019.09.02',
    'image' : 'http://placeimg.com/64/64/1',
    'content' : '홍길동 바보',
  },
  {
    'id' : 2,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '장발장',
    'date' : '2019.05.05',
    'image' : 'http://placeimg.com/64/64/2',
    'content' : '장발장 멍청이',
  },
  {
    'id' : 3,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '김철수',
    'date' : '2019.03.03',
    'image' : 'http://placeimg.com/64/64/3',
    'content' : '김철수 바보',
  },
  {
    'id' : 4,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '장희빈',
    'date' : '2019.01.02',
    'image' : 'http://placeimg.com/64/64/4',
    'content' : '장희빈 바보',
  },
  {
    'id' : 5,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '김희선',
    'date' : '2018.12.25',
    'image' : 'http://placeimg.com/64/64/5',
    'content' : '김희선 바보',
  }
]
*/

class Follow extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        userContents: '',
        getFollowees: '',
        getFollowers:'',
        followerUserId: '', 
        followingToggle: '',
      };
  }

  getUsers() {
    return API.get("upload", "/user")
    .then(userContents => this.setState({userContents : userContents}));
  }

  // 내가 Following한 사람들 리스트
  getFollowees() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/follow/?followerUserId=${currentAuthUserFromSession}`)
    .then(getFollowees => this.setState({getFollowees : getFollowees}));
  }

  /*
  // 나를 Following한 사람들 리스트
  getFollowers() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/follow/?followeeUserId=${currentAuthUserFromSession}`)
    .then(getFollowers => this.setState({getFollowers : getFollowers}));
  }
  */

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    await this.getUsers();
    await this.getFollowees();
    //await this.getFollowers();
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, searchKeyword, isFollowingLoading } = this.props;

    if(isFollowingLoading) {
      this.getFollowees();
    }

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredFolloweeCompanyComponents = (data) => {
      data = data.filter((c) => {
        return c.followeeUserId.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {
    
        let walletAddress
        let customerType
        let companyName
        let companyPhoneNumber
        let address
        let homepage
        let history
        let name
        let phoneNumber
        let email
        let avatarimage
        Object.keys(this.state.userContents).map(userId => {
          const userContent = this.state.userContents[userId];
          if(userContent.userId === c.followeeUserId) {
            walletAddress = userContent.walletAddress;
            customerType = userContent.customerType;
            companyName = userContent.companyName;
            companyPhoneNumber = userContent.companyPhoneNumber;
            address = userContent.address;
            homepage = userContent.homepage;
            history = userContent.history;
            name = userContent.name;
            phoneNumber = userContent.phoneNumber;
            email = userContent.email;
            avatarimage = userContent.avatarimage;
          }
        });

        if(customerType === 'company') {
          return(
            <Feed_Followee
              stateRefresh={this.stateRefresh}
              key={c.followingUUId}
              followerUserId={c.followerUserId}
              followeeUserId={c.followeeUserId}
              followingUUId={c.followingUUId}
              followingToggle={c.followingToggle}
              date={c.date}
              walletAddress = {walletAddress}
              customerType = {customerType}
              companyName = {companyName}
              companyPhoneNumber = {companyPhoneNumber}
              address = {address}
              homepage = {homepage}
              history = {history}
              name = {name}
              phoneNumber = {phoneNumber}
              email = {email}
              avatarimage = {avatarimage}
            />
          );
        }
      })
    }

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredFolloweeFreelancerComponents = (data) => {
      data = data.filter((c) => {
        return c.followeeUserId.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {
    
        let walletAddress
        let customerType
        let companyName
        let companyPhoneNumber
        let address
        let homepage
        let history
        let name
        let phoneNumber
        let email
        let avatarimage
        Object.keys(this.state.userContents).map(userId => {
          const userContent = this.state.userContents[userId];
          if(userContent.userId === c.followeeUserId) {
            walletAddress = userContent.walletAddress;
            customerType = userContent.customerType;
            companyName = userContent.companyName;
            companyPhoneNumber = userContent.companyPhoneNumber;
            address = userContent.address;
            homepage = userContent.homepage;
            history = userContent.history;
            name = userContent.name;
            phoneNumber = userContent.phoneNumber;
            email = userContent.email;
            avatarimage = userContent.avatarimage;
          }
        });

        if(customerType === 'freelancer') {
          return(
            <Feed_Followee
              stateRefresh={this.stateRefresh}
              key={c.followingUUId}
              followerUserId={c.followerUserId}
              followeeUserId={c.followeeUserId}
              followingUUId={c.followingUUId}
              followingToggle={c.followingToggle}
              date={c.date}
              walletAddress = {walletAddress}
              customerType = {customerType}
              companyName = {companyName}
              companyPhoneNumber = {companyPhoneNumber}
              address = {address}
              homepage = {homepage}
              history = {history}
              name = {name}
              phoneNumber = {phoneNumber}
              email = {email}
              avatarimage = {avatarimage}
            />
          );
        }
      })
    }

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
            <Card className={classes.card}>
              <List className={classes.list}>
                <ListItem className={classes.listItemTitle}>
                  <Typography className={classes.typoTitle}>관심 업체</Typography>
                </ListItem>
              </List>
              {/* 기업 리스트 나옴 */}
              {this.state.getFollowees
                ?
                filteredFolloweeCompanyComponents(this.state.getFollowees)
                :
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                </Grid>
              }
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
            <Card className={classes.card}>
              <List className={classes.list}>
                <ListItem className={classes.listItemTitle}>
                  <Typography className={classes.typoTitle}>관심 프리랜서</Typography>
                </ListItem>
              </List>
              {/* 기업 리스트 나옴 */}
              {this.state.getFollowees
                ?
                filteredFolloweeFreelancerComponents(this.state.getFollowees)
                :
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
                </Grid>
              }
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  searchKeyword: state.upload.searchKeyword,
  isFollowingLoading: state.upload.isFollowingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
  followingloadingtrue: (isFollowingLoading) => dispatch(uploadActions.followingloadingtrue(isFollowingLoading)),
  followingloadingfalse: (isFollowingLoading) => dispatch(uploadActions.followingloadingfalse(isFollowingLoading)),
})

Follow = withStyles(styles, {withTheme: true})(Follow)
export default connect(mapStateToProps, mapDispatchToProps)(Follow)