import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    fontSize: '15px',
    height: '50px',
    backgroundColor: '#EC7F7F',
    color: "#ffffff",
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: '#DB4437',
      color: 'white'
    }
  }
};

const GoogleLoginButton = ({ classes, title, onClick, disabled }) => {
  return (
    <Button className={classes.root} onClick={onClick} disabled={disabled}>
      {title}
    </Button>
  );
};

export default withStyles(styles)(GoogleLoginButton);
