import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { connect } from 'react-redux'

// '좋아요' 관련 Icon
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import { API } from "aws-amplify";

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

const styles = theme => ({
  root: {
    marginTop: 5,
    height: 100,
    width: "100%",
    padding: 20,
  },
  starBorderIconButton: {
    padding: 10,
    marginTop: 5,
  },
  starIconButton: {
    padding: 10,
    marginTop: 5,
  },
  starBorderIcon: {
    width: 30,
    height: 30,
  },
  starIcon: {
    width: 30,
    height: 30,
    color: '#FFD700'  // gold
  },
});

class Feed_ViewFollow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completed : 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  // 내가 다른 사람 Following 할때
  postFollowing(postFollowing) {
    return API.post("upload", "/follow", {
      body: postFollowing
    });
  }

  // 내가 Following 했던거를 취소 할때
  deleteFollowing() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    if(currentAuthUserFromSession === this.props.followerUserId) {
      return API.del("upload", `/follow/${this.props.followingUUId}`);
    }
  }

  putPoint(postPoint) {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.put("upload", `/point/${currentAuthUserFromSession}`, {
      body: postPoint
    });
  }

  handleFollowingButton = async event => {
    event.preventDefault();
    const { followingloadingtrue, followingloadingfalse } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(this.props.followingToggle === '1') {
      if(currentAuthUserFromSession === this.props.followerUserId) {
        followingloadingtrue();
        try {
          await this.deleteFollowing();
          followingloadingfalse();
        } catch (e) {
          alert(e);
          followingloadingfalse();
        }
      }
    } else {
      followingloadingtrue();
      try {
        await this.postFollowing({
          followeeUserId: this.props.userId,
          userUUId: this.props.userUUId,
          followingToggle: '1',
        });
        followingloadingfalse();
      } catch (e) {
        alert(e);
        followingloadingfalse();
      }
    }
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    return (
      <div className={classes.root}>
        {this.props.followingToggle === '1' && currentAuthUserFromSession === this.props.followerUserId
        ?
        <IconButton className={classes.starIconButton} aria-label="Add to following" onClick={this.handleFollowingButton}>
          <StarIcon className={classes.starIcon}/>
        </IconButton>
        :
        <IconButton className={classes.starBorderIconButton} aria-label="Add to following" onClick={this.handleFollowingButton}>
          <StarBorderIcon className={classes.starBorderIcon}/>
        </IconButton>
        }
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isFollowingLoading: state.upload.isFollowingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  followingloadingtrue: (isFollowingLoading) => dispatch(uploadActions.followingloadingtrue(isFollowingLoading)),
  followingloadingfalse: (isFollowingLoading) => dispatch(uploadActions.followingloadingfalse(isFollowingLoading)),
})

Feed_ViewFollow = withStyles(styles, {withTheme: true})(Feed_ViewFollow)
export default connect(mapStateToProps, mapDispatchToProps)(Feed_ViewFollow);