import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import AWSLoginForm from './AWSLoginForm'
import ChooseAccount from '../ChooseAccount'
import AWSResetPassword from './AWSResetPassword'
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import KakaoButton from "./KakaoButton";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//import './AWSAuthForm.scss'

import * as authActions from 'redux/actions/auth'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
  },
  Title: {
    fontSize : 20,
    paddingBottom: 10,
  },
  Content: {
    fontSize : 15
  },
  AuthForm__h1: {
    width: '80px',
    height: '80px',
    marginTop: '0px',
    marginBottom: '10px',
    '& img': {
      width: '100%',
    },
    '@media (max-width: 600px)': {
      marginBottom: '30px',
    },
  },
  AuthForm__message: {
    fontSize: '12px',
  },
  AuthForm__link: {
    fontSize: '15px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  }
});

class AWSAuthForm extends Component {
  state = {
    awsloginForm: true,
    awsResetPasswordForm: true,
  }

  toggleLoginForm = () => this.setState({
    awsloginForm: !this.state.awsloginForm,
  })

  toggleResetPasswordForm = () => this.setState({
    awsResetPasswordForm: !this.state.awsResetPasswordForm,
  })

  // Facebook 로그인 관련 메소드
  handleFbLogin = () => {
    const { userHasAuthenticatedTrue } = this.props
    userHasAuthenticatedTrue();
  };

  // Google 로그인 관련 메소드
  handleGlLogin = () => {
    const { userHasAuthenticatedTrue } = this.props
    userHasAuthenticatedTrue();
  };

  // 카카오톡 로그인 관련 메소드
  handleKKLogin = () => {
    const { userHasAuthenticatedTrue } = this.props
    userHasAuthenticatedTrue();
  };

  render() {
    const { awsloginForm, awsResetPasswordForm } = this.state
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <Grid item xs={11} sm={11} md={12} lg={9} xl={8}>
            {/*
            <h2 className="AuthForm__h2">
              일감 앱 </br>
            </h2>
            */}
            <h1 className={classes.AuthForm__h1}>
              {/*<img src="/static/images/logo/app.svg" alt="ilgam_Logo" />*/} {/* deploy시 */}
              <img src="/images/logo/app.svg" alt="otwogong_Logo" /> {/* 테스트 시 */}
            </h1>
            {/* <Link component={RouterLink} to='/login/reset' underline='none' color='inherit'> */}
            {/* </Link> */}
            {awsResetPasswordForm 
            ?
            <div>
            { awsloginForm 
            ?
            <div>
              <Typography className={classes.Title}>개인 고객</Typography>
              <GoogleButton
                onLogin={this.handleGlLogin}
              />
            </div>
            :
            null
            }
            {/*
            { awsloginForm 
            ?
            <FacebookButton
                onLogin={this.handleFbLogin}
              />
            :
            null
            }
            */}
            {/*
            { awsloginForm 
            ?
            <KakaoButton
              onLogin={this.handleKKLogin}
            />
            :
            null
            }
            */}
            <hr />
            <Typography className={classes.Title}>기업 고객</Typography>
            {awsloginForm ? <AWSLoginForm /> : <ChooseAccount />}
            <div className={classes.AuthForm__message}>
              {/*{awsloginForm ? 'Don\'t have an account? ' : 'Have an account? '}*/}
              <span className={classes.AuthForm__link} onClick={this.toggleLoginForm}>
                {awsloginForm 
                ? 
                  <Typography className={classes.Content}>회원 가입</Typography> 
                : 
                  <Typography className={classes.Content}>로그인</Typography> 
                }
              </span>
            </div>
            </div>          
            : <AWSResetPassword />}
            <div className={classes.AuthForm__message}>
              {/* {awsResetPasswordForm ? 'Forgot password? ' : 'Forgot password? '} */}
              <span className={classes.AuthForm__link} onClick={this.toggleResetPasswordForm}>
                {awsResetPasswordForm 
                ?
                  <Typography className={classes.Content}>비밀번호 잊어버리셨나요?</Typography> 
                : 
                  <Typography className={classes.Content}>로그인</Typography> 
                }
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //email: state.auth.email,
  //password: state.auth.password,
})

const mapDispatchToProps = (dispatch) => ({
  //awslogin: () => dispatch(authActions.awslogin()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

AWSAuthForm = withStyles(styles, {withTheme: true})(AWSAuthForm);
export default connect(mapStateToProps, mapDispatchToProps)(AWSAuthForm);
