import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CircularProgress from "@material-ui/core/CircularProgress";

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Feed_FieldForm from './Feed_FieldForm';
import UploadButton from './UploadButton'

import { API } from "aws-amplify";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

const styles = theme => ({
  /*
  root: {
      width : '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      //backgroundColor: theme.palette.background.paper,
      marginLeft: 10,
      marginRight: 10,
  },
  */
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    marginBottom : theme.spacing(13),
  },
  card: {
    width: '100%',
    height: 40,
    marginTop: 0,
    marginBottom: 0,
    marginLeft : theme.spacing(1),
    marginRight : theme.spacing(1),
  },
  followTextSize:{
    fontSize:15,
    align: 'center'
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class PeopleHome extends React.Component {
  _isMounted = false;

  followerUserId = '';
  followingToggle = '';

  constructor(props) {
      super(props);
      this.state = {
        uploadContents: '',
        uploadContent: '',
        userContents: '',
        userContent: '',
        favoriteThumbs: '',
        favoriteThumb: '',
        images: "",
        getNotifications: "",
        fcmTokenId: "",
        userId: "",
        eventToggle : '',
        followUploadToggle : '',
        followCommentToggle : '',
        getFollowees: '',
        //followerUserId: '',
        //followingToggle: '',
        completed : 0,
      };

      
  }

  getPublicUploads() {
    // Feed에서 페이지 넘어오면서 uploadUserId 정보 같이 넘어오게끔
    const uploadUserIdFromSession = sessionStorage.getItem("uploadUserId");
    return API.get("upload", `/upload?userId=${uploadUserIdFromSession}&selectTarget=public`)
    .then(publicUploadContents => this.setState({publicUploadContents : publicUploadContents}));
  }

  getProtectedUploads() {
    // Feed에서 페이지 넘어오면서 uploadUserId 정보 같이 넘어오게끔
    const uploadUserIdFromSession = sessionStorage.getItem("uploadUserId");
    return API.get("upload", `/upload?userId=${uploadUserIdFromSession}&selectTarget=protected`)
    .then(protectedUploadContents => this.setState({protectedUploadContents : protectedUploadContents}));
  }

  getUsers() {
    return API.get("upload", "/user")
    .then(userContents => this.setState({userContents : userContents}));
  }

  getFavorites() {
    return API.get("upload", "/favorite")
    .then(favoriteThumbs => this.setState({favoriteThumbs : favoriteThumbs}));
  }

  // 내가 Following한 사람 리스트
  getFollowees() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const uploadUserIdFromSession = sessionStorage.getItem("uploadUserId");
    return API.get("upload", `/follow/?followerUserId=${currentAuthUserFromSession}&followeeUserId=${uploadUserIdFromSession}`)
    .then(getFollowees => this.setState({getFollowees : getFollowees}));
  }

  // 내가 다른 사람 Following 할때
  postFollowing(postFollowing) {
    return API.post("upload", "/follow", {
      body: postFollowing
    });
  }

  // 내가 Following 했던거를 취소 할때
  deleteFollowing() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.del("upload", `/follow/${currentAuthUserFromSession}`);
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {
    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;

    // console.log('subscribe :' + sessionStorage.getItem("subscribe"));
    /*
      // 아래는 예시를 든것이다. 아래와 같이 this._isMounted를 사용해서 그 안에 setState를 담아주기.
      if (this._isMounted) {
        this.setState({
          news: result.data.hits,
        });
      }
    */
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: " + userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: " + userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
        // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // currentAuthUser(currentAuthUserFromSession);
        userHasAuthenticatedTrue();
      } else {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
        // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // currentAuthUser(currentAuthUserFromSession);
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          alert(e);
        }
    }

    await this.getUsers();
    await this.getPublicUploads(); // (이 주석 없으면 AWS lambda 서버 통해서 MongoDB의 data를 가지고 온다.)
    await this.getProtectedUploads();
    await this.getFavorites();
    await this.getFollowees();
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 사용자가 삭제 버튼을 눌렀을 때 실행하는 함수
  handleDelete = (id) => {
    this._delete(id);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    uploadContents : '',
    userContents : '',
    favoriteThumbs : '',
    completed : 0,
    });
    
    this.getUsers();
    // 초기화 이후 uploadContents 목록을 불러온다.
    this.getUploads();
    this.getFavorites();
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  handleFollowingButton = async event => {
    event.preventDefault();
    const { followingloadingtrue, followingloadingfalse } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const uploadUserIdFromSession = sessionStorage.getItem("uploadUserId");

    if(this.followingToggle === '1') {
      if(currentAuthUserFromSession == this.followerUserId) {
        followingloadingtrue();
        try {
          await this.deleteFollowing();
          followingloadingfalse();
        } catch (e) {
          alert(e);
          followingloadingfalse();
        }
      }
    } else if (this.followingToggle === '0') {
      if(currentAuthUserFromSession == this.followerUserId) {
        followingloadingtrue();
        try {
          await this.deleteFollowing();
          followingloadingfalse();
          window.location.reload();
        } catch (e) {
          alert(e);
          followingloadingfalse();
        }
      }
    } else if (!this.followingToggle) {
      followingloadingtrue();
      try {
        await this.postFollowing({
          followeeUserId: uploadUserIdFromSession,
          followingToggle: '0',
        });
        followingloadingfalse();
      } catch (e) {
        alert(e);
        followingloadingfalse();
      }
    }
  }

  render() {
    const { classes, isFavoriteLoading, isFollowingLoading, theme, searchKeyword } = this.props;
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(isFavoriteLoading) {
      this.getFavorites();
    }
    
    if(isFollowingLoading) {
      this.getFollowees();
    }

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredComponents = (data) => {
      data = data.filter((c) => {
        return c.content.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {

        let WalletAddress;
        let NickName;
        let Avatarimage;
        Object.keys(this.state.userContents).map(userId => {
          const userContent = this.state.userContents[userId];
          if(userContent.userId === c.userId) {
            WalletAddress = userContent.walletAddress;
            NickName = userContent.nickName;
            Avatarimage = userContent.avatarimage;
          }
        });

        let favoriteUserId;
        let favoriteUUId;
        let favoriteContent;
        let favoriteContentNumbers = 0;
        Object.keys(this.state.favoriteThumbs).map(uploadUUId => {
          const favoriteThumb = this.state.favoriteThumbs[uploadUUId];
          if(favoriteThumb.uploadUUId === c.uploadUUId) {
            if(favoriteThumb.userId === currentAuthUserFromSession) {
              favoriteUserId = favoriteThumb.userId;
              favoriteUUId = favoriteThumb.favoriteUUId;
              favoriteContent = favoriteThumb.favoriteContent;
            }
            favoriteContentNumbers = favoriteContentNumbers + 1;
          }
        });

        let followerUserId;
        let followeeUserId;
        let followingToggle;
        Object.keys(this.state.getFollowees).map(followingUUId => {
          const getFollowee = this.state.getFollowees[followingUUId];
          if(getFollowee.followerUserId === currentAuthUserFromSession) {
            if(getFollowee.followeeUserId === c.userId) {
              followerUserId=getFollowee.followerUserId;
              followeeUserId=getFollowee.followeeUserId;
              followingToggle=getFollowee.followingToggle;

              this.followerUserId = followerUserId;
              this.followingToggle = followingToggle;
            }
          }
        });
     
        if(c.selectTarget === 'public') {
          return(
            <Feed_FieldForm
              stateRefresh={this.stateRefresh}
              key={c.uploadUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
              id={c.id}
              userId={c.userId}
              uploadUUId={c.uploadUUId}
              walletAddress={WalletAddress}
              nickName={NickName}
              avatarimage={Avatarimage}
              date={c.date}
              images={c.images}  // AWS S3 사용시
              selectTarget={c.selectTarget}
              content={c.content}
              favoriteUserId={favoriteUserId}
              favoriteUUId={favoriteUUId}
              favoriteContent={favoriteContent}
              favoriteContentNumbers={favoriteContentNumbers}
              followerUserId={followerUserId}
              followeeUserId={followeeUserId}
              followingToggle={followingToggle}
            />
          );
        }
     
        if(c.selectTarget === 'protected') {
          if(followeeUserId && followingToggle==='1') {
            return(
              <Feed_FieldForm
                stateRefresh={this.stateRefresh}
                key={c.uploadUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
                id={c.id}
                userId={c.userId}
                uploadUUId={c.uploadUUId}
                walletAddress={WalletAddress}
                nickName={NickName}
                avatarimage={Avatarimage}
                date={c.date}
                images={c.images}  // AWS S3 사용시
                selectTarget={c.selectTarget}
                content={c.content}
                favoriteUserId={favoriteUserId}
                favoriteUUId={favoriteUUId}
                favoriteContent={favoriteContent}
                favoriteContentNumbers={favoriteContentNumbers}
                followerUserId={followerUserId}
                followeeUserId={followeeUserId}
                followingToggle={followingToggle}
              />
            );
          }
        }
      })
    }

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <Card className={classes.card}>
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <Grid item sm={6} md={6} lg={6} xl={6}>
              <Typography className={classes.followTextSize}>팔로우 신청 하시겠습니까?</Typography>
            </Grid>
            <Grid item sm={6} md={6} lg={6} xl={6}>
              {this.followingToggle === '1' && currentAuthUserFromSession === this.followerUserId
              ?
              <IconButton aria-label="Following" onClick={this.handleFollowingButton} color="primary">
                <PersonAddIcon />
                <Typography>팔로우 중</Typography>
              </IconButton>
              :
              this.followingToggle === '0'
              ?
              <IconButton aria-label="Following" onClick={this.handleFollowingButton}>
                <PersonAddIcon />
                <Typography>팔로우 신청 중</Typography>
              </IconButton>
              :
              <IconButton aria-label="Following" onClick={this.handleFollowingButton}>
                <PersonAddIcon />
                <Typography>팔로우 신청 하기</Typography>
              </IconButton>
              }
            </Grid>
            </Grid>
          </Card>
          {/* filter(검색)기능 */}
          {this.state.protectedUploadContents && this.state.userContents
            ?
            filteredComponents(this.state.protectedUploadContents)
            :
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
            </Grid>
          }
          {this.state.publicUploadContents && this.state.userContents
            ?
            filteredComponents(this.state.publicUploadContents)
            :
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
            </Grid>
          }
        </Grid>
        <UploadButton />
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isFavoriteLoading: state.upload.isFavoriteLoading,
  uploadContents: state.upload.uploadContents,
  // currentUser: state.auth.currentUser,
  searchKeyword: state.upload.searchKeyword,
  isFollowingLoading: state.upload.isFollowingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  // currentAuthUser: (currentUser) => dispatch(authActions.currentAuthUser(currentUser)),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  favoriteloadingtrue: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingtrue(isFavoriteLoading)),
  favoriteloadingfalse: (isFavoriteLoading) => dispatch(uploadActions.favoriteloadingfalse(isFavoriteLoading)),
  followingloadingtrue: (isFollowingLoading) => dispatch(uploadActions.followingloadingtrue(isFollowingLoading)),
  followingloadingfalse: (isFollowingLoading) => dispatch(uploadActions.followingloadingfalse(isFollowingLoading)),
  uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
})

PeopleHome = withStyles(styles, {withTheme: true})(PeopleHome)
export default connect(mapStateToProps, mapDispatchToProps)(PeopleHome)