import React, { Component } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFavoriteList from './UploadFavoriteList'

import { connect } from 'react-redux'

// '좋아요' 관련 Icon
import Badge from '@material-ui/core/Badge';
import FavoriteIcon from '@material-ui/icons/Favorite';

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

// S3 관련
import { API, Storage } from "aws-amplify";

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

import SVGIcon from './SVGIcon'

// import './UploadFeed.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(7),
  },
  topAppBar: {
    // zIndex: theme.zIndex.drawer + 1,
    /*
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    */
  },
  
  // toolbar: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
    fontSize : 16,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  content: {
    marginTop: '50px',
    flexGrow: 1,
    //padding: theme.spacing(0),
  },
  iconButton: {
    margin: theme.spacing(0),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  card: {
    maxWidth: 450,
    marginTop: 15,
    marginBottom: 15,
  },
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class UploadFavorListPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      favoriteThumbsLists: '',
      favoriteThumbsList: '',
      userContentSabuIds: '',
      userContentSabuId: '',
      favorAvatarimage: '',
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    await this.getFavoritesList();
    await this.getUsers();

    /*
    const getFavoriteThumbsLists = sessionStorage.getItem("favoriteThumbsLists");
    const getUserContentSabuIds = sessionStorage.getItem("userContentSabuIds");

    this.setState ({
      favoriteThumbsLists: getFavoriteThumbsLists,
      userContentSabuIds: getUserContentSabuIds,
    });
    */
    /*
    console.log("favoriteThumbsLists :" + favoriteThumbsLists);
    console.log("this.state.favoriteThumbsLists :" + this.state.favoriteThumbsLists);
    console.log("userContentSabuIds :" + userContentSabuIds);
    console.log("this.state.userContentSabuIds :" + this.state.userContentSabuIds);
    */
  }

  // FavoriteList 관련 (event.queryStringParameters 사용 시. 특정 favoriteUUId 불러올 때)
  getFavoritesList() {
    return API.get("upload", `/favorite/?sabuId=${this.props.match.params.id}`)
    .then(favoriteThumbsLists => this.setState({favoriteThumbsLists : favoriteThumbsLists}));
  }

  getUsers() {
    return API.get("upload", `/user/?sabuId=${this.props.match.params.id}`)
    .then(userContentSabuIds => this.setState({userContentSabuIds : userContentSabuIds}))
  }

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme } = this.props;
    // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" className={classes.topAppBar}>
            <Toolbar>
              <Grid container spacing={10} direction="row" justify="flex-start" alignItems="center">
                <Grid item sm={4} md={4} lg={4} xl={4}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" />
                  </IconButton>
                  {/*<Button
                    className="NotUploadButton"
                    label="<"
                    onClick={this.handleNotUpload}
                  />*/}
                </Grid>
                <Grid item sm={8} md={8} lg={8} xl={8}>
                  <Typography className={classes.title} variant="h6" noWrap>
                    좋아요 페이지
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {/* AWS S3 사용시 바로 아래 attachmentURL관련 state 주석 풀기 */}
          { this.state.favoriteThumbsLists.length > 0 && this.state.userContentSabuIds
          ?
          <main className={classes.content}>
            {/* <div className={classes.toolbar} /> */}
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                { Object.keys(this.state.favoriteThumbsLists).map(favoriteUUId => {
                    const favoriteThumbsList = this.state.favoriteThumbsLists[favoriteUUId];

                    // let favorWalletAddress;
                    let favorNickName;
                    let favorAvatarimage;
                    // let favorContentNumbers = 0;
                    Object.keys(this.state.userContentSabuIds).map(userId => {
                      const userContentSabuId = this.state.userContentSabuIds[userId];
                      if(userContentSabuId.userId === favoriteThumbsList.userId) {
                        // favorWalletAddress = userContentSabuId.walletAddress;
                        favorNickName = userContentSabuId.nickName;
                        favorAvatarimage = userContentSabuId.avatarimage;
                      }
                    });

                    return(
                      <UploadFavoriteList
                        stateRefresh={this.stateRefresh}
                        key={favoriteThumbsList.favoriteUUId}
                        id={favoriteThumbsList.favoriteId}
                        favoriteListUserId={favoriteThumbsList.userId}
                        favoriteListSabuId={favoriteThumbsList.sabuId}
                        favorNickName={favorNickName}
                        favorAvatarimage={favorAvatarimage}
                        favoriteListSabuId={favoriteThumbsList.sabuId}
                        favoriteListUUId={favoriteThumbsList.favoriteUUId}
                        favoriteListDate={favoriteThumbsList.favoriteDate}
                        favoriteListContent={favoriteThumbsList.favoriteContent}
                    />
                    );
                  })
                }                
              </Grid>
            </Grid>
          </main>
          :
          <Grid container spacing={0} direction="row" justify="center" alignItems="center">
            <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
          </Grid>
          }
        </div>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  
})

UploadFavorListPage = withStyles(styles, {withTheme: true})(UploadFavorListPage)
export default connect(mapStateToProps, mapDispatchToProps)(UploadFavorListPage);