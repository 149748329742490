import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isValidPrivateKey } from 'utils/crypto'
import { withStyles } from '@material-ui/core/styles';
import KakaoLoginButton from './KakaoLoginButton'
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { API, Auth } from "aws-amplify";
import Amplify from "aws-amplify";

import * as authActions from 'redux/actions/auth'

import KaKaoLogin from 'react-kakao-login';

// AWS config
import config from "../../config";

const styles = theme => ({
  progress: {
    //margin: theme.spacing(2),
    position: 'absolute', 
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

let kakao_email = '';
let kakao_access_token = '';
let kakao_expires_in = '';
let kakao_id_token = '';

class KakaoButton extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      warningMessage: '',
      isLoading: true,
      completed : 0,
    }
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  componentDidMount = async event => {
    this._isMounted = true;

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    const KakaoAppId = config.social.KakaoAppId;

    await Kakao.init(KakaoAppId); // replace you application ID
    this.setState({ isLoading: false });
  }

  isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  handleKakaoLoginSuccess = async (response) => {
    const { userHasAuthenticatedTrue } = this.props
    try {
      // android가 아니고 ios가 아니면 web 로그인 진행
      if (!this.isAndroid() && !this.isIOS()) {
        const { response: { access_token, id_token, expires_in }, profile: { kakao_account: { email, profile: { nickname } } } } = response;
        console.log('access_token:', access_token);
        console.log('id_token:', id_token);
        console.log('expires_in:', expires_in);
        console.log('email:', email);
        console.log('nickname:', nickname);

        let user = {
          email: email,
          name: nickname
        };
      
        const currentTime = Date.now();
        const expiresAt = currentTime + expires_in * 1000;
        console.log('expires_at:', expiresAt);
      
        try {
          const credentials = await Auth.federatedSignIn(
            { customProvider: 'kakao', token: id_token, expires_at: expiresAt },
            user
          );
        } catch(e) {
          console.log(e)
        }
        
        localStorage.setItem("email", email);
    
        this.setState({ isLoading: false });
    
        try {
          const userID = await Auth.currentAuthenticatedUser();
          // console.log("userID: ");
          // console.log(userID);
          const userID2 = await Auth.currentUserInfo(userID);
          // console.log("userID2: ");
          // console.log(userID2);
          if(userID2 === null) {
            const userID3 = JSON.stringify(userID);
            // console.log("userID3: " + userID3);
            const userID4 = userID3.substr(userID3.indexOf('"id":"'));
            // console.log("userID4: " + userID4);
            const userID5 = userID4.substr(6);
            // console.log("userID5: " + userID5);
            const userID6 = userID5.substr(0, userID5.indexOf('"'));
    
            // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
            sessionStorage.setItem("currentUser", userID6);
    
            userHasAuthenticatedTrue();
          } else if (userID2 != null) {
            const userID3 = JSON.stringify(userID2);
            // console.log("userID3: " + userID3);
            const userID4 = userID3.substr(userID3.indexOf('"id":"'));
            // console.log("userID4: " + userID4);
            const userID5 = userID4.substr(6);
            // console.log("userID5: " + userID5);
            const userID6 = userID5.substr(0, userID5.indexOf('"'));
    
            // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
            sessionStorage.setItem("currentUser", userID6);
    
            const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    
            console.log("kakaotalk-currentAuthUserFromSession :")
            console.log(currentAuthUserFromSession)
            userHasAuthenticatedTrue();
          }
        } catch (e) {
            if (e !== "not authenticated") {
              // alert(e);
              console.log(e)
            }
        }
    
        try {
          const user = await this.getUser();
      
          const { userId, customerType1, customerType2 } = user;
    
          localStorage.setItem("customerType1", customerType1);
          localStorage.setItem("customerType2", customerType2);
          
           //const customerType1_FromLocal = localStorage.getItem("customerType1");
           //const customerType2_FromLocal = localStorage.getItem("customerType2");
           //console.log("Login customerType1_FromLocal :")
           //console.log(customerType1_FromLocal)
           //console.log("Login customerType2_FromLocal :")
           //console.log(customerType2_FromLocal)
           // console.log("카카오톡 로그인 완료")
         } catch(e) {
           console.log(e)
           console.log("카카오 로그인시 유저 정보 못받아옴")
         }
        
        //sessionStorage.setItem("FCMLoginToggle", "true");
        localStorage.setItem("loginType", "kakaotalk");
    
        this.props.onLogin(credentials);  // 이 코드를 넣어 줘야 로그인 되면서 페이지 넘어간다.
    
        //this.props.history.push("/"); // 로그인 후 home 페이지로 이동
        
      }

      // android 또는 ios 이면 로그인 진행
      if (this.isAndroid() || this.isIOS()) {
        //alert('Mobile login');
        try{
          localStorage.setItem("mobileKakaoLoginStart", 'True');

          window.ReactNativeWebView.postMessage(JSON.stringify({ type:'KK' }))
        } catch(e) {
          ;
        }
      }
    } catch(e) {
      ;
    }
    //sessionStorage.setItem("FCMLoginToggle", "true");
  };

  handleKakaoLoginFailure = (error) => {
    console.error('Kakao login failed:', error);
  };

  handleKakaoLogout = () => {
    console.info('Kakao logout');
  };

  handleClick = async () => {
    try {
      // android가 아니고 ios가 아니면 web 로그인 진행
      if (!this.isAndroid() && !this.isIOS()) {
        this.loginWithKakao();
        //console.log("kakao click")
      }

      // android 또는 ios 이면 로그인 진행
      if (this.isAndroid() || this.isIOS()) {
        //alert('Mobile login');
        try{
          localStorage.setItem("mobileKakaoLoginStart", 'True');

          window.ReactNativeWebView.postMessage(JSON.stringify({ type:'KK' }))
        } catch(e) {
          ;
        }
      }
    } catch(e) {
      ;
    }
    //sessionStorage.setItem("FCMLoginToggle", "true");
  };

  /*
  loginToKakao = async () => {
    return new Promise((resolve, reject) => {
      Kakao.Auth.login({
        success: function(authObj) {
          kakao_access_token = authObj.access_token;
          kakao_expires_in = authObj.expires_in;

          //console.log("kakao_access_token_1 :")
          //console.log(kakao_access_token)
          //console.log("kakao_expires_in_1 :")
          //console.log(kakao_expires_in)
  
          Kakao.API.request({
            url: '/v2/user/me',
            success: function(res) {
              kakao_email = res.kakao_account.email;
              console.log("res :")
              console.log(res)
              //console.log("kaccount_email_1 :")
              //console.log(res.kakao_account.email)
              //console.log("id :")
              //console.log(res.id)
              resolve();
            },
            fail: function(err) {
              reject(err);
            }
          });
        },
        fail: function(err) {
          reject(err);
        }
      });
    });
  }

  loginWithKakao = async () => {
    const { userHasAuthenticatedTrue } = this.props

    const kakaoUserId = await this.loginToKakao();

    const idTokenResponse = await fetch('https://kauth.kakao.com/oauth/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      //body: `grant_type=authorization_code&client_id=YOUR_CLIENT_ID&redirect_uri=YOUR_REDIRECT_URI&code=${kakaoUserId}`,
      body: `grant_type=authorization_code&client_id=ce61547a1a74b09050781ea62903c3a5&redirect_uri=https://otwogong.com&code=${kakaoUserId}`,
    });

    const idTokenData = await idTokenResponse.json();
    kakao_id_token = idTokenData.id_token;

    console.log('kakao_email:', kakao_email);
    console.log('kakao_access_token:', kakao_access_token);
    console.log('kakao_id_token:', kakao_id_token);

    let user = {
      email: kakao_email,
      //name: profile.getName()
    };
  
    const currentTime = Date.now();
    const expiresAt = currentTime + kakao_expires_in * 1000;
    console.log('expires_at:', expiresAt);
  
    const credentials = await Auth.federatedSignIn(
      'kakaotalk',
      { token: kakao_id_token, expires_at: expiresAt },
      user
    );

    this.setState({ isLoading: false });

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: ");
      // console.log(userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: ");
      // console.log(userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

        //console.log("google-currentAuthUserFromSession :")
        //console.log(currentAuthUserFromSession)
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          // alert(e);
          console.log(e)
        }
    }

    try {
      const user = await this.getUser();
  
      const { userId, customerType1, customerType2 } = user;

      localStorage.setItem("customerType1", customerType1);
      localStorage.setItem("customerType2", customerType2);
      
       //const customerType1_FromLocal = localStorage.getItem("customerType1");
       //const customerType2_FromLocal = localStorage.getItem("customerType2");
       //console.log("Login customerType1_FromLocal :")
       //console.log(customerType1_FromLocal)
       //console.log("Login customerType2_FromLocal :")
       //console.log(customerType2_FromLocal)
       // console.log("카카오톡 로그인 완료")
     } catch(e) {
       console.log(e)
       console.log("카카오 로그인시 유저 정보 못받아옴")
     }
    
    
    //sessionStorage.setItem("FCMLoginToggle", "true");
    localStorage.setItem("loginType", "kakaotalk");

    this.props.onLogin(credentials);  // 이 코드를 넣어 줘야 로그인 되면서 페이지 넘어간다.

    //this.props.history.push("/"); // 로그인 후 home 페이지로 이동

    /
    try {
      this.loginToKakao()
        .then(() => {
          console.log('kakao_email:', kakao_email);
          console.log('kakao_access_token:', kakao_access_token);

          let user = {
            email: kakao_email,
            //name: profile.getName()
          };
        
          const currentTime = Date.now();
          const expiresAt = currentTime + kakao_expires_in * 1000;
          console.log('expires_at:', expiresAt);
        
          const credentials = await Auth.federatedSignIn(
            'kakaotalk',
            { token: kakao_access_token, expires_at: expiresAt },
            user
          );
      
          this.setState({ isLoading: false });
      
          try {
            const userID = await Auth.currentAuthenticatedUser();
            // console.log("userID: ");
            // console.log(userID);
            const userID2 = await Auth.currentUserInfo(userID);
            // console.log("userID2: ");
            // console.log(userID2);
            if(userID2 === null) {
              const userID3 = JSON.stringify(userID);
              // console.log("userID3: " + userID3);
              const userID4 = userID3.substr(userID3.indexOf('"id":"'));
              // console.log("userID4: " + userID4);
              const userID5 = userID4.substr(6);
              // console.log("userID5: " + userID5);
              const userID6 = userID5.substr(0, userID5.indexOf('"'));
      
              // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
              sessionStorage.setItem("currentUser", userID6);
      
              userHasAuthenticatedTrue();
            } else if (userID2 != null) {
              const userID3 = JSON.stringify(userID2);
              // console.log("userID3: " + userID3);
              const userID4 = userID3.substr(userID3.indexOf('"id":"'));
              // console.log("userID4: " + userID4);
              const userID5 = userID4.substr(6);
              // console.log("userID5: " + userID5);
              const userID6 = userID5.substr(0, userID5.indexOf('"'));
      
              // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
              sessionStorage.setItem("currentUser", userID6);
      
              //const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      
              //console.log("google-currentAuthUserFromSession :")
              //console.log(currentAuthUserFromSession)
              userHasAuthenticatedTrue();
            }
          } catch (e) {
              if (e !== "not authenticated") {
                // alert(e);
                console.log(e)
              }
          }
      
          try {
            const user = await this.getUser();
        
            const { userId, customerType1, customerType2 } = user;
      
            localStorage.setItem("customerType1", customerType1);
            localStorage.setItem("customerType2", customerType2);
            
             //const customerType1_FromLocal = localStorage.getItem("customerType1");
             //const customerType2_FromLocal = localStorage.getItem("customerType2");
             //console.log("Login customerType1_FromLocal :")
             //console.log(customerType1_FromLocal)
             //console.log("Login customerType2_FromLocal :")
             //console.log(customerType2_FromLocal)
             // console.log("카카오톡 로그인 완료")
           } catch(e) {
             console.log(e)
             console.log("카카오 로그인시 유저 정보 못받아옴")
           }
          
          
          //sessionStorage.setItem("FCMLoginToggle", "true");
          localStorage.setItem("loginType", "kakaotalk");
      
          this.props.onLogin(credentials);  // 이 코드를 넣어 줘야 로그인 되면서 페이지 넘어간다.
      
          //this.props.history.push("/"); // 로그인 후 home 페이지로 이동
        })
        .catch(err => {
          ;
        });
    } catch (err) {
      ;
    }
    /
  };
  */

  render() {
    const { classes } = this.props
    const { warningMessage } = this.state
    return (
      /*<div className="LoginForm">*/
      <div>
        {this.state.isLoading 
        ?
        <Grid container spacing={0} direction="row" justify="center" alignItems="center">
          <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
        </Grid>
        :
        <KaKaoLogin
            token={'ce61547a1a74b09050781ea62903c3a5'}  //{'JAVASCRIPT KEY를 입력하세요.!!!'}
            onSuccess={this.handleKakaoLoginSuccess}
            //onSuccess={console.log}
            onFail={this.handleKakaoLoginFailure}
            onLogout={this.handleKakaoLogout}
        />
        /*
        <KakaoLoginButton
          title="카카오톡 로그인"
          disabled={this.state.isLoading}
          onClick={this.handleClick}
        />
        */
        }
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //email: state.auth.email,
  //password: state.auth.password,
})

const mapDispatchToProps = (dispatch) => ({
  //awslogin: () => dispatch(authActions.awslogin()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

KakaoButton = withStyles(styles, {withTheme: true})(KakaoButton);
export default connect(mapStateToProps, mapDispatchToProps)(KakaoButton);