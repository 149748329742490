import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth, API } from "aws-amplify";
//import config from "../config";
import Amplify from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

//import './UploadButton.scss' // UploadPhoto 버튼 관련
import { DialogContent } from '@material-ui/core';

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(7),
    marginBottom : theme.spacing(12),
  },
  mainCard1: {
    borderRadius: 0,
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 3000,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
    backgroundColor: '#FCB04C',
  },
  /*
  mainMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    //width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  // img 사용시
  mainMedia: {
    //height: 0,
    //paddingTop: '56.25%', // 16:9,
    width : '100%',
    height : 1000,
    objectFit: 'fill'
  },
  */
  // cardmedia 사용시
  mainMedia1: {
    height: 0,
    paddingTop: '150%',
    // width : '100%',
    //height : 400,
    objectFit: 'fill',
  },
  invoiceButton: {
    position: 'fixed', 
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    cursor: 'pointer',
    right: 15,
    bottom: 10,
    marginBottom: 10,
    [theme.breakpoints.up('xs')]: {
      width: 100,
      height: 100,
      borderRadius: 50,
    },
    [theme.breakpoints.up('sm')]: {
      width: 100,
      height: 100,
      borderRadius: 50,
    },
    [theme.breakpoints.up('md')]: {
      width: 150,
      height: 150,
      borderRadius: 150,
    },
    [theme.breakpoints.up('lg')]: {
      width: 200,
      height: 200,
      borderRadius: 200,
    },
    [theme.breakpoints.up('xl')]: {
      width: 200,
      height: 200,
      borderRadius: 200,
    },
    backgroundColor: '#F8F8FF',
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#C0C0C0',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#C0C0C0',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  invoiceLogoMedia : {
    position: 'fixed', 
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    cursor: 'pointer',
    [theme.breakpoints.up('xs')]: {
      width: 50,
      height: 50,
      right: 20,
      bottom: 25,
      marginBottom: 20,
    },
    [theme.breakpoints.up('sm')]: {
      width: 50,
      height: 50,
      right: 20,
      bottom: 25,
      marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      width: 75,
      height: 75,
      right: 30,
      bottom: 25,
      marginBottom: 20,
    },
    [theme.breakpoints.up('lg')]: {
      width: 90,
      height: 90,
      right: 50,
      bottom: 35,
      marginBottom: 30,
    },
    [theme.breakpoints.up('xl')]: {
      width: 90,
      height: 90,
      right: 50,
      bottom: 35,
      marginBottom: 30,
    },
  },
  invoiceTypo : {
    fontSize : 25,
    color: 'black',
    [theme.breakpoints.up('xs')]: {
      fontSize : 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize : 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize : 23,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize : 30,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize : 30,
    },
  },
  kaissLogoMedia: {
    marginTop: 30,
    marginBottom: 20,
    width: 200,
    height: 60,
  },
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

class Home_Manual extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        searchKeyword: '',
        completed : 0,
        alarmDialogOpen_1: false,
      };
  }

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {

    const { isAuthenticated } = this.props

    if(isAuthenticated === true) {
      // console.log("isAuthenticated === true")
    } else {
    // Cognito 로그인 없이 S3 읽어 온다.
    Amplify.configure({
      Auth: {
        mandatorySignIn: false, // true이면 로그인 시에만 S3 읽어 올수 있다.
      }
    });
   }

    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;
    localStorage.setItem("currentPage", "home");

    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: ");
      // console.log(userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: ");
      // console.log(userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);

        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          // alert(e);
          console.log(e)
        }
    }
  }

  drawerOpen = () => {
    const {drawertoggletrue} = this.props;
    drawertoggletrue();
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 사용자가 삭제 버튼을 눌렀을 때 실행하는 함수
  handleDelete = (id) => {
    this._delete(id);
  }

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    completed : 0,
    });
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      alarmDialogOpen_1: false,
    })
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme, } = this.props;
    const { searchKeyword } = this.state;

    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/* 세로로 정렬시 사용 */}
              <Card className={classes.mainCard1}>
                {/*<Typography className={classes.imageText}>려려 안녕</Typography>*/}
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                    <CardMedia
                      className={classes.mainMedia1}
                      image={'/images/main/home_manual.jpg'}  // 테스트시 사용
                    />
                    {/*
                    <img
                      className={classes.mainMedia}
                      src={'/images/main/Wall_1.png'}  // 테스트시 사용
                      // src={'/images/main/bg_home.jpg'}  // 테스트시 사용
                      // src={'/static/images/main/bg_home.jpg'}  // deploy시 사용
                      // src={this.state.attachmentURL_1}
                    />
                    */}
                  </Grid>
                </Grid>
              </Card>
            </Grid>             
          </Grid>
        </Grid>
        {/*
        <Button onClick={this.drawerOpen} className={classes.invoiceButton}>
          <Typography className={classes.invoiceTypo}>
            무료견적요청
          </Typography>
        </Button>
        <CardMedia
          className={classes.invoiceLogoMedia}
          // image="/static/images/logo/app.svg"
          image="/images/logo/app.svg"
          aria-label="Recipe"
          onClick={this.drawerOpen}
        />
        */}
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isdrawerToggle: state.upload.isdrawerToggle,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  drawertoggletrue: () => dispatch(uploadActions.drawertoggletrue()),
  drawertogglefalse: () => dispatch(uploadActions.drawertogglefalse()),
})

Home_Manual = withStyles(styles, {withTheme: true})(Home_Manual)
export default connect(mapStateToProps, mapDispatchToProps)(Home_Manual)