import { Storage } from "aws-amplify";

/*
public(공개) : 앱의 모든 사용자가 액세스 할 수 있습니다. 파일은 public/S3 버킷 의 경로 아래에 저장됩니다 .
protected(보호됨) : 모든 사용자가 읽을 수 있지만 작성하는 사용자 만 쓸 수 있습니다. 파일은 해당 사용자의 고유 한 
         Amazon Cognito 자격 증명 ID에 해당하는 protected/{user_identity_id}/위치 에 저장됩니다 user_identity_id.
private(개인) : 개별 사용자 만 액세스 할 수 있습니다. 파일은 해당 사용자의 고유 한 Amazon Cognito 자격 증명 ID에 해당하는 
       private/{user_identity_id}/위치 에 저장됩니다 user_identity_id.
*/

// 업로드 메소드 (Private)
export async function s3UploadPrivate(file) {
  
  // 현재 타임 스탬프 ( Date.now())를 사용하여 고유 한 파일 이름을 생성합니다.
  // 물론 앱을 많이 사용하는 경우 고유 한 파일 이름을 만드는 가장 좋은 방법은 아닙니다. 
  // 그러나 지금은 괜찮을 것입니다.
  const filename = `${Date.now()}-${file.name}`;

  // Storage.vault.put()개체를 사용하여 S3의 사용자 폴더에 파일을 업로드.
  // 또는 공개적으로 업로드하는 경우 Storage.put()방법을 사용할 수 있다.
  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type
  });

  /*
  const stored = await Storage.put(filename, file, {
    level: 'private',
    contentType: file.type
  });
  */

  return stored.key;
}

// 업로드 메소드 (Protected)
export async function s3UploadProtected(file) {
  
  // 현재 타임 스탬프 ( Date.now())를 사용하여 고유 한 파일 이름을 생성합니다.
  // 물론 앱을 많이 사용하는 경우 고유 한 파일 이름을 만드는 가장 좋은 방법은 아닙니다. 
  // 그러나 지금은 괜찮을 것입니다.
  const filename = `${Date.now()}-${file.name}`;

  // Storage.vault.put()개체를 사용하여 S3의 사용자 폴더에 파일을 업로드.
  // 또는 공개적으로 업로드하는 경우 Storage.put()방법을 사용할 수 있다.
  const stored = await Storage.put(filename, file, {
    level: 'protected',
    contentType: file.type
  });

  return stored.key;
}

// 업로드 메소드 (Public)
export async function s3UploadPublic(file) {
  
  // 현재 타임 스탬프 ( Date.now())를 사용하여 고유 한 파일 이름을 생성합니다.
  // 물론 앱을 많이 사용하는 경우 고유 한 파일 이름을 만드는 가장 좋은 방법은 아닙니다. 
  // 그러나 지금은 괜찮을 것입니다.
  const filename = `${Date.now()}-${file.name}`;

  // Storage.vault.put()개체를 사용하여 S3의 사용자 폴더에 파일을 업로드.
  // 또는 공개적으로 업로드하는 경우 Storage.put()방법을 사용할 수 있다.
  const stored = await Storage.put(filename, file, {
    level: 'public',
    contentType: file.type
  });

  return stored.key;
}

//--------------------------------------------------------------------------------------------------

// 삭제 메소드 (Private)
export async function s3DeletePrivate(file) {
  
  // 현재 타임 스탬프 ( Date.now())를 사용하여 고유 한 파일 이름을 생성합니다.
  // 물론 앱을 많이 사용하는 경우 고유 한 파일 이름을 만드는 가장 좋은 방법은 아닙니다. 
  // 그러나 지금은 괜찮을 것입니다.
  //const filename = `${Date.now()}-${file.name}`;

  // Storage.vault.put()개체를 사용하여 S3의 사용자 폴더에 파일을 업로드.
  // 또는 공개적으로 업로드하는 경우 Storage.put()방법을 사용할 수 있다.
  const stored = await Storage.remove(file, {
    level: 'private',
    contentType: file.type
  });

  return stored.key;
}

// 삭제 메소드 (Protected)
export async function s3DeleteProtected(file) {
  
  // 현재 타임 스탬프 ( Date.now())를 사용하여 고유 한 파일 이름을 생성합니다.
  // 물론 앱을 많이 사용하는 경우 고유 한 파일 이름을 만드는 가장 좋은 방법은 아닙니다. 
  // 그러나 지금은 괜찮을 것입니다.
  //const filename = `${Date.now()}-${file.name}`;

  // Storage.vault.put()개체를 사용하여 S3의 사용자 폴더에 파일을 업로드.
  // 또는 공개적으로 업로드하는 경우 Storage.put()방법을 사용할 수 있다.
  const stored = await Storage.remove(file, {
    level: 'protected',
    contentType: file.type
  });

  return stored.key;
}

// 삭제 메소드 (Public)
export async function s3DeletePublic(file) {
  
  // 현재 타임 스탬프 ( Date.now())를 사용하여 고유 한 파일 이름을 생성합니다.
  // 물론 앱을 많이 사용하는 경우 고유 한 파일 이름을 만드는 가장 좋은 방법은 아닙니다. 
  // 그러나 지금은 괜찮을 것입니다.
  //const filename = `${Date.now()}-${file.name}`;

  // Storage.vault.put()개체를 사용하여 S3의 사용자 폴더에 파일을 업로드.
  // 또는 공개적으로 업로드하는 경우 Storage.put()방법을 사용할 수 있다.
  const stored = await Storage.remove(file, {
    level: 'public',
    contentType: file.type
  });

  return stored.key;
}