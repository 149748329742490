import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Feed_Customer_Admin from './Feed_Customer_Admin';
//import UploadButton from './UploadButton'

import { API } from "aws-amplify";

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";
//import config from "../config";
import Amplify from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

// import './UploadButton.scss' // UploadPhoto 버튼 관련

const styles = theme => ({
  /*
  root: {
      width : '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      //backgroundColor: theme.palette.background.paper,
      marginLeft: 10,
      marginRight: 10,
  },
  */
  root: {
    width : '100%',
    //minWidth: 1080,
    overFlowX : "auto",
    display: 'flex',
    marginTop : theme.spacing(8),
    marginBottom : theme.spacing(13),
  },
  mainCard: {
    width : '100%',
    // maxWidth: 650,
    // height: 450,
    maxHeight: 400,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  imageText: {
    fontSize: 100,
    color: '#FFFAFA', // snow
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      top: '140px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '200px',
    },
    left: 0,
    width: '100%',
  },
  mainMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    //width : '100%',
    //height : 400,
    objectFit: 'fill'
  },
  cardFrame: {
    width : '100%',
    // maxWidth: 650,
    // height: 30,
    boxShadow: "none",
    background: "#F8F8F8",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  cardTitle: {
    width : '100%',
    // maxWidth: 650,
    height: 30,
    boxShadow: "none",
    background: "#F8F8F8",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  typoTitle1: {
    fontSize : 20,
  },
  typoTitle2: {
    fontSize : 15,
  },
  listIntroduce: {
    width: '100%',
    marginTop: 5,
    padding: 5,
  },
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    // height: 60,
  },
  listItemIntroduceTitle: {
    paddingTop: 5,
    paddingBottom: 5
  },
  listItemIntroduceContent: {
    paddingTop: 2,
    paddingBottom: 0
  },
  typoIntroduceTitle: {
    fontSize : 17,
  },
  typoIntroduceContent: {
    fontSize : 16,
  },
  typoIntroduceConetent2: {
    fontSize : 16,
    // color: 'red'
  },
  typoIntroduceContent3: {
    fontSize : 13,
  },
  /*
  iconAvatar: {
    backgroundColor: '#0000FF',
    margin: 10,
    width: 100,
    height: 100,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft : theme.spacing(0),
    marginRight : theme.spacing(0),
  },
  */
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '65%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
});

/*
const uploadContents = [
  {
    'id' : 1,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '홍길동',
    'date' : '2019.09.02',
    'image' : 'http://placeimg.com/64/64/1',
    'content' : '홍길동 바보',
  },
  {
    'id' : 2,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '장발장',
    'date' : '2019.05.05',
    'image' : 'http://placeimg.com/64/64/2',
    'content' : '장발장 멍청이',
  },
  {
    'id' : 3,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '김철수',
    'date' : '2019.03.03',
    'image' : 'http://placeimg.com/64/64/3',
    'content' : '김철수 바보',
  },
  {
    'id' : 4,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '장희빈',
    'date' : '2019.01.02',
    'image' : 'http://placeimg.com/64/64/4',
    'content' : '장희빈 바보',
  },
  {
    'id' : 5,
    'userId' : 'Jay1',
    'sabuId' : 'ABC123',
    'nickName' : '김희선',
    'date' : '2018.12.25',
    'image' : 'http://placeimg.com/64/64/5',
    'content' : '김희선 바보',
  }
]
*/

class Supplier_Admin extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        suppliers: '',
        userContents: '',
        userContent: '',
        image: "",
        getNotifications: "",
        fcmTokenId: "",
        userId: "",
        eventToggle : '',
        applicantToggle : '',
        //followUploadToggle : '',
        //followCommentToggle : '',
        getFollowees: '',
        pointSum: '',
        getApplications: '',
        getApplication: '',
        searchKeyword: '',
        // uploadButtonToggle: false, // UploadPhoto 버튼 관련
        completed : 0,
      };
  }

  getSuppliers() {
    return API.get("upload", "/user?customerType1=supplier")
    .then(suppliers => this.setState({suppliers : suppliers}));
  }

  getNotifications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    return API.get("upload", `/notification/?userId=${currentAuthUserFromSession}&fcmTokenId=${currentFCMTokenFromSession}`)
    .then(getNotifications => this.setState({getNotifications : getNotifications}));
  }

  postNotification(postNotification) {
    return API.post("upload", "/notification", {
      body: postNotification
    });
  }

  getNotiSetting() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/notiSetting/${currentAuthUserFromSession}`)
  }

  postNotiSetting(postNotificationSet) {
    return API.post("upload", "/notiSetting", {
      body: postNotificationSet
    });
  }

  getPoint() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/point/${currentAuthUserFromSession}`)
  }

  postPoint(postPoint) {
    return API.post("upload", "/point", {
      body: postPoint
    });
  }

  // 내가 지원한 프로젝트 리스트
  getApplications() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/application/?userId=${currentAuthUserFromSession}`)
    .then(getApplications => this.setState({getApplications : getApplications}));
  }

  /*
  // 내가 찜하기(Following)한 사람들 리스트
  getFollowees() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/follow/?followerUserId=${currentAuthUserFromSession}`)
    .then(getFollowees => this.setState({getFollowees : getFollowees}));
  }
  */

  // 모든 component가 구성이 완료 되고 불러온다.
  // 이유는 서버에서 미처 불러올 시간이 안되고 _get()함수가 실행되어 버리면 내용을 못불러올 수 있기 때문이다.
  async componentDidMount() {

    const { isAuthenticated } = this.props
    if(isAuthenticated === true) {
      // console.log("isAuthenticated === true")
    } else {
      // Cognito 로그인 없이 S3 읽어 온다.
      Amplify.configure({
        Auth: {
          mandatorySignIn: true, // true이면 로그인 시에만 S3 읽어 올수 있다.
        }
      });
    }

    this._isMounted = true;
    const { userHasAuthenticatedTrue } = this.props;
    // console.log('subscribe :' + sessionStorage.getItem("subscribe"));
    /*
      // 아래는 예시를 든것이다. 아래와 같이 this._isMounted를 사용해서 그 안에 setState를 담아주기.
      if (this._isMounted) {
        this.setState({
          news: result.data.hits,
        });
      }
    */
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    // 만약 progress바를 확인하고 싶으면 _get() 함수를 지우면 확인 가능하다.

    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: ");
      // console.log(userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: ");
      // console.log(userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        
        // currentAuthUser(currentAuthUserFromSession);
        const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        /*
        const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
        console.log('currentUser_Freelancer: ' + currentAuthUserFromSession_Freelancer);
        const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
        console.log('currentUser_Company: ' + currentAuthUserFromSession_Company);
        console.log('loginType: ' + loginType);
        */

        userHasAuthenticatedTrue();
      } else if (userID2 != null) {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));

        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        
        userHasAuthenticatedTrue();
      }
    } catch (e) {
        if (e !== "not authenticated") {
          // alert(e);
          console.log(e)
        }
    }

    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

    if(!(currentAuthUserFromSession === "ap-northeast-2:0eac84f9-d5d6-454a-8468-f46c99cef2ec"
    || currentAuthUserFromSession === "ap-northeast-2:1ef91eaf-bfd0-49eb-91ce-f70191165a07"
    )) {
      this.props.history.push("/");
    }

    localStorage.setItem("currentPage", "supplier_admin");

    /*
    try {
      Auth.configure({
        // To get the aws credentials, you need to configure 
        // the Auth module with your Cognito Federated Identity Pool
        identityPoolId: "ap-northeast-2:cf6b4614-2115-402c-9b00-97510e4c79ad",
        region: 'ap-northeast-2',
      });
    
      Storage.configure({
        AWSS3: {
            bucket: 'ilgam-s3upload-dev-s3bucket-10u65ulpcmd92',//Your bucket name;
            region: 'ap-northeast-2'//Specify the region your bucket was created in;
        }
      });

      //const anonymousUser = await Auth.currentCredentials()
    } catch (e) {
      console.log(e)
    }

    console.log("anonymousUser :")
    //console.log(anonymousUser)
    */

    /*
    // UploadPhoto 버튼 관련
    const currentAuthUserFromSession_Company = sessionStorage.getItem("currentUser_Company");
    const currentAuthUserFromSession_Freelancer = sessionStorage.getItem("currentUser_Freelancer");
    const loginType = localStorage.getItem("loginType");

    // 기업만 프로젝트 업로드 가능
    if(loginType === 'email' && currentAuthUserFromSession_Company) {
      this.setState({uploadButtonToggle: true})
    } else if(loginType != 'email' && currentAuthUserFromSession_Freelancer) {
      this.setState({uploadButtonToggle: false})
    }
    */

    /*
    await this.getUsers();
    await this.getUploadProjects();
    await this.getUploadEducations();
    await this.getUploadCompanyPromotions();
    await this.getUploadFreelancerPromotions();
    */

    /*
    await this.getFollowees();
    await this.getApplications();
    */

    await this.getSuppliers();

    const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
    const FCMToggleFromSession = sessionStorage.getItem("FCMLoginToggle");

    // console.log("FCM-currentFCMTokenFromSession :" +  currentFCMTokenFromSession);
    // console.log("FCM-currentAuthUserFromSession :" +  currentAuthUserFromSession);
    // console.log("FCM-FCMToggleFromSession :" +  FCMToggleFromSession);


    if(currentFCMTokenFromSession && currentAuthUserFromSession && FCMToggleFromSession === "true") {
      await this.postNotification({
        fcmTokenId: currentFCMTokenFromSession,
        userId: currentAuthUserFromSession,
      });
      //console.log("FCM 토큰값 DB로 저장(로그인)");
      sessionStorage.setItem("FCMLoginToggle", "false");
     
      Object.keys(this.state.getNotifications).map(notificationUUId => {
        const getNotification = this.state.getNotifications[notificationUUId];
        const currentFCMTokenUUID = getNotification.notificationUUId;
        sessionStorage.setItem("currentFCMTokenUUID", currentFCMTokenUUID);
      });
      
    } else {
      //console.log("FCM 토큰값 없음 또는 이미 토큰값 DB에 저장됨");
      sessionStorage.setItem("FCMLoginToggle", "false");
    }

    // notiSetting 관련
    const notiSettingUserId = localStorage.getItem("notiSetting")
    ? JSON.parse(localStorage.getItem("notiSetting")).userId
    : null

    if(notiSettingUserId === currentAuthUserFromSession) {
      //console.log("localStorge_notiSetting 있음.");
      try{
        const localEventToggle = JSON.parse(localStorage.getItem("notiSetting")).eventToggle
        const localApplicantToggle = JSON.parse(localStorage.getItem("notiSetting")).applicantToggle  // 내가 게재한 공고에 지원자가 지원시 알림 설정
        //const localFollowUploadToggle = JSON.parse(localStorage.getItem("notiSetting")).followUploadToggle
        //const localFollowCommentToggle = JSON.parse(localStorage.getItem("notiSetting")).followCommentToggle
        this.setState({
          eventToggle : localEventToggle,
          applicantToggle : localApplicantToggle,
          // followUploadToggle : localFollowUploadToggle,
          // followCommentToggle : localFollowCommentToggle,
        });
      } catch(e) {
        //console.log("localNoniSetting() 에러.");    
      }
    } else {
      //console.log("localStorge_notiSetting 없음.");
      try{
        const notificationSetting = await this.getNotiSetting();

        const { userId, eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ } = notificationSetting;
        
        this.setState({userId});
  
        if(this.state.userId === currentAuthUserFromSession)
        {
          this.setState({ eventToggle, applicantToggle, /*followUploadToggle, followCommentToggle*/ });
        }

        localStorage.setItem(
          "notiSetting",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            eventToggle: this.state.eventToggle,
            applicantToggle: this.state.applicantToggle,
            /*
            followUploadToggle: this.state.followUploadToggle,
            followCommentToggle: this.state.followCommentToggle
            */
          })
        )
        //console.log("localStorge_notiSetting 저장됨.");
      } catch(e) {
        // alert(e);        
        await this.postNotiSetting({
          eventToggle: "true",
          applicantToggle: "true",
          /*
          followUploadToggle: "true",
          followCommentToggle: "true",
          */
        });

        localStorage.setItem(
          "notiSetting",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            eventToggle: "true",
            applicantToggle: "true",
            /*
            followUploadToggle: "true",
            followCommentToggle: "true",
            */
          })
        )
        
        //console.log("postNotiSetting() 실행됨.");
      }
    }

    /*
    // point 관련
    const pointUserId = localStorage.getItem("point")
    ? JSON.parse(localStorage.getItem("point")).userId
    : null

    if(pointUserId === currentAuthUserFromSession) {
      console.log("localStorge_point 있음.");
      try{
        const localPointSum = JSON.parse(localStorage.getItem("point")).pointSum
        this.setState({
          pointSum : localPointSum,
        });
        // console.log("localPointSum :" + this.state.pointSum);
      } catch(e) {
        console.log("localPoint() 에러.");    
      }
    } else {
      console.log("localStorge_point 없음.");
      try{
        const point = await this.getPoint();

        const { userId, pointSum } = point;
        
        this.setState({userId});
  
        if(this.state.userId === currentAuthUserFromSession)
        {
          this.setState({ pointSum });
        }

        localStorage.setItem(
          "point",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            pointSum: this.state.pointSum
          })
        )
        console.log("localStorge_point 저장됨.");
      } catch(e) {
        // alert(e);        
        await this.postPoint({
          pointEvent: "default",
        });

        localStorage.setItem(
          "point",
          JSON.stringify({
            userId: currentAuthUserFromSession,
            pointSum: 0
          })
        )
        
        console.log("postPoint() 실행됨.");
      }
    }
    */
  }

  drawerOpen = () => {
    const {drawertoggletrue} = this.props;
    drawertoggletrue();
  }

  // 다이얼 로그
  // true에서 false로, false에서 true로 스위치 할 수 있는 간단한 함수
  handleDialogToggle = () => this.setState({
   dialog: !this.state.dialog
  })

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // 사용자가 삭제 버튼을 눌렀을 때 실행하는 함수
  handleDelete = (id) => {
    this._delete(id);
  }

  /*
  // UploadPhoto 버튼 관련
  handleUpload = () => {
    const { upload } = this.props
    upload()
  }
  */

  // 말그대로 state를 초기화 해주면 되는 함수
  stateRefresh = () => {
    this.setState({
    uploadContents : '',
    userContents : '',
    completed : 0,
    });
    
    //this.getUsers();
    //this.getPublicUploads();
  }
 
  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  render() {
    const { classes, theme, /*searchKeyword,*/ isApplyingLoading, isFollowingLoading } = this.props;
    const { searchKeyword } = this.state;

    /*
    if(isApplyingLoading) {
      this.getApplications();
    }

    if(isFollowingLoading) {
      this.getFollowees();
    }
    */

    // 각 데이터를 필터를 거친후 만들어주게끔 설정하는 함수
    const filteredSuppliers = (data) => {
      data = data.filter((c) => {
        return c.history.indexOf(searchKeyword) > -1;
      });
      return data.map((c) => {

        return(
          <Feed_Customer_Admin
            stateRefresh={this.stateRefresh}
            key={c.userUUId}  // 리액트에서는 리스트에 대한 정보를 출력 할때 각 출력되는 정보는 key속성을 넣어 주어야 한다.
            id={c.id}
            userId={c.userId}
            userUUId={c.userUUId}
            customerType1={c.customerType1}
            customerType2={c.customerType2}
            companyName={c.companyName}
            name={c.name}
            history={c.history}
            address={c.address}
            date={c.date}
            avatarimage={c.avatarimage}
            adminConfirm={c.adminConfirm}
            level={c.level}
          />
        );
      })
    }
    
    return (
      <div className={classes.root}>
        {/*<Grid container spacing={0} direction="row" align = "center" justify="flex-start" alignItems="flex-start">*/}
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          {/*
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"> {/ 세로로 정렬시 사용 /}
              <Card className={classes.mainCard}>
                <CardMedia
                  className={classes.mainMedia}
                  image={'/images/main/bg_home.jpg'}
                />
              </Card>
            </Grid>             
          </Grid>
          */}
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card className={classes.cardTitle}>
                <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography className={classes.typoTitle1}>
                      공급기업 리스트
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Button onClick={this.drawerOpen} className={classes.typoTitle2}>
                      상세 분야를 확인해보세요
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={8} xl={8} align="center" spacing={0}>
            {this.state.suppliers
              ?
              filteredSuppliers(this.state.suppliers)
              :
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <CircularProgress className={classes.progress} variant="determinate" value={this.state.completed} />
              </Grid>
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  uploadContents: state.upload.uploadContents,
  // currentUser: state.auth.currentUser,
  // searchKeyword: state.upload.searchKeyword,
  isdrawerToggle: state.upload.isdrawerToggle,
  isApplyingLoading: state.upload.isApplyingLoading,
  isFollowingLoading: state.upload.isFollowingLoading,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  // currentAuthUser: (currentUser) => dispatch(authActions.currentAuthUser(currentUser)),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  // upload: () => dispatch(uploadActions.upload()),  // UploadPhoto 버튼 관련
  // uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
  drawertoggletrue: () => dispatch(uploadActions.drawertoggletrue()),
  drawertogglefalse: () => dispatch(uploadActions.drawertogglefalse()),
  applyingloadingtrue: (isApplyingLoading) => dispatch(uploadActions.applyingloadingtrue(isApplyingLoading)),
  applyingloadingfalse: (isApplyingLoading) => dispatch(uploadActions.applyingloadingfalse(isApplyingLoading)),
  followingloadingtrue: (isFollowingLoading) => dispatch(uploadActions.followingloadingtrue(isFollowingLoading)),
  followingloadingfalse: (isFollowingLoading) => dispatch(uploadActions.followingloadingfalse(isFollowingLoading)),
})

Supplier_Admin = withStyles(styles, {withTheme: true})(Supplier_Admin)
export default connect(mapStateToProps, mapDispatchToProps)(Supplier_Admin)