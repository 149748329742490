import React from 'react';
import { Router, Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CardMedia from '@material-ui/core/CardMedia';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';  
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';

// Responsive Drawer
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';

// 라우터 관련
// 'react-redux'라이브러리에서 connect 변수 import. 
// 단 여기서 connect 변수는 export 시에 default로 하지 않았기 때문에(export default connect가 아니기 때문에) -> { } 가 붙는다
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'
// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as uploadActions from 'redux/actions/upload'

import Hammer from 'hammerjs';

// import { createBrowserHistory } from 'history';

//import './MainAppShell.scss'

const drawerWidth = 240;

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(3),
    overFlowX : "auto",
    display: 'flex',
  },
  menu: {
    marginTop: 15,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    marginLeft: 18,
    marginRight: 18
  },
  TableHead: {
    fontSize: '1.0rem'
  },
  /*
  table: {
    minWidth : 1080
  },
  */
  progress : theme.spacing(2),
  menuButton: {
    marginRight: theme.spacing(5),
  },
  title: {
    flexGrow: 1,
    fontSize: 15,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  menuIcon:{
    width: 23,
    height: 23
  },
  logoMedia: {
    width: 80, // Original 50
    height: 80, // Original 50
  },
  login: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
    
  },
  searchBadge: {
    fontSize: 25,
  },
  notificationsIcon: {
    fontSize: 25,
    // color: '#0c93d0'
  },
  arrowBackIosIcon: {
    width: 23,
    height: 23
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    /*
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    */
  },
  searchIcon: {
    height: 10,
    width: 10,
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldBorder: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {  // 평소
      borderColor: "#0c93d0",
      borderWidth: 1,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {  // hover
      borderColor: "#0c93d0",
      borderWidth: 1,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {  // 글 작성시
      borderColor: "#0c93d0",
      borderWidth: 1,
    }
  },
  inputRoot: {
    fontSize: 15,
    width: '100%',
    color: 'inherit',
  },
  inputInput: {
    fontSize: 15,
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    /*
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    */
  },
  searchTypo: {
    fontSize: 15,
  },
  clearIcon: {
    height: 25,
    width: 25,
    paddingRight: 3,
  },
  AppShellText: {
    fontSize : 18,
    //color: 'inherit',
    color: '#000000',  // black
    '&:hover': {
      color: "#0c93d0",  // 일감 로고 색깔
      /*
      color: "#ffffff",
      backgroundColor: '#1E90FF',
      borderColor: '#0062cc',
      boxShadow: 'none',
      */
    },
    padding: 0,
    // underline: 'none', -> 여기서는 동작 안해서 아래 코드에서 직접 작성
  },
  // PC버전에서 왼쪽에 drawbar 적용 코드
  drawer: {
    [theme.breakpoints.up('md')]: { // 원래는 sm 이었음.
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  //
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    // background: "#696969",
    boxShadow: "none",
    // PC버전에서 왼쪽에 drawbar 적용 코드
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: { // 원래는 sm
      width: `calc(100% - ${drawerWidth}px)`,
    },
    //
  },
  appBar3: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#FFFFFF",
    // background: "#696969",
    boxShadow: "none",
    //
  },
  toolBar: {
    height : 80,  // Original 15
  },
  appBar2: {
    marginTop: '80px',  // Original 47
    background: "#FFFFFF",
    //background: "#F5F5F5",
    //boxShadow: "none",
    // PC버전에서 왼쪽에 drawbar 적용 코드
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: { // 원래는 sm
      width: `calc(100% - ${drawerWidth}px)`,
    },
    //
  },
  appBar4: {
    marginTop: '80px',  // Original 47
    background: "#FFFFFF",
    //background: "#F5F5F5",
    //boxShadow: "none",
  },
  toolBar2: {
    height : 71,  // Original 15
  },
  indicator: {
    backgroundColor: '#0c93d0',
  },
  indiText: {
    textColor: '#0c93d0'
  },
  // PC버전에서 왼쪽에 drawbar 적용 코드
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  drawerPaper: {
    width: drawerWidth,
    // background: "#E8E8E8"  // 살짝 짙은 grey
    // background: "#F5F5F5" // 옅은 grey
    // opacity : 0.5
    [theme.breakpoints.up('md')]: {
      height: 805,
    },
  },
  //
  menuitemTitle: {
    padding: 0,
    background: 'white'
  },
  menuitem: {
    padding: 0,
  },
  drawerlink: {
    width: drawerWidth,
    // height: '100%',
  },
  drawerlinkword : {
    marginLeft : theme.spacing(5),
    fontSize: 18
  },
  expandMoreIcon: {
    width: 20,
    height: 20
  },
  // extension 기능
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  content: {
    marginTop: '17px',
    flexGrow: 1,
    padding: theme.spacing(0),
    background: "#F8F8F8",
  },
  hideIndicator: {
    height: 0
  },
  iconButton: {
    margin: theme.spacing(0),
    '&:hover': {
      color: "#0c93d0",
      backgroundColor: 'transparent', // Remove the gray background on hover
    },
  },
})

class MainAppShell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          expanded_0 : false,  // extension 기능
          expanded_1 : false,
          expanded_2 : false,
          expanded_3 : false,
          expanded_4 : false,
          expanded_5 : false,
          expanded_6 : false,
          expanded_7 : false,
          expanded_8 : false,
          expanded_9 : false,
          /*
          expanded_10 : false,
          expanded_11 : false,
          expanded_12 : false,
          expanded_13 : false,
          expanded_14 : false,
          expanded_15 : false,
          */
          // mobileOpen : false,  // 현재 Redux사용해서 필요 없음. 나중에 지우기
          searchKeyword: '',
          isSearchToggle: false,  // 검색창 나오에 하기 위한 토글
          tabLinkValue: 0,
          tabindicator: true,
          notificationLists: '',
          adminNotificationLists: '',
          notificationChecks: '',
          notificationCheck: '',
          userId: '',
          notiCheckingDate: '',
          date: '',
          notificationNumbers: 0, // badge에 나타내는 최종 숫자(following + 관리자 이벤트)
          notiEventNumbers: 0,  //  관리자 발송 이벤트 관련
          // notiFollowingNumbers: 0,  // Following 관련
        };
    }

    /*
    shouldComponentUpdate() {
      this.updatechange();
      return true;
    }
    */

    /*
    // 내가 Following한 사람들 리스트
    getFollowees() {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

      return API.get("upload", `/follow/?followerUserId=${currentAuthUserFromSession}`)
        .then(getFollowees => this.setState({getFollowees : getFollowees}));
    }
    */

    /*
    getNotificationLists() {
      return API.get("upload", `/notificationList`)
      .then(notificationLists => this.setState({notificationLists : notificationLists}));
    }

    // 관리자가 발송하는 이벤트 받는 코드
    getAdminNotificationLists() {
      return API.get("upload", `/notificationList?userId=ap-northeast-2:5c47086a-d50f-4914-9f3c-9e91904eed46`)
      .then(adminNotificationLists => this.setState({adminNotificationLists : adminNotificationLists}));
    }
    */

    // 현재 관리자가 보내는 알림만 받게끔 코딩 할 예정임. 
    getAdminNotificationLists() {
      return API.get("upload", `/notificationList`)
      .then(adminNotificationLists => this.setState({adminNotificationLists : adminNotificationLists}));
    }

    // notiChecking 관련 
    getNotiChecking() {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

      return API.get("upload", `/notiChecking/${currentAuthUserFromSession}`)
    }

    postNotiChecking(postNotificationCheck) {
      return API.post("upload", "/notiChecking", {
        body: postNotificationCheck
      });
    }

    putNotiChecking(putNotificationCheck) {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

      return API.put("upload", `/notiChecking/${currentAuthUserFromSession}`, {
        body: putNotificationCheck
      });
    }

    /*
    // 삭제 API
    deleteNotification() {
      // const currentFCMTokenUUIDFromSession = sessionStorage.getItem("currentFCMTokenUUID");
      const currentFCMTokenFromSession = sessionStorage.getItem("currentFCMToken");
      return API.del("upload", `/notification/${currentFCMTokenFromSession}`);
    }
    */

    // render() 실행 후 동작. 뒤로 back 할때 필요
    componentDidUpdate() {
      this.updatechange();
      /*
      const customerType1_FromLocal = localStorage.getItem("customerType1");
      if(customerType1_FromLocal === 'buyer') {
        this.updatechange_buyer();
      } else if(customerType1_FromLocal === 'supplier') {
        this.updatechange_supplier();
      }
      */
    }

    // render() 실행 전 동작. 새로 고침시 이 함수 먼저 실행 
    componentWillMount() {
      this.mountchange();
      /*
      const customerType1_FromLocal = localStorage.getItem("customerType1");
      if(customerType1_FromLocal === 'buyer') {
        this.mountchange_buyer();
      } else if(customerType1_FromLocal === 'supplier') {
        this.mountchange_supplier();
      }
      */
    }

    // render() 실행 후 동작. 새로 고침시 render()함수 실행후 이 함수 실행
    async componentDidMount() {
      this.mountchange();

      /*
      const customerType1_FromLocal = localStorage.getItem("customerType1");
      if(customerType1_FromLocal === 'buyer') {
        this.mountchange_buyer();
      } else if(customerType1_FromLocal === 'supplier') {
        this.mountchange_supplier();
      }
      */

      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      /*
      const SearchKeyword = sessionStorage.getItem("searchKeyword");
      const isSessionSearchToggle = sessionStorage.getItem("isSearchToggle");

      if(isSessionSearchToggle === 'true') {
        this.setState({isSearchToggle: true})
        //searchtoggletrue();
      } else if(isSessionSearchToggle === 'false') {
        this.setState({isSearchToggle: false})
        //searchtogglefalse();
      }
      
      if(SearchKeyword) {
        this.setState({
          searchKeyword: SearchKeyword,
        })
      }
      */
      
      // await this.getNotificationLists();
      // await this.getFollowees();
      await this.getAdminNotificationLists();
      
      // notichecking 관련
      const notiCheckingUserId = localStorage.getItem("notiChecking")
      ? JSON.parse(localStorage.getItem("notiChecking")).userId
      : null

      if(notiCheckingUserId === currentAuthUserFromSession){
        // console.log("localStorge_notiChecking 있음.");
        try{
          const notificationCheck = await this.getNotiChecking();
  
          const { userId, notiCheckingDate, date } = notificationCheck;
          
          this.setState({userId});
    
          if(this.state.userId === currentAuthUserFromSession)
          {
      
            this.setState({
              notificationCheck,
              notiCheckingDate,
              date,
            });

            // console.log("notiCheckingDate :" + this.state.notiCheckingDate);
            // console.log("date :" + this.state.date);

            // 관리자 이벤트 관련 숫자 구하기
            // 시간차이 구하기(최근 클릭한 시간 기준. 초 단위)
            let t1 = moment(this.state.notiCheckingDate, "YYYY-MM-DD HH:mm");  // 마지막 클릭한 시간 (DB에 저장되어 있는 가장 최근 getNotiChecking 시간)       
            let adminNotificationNumber = 0;
            Object.keys(this.state.adminNotificationLists).map(notiListUUId => {
              const adminNotificationList = this.state.adminNotificationLists[notiListUUId];
              const t5 = moment(adminNotificationList.date, "YYYY-MM-DD HH:mm");  // DB에 저장되어 있는 notiList의 시간
              const t6 = moment.duration(t1.diff(t5)).asMilliseconds();
              // console.log('밀리세컨즈 차이: ', moment.duration(t1.diff(t2)).asMilliseconds());
              if (t6 < 0) {
                // 값이 - 이면 클릭한 이후에 된거라서 Badge에 표시 해주면 됨.
                // 값이 + 이면 이미 읽은 것으로 표시해주면 됨.
                adminNotificationNumber = adminNotificationNumber + 1;
              }
            });
            this.setState({notiEventNumbers: adminNotificationNumber});
            
            /*
            // Following 관련 숫자 구하기
            // 시간차이 구하기(최근 클릭한 시간 기준. 초 단위)
            // let t1 = moment(this.state.notiCheckingDate, "YYYY-MM-DD HH:mm");  // 마지막 클릭한 시간 (DB에 저장되어 있는 가장 최근 getNotiChecking 시간)       
            let notificationNumber = 0;
            Object.keys(this.state.notificationLists).map(notiListUUId => {
              const notificationList = this.state.notificationLists[notiListUUId];

              let followingTrue = "false";
              Object.keys(this.state.getFollowees).map(followingUUId => {
                const getFollowee = this.state.getFollowees[followingUUId];
                if(getFollowee.followeeUserId === notificationList.userId) {
                  followingTrue = "true";
                }
              })

              if(followingTrue === "true") {
                const t2 = moment(notificationList.date, "YYYY-MM-DD HH:mm");  // DB에 저장되어 있는 notiList의 시간
                const t3 = moment.duration(t1.diff(t2)).asMilliseconds();
                // console.log('밀리세컨즈 차이: ', moment.duration(t1.diff(t2)).asMilliseconds());
                if (t3 < 0) {
                  // 값이 - 이면 클릭한 이후에 된거라서 Badge에 표시 해주면 됨.
                  // 값이 + 이면 이미 읽은 것으로 표시해주면 됨.
                  notificationNumber = notificationNumber + 1;
                }
              }
            });
            this.setState({notiFollowingNumbers: notificationNumber});
            */

            let eventNum = this.state.notiEventNumbers;
            /*
            let followingNum = this.state.notiFollowingNumbers;
            let totalNumber = followingNum + eventNum;
            */
            let totalNumber = eventNum;
            this.setState({notificationNumbers: totalNumber});
          }
        } catch(e) {
          // console.log("localNotiChecking() 실행됨.");

          const localNotiCheckingDate = JSON.parse(localStorage.getItem("notiChecking")).notiCheckingDate

          // 관리자 이벤트 관련 숫자 구하기
          // 시간차이 구하기(최근 클릭한 시간 기준. 초 단위)
          let t1 = moment(localNotiCheckingDate, "YYYY-MM-DD HH:mm");  // 마지막 클릭한 시간 (DB에 저장되어 있는 가장 최근 getNotiChecking 시간)       
          let adminNotificationNumber = 0;
          Object.keys(this.state.adminNotificationLists).map(notiListUUId => {
            const adminNotificationList = this.state.adminNotificationLists[notiListUUId];
            const t5 = moment(adminNotificationList.date, "YYYY-MM-DD HH:mm");  // DB에 저장되어 있는 notiList의 시간
            const t6 = moment.duration(t1.diff(t5)).asMilliseconds();
            // console.log('밀리세컨즈 차이: ', moment.duration(t1.diff(t2)).asMilliseconds());
            if (t6 < 0) {
              // 값이 - 이면 클릭한 이후에 된거라서 Badge에 표시 해주면 됨.
              // 값이 + 이면 이미 읽은 것으로 표시해주면 됨.
              adminNotificationNumber = adminNotificationNumber + 1;
            }
          });
          this.setState({notiEventNumbers: adminNotificationNumber});

          /*
          // Following 관련 숫자 구하기
          // 시간차이 구하기(최근 클릭한 시간 기준. 초 단위)
          // let t1 = moment(localNotiCheckingDate, "YYYY-MM-DD HH:mm");  // 마지막 클릭한 시간 (DB에 저장되어 있는 가장 최근 getNotiChecking 시간)       
          let notificationNumber = 0;
          Object.keys(this.state.notificationLists).map(notiListUUId => {
            const notificationList = this.state.notificationLists[notiListUUId];

            let followingTrue = "false";
            Object.keys(this.state.getFollowees).map(followingUUId => {
              const getFollowee = this.state.getFollowees[followingUUId];
              if(getFollowee.followeeUserId === notificationList.userId) {
                followingTrue = "true";
              }
            })

            if(followingTrue === "true") {
              const t2 = moment(notificationList.date, "YYYY-MM-DD HH:mm");  // DB에 저장되어 있는 notiList의 시간
              const t3 = moment.duration(t1.diff(t2)).asMilliseconds();
              // console.log('밀리세컨즈 차이: ', moment.duration(t1.diff(t2)).asMilliseconds());
              if (t3 < 0) {
                // 값이 - 이면 클릭한 이후에 된거라서 Badge에 표시 해주면 됨.
                // 값이 + 이면 이미 읽은 것으로 표시해주면 됨.
                notificationNumber = notificationNumber + 1;
              }
            }
          });
          this.setState({notiFollowingNumbers: notificationNumber});
          */

         let eventNum = this.state.notiEventNumbers;
         /*
         let followingNum = this.state.notiFollowingNumbers;
         let totalNumber = followingNum + eventNum;
         */
        let totalNumber = eventNum;
         this.setState({notificationNumbers: totalNumber});
        }
      } else {
        //console.log("localStorge_notiChecking 없음.");
        try{
          const notificationCheck = await this.getNotiChecking();

          const { userId, notiCheckingDate, date } = notificationCheck;
        
          this.setState({userId});
  
          if(this.state.userId === currentAuthUserFromSession)
          {
    
            this.setState({
              notificationCheck,
              notiCheckingDate,
              date,
            });

            // console.log("notiCheckingDate :" + this.state.notiCheckingDate);
            // console.log("date :" + this.state.date);

            // 관리자 이벤트 관련 숫자 구하기
            // 시간차이 구하기(최근 클릭한 시간 기준. 초 단위)
            let t1 = moment(this.state.notiCheckingDate, "YYYY-MM-DD HH:mm");  // 마지막 클릭한 시간 (DB에 저장되어 있는 가장 최근 getNotiChecking 시간)       
            let adminNotificationNumber = 0;
            Object.keys(this.state.adminNotificationLists).map(notiListUUId => {
              const adminNotificationList = this.state.adminNotificationLists[notiListUUId];
              const t5 = moment(adminNotificationList.date, "YYYY-MM-DD HH:mm");  // DB에 저장되어 있는 notiList의 시간
              const t6 = moment.duration(t1.diff(t5)).asMilliseconds();
              // console.log('밀리세컨즈 차이: ', moment.duration(t1.diff(t2)).asMilliseconds());
              if (t6 < 0) {
                // 값이 - 이면 클릭한 이후에 된거라서 Badge에 표시 해주면 됨.
                // 값이 + 이면 이미 읽은 것으로 표시해주면 됨.
                adminNotificationNumber = adminNotificationNumber + 1;
              }
            });
            this.setState({notiEventNumbers: adminNotificationNumber});

            /*
            // Following 관련 숫자 구하기
            // 시간차이 구하기(최근 클릭한 시간 기준. 초 단위)
            // let t1 = moment(this.state.notiCheckingDate, "YYYY-MM-DD HH:mm");  // 마지막 클릭한 시간 (DB에 저장되어 있는 가장 최근 getNotiChecking 시간)       
            let notificationNumber = 0;
            Object.keys(this.state.notificationLists).map(notiListUUId => {
              const notificationList = this.state.notificationLists[notiListUUId];

              let followingTrue = "false";
              Object.keys(this.state.getFollowees).map(followingUUId => {
                const getFollowee = this.state.getFollowees[followingUUId];
                if(getFollowee.followeeUserId === notificationList.userId) {
                  followingTrue = "true";
                }
              })

              if(followingTrue === "true") {
                const t2 = moment(notificationList.date, "YYYY-MM-DD HH:mm");  // DB에 저장되어 있는 notiList의 시간
                const t3 = moment.duration(t1.diff(t2)).asMilliseconds();
                // console.log('밀리세컨즈 차이: ', moment.duration(t1.diff(t2)).asMilliseconds());
                if (t3 < 0) {
                  // 값이 - 이면 클릭한 이후에 된거라서 Badge에 표시 해주면 됨.
                  // 값이 + 이면 이미 읽은 것으로 표시해주면 됨.
                  notificationNumber = notificationNumber + 1;
                }
              }
            });

            this.setState({notiFollowingNumbers: notificationNumber});
            */

            let eventNum = this.state.notiEventNumbers;
            /*
            let followingNum = this.state.notiFollowingNumbers;
            let totalNumber = followingNum + eventNum;
            */
            let totalNumber = eventNum;
            this.setState({notificationNumbers: totalNumber});

            localStorage.setItem(
              "notiChecking",
              JSON.stringify({
                userId: currentAuthUserFromSession,
                notiCheckingDate: moment()
              })
            )
          }
        } catch(e) {
          // alert(e);
          console.log("postNotiChecking() 실행됨.");
        
          await this.postNotiChecking({
            notiCheckingDate: moment()
          });

          localStorage.setItem(
            "notiChecking",
            JSON.stringify({
              userId: currentAuthUserFromSession,
              notiCheckingDate: moment()
            })
          )
        }
      }

      // Add Hammer code here
      // 0: /, 1: homegetprojects, 2:myHome, 3: homemanual
      const hammerTarget = document.getElementById('tablinkHammer1'); // replace with the ID of your element
      const hammer = new Hammer(hammerTarget);
      hammer.on('swipeleft', () => {
        const { tabLinkValue } = this.state;
        //console.log("this.state.tabLinkValue_left", this.state.tabLinkValue)
        let newTabLinkValue;
        switch (tabLinkValue) {
          case 0:
            newTabLinkValue = 1;
            this.props.history.push('/culture');
            break;
          case 1:
            newTabLinkValue = 2;
            this.props.history.push('/education');
            break;
          case 2:
            newTabLinkValue = 3;
            this.props.history.push('/entrance');
            break;
          case 4:
              newTabLinkValue = 0;
              this.props.history.push('/introduce');
              break;
          default:
            newTabLinkValue = tabLinkValue;
            break;
        }
        this.setState({ tabLinkValue: newTabLinkValue });
      });
      
      hammer.on('swiperight', () => {
        const { tabLinkValue } = this.state;
        //console.log("this.state.tabLinkValue_right", this.state.tabLinkValue)
        let newTabLinkValue;
        switch (tabLinkValue) {
          case 3:
            newTabLinkValue = 2;
            this.props.history.push('/education');
            break;
          case 2:
            newTabLinkValue = 1;
            this.props.history.push('/entrance');
            break;
          case 1:
            newTabLinkValue = 0;
            this.props.history.push('/introduce');
            break;
          case 0:
            newTabLinkValue = 4;
            this.props.history.push('/');
            break;
          default:
            newTabLinkValue = tabLinkValue;
            break;
        }
        this.setState({ tabLinkValue: newTabLinkValue });
      });
    }

    updatechange = () => {
      if(this.props.history.location.pathname === '/introduce') {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 0,
            tabindicator: true        
          });
        }
      }
      else if(this.props.history.location.pathname === '/entrance') {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 1,
            tabindicator: true
          });
        } 
      }
      else if(this.props.history.location.pathname === '/education') {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 2,
            tabindicator: true
          });
        }
      }
      else if(this.props.history.location.pathname === '/culture') {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 3,
            tabindicator: true
          });
        }
      }
      else if(this.props.history.location.pathname === '/mypage' || this.props.history.location.pathname === '/myaccount'
      /*|| this.props.history.location.pathname === '/follow'*/ || this.props.history.location.pathname === '/notiSetting' 
      || this.props.history.location.pathname === '/notiSending' || this.props.history.location.pathname === '/') {
        window.onpopstate = ()=> {
          this.setState({
            tabLinkValue: 4,
            tabindicator: false
          });
        }
      }

      // console.log("shouldComponentUpdate : " +this.props.history.location.pathname);
      // console.log("shouldComponentUpdate, tabLinkValue : " + this.state.tabLinkValue);
      // console.log("shouldComponentUpdate, tabindicator : " + this.state.tabindicator);
    }
    
    mountchange = () => {
      if(this.props.history.location.pathname === '/introduce') {
        this.setState({
          tabLinkValue: 0,
          tabindicator: true
        });
      }
      else if(this.props.history.location.pathname === '/entrance') {
        this.setState({
          tabLinkValue: 1,
          tabindicator: true
        });
      }
      else if(this.props.history.location.pathname === '/education') {
        this.setState({
          tabLinkValue: 2,
          tabindicator: true
        });
      }
      else if(this.props.history.location.pathname === '/culture') {
        this.setState({
          tabLinkValue: 3,
          tabindicator: true
        });
      }
      else if(this.props.history.location.pathname === '/mypage' || this.props.history.location.pathname === '/myaccount'
      /*|| this.props.history.location.pathname === '/follow'*/ || this.props.history.location.pathname === '/notiSetting'
      || this.props.history.location.pathname === '/notiSending' || this.props.history.location.pathname === '/') {
        this.setState({
          tabLinkValue: 4,
          tabindicator: false
        });
      }
      // console.log("componentWillMount : " +this.props.history.location.pathname);
      // console.log("componentWillMount, tabLinkValue : " + this.state.tabLinkValue);
      // console.log("componentWillMount, tabindicator : " + this.state.tabindicator);
    }
    
    /*
    // 현재는 Redux 사용해서 필요 없다. 나중에 지우기
    // toggle값의 반대값을 넣을 수 있도록 한다. 스위치 같이.
    handleDrawerToggle = () => this.setState({mobileOpen: !this.state.mobileOpen});

    hiddenDrawerClick = (e, mobileOpen) => {
      if(this.state.mobileOpen) {
        this.setState({mobileOpen: !this.state.mobileOpen});
      }
      else {
        this.setState({mobileOpen: this.state.mobileOpen});
      }
    }
    */

    // toggle값의 반대값을 넣을 수 있도록 한다. 스위치 같이.
    handleDrawerToggle = () =>{
      const { isdrawerToggle, drawertoggletrue, drawertogglefalse } = this.props;
      if(isdrawerToggle === true) {
        drawertogglefalse();
      }
      if(isdrawerToggle === false) {
        drawertoggletrue();
      }
    }

    hiddenDrawerClick = (e) => {
      const { isdrawerToggle, drawertoggletrue, drawertogglefalse } = this.props;
      if(isdrawerToggle) {
        if(isdrawerToggle === true) {
          drawertogglefalse();
        }
        if(isdrawerToggle === false) {
          drawertoggletrue();
        }
      }
      else {
        if(isdrawerToggle === true) {
          drawertoggletrue();
        }
        if(isdrawerToggle === false) {
          drawertogglefalse();
        }
      }
    }

    handleValueChange = (e) => {
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    }

    // tabs 클릭시 바뀌는 state값
    handleChange = (event, tabLinkValue, tabindicator) => {
      this.setState({ tabLinkValue, tabindicator : true });
      // this.props.history.push(tabLinkValue);
    };
    
    // drawer 클릭시 로컬스토리지에 각 페이지 정하기 위한 코드
    // drawer안에 Link 클릭시 바뀌는 state 값
    drawerhandleChange_1 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "3dprinter")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();  // 페이지 넘어 갈때 검색 관련 내용 초기화
    };

    drawerhandleChange_2 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "iot")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_3 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "smartfactory")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_3_1 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "arvr")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_4 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "autocad")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_5 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "solidworks")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_6 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "inventor")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_7 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "catia")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_8 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "4iretc")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_9 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "melsec")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_10 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "ls")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_11 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "siemens")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_12 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "plcetc")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_13 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "machineassembly")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_14 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "machineharness")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_15 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "factoryharness")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_16 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "machinerepair")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_17 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "partsrepair")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_18 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "lathemilling")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_19 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "cnclathe")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_20 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "mct")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_21 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "engravingmachine")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_22 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "press")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_23 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "cam")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_24 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "processingetc")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_25 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "cylindricalgrinding")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_26 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "planegrinding")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_27 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "rotarygrinding")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_28 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "centerlessgrinding")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_29 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "toolgrinding")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_30 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "honing")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_31 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "profile")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_32 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "grindingetc")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_33 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "Argonwelding")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_34 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "laserwelding")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_35 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "lasermarking")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_36 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "weldingetc")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_37 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "gundrill")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_38 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "heattreatment")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_39 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "plated")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_40 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "3dmeasuring")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_41 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "etcetc")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_42 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "english")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_43 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "chinese")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_44 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "japanese")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_45 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "vietnamese")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_46 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "languageetc")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_47 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "factoryshare")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    drawerhandleChange_48 = (event, tabindicator) => {
      this.setState({ tabindicator : false });
      localStorage.setItem('currentPage', "useddeal")
      localStorage.setItem('currentTapLink', '0');
      this.handleAppbarBack();
    };

    // extension 기능
    handleExpandClick_0 = () => {
      this.setState({expanded_0: !this.state.expanded_0});
      this.setState({
        expanded_1: false, 
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_1 = () => {
      this.setState({expanded_1: !this.state.expanded_1});
      this.setState({
        expanded_0: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_2 = () => {
      this.setState({expanded_2: !this.state.expanded_2});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_3 = () => {
      this.setState({expanded_3: !this.state.expanded_3});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_4 = () => {
      this.setState({expanded_4: !this.state.expanded_4});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_5 = () => {
      this.setState({expanded_5: !this.state.expanded_5});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_6 = () => {
      this.setState({expanded_6: !this.state.expanded_6});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_7 = () => {
      this.setState({expanded_7: !this.state.expanded_7});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_8 = () => {
      this.setState({expanded_8: !this.state.expanded_8});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_9 = () => {
      this.setState({expanded_9: !this.state.expanded_9});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }
    
    /*
    handleExpandClick_10 = () => {
      this.setState({expanded_10: !this.state.expanded_10});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_11 = () => {
      this.setState({expanded_11: !this.state.expanded_11});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_12 = () => {
      this.setState({expanded_12: !this.state.expanded_12});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_13: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_13 = () => {
      this.setState({expanded_13: !this.state.expanded_13});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_14: false,
        expanded_15: false,
      });
    }

    handleExpandClick_14 = () => {
      this.setState({expanded_14: !this.state.expanded_14});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_15: false,
      });
    }

    handleExpandClick_15 = () => {
      this.setState({expanded_15: !this.state.expanded_15});
      this.setState({
        expanded_0: false,
        expanded_1: false,
        expanded_2: false,
        expanded_3: false,
        expanded_4: false,
        expanded_5: false,
        expanded_6: false,
        expanded_7: false,
        expanded_8: false,
        expanded_9: false,
        expanded_10: false,
        expanded_11: false,
        expanded_12: false,
        expanded_13: false,
        expanded_14: false,
      });
    }
    */

    isAndroid() {
      return /Android/i.test(navigator.userAgent);
    }

    isIOS() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    handleLogout = async event => {
      const loginType = localStorage.getItem("loginType")
      
      const { userHasAuthenticatedFalse } = this.props
      // DB에 FCM Token 삭제 API
      /*
      try {
        await this.deleteNotification();
        sessionStorage.setItem("FCMLoginToggle", "false");
        // window.location.reload();
      } catch (e) {
        // alert(e);
        console.log("deleteNotification() 에러")
        sessionStorage.setItem("FCMLoginToggle", "false");
      }
      */
      // 로컬스트로리지 삭제
      // localStorage.removeItem("notiChecking"); // <- 로그아웃해도 notiChecking관련 localstoge는 안 지울것임. 나중에 재 로그인 위해서

      // android가 아니고 ios가 아니면 web 로그아웃 진행
      if (!this.isAndroid() && !this.isIOS()) {
        if (loginType === "facebook") {
          // 페이스북 로그아웃
          // window.FB.logout(function() { window.location.reload() });
          // window.FB.logout();
          await FB.api(
            '/me/permissions',
            'DELETE',
            {},
            function(response) {
              // Here fb should remove the token.
              // response should be "success: true"
              window.FB.logout(() => {
              // Here logout.
              });
            }
          );
          console.log("facebook Log Out!");
          // window.location.reload();
        } else if (loginType === "google") {
          // 구글 로그아웃
          // this.googleSignOut();
          document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://www.otwogong.com";
          // document.location.href="https://accounts.google.com/logout?continue=https://appengine.google.com/_ah/logout?continue=https://localhost:5555";
          //console.log("google Log Out!");
        }

        // 로그아웃시 세션 지우기 기능
        await Auth.signOut()
      }

      // android 또는 ios 이면 로그아웃 진행
      if (this.isAndroid() || this.isIOS()) {
        //alert('Mobile login');
      }
      

      userHasAuthenticatedFalse();
      //sessionStorage.setItem("currentUser", null);

      // console.log("Log Out!");

      sessionStorage.clear()
      localStorage.clear()

      // this.props.history.push("/");
    }

    /*
    // 구글 로그아웃 (사용안함. 실제 로그아웃 잘 안됨. 참조만 하기)
    googleSignOut = () => {
      let auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signOut().then(function () {
        console.log('google User signed out.');
        auth2.disconnect();
      });
      auth2.disconnect();
    }
    */

    // App bar의 검색창에 필터(Filter)기능 사용해서 검색기능 추가 함수
    handleSearch = () => {
      /*
      const { searchtoggletrue } = this.props;
      searchtoggletrue();
      */
      this.setState({
        searchKeyword: '',
        isSearchToggle: true
      });
      sessionStorage.setItem("searchKeyword", '');
      sessionStorage.setItem("isSearchToggle", 'false');
    }

    handleAppbarBack = () => {
      /*
      const { uploadsearch, searchtogglefalse } = this.props;
      uploadsearch('');
      searchtogglefalse();
      */
      this.setState({
        searchKeyword: '',
        isSearchToggle: false
      });
      sessionStorage.setItem("searchKeyword", '');
      sessionStorage.setItem("isSearchToggle", 'false');
    }

    handleSearchBtn = () => {
      const { searchKeyword } = this.state;
      // const { uploadsearch } = this.props;
      // uploadsearch(searchKeyword);

      if (searchKeyword) {
        localStorage.setItem('currentPage', "search")
        localStorage.setItem('currentTapLink', '0');
        sessionStorage.setItem("searchKeyword", searchKeyword);
        sessionStorage.setItem("isSearchToggle", 'true');
        
        this.props.history.push('/fieldform/search/projects')
      }
    }

    handleKeyPress = (e) => {  
      // key 13 = 엔터를 의미
      if(e.charCode === 13) {
        this.handleSearchBtn()
      }
    }

    handleSearchClear = () => {
      this.setState({searchKeyword: ''})
    }

    handleNotification = async () => {
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");

      if(currentAuthUserFromSession)
      {
        try {
          await this.putNotiChecking({
            // notiCheckingToggle: "true",
            notiCheckingDate: moment()
          });

          localStorage.setItem(
            "notiChecking",
            JSON.stringify({
              userId: currentAuthUserFromSession,
              notiCheckingDate: moment()
            })
          )
          console.log("putNotiChecking 동작됨.");
        } catch (e) {
          // alert(e);
          console.log("userId is not matched");
        }
      } else
      {
        console.log("userId is not matched");
      }
    }

    gototab = () => {
      this.setState({
        tabLinkValue: 4,
        tabindicator: true
      });
    }

    render() {
      const { classes, theme, container, isAuthenticated, isdrawerToggle } = this.props;
      const { searchKeyword, isSearchToggle } = this.state;
      const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
      const customerType1_FromLocal = localStorage.getItem("customerType1");
      const customerType2_FromLocal = localStorage.getItem("customerType2");
      const loginType = localStorage.getItem("loginType");

      const drawer = (
        <div>
          <div className={classes.toolbar} />
          <MenuItem className={classes.menuitemTitle}>
            <div className={classes.drawerlinkword}>
              일감 분야
            </div>
          </MenuItem>
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_1}>
            <div className={classes.drawerlinkword}>
              4차 산업
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_1,
              })}
              onClick={this.handleExpandClick_1}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_1} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_1} component={RouterLink} to='/fieldform/3dprinter/projects' underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_1} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  3D 프린터
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_2} component={RouterLink} to="/fieldform/iot/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_2} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  IoT 및 센서
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_3} component={RouterLink} to="/fieldform/smartfactory/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_3} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  스마트 팩토리
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_3_1} component={RouterLink} to="/fieldform/arvr/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_3_1} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  AR, VR
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_1} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_1} component={RouterLink} to='/login' underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  3D 프린터
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_2} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  IoT 및 센서
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_3} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  스마트 팩토리
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_3_1} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  AR, VR
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_2}>
            <div className={classes.drawerlinkword}>
              설계 분야
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_2,
              })}
              onClick={this.handleExpandClick_2}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_2} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_4} component={RouterLink} to="/fieldform/autocad/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_4} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  오토 캐드
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_5} component={RouterLink} to="/fieldform/solidworks/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_5} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  솔리드 웍스
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_6} component={RouterLink} to="/fieldform/inventor/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_6} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  인벤터
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_7} component={RouterLink} to="/fieldform/catia/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_7} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  카티아
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_8} component={RouterLink} to="/fieldform/4iretc/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_8} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_2} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_4} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  오토 캐드
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_5} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  솔리드 웍스
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_6} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  인벤터
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_7} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  카티아
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_8} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_3}>
            <div className={classes.drawerlinkword}>
              PLC 자동화
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_3,
              })}
              onClick={this.handleExpandClick_3}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_3} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_9} component={RouterLink} to="/fieldform/melsec/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_9} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  melsec
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_10} component={RouterLink} to="/fieldform/ls/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_10} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  LS 산전
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_11} component={RouterLink} to="/fieldform/siemens/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_11} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  지멘스
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_12} component={RouterLink} to="/fieldform/plcetc/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_12} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_3} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_9} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  melsec
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_10} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  LS 산전
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_11} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  지멘스
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_12} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_4}>
            <div className={classes.drawerlinkword}>
              조립, 유지보수
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_4,
              })}
              onClick={this.handleExpandClick_4}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_4} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_13} component={RouterLink} to="/fieldform/machineassembly/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_13} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  장비 조립
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_14} component={RouterLink} to="/fieldform/machineharness/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_14} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  장비 배선
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_15} component={RouterLink} to="/fieldform/factoryharness/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_15} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  공장 배선
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_16} component={RouterLink} to="/fieldform/machinerepair/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_16} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  장비 유지, 보수
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_17} component={RouterLink} to="/fieldform/partsrepair/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_17} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  부품 수리
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_4} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_13} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  장비 조립
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_14} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  장비 배선
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_15} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  공장 배선
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_16} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  장비 유지, 보수
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_17} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  부품 수리
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_5}>
            <div className={classes.drawerlinkword}>
              가공 분야
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_5,
              })}
              onClick={this.handleExpandClick_5}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_5} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_18} component={RouterLink} to="/fieldform/lathemilling/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_18} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  범용 선반,밀링
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_19} component={RouterLink} to="/fieldform/cnclathe/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_19} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  CNC 선반
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_20} component={RouterLink} to="/fieldform/mct/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_20} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  MCT
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_21} component={RouterLink} to="/fieldform/engravingmachine/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_21} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  조각기
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_22} component={RouterLink} to="/fieldform/press/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_22} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  프레스, 판금
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_23} component={RouterLink} to="/fieldform/cam/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_23} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  Cam 프로그램
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_24} component={RouterLink} to="/fieldform/processingetc/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_24} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_5} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_18} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  범용 선반,밀링
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_19} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  CNC 선반
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_20} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  MCT
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_21} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  조각기
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_22} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  프레스, 판금
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_23} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  Cam 프로그램
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_24} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_6}>
            <div className={classes.drawerlinkword}>
              연마 분야
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_6,
              })}
              onClick={this.handleExpandClick_6}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_6} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_25} component={RouterLink} to="/fieldform/cylindricalgrinding/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_25} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  원통 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_26} component={RouterLink} to="/fieldform/planegrinding/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_26} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  평면 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_27} component={RouterLink} to="/fieldform/rotarygrinding/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_27} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  로터리 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_28} component={RouterLink} to="/fieldform/centerlessgrinding/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_28} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  센터리스 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_29} component={RouterLink} to="/fieldform/toolgrinding/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_29} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  공구 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_30} component={RouterLink} to="/fieldform/honing/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_30} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  호닝
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_31} component={RouterLink} to="/fieldform/profile/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_31} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  프로파일
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_32} component={RouterLink} to="/fieldform/grindingetc/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_32} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_6} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_25} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  원통 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_26} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  평면 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_27} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  로터리 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_28} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  센터리스 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_29} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  공구 연마
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_30} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  호닝
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_31} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  프로파일
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_32} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_7}>
            <div className={classes.drawerlinkword}>
              용접 및 마킹 분야
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_7,
              })}
              onClick={this.handleExpandClick_7}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_7} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_33} component={RouterLink} to="/fieldform/Argonwelding/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_33} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  알곤 용접
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_34} component={RouterLink} to="/fieldform/laserwelding/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_34} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  레이저 용접
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_35} component={RouterLink} to="/fieldform/lasermarking/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_35} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  레이저 마킹
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_36} component={RouterLink} to="/fieldform/weldingetc/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_36} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_7} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_33} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  알곤 용접
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_34} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  레이저 용접
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_35} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  레이저 마킹
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_36} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_8}>
            <div className={classes.drawerlinkword}>
              기타 (가공공정 관련)
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_8,
              })}
              onClick={this.handleExpandClick_8}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_8} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_37} component={RouterLink} to="/fieldform/gundrill/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_37} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  건드릴
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_38} component={RouterLink} to="/fieldform/heattreatment/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_38} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  열처리
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_39} component={RouterLink} to="/fieldform/plated/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_39} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  도금 및 아노다이징
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_40} component={RouterLink} to="/fieldform/3dmeasuring/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_40} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  3차원 측정
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_41} component={RouterLink} to="/fieldform/etcetc/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_41} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_8} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_37} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  건드릴
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_38} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  열처리
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_39} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  도금 및 아노다이징
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_40} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  3차원 측정
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_41} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_9}>
            <div className={classes.drawerlinkword}>
              통역, 번역
            </div>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.state.expanded_9,
              })}
              onClick={this.handleExpandClick_9}
            >
              <ExpandMoreIcon className={classes.expandMoreIcon}/>
            </IconButton>
          </MenuItem>
          {isAuthenticated
          ?
          <Collapse in={this.state.expanded_9} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_42} component={RouterLink} to="/fieldform/english/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_42} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  영어
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_43} component={RouterLink} to="/fieldform/chinese/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_43} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  중국어
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_44} component={RouterLink} to="/fieldform/japanese/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_44} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  일본어
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_45} component={RouterLink} to="/fieldform/vietnamese/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_45} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  베트남어
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_46} component={RouterLink} to="/fieldform/languageetc/projects" underline='none' color='inherit'>
              {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_46} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          :
          <Collapse in={this.state.expanded_9} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_42} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  영어
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_43} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  중국어
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_44} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  일본어
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_45} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  베트남어
                </div>
              </Link>
            </MenuItem>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange_46} component={RouterLink} to="/login" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  기타
                </div>
              </Link>
            </MenuItem>
          </Collapse>
          }
          <Divider />
          {isAuthenticated
          ?
          <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
            <Link className={classes.drawerlink} onClick={this.drawerhandleChange_47} component={RouterLink} to="/fieldform/factoryshare/projects" underline='none' color='inherit'>
            {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_47} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
              <div className={classes.drawerlinkword}>
                공장 공유
              </div>
            </Link>
          </MenuItem>
          :
          <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
            <Link className={classes.drawerlink} onClick={this.drawerhandleChange_47} component={RouterLink} to="/login" underline='none' color='inherit'>
              <div className={classes.drawerlinkword}>
                공장 공유
              </div>
            </Link>
          </MenuItem>
          }
          <Divider />
          {isAuthenticated
          ?
          <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
            <Link className={classes.drawerlink} onClick={this.drawerhandleChange_48} component={RouterLink} to="/fieldform/useddeal/projects" underline='none' color='inherit'>
            {/*<Link className={classes.drawerlink} onClick={this.drawerhandleChange_48} component={RouterLink} to='/upload' underline='none' color='inherit'>*/}
              <div className={classes.drawerlinkword}>
                중고 장터
              </div>
            </Link>
          </MenuItem>
          :
          <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
            <Link className={classes.drawerlink} onClick={this.drawerhandleChange_48} component={RouterLink} to="/login" underline='none' color='inherit'>
              <div className={classes.drawerlinkword}>
                중고 장터
              </div>
            </Link>
          </MenuItem>
          }
          <Divider />
          {isAuthenticated
          ?
          <div>
            <MenuItem className={classes.menuitem} onClick={this.handleExpandClick_0}>
              <div className={classes.drawerlinkword}>
                MY PAGE
              </div>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.expanded_0,
                })}
                onClick={this.handleExpandClick_0}
              >
                <ExpandMoreIcon className={classes.expandMoreIcon}/>
              </IconButton>
            </MenuItem>
            <Collapse in={this.state.expanded_0} timeout="auto" unmountOnExit>
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              {currentAuthUserFromSession && customerType2_FromLocal === "freelancer"
              ?
                <Link className={classes.drawerlink} onClick={this.drawerhandleChange} component={RouterLink} to={`/user/freelancer/${currentAuthUserFromSession}`} underline='none' color='inherit'>
                  <div className={classes.drawerlinkword}>
                    마이 페이지
                  </div>
                </Link>
                :
                null
              }
              {currentAuthUserFromSession && customerType2_FromLocal === "company"
              ?
                <Link className={classes.drawerlink} onClick={this.drawerhandleChange} component={RouterLink} to={`/user/company/${currentAuthUserFromSession}`} underline='none' color='inherit'>
                  <div className={classes.drawerlinkword}>
                    마이 페이지
                  </div>
                </Link>
                :
                null
              }
            </MenuItem>
            {currentAuthUserFromSession && loginType === "email"
            ?
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange} component={RouterLink} to="/myaccount" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  계정 변경
                </div>
              </Link>
            </MenuItem>
            :
            null
            }
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange} component={RouterLink} to="/notiSetting" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  알림 세팅
                </div>
              </Link>
            </MenuItem>
            {currentAuthUserFromSession === "ap-northeast-2:0eac84f9-d5d6-454a-8468-f46c99cef2ec"
            || currentAuthUserFromSession === "ap-northeast-2:1ef91eaf-bfd0-49eb-91ce-f70191165a07"
            ?
            <MenuItem className={classes.menuitem} onClick={this.hiddenDrawerClick}>
              <Link className={classes.drawerlink} onClick={this.drawerhandleChange} component={RouterLink} to="/projectadmin" underline='none' color='inherit'>
                <div className={classes.drawerlinkword}>
                  관리자 페이지
                </div>
              </Link>
            </MenuItem>
            :
            null
            }
            </Collapse>
          </div>
          :
          null
          }
          <Divider />
        </div>
      );

      //-----------------------------------------------------------
      // tabindicator가 true 일때 tabindicator 나오는 코드
      const tabs_1 = (
        <div>
          <Tabs
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            //indicatorColor="primary"
            classes={{indicator: classes.indicator}}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="학원소개" dir={theme.direction} to="/introduce" component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            <Tab label="입학안내" dir={theme.direction} to="/entrance" component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            <Tab label="교육과정" dir={theme.direction} to="/education" component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} */ 
            />
            <Tab label="문화체험" dir={theme.direction} to="/culture" component={RouterLink} className={classes.AppShellText} underline='none'/>
            {/*
            <Tab label="등록 업체" dir={theme.direction} to="/follow" component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
          </Tabs>
        </div>
      );

      // supplier 일때
      // tabindicator가 false 일때 tabindicator 안나오는 코드
      const tabs_2 = (
        <div>
          <Tabs
            classes={{ indicator: classes.hideIndicator }}
            value={this.state.tabLinkValue}
            onChange={this.handleChange}
            // indicatorColor="primary"
            // textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="학원소개" dir={theme.direction} to="/introduce" component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            <Tab label="입학안내" dir={theme.direction} to="/entrance" component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/',
              //state: {value : this.state.tabLinkValue}
              })} */  
            />
            <Tab label="교육과정" dir={theme.direction} to="/education" component={RouterLink} className={classes.AppShellText} underline='none' 
              /* onClick={() => this.props.history.push({
              pathname: '/design',
              //state: {value : this.state.tabLinkValue}
              })} */ 
            />
            <Tab label="문화체험" dir={theme.direction} to="/culture" component={RouterLink} className={classes.AppShellText} underline='none'/>
            {/*
            <Tab label="등록 업체" dir={theme.direction} to="/follow" component={RouterLink} className={classes.AppShellText} underline='none' 
              / onClick={() => this.props.history.push({
              pathname: '/law',
              //state: {value : this.state.tabLinkValue}
              })} / 
            />
            */}
          </Tabs>
        </div>
      );
      //-----------------------------------------------------------

      return (
        <div>
          {/* 네비게이션 바 */}
          <div className={classes.root}>
            <CssBaseline />
            {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
            {isAuthenticated
            ?
            <AppBar position="fixed" color="default" className={classes.appBar}>
              <Toolbar className={classes.toolBar} variant="dense"> {/* variant="dense" -> toolbar 크기 조정 가능 */}
              { isSearchToggle
                ?
                <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                  <Grid item sm={2} md={2} lg={2} xl={2}>
                    <IconButton
                      edge="start"
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="back"
                      onClick={this.handleAppbarBack}
                    >
                      <ArrowBackIosIcon className={classes.arrowBackIosIcon}/>
                      {/* <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" /> */}                    
                    </IconButton>
                  </Grid>
                  {/* 아래는 기존 Seach 아이콘 관련 */}
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <div className={classes.search}>
                      <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                        {/*<Grid item sm={10} md={10} lg={10} xl={10}>*/}
                        <Grid item sm={12} md={12} lg={12} xl={12}>
                          <TextField
                            className={classes.textFieldBorder}
                            InputLabelProps={{
                              classes: {
                                root: classes.inputRoot
                              },
                            }}
                            InputProps={{
                              classes: {
                                input: classes.inputInput,
                              },
                              endAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    edge="start"
                                    className={classes.iconButton}
                                    color="inherit"
                                    aria-label="back"
                                    onClick={this.handleSearchBtn}
                                  >
                                    <SearchIcon className={classes.searchIcon}/>        
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            placeholder="전체 검색"
                            // 상태 변화가 일어나면 handleValueChange함수가 실행 되게끔 하였고
                            // 함수안의 state 값이 변경된 이름값으로 변경된다.
                            // 실제로 변경된 값은 value라는 값으로 화면에 출력이 된다.
                            name="searchKeyword"
                            value={searchKeyword}
                            onChange={this.handleValueChange}
                            autoComplete="off"  // 추후 자동완성기능 넣으려면 바꾸기
                            onKeyPress={(e) => this.handleKeyPress(e)}
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        {/*
                        <Grid item sm={2} md={2} lg={2} xl={2} align='flex-start'>
                          <Typography variant="body2" style={{ cursor: 'pointer' }}
                            variant="h6" noWrap onClick={this.handleSearchClear}
                          >
                            <ClearIcon className={classes.clearIcon}/>
                          </Typography>
                        </Grid>
                        */}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                :
                <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                  <Grid item sm={2} md={2} lg={2} xl={2}>
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={this.handleDrawerToggle}
                      className={classes.navIconHide}
                    >
                      <MenuIcon className={classes.menuIcon}/>
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={5} md={5} lg={5} xl={5}>
                    <Link className={classes.drawerlink} onClick={this.gototab} component={RouterLink} to="/" underline='none' color='inherit'>
                      <CardMedia
                        className={classes.logoMedia}
                        // image="/static/images/logo/app.svg"
                        // image="/images/logo/app.svg"
                        image="/images/logo/mmlogo1.png"
                        aria-label="Recipe" 
                      />
                    </Link>
                  </Grid>
                  <Grid container item xs={4} sm={2} md={2} lg={2} xl={2} alignItems="flex-end">
                    <Link component={RouterLink} to="/notiGetting" underline='none' color='inherit'>
                      <IconButton  
                        edge="start"
                        className={classes.iconButton}
                        color="inherit"
                        aria-label="back"
                        onClick={this.handleNotification}
                      >
                        {this.state.notificationNumbers
                        ?
                        <Badge className={classes.searchBadge} badgeContent={this.state.notificationNumbers} color="secondary">
                          <NotificationsNoneOutlinedIcon/>
                        </Badge>
                        :
                        <NotificationsNoneOutlinedIcon className={classes.notificationsIcon}/>
                        }
                      </IconButton>
                    </Link>
                  </Grid>
                  {/*
                  <Grid item sm={2} md={2} lg={2} xl={2}> 
                      <IconButton  
                        edge="start"
                        className={classes.iconButton}
                        color="inherit"
                        aria-label="back"
                        onClick={this.handleSearch}  // 클릭시 Search 팝업창 나오게끔 하기
                      >
                        <SearchIcon className={classes.searchIcon}/>
                      </IconButton>
                  </Grid>
                  */}
                  <Grid container item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <IconButton
                      edge="start"
                      className={classes.iconButton}
                      color="inherit"
                      aria-label="back"
                      onClick={this.handleLogout}
                    >
                      <Typography className={classes.login}>로그아웃</Typography>
                      {/*<SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" />*/}
                    </IconButton>
                  </Grid>
                </Grid>
              }
              </Toolbar>
              <Divider />
            </AppBar>
            :
            <AppBar position="fixed" color="default" className={classes.appBar3}>
              <Toolbar className={classes.toolBar} variant="dense"> {/* variant="dense" -> toolbar 크기 조정 가능 */}
                <Grid container spacing={0} direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    {/*
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={this.handleDrawerToggle}
                      className={classes.navIconHide}
                    >
                      <MenuIcon className={classes.menuIcon}/>
                    </IconButton>
                    */}
                  </Grid>
                  <Grid item xs={3} sm={5} md={5} lg={5} xl={5}>
                    <Link className={classes.drawerlink} onClick={this.gototab} component={RouterLink} to="/" underline='none' color='inherit'>
                      <CardMedia
                        className={classes.logoMedia}
                        // image="/static/images/logo/app.svg"
                        // image="/images/logo/app.svg"
                        image="/images/logo/mmlogo1.png"
                        aria-label="Recipe" 
                      />
                    </Link> 
                  </Grid>
                  <Grid container item xs={4} sm={2} md={2} lg={2} xl={2} alignItems="flex-end">
                    {/*
                    // 나중에 회원가입 살리기
                    <Link component={RouterLink} to="/chooseaccount" underline='none' color='inherit'>
                      <IconButton
                        edge="start"
                        className={classes.iconButton}
                        color="inherit"
                        aria-label="back"
                        // onClick={this.handleSubmit}
                      >
                        <Typography className={classes.login}>회원가입</Typography>
                        {/<SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" />/}
                      </IconButton>
                    </Link>
                    */}
                  </Grid>
                  <Grid container item xs={3} sm={3} md={3} lg={3} xl={3} alignItems="flex-end">
                    {/*
                    // 나중에 로그인 살리기
                    <Link component={RouterLink} to="/login" underline='none' color='inherit'>
                      <IconButton
                        edge="start"
                        className={classes.iconButton}
                        color="inherit"
                        aria-label="back"
                        // onClick={this.handleSubmit}
                      >
                        <Typography className={classes.login}>로그인</Typography>
                        {/<SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#FFFFFF" />/}
                      </IconButton>
                    </Link>
                    */}
                  </Grid>
                </Grid>
              </Toolbar>
              <Divider />
            </AppBar>
            }
            {isAuthenticated
            ?
            <AppBar position="fixed" className={classes.appBar2} color="default">
              <Toolbar className={classes.toolBar2} variant="dense">
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    {this.state.tabindicator ? tabs_1 : tabs_2}
                  </Grid>
                </Grid>
              </Toolbar>
              <Divider />
            </AppBar>
            :
            <AppBar position="fixed" className={classes.appBar4} color="default">
              <Toolbar className={classes.toolBar2} variant="dense">
                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    {this.state.tabindicator ? tabs_1 : tabs_2}
                  </Grid>
                </Grid>
              </Toolbar>
              <Divider />
            </AppBar>
            }
            {isAuthenticated
            ?
            <nav className={classes.drawer}>
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden mdUp implementation="css">  {/* 원래는 smUp */}
              {/* mobileOpen값이 true일때 Drawer가 보일수 있도록 한다 */}
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  // open={this.state.mobileOpen}
                  open={isdrawerToggle}
                  onClose={this.handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              {/* PC버전에서 왼쪽에 drawbar 적용 코드 */}
              <Hidden smDown implementation="css">  {/* 원래는 xsDown */}
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
              {/* */}
            </nav>
            :
            <div></div>
            }
            {/*{console.log('First:' + this.state.value)}*/}
            <main className={classes.content}>
              <div className={classes.toolbar}/>
              {/*<div position="fixed" className={classes.appBar}>*/}
                {/* 실제 사용자가 선택한 링크 (App.js의 라우팅과 연결) */}
                {/*<div id="content" style={{margin: 'auto', marginTop: '5px'}}>*/}
                <div id="tablinkHammer1"> {/* add ID to your element */} 
                  {React.cloneElement(this.props.children)}
                </div>
              {/*</div>*/}
            </main>
          </div>
        </div>
      );
    }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //isKlaytnLoggedIn: state.auth.isKlaytnLoggedIn,
  isAuthenticated: state.auth.isAuthenticated,
  // currentUser: state.auth.currentUser,
  // searchKeyword: state.upload.searchKeyword,
  // isSearchToggle: state.upload.isSearchToggle,
  isdrawerToggle: state.upload.isdrawerToggle,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
  //integrateWallet: (privateKey) => dispatch(authActions.integrateWallet(privateKey)),
  //removeWallet: () => dispatch(authActions.removeWallet()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
  // uploadsearch: (searchKeyword) => dispatch(uploadActions.uploadsearch(searchKeyword)),
  // searchtoggletrue: () => dispatch(uploadActions.searchtoggletrue()),
  // searchtogglefalse: () => dispatch(uploadActions.searchtogglefalse()),
  drawertoggletrue: () => dispatch(uploadActions.drawertoggletrue()),
  drawertogglefalse: () => dispatch(uploadActions.drawertogglefalse()),
})


MainAppShell = withStyles(styles, {withTheme: true})(MainAppShell);
MainAppShell = withRouter(MainAppShell);
export default connect(mapStateToProps, mapDispatchToProps)(MainAppShell)